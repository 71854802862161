//= require ./services.module.js
angular.module("services").factory("DuplicateChecker", [
  "$http",
  "$q",
  "ResourceDependencyInjector",
  function ($http, $q, ResourceDependencyInjector) {
    var canceler;

    function getDuplicates(queryArguments, person) {
      if (canceler) {
        canceler.resolve();
      }
      canceler = $q.defer();

      var query = _.compact(queryArguments).join(" ");

      return $http
        .get("/person/check_duplicates.json", {
          params: {
            q: query.replace(/\//g, " "),
            person_id: person.id,
            person_type: person.resourceType,
          },
          timeout: canceler.promise,
        })
        .then(function (response) {
          // append the query as first autocomplete suggestion
          return _.map(response.data.data, function (duplicate) {
            var resource = ResourceDependencyInjector.inject(duplicate.resource_type);
            return new resource(duplicate);
          });
        })
        .catch(angular.noop);
    }

    function initialize(scope, person) {
      var resource = ResourceDependencyInjector.inject(person.serviceName || person.constructor.name);
      scope.person = person;
      var oldResourceDuplicates = resource.duplicatesWatch;

      function duplicatesWatch(nv) {
        if (!_.some(nv)) {
          return;
        }

        getDuplicates(nv, person).then(function (duplicates) {
          scope.duplicates = {};
          _.each(duplicates, function (duplicate) {
            scope.duplicates[duplicate.resourceType] = scope.duplicates[duplicate.resourceType] || [];
            scope.duplicates[duplicate.resourceType].push(duplicate);
          });

          person.$hasDuplicates = _.keys(scope.duplicates).length > 0;
        });
      }

      scope.$doCheck = function () {
        if (oldResourceDuplicates != resource.duplicatesWatch) {
          duplicatesWatch(resource.duplicatesWatch);
          oldResourceDuplicates = resource.duplicatesWatch;
        }
      };
    }

    return {
      initialize: initialize,
      getDuplicates: getDuplicates,
    };
  },
]);
