export default class AdvancedSearchSelectorCtrl {
  public modifiers: Array<{name: string, value: string}>;
  public fields: any;
  public selectedFields: string[];
  public fieldCount: number;
  static $inject = ["defaultSelectorFields"];

  constructor (
    private defaultSelectorFields: any
  ) {
    this.modifiers = [
      { name: "MUST", value: "must" },
      { name: "OR", value: "or" },
      { name: "NOT", value: "not" },
    ];
  }

  public $onInit() {
    this.defaultSelectorFields.then((fields) => {
      this.fields = fields;
      if (this.selectedFields) {
        this.fields = _.pick(this.fields, this.selectedFields);
      }

      this.fieldCount = _.keys(this.fields).length;
    });
  };

  public getStaticList(fieldName) {
    return _.pick(this.fields[fieldName], "staticList").staticList;
  };

  public isGenericField(fieldName) {
    return _.pick(this.fields[fieldName], "autocomplete").autocomplete;
  };
};