import { Transition } from "@uirouter/angularjs";

export default class CompaniesDocumentsCtrl {
  public openDocument: { openDocumentFullScreen: (documents: any) => void } | undefined;
  public company: any;
  public closeSidebar: { close: () => void };
  static $inject = ["$transition$", "SidebarService"];

  constructor(private $transition$: Transition, private SidebarService: any) {
    this.closeSidebar = this.SidebarService.closeSidebar();
  }

  public $onInit () {
    this.openDocument = _.where(this.company.documents, {
      id: parseInt(this.$transition$.params().document_id) || this.company.documents[0],
    })[0];
  };
};