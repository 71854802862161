import ChooseComponayModalCtrl from "./choose-company-modal.controller";
import chooseCompanyModalTemplate from "./choose-company-modal.template.html";

angular.module("persons.modals").component("chooseCompany", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: chooseCompanyModalTemplate,
  controller: ChooseComponayModalCtrl,
});
