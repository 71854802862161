import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { MaritalStatus } from "./marital_status.service";

angular
  .module("marital_statuses")
  .factory("MaritalStatusesSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(MaritalStatus, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("MaritalStatusFactory", MaritalStatus);
