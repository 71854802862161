//= require ./values.module.js

angular
  .module("values")

  .constant("freeDateOptions", [
    { name: "FROM_NOW", code: 1 },
    { name: "BY_ARRANGEMENT", code: 2 },
    { name: "TWO_WEEKS_UNTIL_MONTH_END", code: 3 },
    { name: "FOUR_WEEKS_UNTIL_MONTH_END", code: 4 },
    { name: "FOUR_WEEKS_UNTIL_QUARTER_END", code: 5 },
    { name: "TWO_MONTHS_UNTIL_MONTH_END", code: 11 },
    { name: "THREE_MONTHS_UNTIL_MONTH_END", code: 6 },
    { name: "SIX_MONTHS_UNTIL_MONTH_END", code: 7 },
    { name: "SIX_MONTHS_UNTIL_QUARTER_END", code: 8 },
    { name: "TWELVE_MONTHS_UNTIL_MONTH_END", code: 9 },
    { name: "TWELVE_MONTHS_UNTIL_QUARTER_END", code: 10 },
  ]);
