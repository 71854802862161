import { TransitionService, Transition } from "@uirouter/angularjs";

export default class CandidatesDocumentsViewCtrl {
  private closeSidebar: () => void;
  private openDocument: any;
  private candidate: any;
  private $transition$: Transition;
  private openDocumentId: number;

  static $inject = ["SidebarService", "$transitions",];

  constructor (private SidebarService: any, private $transitions: TransitionService) {
    this.closeSidebar = SidebarService.closeSidebar;
    $transitions.onSuccess({}, (transition) => {
      if (this.$transition$) {
        this.openDocumentId = parseInt(this.$transition$.params().document_id);
      }
    });
  }

  public openDocumentFullScreen (event) {
    event.preventDefault();
    if (!this.openDocument) {
      return;
    }
    this.openDocument.openDocumentFullScreen(this.candidate.documents);
  };
}