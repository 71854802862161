import { ProjectsIAEditCtrl } from "./projects-ia-edit-view.controller";
import projectsIaEditViewTemplate from "./projects-ia-edit-view.template.html";

angular.module("projects.views").component("projectsIAEditView", {
  bindings: {
    project: "<projectResponse",
    transactionBases: "<transactionBasesResponse",
    scrollspyScope: "<",
    users: "<",
    jobTypes: "<",
  },
  template: projectsIaEditViewTemplate,
  controller: ProjectsIAEditCtrl,
});
