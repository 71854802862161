export default class GenericStaticSelectorCtrl {
  public condition: { query?: string | number};
  public staticList: any[];
  
  constructor () {}

  public $onInit() {
    var entryId = _.isEmpty(this.condition.query) ? undefined : Number(this.condition.query);
    this.condition.query = entryId ? _.findWhere(this.staticList, { id: entryId }) : this.condition.query;
  };
}
