import selectActionModalCtrl from "./select-action-modal.controller";
import selectActionModalTemplate from "./select-action-modal.template.html";

angular.module("actions.modals").component("selectActionModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: selectActionModalTemplate,
  controller: selectActionModalCtrl,
});
