import { initContract } from "@ts-rest/core";

import { TCountry } from "../countries";
import { TBaseResource } from "../../api-routing";

const c = initContract();
const CountriesSchema = c.type<TBaseResource<TCountry[]>>();
export const countriesContract = c.router({
  query: {
    method: "GET",
    path: "/countries",
    responses: {
      200: CountriesSchema,
    },
    summary: "Get all countries",
  },
});
