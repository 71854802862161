import { IFilterService } from "angular";
export default class CompaniesIndexCtrl {
  public loader: () => any; 
  public companiesLazyLoading: boolean;
  public attributes: any;
  public stateParams: any;
  public companies: any;
  static $inject = ["makeCollectionProxy", "CompaniesFactory", "SavedSearchesFactory", "$rootScope", "$filter", "PrsmTableAttributeService"];

  constructor(
    private makeCollectionProxy: any,
    private Company: any,
    private SavedSearch: any,
    private $rootScope: IExtendedRootScopeService,
    private $filter: IFilterService,
    private PrsmTableAttributeService: any,
  ) {
    this.$rootScope.setPageTitle(this.$filter("translate")("COMPANY"));
  }

  public $onInit() {
    this.attributes = new this.PrsmTableAttributeService(
      "companies-index-table-attributes",
      this.Company.defaultTableAttributes
    ).getAttributes();

    if (this.stateParams.saved_search_id) {
      this.loader = this.SavedSearch.results.bind(this.Company);
    } else {
      this.loader = this.Company.query.bind(this.Company);
    }

    this.makeCollectionProxy(this.loader, this.stateParams)
      .then((response) => {
        this.companies = response;
      })
      .then(() => {
        this.companiesLazyLoading = false;
      });
  };
};