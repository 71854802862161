import { ProjectsIAShowCtrl } from "./projects-ia-show-view.controller";
import projectsIaShowViewTemplate from "./projects-ia-show-view.template.html";

angular.module("projects.views").component("projectsIAShowView", {
  bindings: {
    project: "<projectResponse",
    scrollspyScope: "<",
    $state$: "<",
  },
  template: projectsIaShowViewTemplate,
  controller: ProjectsIAShowCtrl,
});
