import { initContract } from "@ts-rest/core";

import { TDrivingLicence } from "../driving_licences";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const DrivingLicenceSchema = c.type<TBaseResource<TDrivingLicence[]>>();
export const drivingLicencesContract = c.router({
  query: {
    method: "GET",
    path: "/driving_licences",
    responses: {
      200: DrivingLicenceSchema,
    },
    summary: "Get all driving licenses",
  },
});
