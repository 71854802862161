import PrioritySearchCtrl from "./priority-search.controller";
import prioritySearchTemplate from "./priority-search.template.html";

angular.module("directives.advanced_search").component("prioritySearch", {
  bindings: {
    advancedQuery: "=",
  },
  template: prioritySearchTemplate,
  controller: PrioritySearchCtrl,
});
