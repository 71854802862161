export default class CompaniesTableBodyCtrl {
    public isCompaniesTable: boolean;
    public draggingHelper: () => (link: string) => void;
    public startCallback: (event: any, ui: any, title: any) => () => void;

    static $inject = ["$templateCache", "QuicklistsFactory", "CompaniesFactory"];

    constructor(
        private $templateCache: ng.ITemplateCacheService,
        private Quicklist: any,
        public Company: any
    ) {
        this.$templateCache.put("info-popover-company", "<info-popover resource='company'></info-popover>");

        angular.extend(this, {
            draggingHelper: this.Quicklist.draggingHelper,
            startCallback: this.Quicklist.startCallback,
        });
        this.isCompaniesTable = true;
    }
};