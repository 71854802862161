import "angular";
import "jquery-ui/dist/jquery-ui.min";
import "jquery-ui/ui/plugin";
import "jquery-ui/ui/widgets/mouse";
import "jquery-ui/ui/widgets/draggable";
import "angularjs-rails-resource";
import "angularjs-rails-resource/extensions/snapshots";
import "@uirouter/angularjs";
import "ui-select";
import "tinymce/tinymce";
import "tinymce/themes/modern/theme";
import "tinymce/plugins/lists/plugin";
import "tinymce/plugins/advlist/plugin";
import "tinymce/plugins/charmap/plugin";
import "tinymce/plugins/paste/plugin";
import "angular-ui-tinymce";
import "angular-ui-bootstrap";
import "angular-animate";
import "angular-http-auth";
import "angular-sanitize";
import "angular-mocks";
import "angular-dragdrop";
import "angular-file-upload";
import "angular-scroll";
import "ngprogress/build/ngProgress.min";
import "ng-infinite-scroll";
import "underscore";
import "angular-toarrayfilter";
import "angular-hotkeys/build/hotkeys.min";
import "intl-tel-input/build/js/utils";
import "intl-tel-input";
import "betsol-ng-intl-tel-input";
import "ng-onload";
import "ng-idle";
import "angular-poller";
import "angular-cookies";
import "chart.js";
import "angular-chart.js";
import "chartjs-plugin-labels";
import "chartjs-gauge";
import "moment";
import "moment/locale/de";
import "angular-moment";
import "angular-dynamic-locale";
import "bootstrap-daterangepicker";
import "angular-daterangepicker/js/angular-daterangepicker.min";
import "angular-translate";
import "angular-translate/dist/angular-translate-loader-static-files/angular-translate-loader-static-files";
import "orgchart";
import "deep-diff";

import "./action_types/index";
import "./actions/index";
import "./cancelation_periods/index";
import "./candidates/index";
import "./candidates_projects/index";
import "./career_levels/index";
import "./companies/index";
import "./companies_transaction_bases/index";
import "./countries/index";
import "./courses/index";
import "./dashboards/index";
import "./contract_drafts/index";
import "./deals/index";
import "./directives/index";
import "./departments/index";
import { Department } from "./departments/services/departments.service";
import "./hierarchies/index";
import "./documents/index";
import "./driving_licences/index";
import "./employees/index";
import "./employees_candidates_projects/index";
import "./expert_profiles/index";
import "./filters/index";
import "./functional_areas/index";
import "./graduations/index";
import "./highschools/index";
import "./invoices/index";
import "./job_categories/index";
import "./job_offers/index";
import "./job_types/index";
import "./languages/index";
import "./leadership_experiences/index";
import "./locations/index";
import "./login/index";
import "./marital_statuses/index";
import "./modals/index";
import "./organisation_units/index";
import "./outlook_delivery/index";
import "./persons/index";
import "./persons_graduations/index";
import "./priorities/index";
import "./project_reportings/index";
import "./projects/index";
import "./provisions/index";
import "./quicklists/index";
import "./regions/index";
import "./researcher_commissions/index";
import "./saved_searches/index";
import "./sendouts/index";
import "./services/index";
import "./tags/index";
import "./textkernel/index";
import "./trackings/index";
import "./transaction_bases/index";
import "./users/index";
import "./values/index";
import "./working_relationships/index";
import { JobCategory } from "./job_categories/services/job_categories.service";
import { Priority } from "./priorities/services/priority.service";
import { Hierarchy } from "./hierarchies/services/hierarchies.service";
import { ActionType } from "./action_types/services/action_types.service";
import { JobType } from "./job_types/services/job_types.service";

import emptyTemplate from "./directives/layout/empty-tmpl.html";

angular
  .module("app", [
    "actions",
    "action_types",
    "companies",
    "transaction_bases",
    "companies_transaction_bases",
    "trackings",
    "candidates",
    "employees",
    "projects",
    "candidates_projects",
    "employees_candidates_projects",
    "expert_profiles",
    "sendouts",
    "marital_statuses",
    "leadership_experiences",
    "quicklists",
    "tags",
    "project_reporting",
    "dashboards",
    "saved_searches",
    "directives.advanced_search",
    "components.money",
    "languages",
    "countries",
    "jobCategories",
    "departments",
    "hierarchies",
    "priorities",
    "job_offers",
    "job_types",
    "driving_licences",
    "regions",
    "career_levels",
    "functional_areas",
    "courses",
    "highschools",
    "graduations",
    "cancelation_periods",
    "persons_graduations",
    "documents",
    "working_relationships",
    "researcher_commissions",
    "locations",
    "contract_drafts",
    "deals",
    "organisation_units",
    "provisions",
    "invoices",
    "login",
    "directives",
    "services",
    "modals",
    "persons.modals",
    "persons.components",
    "ui.router",
    "rails",
    "ngAnimate",
    "ngSanitize",
    "ui.bootstrap",
    "ui.bootstrap.dropdown",
    "ui.bootstrap.popover",
    "ui.bootstrap.datepicker",
    "ui.bootstrap.pagination",
    "ui.bootstrap.tooltip",
    "daterangepicker",
    "angularMoment",
    "ui.select",
    "ui.tinymce",
    "ngProgress",
    "infinite-scroll",
    "ngDragDrop",
    "duScroll",
    "angular-toArrayFilter",
    "cfp.hotkeys",
    "ngOnload",
    "ngIdle",
    "emguo.poller",
    "outlook.delivery",
    "betsol.intlTelInput",
    "ngCookies",
    "chart.js",
    "textkernel",
    "pascalprecht.translate",
    "services",
    "values",
    "values.routing-options",
    "tmh.dynamicLocale",
  ])
  .config([
    "$httpProvider",
    function ($httpProvider) {
      $httpProvider.defaults.headers.common["X-CSRF-Token"] = $("meta[name=csrf-token]").attr("content");
    },
  ])

  .config([
    "$compileProvider",
    function ($compileProvider) {
      $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|callto|skype):/);
      // Angular before v1.2 uses $compileProvider.urlSanitizationWhitelist(...)
    },
  ])

  .config([
    "pollerConfig",
    function (pollerConfig) {
      pollerConfig.stopOn = "$stateChangeStart"; // If you use ui-router.
      pollerConfig.resetOn = "$stateChangeStart";
    },
  ])

  .config([
    "railsSerializerProvider",
    function (railsSerializerProvider) {
      railsSerializerProvider.exclusionMatchers("$");
    },
  ])

  .config([
    "intlTelInputOptions",
    function (intlTelInputOptions) {
      angular.extend(intlTelInputOptions, {
        defaultCountry: "de",
        preferredCountries: ["de", "at", "ch", "it"],
        autoFormat: true,
        autoPlaceholder: true,
      });
    },
  ])

  .config([
    "ChartJsProvider",
    "ChartColors2021",
    function (ChartJsProvider, ChartColors2021) {
      ChartJsProvider.setOptions({ plugins: { labels: false }, colors: ChartColors2021 });
    },
  ])

  .config([
    "$logProvider",
    function ($logProvider) {
      if (window.environment != "development") {
        $logProvider.debugEnabled(false);
      }
    },
  ])

  //for DatePicker options
  .config([
    "uibDatepickerConfig",
    function (uibDatepickerConfig) {
      uibDatepickerConfig.startingDay = 1;
    },
  ])

  .config([
    "$translateProvider",
    function ($translateProvider) {
      $translateProvider.useStaticFilesLoader({
        prefix: `${process.env.PUBLIC_PATH}translations/`,
        suffix: `-${process.env.CACHE_BREAKER}.json`,
      });

      $translateProvider.preferredLanguage("de");
      $translateProvider.useSanitizeValueStrategy("sanitize");
      $translateProvider.useSanitizeValueStrategy("sanitizeParameters");

      $translateProvider.forceAsyncReload(true);
    },
  ])

  .config([
    "$stateProvider",
    "$urlRouterProvider",
    "$stateGeneratorProvider",
    "$provide",
    "candidateOpts",
    "employeeOpts",
    "projectOpts",
    "companyOpts",
    "expertProfileOpts",
    "jobOfferOpts",
    "savedSearchOpts",
    function (
      $stateProvider,
      $urlRouterProvider,
      $stateGeneratorProvider,
      $provide,
      candidateOpts,
      employeeOpts,
      projectOpts,
      companyOpts,
      expertProfileOpts,
      jobOfferOpts,
      savedSearchOpts
    ) {
      $stateProvider
        .state("login", {
          url: "/login?redirect_to",
          component: "loginPageView",
          unauthenticated: true,
          isLoginPage: true,
          params: {
            toState: "root.home", // default state to proceed to after login
            toParams: {},
          },
        })
        .state("whiteboard-presenter", {
          url: "/whiteboard-presenter?user_id&teams[]",
          component: "whiteboardPresenter",
        })
        .state("actions", {
          url: "/actions/:action_id?params&referrer",
          resolve: {
            action: [
              "ActionsFactory",
              "$transition$",
              function (Action, $transition$) {
                var params = _.pick($transition$.params(), _.identity);
                if (params.action_id) {
                  return Action.get(params.action_id);
                }
              },
            ],
          },
          onEnter: [
            "RedirectLocation",
            "$transition$",
            "$location",
            "action",
            function (RedirectLocation, $transition$, $location, action) {
              var params = _.pick($transition$.params(), _.identity);

              setTimeout(function () {
                action.openActionModal();
              }, 500);

              if (RedirectLocation.decode(params.referrer)) {
                $location.path(RedirectLocation.decode(params.referrer)).search(params.params);
              } else {
                $location.path("/");
              }
            },
          ],
        })
        .state("deals", {
          url: "/deals/:deal_id?params&referrer",
          resolve: {
            deal: [
              "DealsFactory",
              "$transition$",
              function (Deal, $transition$) {
                var params = _.pick($transition$.params(), _.identity);

                if (params.deal_id) {
                  return Deal.get(params.deal_id);
                }
              },
            ],
          },
          onEnter: [
            "RedirectLocation",
            "$transition$",
            "$location",
            "deal",
            function (RedirectLocation, $transition$, $location, deal) {
              var params = _.pick($transition$.params(), _.identity);

              setTimeout(function () {
                deal.edit();
              }, 500);

              if (RedirectLocation.decode(params.referrer)) {
                $location.path(RedirectLocation.decode(params.referrer)).search(params.params);
              } else {
                $location.path("/");
              }
            },
          ],
        })
        .state("root", {
          abstract: true,
          component: "rootView",
          params: {
            action_id: { inherit: true, squash: true },
          },
          resolve: {
            users: [
              "UsersFactory",
              function (User) {
                return User.getAll();
              },
            ],
            user: [
              "Session",
              function (Session) {
                return Session.user;
              },
            ],
            action_types: [
              function () {
                return ActionType.getAll();
              },
            ],
            jobTypes: [
              function () {
                return JobType.getAll();
              },
            ],
            jobCategories: [
              function () {
                return JobCategory.getAll();
              },
            ],
            departments: [
              function () {
                return Department.getAll();
              },
            ],
            hierarchies: [
              function () {
                return Hierarchy.getAll();
              },
            ],
            priorities: [
              function () {
                return Priority.getAll();
              },
            ],
            locale: [
              "Session",
              "$translate",
              function (Session, $translate) {
                // LOAD FORM ONE FILE TO IMPROVE PERFORMANCE
                //$translatePartialLoader.addPart("unified");

                var lang = Session.user.preferredLanguage();
                return $translate.use(lang);
              },
            ],
          },
        })
        .state("root.home", {
          url: "/",
          component: "dashboards",
          resolve: {},
        })
        .state("root.project_reportings", {
          url: "/project-reportings?user_id&year",
          component: "projectReportings",
          resolve: {
            stateParams: [
              "$transition$",
              function ($transition$) {
                // remove undefined params
                return _.pick($transition$.params(), _.identity);
              },
            ],
            user: [
              "UsersFactory",
              "stateParams",
              "Session",
              function (User, stateParams, Session) {
                var params = _.defaults(stateParams, { user_id: Session.user.id });
                return User.get(params.user_id);
              },
            ],
            kpisPerYear: [
              "user",
              "moment",
              "stateParams",
              function (user, moment, stateParams) {
                var params = _.defaults(stateParams, { year: moment().year() });
                if (!_.isEmpty(user.kpis)) {
                  return user.kpis;
                } else {
                  return user.loadKpisPerYear(params);
                }
              },
            ],
            options: [
              "kpisPerYear",
              function (kpisPerYear) {
                return { selectedYear: kpisPerYear.year };
              },
            ],
          },
        })
        .state("root.whiteboard", {
          url: "/whiteboard?user_id",
          component: "whiteboard",
          resolve: {
            whiteboardConfig: [
              "UsersFactory",
              "Session",
              function (User, Session) {
                return User.$get("users/" + Session.user.id + "/whiteboard_entries.json");
              },
            ],
          },
        })
        .state("root.project_pipeline", {
          url: "/project-pipeline?user_id",
          component: "projectPipeline",
          resolve: {
            projectPipelineConfig: [
              "UsersFactory",
              "Session",
              function (User, Session) {
                return User.$get("users/" + Session.user.id + "/whiteboard_entries.json");
              },
            ],
          },
        });

      // Candidates Table

      $stateProvider.state("root.candidates", {
        url: "/candidates?additional_pages&q&advanced&saved_search_id&sort&document_id",
        component: "candidatesIndexView",
        resolve: angular.merge(angular.copy(candidateOpts.requirements), angular.copy(savedSearchOpts.requirements)),
        onExit: [
          "CandidatesHotkeys",
          function (CandidatesHotkeys) {
            CandidatesHotkeys.deregisterHotkeys();
          },
        ],
        onEnter: [
          "CandidatesHotkeys",
          function (CandidatesHotkeys) {
            CandidatesHotkeys.registerHotkeys();
          },
        ],
        params: {
          additional_pages: {
            value: "0",
            squash: true,
          },
        },
      });

      $stateGeneratorProvider
        .addStates("root.candidates", {
          viewLayerID: "deckPrimary",
          params: candidateOpts,
          urlParamName: ":candidate_id",
          subDeckOpen: true,
        })
        .addStates("root.candidates.show", ["projects", "deckSecondary", projectOpts, ":project_id"])
        .addRoundtrip("root.candidates.show.projects.show.job_offer", {
          paramName: ":job_offer_id",
          url: "/job_offer",
          redirect: "root.projects.show.job_offer",
        })
        .addRoundtrip("root.candidates.show.projects.show.company", {
          paramName: ":company_id",
          url: "/company",
          redirect: "root.companies",
        })
        .addRoundtrip("root.candidates.show.projects.show.employee", {
          paramName: ":employee_id",
          url: "/employee",
          redirect: "root.companies.list.employees",
        })
        .addRoundtrip(
          "root.candidates.show.projects.show.expert_profile",
          angular.extend({ redirect: "root.projects.show.expert_profile" }, expertProfileOpts.urlParameters)
        )
        .addRoundtrip(
          "root.candidates.show.projects.show.projects",
          angular.extend({ redirect: "root.candidates.show.projects" }, { paramName: ":project_id", url: "/projects" })
        )
        .addRoundtrip(
          "root.candidates.show.projects.show.candidate",
          angular.extend(
            { redirect: "root.candidates.show.projects" },
            { paramName: ":candidate_id", url: "/candidate" }
          )
        )
        .addStates("root.candidates.show", {
          stateName: "expert_profile",
          viewLayerID: "deckSecondary",
          params: expertProfileOpts,
          urlParamName: expertProfileOpts.urlParameters.paramName,
          url: expertProfileOpts.urlParameters.url + "?" + expertProfileOpts.urlParameters.queryParams,
        })
        .addRoundtrip(
          "root.candidates.show.expert_profile.edit.projects",
          angular.extend({ redirect: "root.candidates.show.projects" }, { paramName: ":project_id", url: "/projects" })
        )
        .addRoundtrip(
          "root.candidates.show.expert_profile.new.projects",
          angular.extend({ redirect: "root.candidates.show.projects" }, { paramName: ":project_id", url: "/projects" })
        )
        .addRoundtrip(
          "root.candidates.show.expert_profile.edit.expert_profile",
          angular.extend({ redirect: "root.candidates.show.expert_profile" }, expertProfileOpts.urlParameters)
        )
        .addStates("root.candidates.show", ["companies", "deckSecondary", companyOpts, ":company_id"])
        .addRoundtrip("root.candidates.show.companies.show.employees", {
          paramName: ":employee_id",
          url: "/employees",
          redirect: "root.companies.show.employees",
        })
        .addRoundtrip("root.candidates.show.companies.show.projects", {
          paramName: ":project_id",
          url: "/projects",
          redirect: "root.companies.show.projects",
        })
        .addRoundtrip("root.candidates.show.companies.show.candidates", {
          paramName: ":candidate_id",
          url: "/candidates",
          redirect: "root.candidates.show.companies",
        })
        .addRoundtrip(
          "root.expert_profile",
          angular.extend({ redirect: "root.candidates.show.expert_profile" }, expertProfileOpts.urlParameters)
        );

      $stateProvider.state("root.candidates.show.documents", {
        url: "/documents?document_id",
        resolve: {
          sidebar: [
            "SidebarFactory",
            function (SidebarFactory) {
              return new SidebarFactory("deckSub");
            },
          ],
          objectType: function () {
            return "candidate";
          },
          scrollspyScope: function () {
            return _.uniqueId("scrollspy");
          },
        },
        onExit: [
          "sidebar",
          function (sidebar) {
            sidebar.close();
          },
        ],
        onEnter: [
          "sidebar",
          function (sidebar) {
            sidebar.open();
          },
        ],
        views: {
          "deck-sub@root.candidates": {
            component: "genericDeck",
          },
          "deck-content@root.candidates.show.documents": {
            component: "candidatesDocumentsView",
          },
        },
      });

      // Companies Table

      $urlRouterProvider.when("/companies/list", "/companies");
      $stateProvider
        .state("root.companies", {
          url: "/companies?additional_pages&q&advanced&saved_search_id",
          component: "companiesIndex",
          resolve: angular.merge(angular.copy(companyOpts.requirements), angular.copy(savedSearchOpts.requirements)),
          onExit: [
            "CompaniesHotkeys",
            function (CompaniesHotkeys) {
              CompaniesHotkeys.deregisterHotkeys();
            },
          ],
          onEnter: [
            "CompaniesHotkeys",
            function (CompaniesHotkeys) {
              CompaniesHotkeys.registerHotkeys();
            },
          ],
          params: {
            additional_pages: {
              value: "0",
              squash: true,
            },
          },
        })
        .state("root.companies.list", {
          url: "/list",
        });

      $stateGeneratorProvider
        .addStates("root.companies", [null, "deckPrimary", companyOpts, "{company_id:[0-9]+}"])
        .addStates("root.companies.show", ["employees", "deckSecondary", employeeOpts, ":employee_id"])
        .addStates("root.companies.show", ["projects", "deckSecondary", projectOpts, ":project_id"])
        .addRoundtrip("root.companies.show.projects.show.job_offer", {
          paramName: ":job_offer_id",
          url: "/job_offer",
          redirect: "root.projects.show.job_offer",
        })
        .addRoundtrip(
          "root.companies.show.projects.show.expert_profile",
          angular.extend({ redirect: "root.candidates.show.expert_profile" }, expertProfileOpts.urlParameters)
        )
        .addRoundtrip("root.companies.show.projects.show.employee", {
          paramName: ":employee_id",
          url: "/employee",
          redirect: "root.companies.show.employees",
        })
        .addRoundtrip("root.companies.show.projects.show.company", {
          paramName: ":company_id",
          url: "/companies",
          redirect: "root.companies",
        })

        .addStates("root.companies.show", ["candidates", "deckSecondary", candidateOpts, ":candidate_id"])
        .addRoundtrip("root.companies.show.candidates.show.projects", {
          paramName: ":project_id",
          url: "/projects",
          redirect: "root.candidates.show.projects",
        })
        .addRoundtrip(
          "root.companies.show.candidates.show.expert_profile",
          angular.extend({ redirect: "root.candidates.show.expert_profile" }, expertProfileOpts.urlParameters)
        )

        .addStates("root.companies.list", {
          stateName: "employees",
          viewLayerID: "deckPrimary",
          params: employeeOpts,
          urlParamName: ":employee_id",
          rootView: "@root.companies",
        })
        .addRoundtrip("root.companies.list.employees.show.companies", {
          paramName: ":company_id",
          url: "/company",
          redirect: "root.companies.show.employees",
        })
        .addRoundtrip("root.companies.show.employees.show.companies", {
          paramName: ":company_id",
          url: "/company",
          redirect: "root.companies.show.employees",
        })
        .addRoundtrip("root.companies.list.employees.show.projects.show.candidate", {
          paramName: ":candidate_id",
          url: "/candidate",
          redirect: "root.projects.show.candidate",
        })
        .addRoundtrip("root.companies.list.employees.show.projects.show.employee", {
          paramName: ":employee_id",
          url: "/employee",
          redirect: "root.projects.show.employee",
        })
        .addRoundtrip("root.companies.list.employees.show.projects.show.company", {
          paramName: ":company_id",
          url: "/company",
          redirect: "root.projects.show.company",
        })
        .addRoundtrip(
          "root.companies.list.employees.show.projects.show.expert_profile",
          angular.extend({ redirect: "root.projects.show.expert_profile" }, expertProfileOpts.urlParameters)
        )
        .addRoundtrip("root.companies.list.employees.show.projects.show.job_offer", {
          paramName: ":job_offer_id",
          url: "/job_offer",
          redirect: "root.projects.show.job_offer",
        });

      $stateProvider.state("root.companies.show.documents", {
        url: "/documents?document_id",
        resolve: {
          sidebar: [
            "SidebarFactory",
            function (SidebarFactory) {
              return new SidebarFactory("deckSub");
            },
          ],
          objectType: function () {
            return "client";
          },
          scrollspyScope: function () {
            return _.uniqueId("scrollspy");
          },
        },
        onExit: [
          "sidebar",
          function (sidebar) {
            sidebar.close();
          },
        ],
        onEnter: [
          "sidebar",
          function (sidebar) {
            sidebar.open();
          },
        ],
        views: {
          "deck-sub@root.companies": {
            component: "genericDeck",
          },
          "deck-content@root.companies.show.documents": {
            component: "candidatesDocumentsView",
          },
        },
      });

      $stateGeneratorProvider
        .addStates("root.companies.list.employees.show", {
          stateName: "projects",
          viewLayerID: "deckSecondary",
          params: projectOpts,
          urlParamName: ":project_id",
          rootView: "@root.companies",
        })
        .addRoundtrip("root.companies.show.employees.show.projects", {
          paramName: ":project_id",
          url: "/projects",
          redirect: "root.companies.list.employees.show.projects",
        });

      // Manager Table

      $stateProvider.state("root.employees", {
        url: "/employees?additional_pages&q&advanced&saved_search_id",
        component: "employeesIndexView",
        resolve: angular.merge(angular.copy(employeeOpts.requirements), angular.copy(savedSearchOpts.requirements)),
        params: {
          additional_pages: {
            value: "0",
            squash: true,
          },
        },
      });

      $stateGeneratorProvider
        .addStates("root.employees", [null, "deckPrimary", employeeOpts, "{employee_id:[0-9]+}"])
        .addStates("root.employees.show", ["companies", "deckSecondary", companyOpts, "{company_id:[0-9]+}"])
        .addStates("root.employees.show", ["projects", "deckSecondary", projectOpts, "{project_id:[0-9]+}"])

        .addRoundtrip("root.employees.show.candidates", {
          paramName: ":candidate_id",
          url: "/candidates",
          redirect: "root.candidates",
        })

        .addRoundtrip("root.employees.show.companies.show.candidates", {
          paramName: ":candidate_id",
          url: "/candidates",
          redirect: "root.candidates",
        })
        .addRoundtrip("root.employees.show.companies.show.projects", {
          paramName: ":project_id",
          url: "/projects",
          redirect: "root.companies.show.projects",
        })
        .addRoundtrip("root.employees.show.companies.show.employees", {
          paramName: ":employee_id",
          url: "/employees",
          redirect: "root.companies.show.employees",
        })
        .addRoundtrip("root.employees.show.projects.show.candidate", {
          paramName: ":candidate_id",
          url: "/candidates",
          redirect: "root.projects.show.candidate",
        })
        .addRoundtrip("root.employees.show.projects.show.employee", {
          paramName: ":employee_id",
          url: "/employee",
          redirect: "root.projects.show.employee",
        })
        .addRoundtrip("root.employees.show.projects.show.company", {
          paramName: ":company_id",
          url: "/company",
          redirect: "root.projects.show.company",
        })
        .addRoundtrip(
          "root.employees.show.projects.show.expert_profile",
          angular.extend({ redirect: "root.projects.show.expert_profile" }, expertProfileOpts.urlParameters)
        )
        .addRoundtrip("root.employees.show.projects.show.job_offer", {
          paramName: ":job_offer_id",
          url: "/job_offer",
          redirect: "root.projects.show.job_offer",
        });

      // Projects Table

      $stateProvider.state("root.projects", {
        url: "/projects?additional_pages&q&advanced&saved_search_id",
        component: "projectsIndexView",
        resolve: angular.merge(angular.copy(projectOpts.requirements), angular.copy(savedSearchOpts.requirements)),
        onExit: [
          "ProjectsHotkeys",
          function (ProjectsHotkeys) {
            ProjectsHotkeys.deregisterHotkeys();
          },
        ],
        onEnter: [
          "ProjectsHotkeys",
          function (ProjectsHotkeys) {
            ProjectsHotkeys.registerHotkeys();
          },
        ],
        params: {
          additional_pages: {
            value: "0",
            squash: true,
          },
        },
      });

      var docViewActive = angular.merge({}, candidateOpts, {
        show: {
          resolve: {
            documentView: [
              function () {
                return true;
              },
            ],
          },
        },
      });

      $stateGeneratorProvider
        .addStates("root.projects", [null, "deckPrimary", projectOpts, ":project_id"])
        .addStates("root.projects.show", ["candidate", "deckSecondary", docViewActive, ":candidate_id"])
        .addRoundtrip(
          "root.projects.show.expert_profile",
          angular.extend({ redirect: "root.candidates.show.expert_profile" }, expertProfileOpts.urlParameters)
        )
        .addRoundtrip(
          "root.projects.show.candidate.show.expert_profile",
          angular.extend({ redirect: "root.candidates.show.expert_profile" }, expertProfileOpts.urlParameters)
        )
        .addStates("root.projects.show", ["job_offer", "deckSecondary", jobOfferOpts, ":job_offer_id"])
        .addRoundtrip("root.projects.show.candidate.show.projects", {
          paramName: ":project_id",
          url: "/project?project_id",
          redirect: "root.candidates.show.projects",
        })
        .addStates("root.projects.show", ["employee", "deckSecondary", employeeOpts, ":employee_id"])
        .addRoundtrip("root.projects.show.employee.show.projects", {
          paramName: ":project_id",
          url: "/projects",
          redirect: "root.companies.list.employees.show.projects",
        })
        .addRoundtrip("root.projects.show.employee.show.company", {
          paramName: ":company_id",
          url: "/company",
          redirect: "root.companies",
        })
        .addRoundtrip("root.projects.show.employee.show.companies", {
          paramName: ":company_id",
          url: "/companies",
          redirect: "root.employees.show.companies",
        })
        .addRoundtrip("root.projects.show.candidate.show.companies", {
          paramName: ":company_id",
          url: "/companies",
          redirect: "root.candidates.show.companies",
        })
        .addStates("root.projects.show", ["company", "deckSecondary", companyOpts, ":company_id"])
        .addRoundtrip("root.projects.show.company.show.employees", {
          paramName: ":employee_id",
          url: "/employees",
          redirect: "root.companies.show.employees",
        })
        .addRoundtrip("root.projects.show.company.show.candidates", {
          paramName: ":candidate_id",
          url: "/candidates",
          redirect: "root.projects.show.candidate",
        })
        .addRoundtrip("root.projects.show.company.show.projects", {
          paramName: ":project_id",
          url: "/projects",
          redirect: "root.companies.show.projects",
        });

      $urlRouterProvider.when("/quicklists/", "/");

      $stateProvider
        .state("root.quicklists", {
          url: "/quicklists",
          template: emptyTemplate,
          abstract: true,
        })
        .state("root.quicklists.show", {
          url: "/:quicklist_id?q&advanced&saved_search_id",
          component: "quicklistShowView",
          resolve: angular.merge(angular.copy(savedSearchOpts.requirements), {
            quicklistResponse: [
              "stateParams",
              "QuicklistsFactory",
              function (stateParams, QuicklistsFactory) {
                return QuicklistsFactory.get(stateParams.quicklist_id);
              },
            ],
            scrollspyScope: function () {
              return _.uniqueId("scrollspy");
            },
          }),
        })
        .state("root.quicklists.edit", {
          url: "/:quicklist_id/edit?q&advanced&saved_search_id",
          resolve: angular.merge(angular.copy(savedSearchOpts.requirements), {
            sidebar: [
              "SidebarFactory",
              function (SidebarFactory) {
                return new SidebarFactory("deckPrimary");
              },
            ],
            quicklistResponse: [
              "stateParams",
              "QuicklistsFactory",
              function (stateParams, QuicklistsFactory) {
                return QuicklistsFactory.get(stateParams.quicklist_id);
              },
            ],
            objectType: [
              function () {
                return "candidate";
              },
            ],
            scrollspyScope: function () {
              return _.uniqueId("scrollspy");
            },
          }),
          onExit: [
            "sidebar",
            function (sidebar) {
              sidebar.close();
            },
          ],
          onEnter: [
            "sidebar",
            function (sidebar) {
              sidebar.open();
            },
          ],
          views: {
            "": {
              component: "quicklistShowView",
            },
            "deck-primary@root.quicklists.edit": {
              component: "genericDeck",
            },
            "deck-content@root.quicklists.edit": {
              component: "quicklistEditView",
            },
            "deck-sidebar@root.quicklists.edit": {
              component: "quicklistMenuView",
            },
          },
        });

      $stateProvider.state("root.quicklists.show.company", {
        url: "/company",
        component: "companiesIndex",
        resolve: companyOpts.requirements,
      });

      $stateGeneratorProvider
        .addStates("root.quicklists.show.company", {
          viewLayerID: "deckPrimary",
          params: companyOpts,
          urlParamName: ":company_id",
          forceRootStateName: true,
        })
        .addStates("root.quicklists.show.company.show", {
          stateName: "employees",
          viewLayerID: "deckSecondary",
          params: employeeOpts,
          urlParamName: ":employee_id",
          rootView: "@root.quicklists.show",
        })

        .addStates("root.quicklists.show.company.show", {
          stateName: "projects",
          viewLayerID: "deckSecondary",
          params: projectOpts,
          urlParamName: ":project_id",
          rootView: "@root.quicklists.show",
        })

        .addStates("root.quicklists.show.company.show", {
          stateName: "candidates",
          viewLayerID: "deckSecondary",
          params: candidateOpts,
          urlParamName: ":candidate_id",
          rootView: "@root.quicklists.show",
        })

        .addRoundtrip("root.quicklists.show.company.show.projects.show.employee", {
          paramName: ":employee_id",
          url: "/employee",
          redirect: "root.quicklists.show.company.show.employees",
        })

        .addRoundtrip("root.quicklists.show.company.show.projects.show.company", {
          paramName: ":company_id",
          url: "/company",
          redirect: "root.quicklists.show.company",
        });

      $stateProvider.state("root.quicklists.show.candidate", {
        url: "/candidate",
        resolve: candidateOpts.requirements,
        subDeckOpen: true,
      });

      $stateGeneratorProvider
        .addStates("root.quicklists.show.candidate", {
          viewLayerID: "deckPrimary",
          params: candidateOpts,
          urlParamName: ":candidate_id",
          forceRootStateName: true,
          subDeckOpen: true,
        })
        .addStates("root.quicklists.show.candidate.show", {
          stateName: "projects",
          viewLayerID: "deckSecondary",
          params: projectOpts,
          urlParamName: ":project_id",
          rootView: "@root.quicklists.show",
        })
        .addStates("root.quicklists.show.candidate.show", {
          stateName: "companies",
          viewLayerID: "deckSecondary",
          params: companyOpts,
          urlParamName: ":company_id",
          rootView: "@root.quicklists.show",
        })
        .addStates("root.quicklists.show.candidate.show", {
          stateName: "expert_profile",
          viewLayerID: "deckSecondary",
          rootView: "@root.quicklists.show",
          params: expertProfileOpts,
          urlParamName: expertProfileOpts.urlParameters.paramName,
          url: expertProfileOpts.urlParameters.url + "?" + expertProfileOpts.urlParameters.queryParams,
        })
        .addRoundtrip(
          "root.quicklists.show.candidate.show.projects.show.expert_profile",
          angular.extend(
            { redirect: "root.quicklists.show.candidate.show.expert_profile" },
            expertProfileOpts.urlParameters
          )
        )
        .addRoundtrip("root.quicklists.show.candidate.show.projects.show.company", {
          paramName: ":company_id",
          url: "/company",
          redirect: "root.quicklists.show.candidate.show.companies",
        })
        .addRoundtrip("root.quicklists.show.candidate.show.projects.show.employee", {
          paramName: ":employee_id",
          url: "/employees",
          redirect: "root.projects.show.employee",
        });

      $stateProvider.state("root.quicklists.show.candidate.show.documents", {
        url: "/documents?document_id",
        resolve: {
          sidebar: [
            "SidebarFactory",
            function (SidebarFactory) {
              return new SidebarFactory("deckSub");
            },
          ],
          objectType: function () {
            return "candidate";
          },
          scrollspyScope: function () {
            return _.uniqueId("scrollspy");
          },
        },
        onExit: [
          "sidebar",
          function (sidebar) {
            sidebar.close();
          },
        ],
        onEnter: [
          "sidebar",
          function (sidebar) {
            sidebar.open();
          },
        ],
        views: {
          "deck-sub@root.quicklists.show.candidate": {
            component: "genericDeck",
          },
          "deck-content@root.quicklists.show.candidate.show.documents": {
            component: "candidatesDocumentsView",
          },
        },
      });

      $stateProvider.state("root.quicklists.show.employee", { url: "/employee", resolve: employeeOpts.requirements });
      $stateGeneratorProvider
        .addStates("root.quicklists.show.employee", [null, "deckPrimary", employeeOpts, ":employee_id", true])
        .addStates("root.quicklists.show.employee.show", {
          stateName: "companies",
          viewLayerID: "deckSecondary",
          params: companyOpts,
          urlParamName: ":company_id",
          rootView: "@root.quicklists.show",
        })
        .addRoundtrip("root.quicklists.show.employee.show.companies.show.employees", {
          paramName: ":employee_id",
          url: "/employees",
          redirect: "root.companies.show.employees",
        })
        .addRoundtrip("root.quicklists.show.employee.show.company.show.employees", {
          paramName: ":employee_id",
          url: "/employees",
          redirect: "root.companies.show.employees",
        })
        .addRoundtrip("root.quicklists.show.employee.show.company.show.projects", {
          paramName: ":project_id",
          url: "/projects",
          redirect: "root.companies.show.projects",
        })
        .addStates("root.quicklists.show.employee.show", {
          stateName: "projects",
          viewLayerID: "deckSecondary",
          params: projectOpts,
          urlParamName: ":project_id",
          rootView: "@root.quicklists.show",
        });

      $urlRouterProvider.otherwise("/");
    },
  ])

  .config([
    "KeepaliveProvider",
    "IdleProvider",
    function (KeepaliveProvider, IdleProvider) {
      // CSRF token checks do not work for GET or HEAD requests.
      KeepaliveProvider.http({ url: "/keepalive.json", method: "POST" }); // sends every minute a request to the API
      KeepaliveProvider.interval(10);
      IdleProvider.timeout(300); // 5 min
      IdleProvider.idle(60 * 60 * 10 - 300); // 10h - 5 min
    },
  ])

  .config([
    "$httpProvider",
    function ($httpProvider) {
      $httpProvider.interceptors.push("myCSRF");
    },
  ])

  .config([
    "$sceDelegateProvider",
    function ($sceDelegateProvider) {
      $sceDelegateProvider.resourceUrlWhitelist([
        // Allow same origin resource loads.
        "self",
        // Allow loading from our assets domain. **.
        "https://sso-staging.textkernel.de/**",
        "https://sso.textkernel.de/**",
      ]);
    },
  ])

  .run([
    "$rootScope",
    "$state",
    "$transitions",
    "$log",
    "Session",
    "AuthService",
    "AUTH_EVENTS",
    "ngProgress",
    "$timeout",
    "moment",
    "amMoment",
    "RedirectLocation",
    "sidebarStates",
    "$templateCache",
    "PopoverLoaderTemplate",
    "tmhDynamicLocale",
    "tmhDynamicLocaleCache",
    function (
      $rootScope,
      $state,
      $transitions,
      $log,
      Session,
      AuthService,
      AUTH_EVENTS,
      ngProgress,
      $timeout,
      moment,
      amMoment,
      RedirectLocation,
      sidebarStates,
      $templateCache,
      PopoverLoaderTemplate,
      tmhDynamicLocale,
      tmhDynamicLocaleCache
    ) {
      function getInjectedLocale() {
        var localInjector = angular.injector(["ngLocale"]);
        return localInjector.get("$locale");
      }

      // put de-de language into cache
      require("angular-i18n/angular-locale_de-de.js");
      tmhDynamicLocaleCache.put("de", getInjectedLocale());

      // put en-us language into cache
      require("angular-i18n/angular-locale_en-us.js");
      tmhDynamicLocaleCache.put("en", getInjectedLocale());

      $templateCache.put("popover-loader", PopoverLoaderTemplate);

      Date.prototype.toJSON = function () {
        return moment(this).format();
      };
      amMoment.changeLocale("de");
      tmhDynamicLocale.set("de");
      $rootScope.$state = $state;
      $rootScope.microsoft_login_url = window.microsoft_login_url;
      $rootScope.setPageTitle = function (title) {
        $rootScope.pageTitle = title;
      };

      $rootScope.isLoginPage = function () {
        return $state.current.isLoginPage;
      };
      $rootScope.sidebarStates = sidebarStates;

      if (window.user_session && window.user_session.user) {
        Session.create(window.user_session);
        amMoment.changeLocale(Session.user.locale);
        tmhDynamicLocale.set(Session.user.locale);
      } else {
        $state.go("login", { redirect_to: RedirectLocation.encode() });
      }

      var logoutSuccessListener = $rootScope.$on(AUTH_EVENTS.logoutSuccess, function (event) {
        console.log($state.params);
        $state.go("login", { redirect_to: RedirectLocation.encode() });
        $timeout(function () {
          location.reload();
        }, 1);
      });

      var startTransitionListener = $transitions.onStart({}, function (transition) {
        $rootScope.$emit("$stateChangeStart");
        const paramsCopy = Object.assign({}, transition.params());
        const stateService = transition.router.stateService;

        if (!_.isUndefined(transition.to().redirect)) {
          return transition.router.stateService.target(transition.to().redirect, paramsCopy);
        }

        if (!transition.to().unauthenticated && !AuthService.isAuthenticated()) {
          $log.info("User is not logged in, aborting routing.");

          return transition.router.stateService.target("login", { redirect_to: RedirectLocation.encode() });
        } else {
          ngProgress.start();
          $rootScope.navigating = true;
        }
      });

      var successTransitionListener = $transitions.onSuccess({}, function (transition) {
        ngProgress.complete();
        $rootScope.navigating = false;

        // reset page title so it doesn't leak between pages
        $rootScope.setPageTitle(null);
      });

      var errorTransitionListener = $transitions.onError({}, function (transition) {
        console.log(
          "TransitionError: " +
            transition.error() +
            " occured while navigating to " +
            transition.to().name +
            " with params: ",
          transition.params("to")
        );

        ngProgress.reset();
        $rootScope.navigating = false;
      });

      // custom event
      $rootScope.$on("$stateChangeAbort", function (event) {
        ngProgress.reset();
        $rootScope.navigating = false;
      });

      $rootScope.$on("$destroy", function () {
        logoutSuccessListener();
        startTransitionListener();
        successTransitionListener();
        errorTransitionListener();
      });

      ngProgress.color("#B58D4F");
    },
  ]);
