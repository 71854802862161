import { IQService } from "angular";

export default class GenericSelectorCtrl {
  public cancelers: ng.IDeferred<any>[] = [];
  public condition: { 
    field?: string;
    query?: string;
    suggestions?: {name: string | undefined ; synonyms?: string[]} [] 
  };
  static $inject = ["$q", "Autocompletes", "defaultSelectorFields"];

  constructor (
    private $q: IQService,
    private Autocompletes: any,
    private defaultSelectorFields: any
  ) {
    this.cancelers = [];
  }

  public autocomplete(text, condition) {
    if (!text.length) {
      condition.suggestions = [];
      return false;
    }
    var field = condition.field;
    var scope;
    if (_.isUndefined(field)) {
      scope = null;
    } else if (
      _.chain(this.defaultSelectorFields)
        .pick(_.matcher({ autocomplete: true }))
        .keys()
        .indexOf(field)
        .value() > -1
    ) {
      scope = field;
    }

    _.invoke(this.cancelers, "resolve");
    this.cancelers.push(this.$q.defer());

    return this.Autocompletes.autocomplete(text, scope, _.last(this.cancelers)).then((res) => {
      condition.suggestions = _.chain(res)
        .map((entry) => {
          if (scope && this.defaultSelectorFields[scope].getName) {
            return {
              name: this.defaultSelectorFields[scope].getName(entry),
              synonyms: _.propertyOf(entry[entry.resource_type])("synonyms"),
            };
          } else {
            return {
              name: entry.highlight[0],
              synonyms: _.propertyOf(entry[entry.resource_type])("synonyms"),
            };
          }
        })
        .uniq((x) => {
          return _.propertyOf(this.defaultSelectorFields[scope])("skipUniq") ? _.uniqueId() : x.name;
        })
        .value();
    });
  };

  public $onInit() {
    if (!_.isArray(this.condition.suggestions)) {
      this.condition.suggestions = [{ name: this.condition.query }];
    }

    this.defaultSelectorFields.then((result) => (this.defaultSelectorFields = result));
  };
};