import { LonglistTableBodyControllerCtrl } from "./longlist-table-body.controller";

import longlistTableBodyTemplate from "./longlist-table-body.template.html";

angular.module("candidates_projects.components").component("longlistTableBody", {
  bindings: {
    candidatesProjects: "=data",
    attributes: "=",
    activeRow: "=",
    statePrefix: "@",
    stateArgs: "&",
    context: "=",
    tableRowIdentifier: "=",
  },
  template: longlistTableBodyTemplate,
  controller: LonglistTableBodyControllerCtrl,
});
