import { SendoutsTableBodyCtrl } from "./sendouts-table-body.controller";
import sendoutsTableBodyTemplate from "./sendouts-table-body.template.html";

angular.module("sendouts.components").component("sendoutsTableBody", {
  bindings: {
    sendouts: "=data",
    attributes: "=",
    activeRow: "=",
    statePrefix: "@",
  },
  template: sendoutsTableBodyTemplate,
  controller: SendoutsTableBodyCtrl,
});
