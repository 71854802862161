import { snakeCase } from "change-case-all";
import { deepMapKeysMega } from "../../api-routing/deep-map-keys-mega";

import { TTransactionBases } from "../transaction_bases";
import { TransactionBasis } from "./transaction_bases.service";

angular
  .module("transaction_bases")
  .factory("TransactionBasesSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = function (data) {
          return _.isArray(data) ? data.map((element) => new TransactionBasis(element)) : new TransactionBasis(data);
        };
        this.serialize = function (data) {
          return deepMapKeysMega<Partial<TTransactionBases>>(data, snakeCase);
        };
      });
    },
  ])
  .service("TransactionBasesFactory", TransactionBasis);
