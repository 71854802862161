import { Money } from "../../../directives/money/services/money.service";

export default class InvoicesFormCtrl {
  private project: any;
  private invoices: any[];
  private preload: boolean;
  private advancedQuery: any;
  private lazyLoading: boolean;
  
  static $inject = ["SavedSearchesFactory", "makeCollectionProxy", "InvoicesFactory",];

  constructor (private SavedSearch: any, private makeCollectionProxy: any, private Invoice: any) {}

  public $onInit () {
    _.each(this.project.invoices, (invoice) => {
      invoice.invoiceRecipient = invoice.invoiceRecipient || this.project.invoiceRecipient;
    });
    this.invoices = this.project.invoices;

    if (this.preload) {
      this.advancedQuery = new this.SavedSearch();
      this.initSearchResults = this.initSearchResults.bind(this);
      this.updateInvoices(this.advancedQuery.collectSearchParams());
    }
  };

  private initSearchResults (invoices) {
    this.project.invoices = invoices;
    this.lazyLoading = false;

    if (this.project.invoices && this.project.invoices.length === 0) {
      this.addInvoice();
      this.project.snapshot();
    }
  };

  private updateInvoices (params) {
    this.lazyLoading = true;

    var params = _.extend(this.project.params(), {
      advanced: JSON.stringify(this.advancedQuery),
      additional_pages: Math.min(this.project.invoicesCount / 20 + 1, 3),
    });

    this.makeCollectionProxy(_.partial(this.Invoice.query.bind(this.Invoice), _, this.project.params()), params).then(
      this.initSearchResults.bind(this),
    );
  };

  public addInvoice () {
    var invoice = new this.Invoice({
      value: new Money({ inCents: false }),
      invoiceRecipient: this.project.invoiceRecipient,
    });
    this.project.invoices.push(invoice);
  };

  public deleteInvoice (index) {
    this.project.invoices.splice(index, 1);
  };
}