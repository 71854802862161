import { CandidatesProjectsTableBodyCtrl } from "./candidates-projects-table-body.controller";

import candidatesProjectsTableBodyTemplate from "./candidates-projects-table-body.template.html";

angular.module("candidates_projects.components").component("candidatesProjectsTableBody", {
  bindings: {
    candidatesProjects: "=data",
    attributes: "=",
    activeRow: "=",
    identifier: "@",
    initialViewState: "<",
  },
  template: candidatesProjectsTableBodyTemplate,
  controller: CandidatesProjectsTableBodyCtrl,
});
