import JobOffersShowCtrl from "./show.controller";
import jobShowTemplate from "./show.template.html";

angular.module("job_offers.views").component("jobOffersShow", {
  bindings: {
    jobOffer: "<jobOfferResponse",
    scrollspyScope: "<",
  },
  template: jobShowTemplate,
  controller: JobOffersShowCtrl,
});
