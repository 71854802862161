import { IFilterService } from "angular";

export default class AmountCoveredLineChartCtrl {
  public colors: string[] = [];
  public options: any;
  public projectReportingWeeks: any;
  public amountCoveredSollPerYear: any;
  public labels: string[];
  public series: string[];
  public data: Array<string[] | number[]>;
  public sales: any;
  static $inject = ["ChartColors2021", "$filter"];

  constructor(
    private ChartColors: any,
    private $filter: IFilterService,
    ) {}

    public $onInit() {
      this.colors = [this.ChartColors[3], this.ChartColors[0], this.ChartColors[1]];
      this.options = {
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            boxWidth: 12,
            fontSize: 10,
            padding: 16,
            usePointStyle: true,
          },
        },
      };
    };

    public isEmpty (obj) {
      return !_.chain(obj)
        .flatten()
        .any((value) => {
          return value > 0;
        })
        .value();
    };

    public updateChartData() {
      if (_.isObject(this.projectReportingWeeks) && _.isObject(this.amountCoveredSollPerYear)) {
        var currency = this.amountCoveredSollPerYear.currency.isoCode;
        this.labels = _.keys(this.projectReportingWeeks);
        this.series = [
          this.$filter("translate")("CURRENT_IN_TOKEN_CAPS", { currency: currency }),
          this.$filter("translate")("TARGET_IN_YEAR", { currency: currency }),
          this.$filter("translate")("TARGET_IN_PERIOD", { currency: currency }),
        ];

        var sumAmountCoveredIst = 0;
        var sumAmountCoveredSoll = 0;

        _.each(this.projectReportingWeeks, (week) => {
          if (_.isUndefined(this.sales)) {
            sumAmountCoveredIst = sumAmountCoveredIst + week.amountCoveredIst.cents / 100;
            sumAmountCoveredSoll = sumAmountCoveredSoll + week.amountCoveredSoll.cents / 100;
          } else {
            // LEGACY 2019
            sumAmountCoveredIst =
              sumAmountCoveredIst +
              (this.sales ? week.salesAmountCoveredIst.cents : week.researchAmountCoveredIst.cents) / 100;
            sumAmountCoveredSoll =
              sumAmountCoveredSoll +
              (this.sales ? week.salesAmountCoveredSoll.cents : week.researchAmountCoveredSoll.cents) / 100;
          }

          week.amountCoveredIstPerWeek = sumAmountCoveredIst.toFixed(0);
          week.amountCoveredSollPerWeek = sumAmountCoveredSoll.toFixed(0);
        });

        this.data = [
          _.pluck(this.projectReportingWeeks, "amountCoveredIstPerWeek"),
          _.map(this.projectReportingWeeks, () => {
            return this.amountCoveredSollPerYear ? this.amountCoveredSollPerYear.cents : 0;
          }),
          _.pluck(this.projectReportingWeeks, "amountCoveredSollPerWeek"),
        ];
      }
    };

    public $onChanges(changes) {
      if (
        (changes.projectReportingWeeks &&
          changes.projectReportingWeeks.previousValue != changes.projectReportingWeeks.currentValue) ||
        (changes.amountCoveredSollPerYear &&
          changes.amountCoveredSollPerYear.previousValue != changes.amountCoveredSollPerYear.currentValue)
      ) {
        this.updateChartData();
      }
    };
};