import workingRelationshipListTemplate from "./working-relationship-list.template.html";
import WorkingRelationshipListCtrl from "./working-relationship-list.controller";

angular.module("working_relationships.components").component("workingRelationshipList", {
  bindings: {
    person: "=",
    initialViewState: "<",
  },
  template: workingRelationshipListTemplate,
  controller: WorkingRelationshipListCtrl,
});
