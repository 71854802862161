//= require ../companies.module.js

angular.module("companies").service("CompaniesFactory", [
  "RailsResource",
  "RailsResourceSnapshotsMixin",
  "CompaniesModalMixin",
  "railsSerializer",
  "ActionsFactory",
  "Highlighter",
  "$rootScope",
  "$sce",
  function (
    RailsResource,
    RailsResourceSnapshotsMixin,
    CompaniesModalMixin,
    railsSerializer,
    Action,
    Highlighter,
    $rootScope,
    $sce
  ) {
    function Company() {
      (<any>Company).__super__.constructor.apply(this, arguments);

      this.params = function () {
        return { company_id: this.id };
      }.bind(this);

      this.autocompleteText = function () {
        return this.name;
      };

      this.mapSearchScopesToHumanReadable = Company.mapSearchScopesToHumanReadable;

      this.createNewAction = function (actionTypeIdentifier) {
        Action.createNewAction(this, actionTypeIdentifier);
      }.bind(this);

      this.saveCustomNote = function (action) {
        Action.saveCustomNote(this, action);
      }.bind(this);

      this.resourceType = "Company";
      this.serviceName = "CompaniesFactory";

      if (this.homepage && !/^(https|http?):\/\//i.test(this.homepage)) {
        this.homepage = "http://" + this.homepage;
      }

      this.makeVisible = function () {
        (<any>Company).$patch((<any>Company).$url(this.id + ".json"), { visible: true }).then(
          function (result) {
            this.visible = result.visible;
            $rootScope.$broadcast("company_action_changed_" + this.id);
          }.bind(this)
        );
      }.bind(this);

      this.filterDepartments = function () {
        return { job_category_ids: { job_category_ids: _.pluck(this.jobCategories, "id") } };
      }.bind(this);
    }

    Company.mapSearchScopesToHumanReadable = function (scopeDescriptor) {
      switch (scopeDescriptor) {
        case "name":
        case "name.standard":
        case "name.de":
          return "NAME";
        case "siteAddresses":
          return "ADDRESS";
        case "tags.name":
          return "TAG";
        case "jobCategories.name":
          return "MARKETS";
        case "responsibleUser.fullName.notAnalyzed":
        case "responsibleUser.symbole":
          return "RESPONSIBLE_USER";
        case "employees.fullNameWithTitle":
          return "MANAGER";
        case "employees.tags.name":
          return "MANAGER_TAGS";
        case "allActions.description.de":
          return "HISTORY_ENTRIES";
        default:
          return undefined;
      }
    };

    Company.defaultTableAttributes = {
      id: {
        title: "ID",
        show: true,
        pinned: true,
        menu: true,
        class: "id",
        sortField: "id",
      },
      name: {
        title: "COMPANY",
        show: true,
        fixedWidth: true,
        class: "title",
        sortField: "name.not_analyzed",
      },
      responsibleUser: {
        title: "EMPLOYEE_TOKEN",
        show: true,
        class: "responsible",
        sortField: "responsible_user.full_name.not_analyzed",
      },
      city: {
        title: "ORIGIN",
        show: true,
        class: "city",
        sortField: "main_site_address.city",
      },
      region: {
        title: "REGION",
        show: true,
        class: "region",
        sortField: "region.name.not_analyzed",
      },
      priority: {
        title: "PRIORITY",
        show: true,
        class: "priority",
        sortField: "priority",
      },
      tags: {
        title: "TAGS",
        show: true,
        class: "tags",
      },
      createdAt: {
        title: "CREATED_AT",
        show: false,
        class: "date",
        sortField: "created_at",
      },
    };

    RailsResource.extendTo(Company);
    (<any>Company).configure({
      url: "/companies",
      name: "company",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.nestedAttribute("employees");
        this.resource("employees", "EmployeesFactory");
        this.nestedAttribute("projects");
        this.resource("projects", "ProjectsFactory");
        this.nestedAttribute("responsible_user");
        this.resource("responsible_user", "UsersFactory");
        this.serializeWith("transactionBasis", "CompaniesTransactionBasesSerializer");
        this.serializeWith("tags", "TagSerializer");
        this.resource("actions", "ActionsFactory");
        this.resource("sendouts", "SendoutsFactory");
        this.serializeWith("jobCategories", "JobCategoriesSerializer");
        this.serializeWith("region", "RegionSerializer");

        this.rename("documents", "companyDocuments");
        this.resource("documents", "DocumentsFactory");

        this.serializeWith("priority", "PrioritySerializer");
        this.serializeWith("comment", "SanitizeSerializer");

        this.exclude("$snapshots");
      }),
    });
    (<any>Company).extend(RailsResourceSnapshotsMixin);
    (<any>Company).extend(CompaniesModalMixin);
    (<any>Company).extend(Highlighter);

    return Company;
  },
]);
