import { Language } from "../../../../languages/services/languages.service";
export default class LanguageSelectorCtrl {
  public languages: Language[];
  public languageLevelInWords: (level: number) => string;
  
  constructor () {
  }

  public $onInit() {
    Language.getAll().then((languages: Language[]) => (this.languages = languages));
    this.languageLevelInWords = Language.levelInWords;  
  };
};
