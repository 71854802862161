import { initContract } from "@ts-rest/core";

import { prioritiesContract } from "../priorities/services/priorities.contract";
import { graduationsContract } from "../graduations/services/graduations.contract";
import { jobCategoriesContract } from "../job_categories/services/job_categories.contract";
import { departmentsContract } from "../departments/services/departments.contract";
import { jobTypesContract } from "../job_types/services/job_types.contract";
import { drivingLicencesContract } from "../driving_licences/services/driving_licences.contract";
import { careerLevelsContract } from "../career_levels/services/career_levels.contract";
import { functionalAreasContract } from "../functional_areas/services/functional_areas.contract";
import { highschoolsContract } from "../highschools/services/highschools.contract";
import { coursesContract } from "../courses/services/courses.contract";
import { leadershipExperiencesContract } from "../leadership_experiences/services/leadership_experiences.contract";
import { cancelationPeriodsContract } from "../cancelation_periods/services/cancelation_periods.contract";
import { maritalStatusesContract } from "../marital_statuses/services/marital_status.contract";
import { hierarchiesContract } from "../hierarchies/services/hierarchies.contract";
import { organisationUnitsContract } from "../organisation_units/services/organisation_units.contract";
import { RegionsContract } from "../regions/services/regions.contract";
import { languagesContract } from "../languages/services/languages.contract";
import { countriesContract } from "../countries/services/countries.contract";
import { transactionBasisContract } from "../transaction_bases/services/transaction_bases.contract";
import { companiesTransactionBasisContract } from "../companies_transaction_bases/services/companies_transaction_bases.contract";
import { actionTypesContract } from "../action_types/services/action_types.contract";
import { tagsContract } from "../tags/services/tags.contract";

const c = initContract();

export const contract = c.router(
  {
    priorities: prioritiesContract,
    graduations: graduationsContract,
    jobCategories: jobCategoriesContract,
    departments: departmentsContract,
    jobTypes: jobTypesContract,
    drivingLicences: drivingLicencesContract,
    careerLevels: careerLevelsContract,
    functionalAreas: functionalAreasContract,
    highschools: highschoolsContract,
    courses: coursesContract,
    leadershipExperiences: leadershipExperiencesContract,
    cancelationPeriods: cancelationPeriodsContract,
    maritalStatuses: maritalStatusesContract,
    hierarchies: hierarchiesContract,
    organisationUnits: organisationUnitsContract,
    regions: RegionsContract,
    languages: languagesContract,
    countries: countriesContract,
    transactionBases: transactionBasisContract,
    companiesTransactionBasis: companiesTransactionBasisContract,
    actionTypes: actionTypesContract,
    tags: tagsContract,
  },
  {
    strictStatusCodes: true,
  }
);
