import LastUpdateSelectorCtrl from "./last-update-selector.controller";
import lastUpdateSelectorTemplate from "./last-update-selector.template.html";

angular.module("directives.advanced_search").component("lastUpdateSelector", {
  bindings: {
    advancedQuery: "=",
    lastUpdateSelectable: "=",
  },
  template: lastUpdateSelectorTemplate,
  controller: LastUpdateSelectorCtrl,
});
