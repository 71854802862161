//= require ./routing-options.module.js

import { CancelationPeriod } from "../../cancelation_periods/services/cancelation_periods.service";
import { Priority } from "../../priorities/services/priority.service";
import { Country } from "../../countries/services/countries.service";

angular
  .module("values.routing-options")

  .constant("employeeOpts", {
    show: {
      resolve: {
        employeeResponse: [
          "$transition$",
          "EmployeesFactory",
          function ($transition$, EmployeesFactory) {
            return EmployeesFactory.get($transition$.params().employee_id);
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "employeesShowView",
        };
        viewObj["deck-sidebar@" + state] = {
          component: "employeesMenuView",
        };
        return viewObj;
      },
    },
    new: {
      resolve: {
        employeeResponse: [
          "$transition$",
          "EmployeesFactory",
          function ($transition$, Employee) {
            return Employee.$get("employees/new", { company_id: $transition$.params().company_id });
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "employeesEditView",
        };
        viewObj["deck-sidebar@" + state] = {
          component: "employeesMenuView",
        };
        return viewObj;
      },
    },
    edit: {
      resolve: {
        employeeResponse: [
          "$transition$",
          "EmployeesFactory",
          function ($transition$, EmployeesFactory) {
            return EmployeesFactory.get($transition$.params().employee_id);
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "employeesEditView",
        };
        viewObj["deck-sidebar@" + state] = {
          component: "employeesMenuView",
        };
        return viewObj;
      },
    },
    requirements: {
      countries: [
        function () {
          return Country.getAll();
        },
      ],
      priorities: [
        function () {
          return Priority.getAll();
        },
      ],
      cancelationPeriods: [
        function () {
          return CancelationPeriod.getAll();
        },
      ],
      objectType: [
        function () {
          return "employee";
        },
      ],
      scrollspyScope: [
        function () {
          return _.uniqueId("scrollspy");
        },
      ],
    },
  });
