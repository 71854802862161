//= require ../candidates_projects.module.js

angular.module("candidates_projects").service("CandidatesProjectStatuses", [
  "DealsFactory",
  "$translate",
  "$filter",
  function (Deal, $translate, $filter) {
    function CandidatesProjectStatuses() {}

    CandidatesProjectStatuses.statuses = [
      { name: "LONGLIST", id: 0, order: 0, disabled: true },
      {
        name: "SHORTLIST",
        id: 1,
        order: 1,
        onChangeHintTemplate:
          "{{ 'SHORTLIST_CHANGE_HINT_FITS' | translate }}:<br><ul><li>{{ 'SHORTLIST_CHANGE_HINT_GEOGRAPHICAL' | translate }}</li><li>{{ 'SHORTLIST_CHANGE_HINT_TECHNICAL' | translate }}</li><li>{{ 'SHORTLIST_CHANGE_HINT_PERSONAL' | translate }}</li><li>{{ 'SHORTLIST_CHANGE_HINT_SALARY' | translate }}</li><li>{{ 'SHORTLIST_CHANGE_HINT_WANTS_AWAY' | translate }}</li></ul>",
      },
      { name: "QUALIFICATION_FOR_PROJECT", id: 9, order: 2, disabled: true },
      { name: "SENDOUT", id: 5, order: 3, disabled: true },
      { name: "INTERVIEW", id: 2, order: 4, disabled: true },
      {
        name: "CLOSING",
        id: 10,
        order: 5,
        callback: function (cp) {
          return Deal.createAndEdit({
            candidatesProjectId: cp.id,
            add_action: cp.lastAction,
            closing: true,
          });
        },
      },
      {
        name: "DEAL",
        id: 3,
        order: 6,
        callback: function (cp) {
          return Deal.createAndEdit({
            candidatesProjectId: cp.id,
            add_action: cp.lastAction,
            closing: false,
          });
        },
        disabled: true,
      },
      {
        name: "LOST",
        id: 4,
        order: 7,
        callback: function (cp) {
          return cp.lost(cp.lastAction);
        },
      },
      { name: "AWAITING_FEEDBACK", id: 7, order: 8 },
      { name: "NOT_REACHED", id: 8, order: 9 },
      { name: "CONTACTED", id: 6, order: 10 },
    ];

    return CandidatesProjectStatuses;
  },
]);
