export default class ChooseContactModalCtrl {
  
  private emailObject: {
    email: string;
    params: any;
    callback: any;
  };
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private resolve: {
    person: {
      privateEmail?: string;
      workEmail?: string;
    };
    params: any;
    sendMessageToObject: {
      createMessage: (messageType: string, emailObject: { email: string; params: any; callback: any }) => any;
    };
    messageType: string;
  };
  
  constructor() {}

  public $onInit () {
    var emails = _.chain([this.resolve.person.privateEmail, this.resolve.person.workEmail])
      .compact()
      .uniq()
      .value();

    angular.extend(this, {
      person: this.resolve.person,
      emails: emails,
      emailObject: {
        email: _.first(emails),
        params: this.resolve.params,
        callback: null,
      },
    });
  };

  public save () {
    this.emailObject.callback = this.resolve.sendMessageToObject.createMessage(
      this.resolve.messageType,
      this.emailObject
    );
    this.modalInstance.close(this.emailObject);
  };

  public cancel () {
    this.modalInstance.dismiss("cancel");
  };
}