//= require ../prsm-table.module.js

angular.module("components.prsmTable").controller("PrsmTableHighlightsCtrl", [
  "$filter",
  "$translate",
  function ($filter, $translate) {
    var _this = this;

    _this.highlightPopoverHtml = function (key, value) {
      var list_str = _.chain(value)
        .first(5)
        .map(function (res) {
          return "<li>" + res + "</li>";
        })
        .value()
        .join("");
      return (
        "<div><h6>" +
        $filter("translate")(_this.resource.mapSearchScopesToHumanReadable(key).toString()) +
        '</h6><ul class="is-unstyled">' +
        list_str +
        '</ul><div class="text-center has-margin-top-3">' +
        (value.length > 5 ? "<b>... und " + (value.length - 5) + " weitere</b>" : "") +
        "</div></div>"
      );
    };
  },
]);
