import { initContract } from "@ts-rest/core";

import { TCourse } from "../courses";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const CoursesSchema = c.type<TBaseResource<TCourse[]>>();
export const coursesContract = c.router({
  query: {
    method: "GET",
    path: "/courses",
    responses: {
      200: CoursesSchema,
    },
    summary: "Get all courses",
  },
});
