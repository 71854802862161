export default class showActionsListModalCtrl {
  private actions: any[];
  private step: any;
  private attributes: any;
  private dismiss: () => void;
  private resolve: any;
  
  static $inject = ["ActionsFactory", "PrsmTableHelper"];

  constructor (private Action: any, private PrsmTableHelper: any) {
  }

  public $onInit() {
    this.extend(this.resolve);
    this.attributes = this.getAttributes(
      "actions-list-table-attributes-" + this.step.key,
      this.defaultAttributes()
    );
  }

  private extend(resolve) {
    this.actions = resolve.actions;
    this.step = resolve.step;
  }

  private defaultAttributes() {
    if (this.step.key.match(/(deals)$/i)) {
      return this.PrsmTableHelper.setVisibleAttributes(
        angular.copy(this.Action.defaultTableAttributes),
        [
          "id",
          "type",
          "responsibleUser",
          "processProvisionConsultant",
          "project",
          "jobType",
          "deal",
          "amountCovered",
          "connectionCdsSap",
          "status",
          "countableDate",
          "candidate",
          "company",
        ],
        [
          "id",
          "processProvisionConsultant",
          "project",
          "jobType",
          "deal",
          "amountCovered",
          "connectionCdsSap",
          "candidate",
          "company",
        ]
      );
    } else if (this.step.key.match(/(closings)$/i)) {
      return this.PrsmTableHelper.setVisibleAttributes(
        angular.copy(this.Action.defaultTableAttributes),
        [
          "id",
          "type",
          "responsibleUser",
          "consultant",
          "researcher",
          "processProvisionConsultant",
          "project",
          "jobType",
          "deal",
          "amountCovered",
          "status",
          "countableDate",
          "candidate",
          "company",
        ],
        ["id", "processProvisionConsultant", "project", "jobType", "deal", "amountCovered", "candidate", "company"]
      );
    } else if (this.step.key.match(/(managerCalls)$/i)) {
      return this.PrsmTableHelper.setVisibleAttributes(
        angular.copy(this.Action.defaultTableAttributes),
        _.keys(_.omit(this.Action.defaultTableAttributes, "connectionCdsSap")),
        ["type", "responsibleUser", "project", "status", "countableDate", "employees", "description"]
      );
    } else {
      return _.omit(this.Action.defaultTableAttributes, "connectionCdsSap");
    }
  }

  private getAttributes(key: string, defaultAttributes) {
    return this.PrsmTableHelper.getAttributes(key, defaultAttributes);
  }

  public cancel () {
    this.dismiss();
  };
}