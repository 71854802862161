import { Money } from "../../../directives/money/services/money.service";

export default class AmountCoveredConsultantPieChartCtrl {
  public data: number[] = [];
  public labels: string[] = [];
  public colors: string[];
  public options: any;
  public consultantData: any;
  public showSum: boolean;
  public sumData: any;
  public isEmpty: boolean;
  
  static $inject = ["$filter", "ChartColors2021"];

  constructor(private $filter: any, private ChartColors: any) {}

  public $onInit() {
    this.colors = this.ChartColors;
    this.options = {
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return (
              data.labels[tooltipItem.index] +
              ": " +
              this.$filter("number")(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
            );
          },
        },
      },
      plugins: {
        labels: {
          render: (args) => {
            return this.$filter("number")(args.value);
          },
          fontColor: "#000",
          position: "outside",
          outsidePadding: 6,
          textMargin: 6,
        },
      },
    };
  };

  public updateChartData() {
    if (_.isObject(this.consultantData)) {
      if (this.showSum) {
        this.data = [
          this.consultantData.deals.candidateProvisionAmountCovered.cents / 100,
          this.consultantData.deals.customerProvisionAmountCovered.cents / 100,
          this.consultantData.deals.candidateProcessProvisionAmountCovered.cents / 100,
          this.consultantData.deals.processProvisionAmountCovered.cents / 100,
        ];

        this.sumData = new Money({
          cents: Math.round(
            _.reduce(
              this.data,
              (memo, value) => {
                return memo + value;
              },
              0
            )
          ),
          currency: {
            isoCode: this.consultantData.deals.candidateProvisionAmountCovered.currencyIso,
          },
          inCents: true,
        });

        this.data = _.map(this.data, (entry) => {
          return Math.round(entry);
        });
      } else {
        this.data = [
          this.consultantData.deals.candidateProvision,
          this.consultantData.deals.customerProvision,
          this.consultantData.deals.candidateProcessProvision,
          this.consultantData.deals.processProvision,
        ];
      }

      this.labels = [
        this.$filter("translate")("CANDIDATES_LEAD"),
        this.$filter("translate")("CLIENT_LEAD"),
        this.$filter("translate")("CANDIDATES_PROCESS"),
        this.$filter("translate")("OVERALL_PROCESS"),
      ];
    }

    this.isEmpty = !_.reduce(
      this.data, (memo, num) => {
        return memo + num;
      },
      0
    );
  };

  public $onChanges (changes) {
    if (
      (changes.consultantData && changes.consultantData.previousValue != changes.consultantData.currentValue) ||
      (changes.showSum && changes.showSum.previousValue != changes.showSum.currentValue)
    ) {
      this.updateChartData();
    }
  };
};