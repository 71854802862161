export default class WhiteboardCtrl {
  private allUsers: any[];
  private sessionUserId: number;
  private attributes: any;
  private stateParams: any;
  private teams: any[];
  private $transition$: any;
  private users: any[];
  private whiteboardOptions: any;
  private whiteboardConfig: any;

  static $inject = ["makeCollectionProxy",
                    "ProjectReportingsFactory",
                    "UsersFactory",
                    "SavedSearchesFactory",
                    "Session",
                    "PrsmTableAttributeService"];

  constructor (private makeCollectionProxy: any,
    private ProjectReporting: any, 
    private User: any, 
    private SavedSearch: any, 
    private Session: any, 
    private PrsmTableAttributeService: any) {
      this.allUsers = _.filter(User.getAll(), { isSelectable: true });
      this.sessionUserId = Session.user.id;
      this.attributes = {};
      this.initializeLazyLoading = this.initializeLazyLoading.bind(this);
      this.whiteboardQueryFn = this.whiteboardQueryFn.bind(this);
      this.initializeWhiteboardSearch = this.initializeWhiteboardSearch.bind(this);
      this.setKpiKey = this.setKpiKey.bind(this);

    }

  
  public whiteboardQueryFn(params) {
    return this.ProjectReporting.$get("/project_reporting/whiteboard", params);
  }

  public setTableAttributes(userOrTeam) {
    this.attributes[userOrTeam.id] = new this.PrsmTableAttributeService(
      "whiteboard-table-userOrTeam-" + userOrTeam.id + "-attributes",
      this.ProjectReporting.whiteboardAttributes
    ).getAttributes();
  }

  public initializeLazyLoading(userOrTeam, params) {
    var advancedQuery = new this.SavedSearch({
      defaultCondition: { modifier: "must", field: "company_name", query: "" },
      out: { show: true, range: {} },
      deal: { show: true, range: {} },
      kpiFilter: {},
    });

    advancedQuery.deleteAdvancedSearchCondition(0);

    var advancedWhiteboardSearchOptions: any = {
      initFn: this.initializeWhiteboardSearch,
      advancedQuery: advancedQuery,
    };

    this.setTableAttributes(userOrTeam);

    advancedWhiteboardSearchOptions.user = userOrTeam;

    _.defaults(userOrTeam, {
      $attributes: this.attributes[userOrTeam.id],
      $advancedQuery: advancedQuery,
      $advancedWhiteboardSearchOptions: advancedWhiteboardSearchOptions,
    });

    userOrTeam.$whiteboardsLazyLoading = true;

    params = _.extend(
      { advanced: JSON.stringify(userOrTeam.$advancedQuery.beforeStringify()) },
      _.omit(this.stateParams, "user_id"),
      params
    );
    this.makeCollectionProxy(this.whiteboardQueryFn.bind(this.ProjectReporting), params).then( (result) => {
      userOrTeam.$currentLonglistEntries = result;
      userOrTeam.$whiteboardsLazyLoading = false;
    });
  }

  public initializeWhiteboardSearch (userOrTeam) {
    if (userOrTeam.resourceType == "User") {
      this.initializeLazyLoading(userOrTeam, { user_id: userOrTeam.id });
    } else {
      this.stateParams["teams[]"] = _.chain(this.stateParams["teams[]"]).compact().flatten().value();
      this.teams = this.User.pickTranslatedTeams(this.stateParams["teams[]"]);

      this.initializeLazyLoading(userOrTeam, { "teams[]": [userOrTeam.id] });
    }
  };

  public $onInit () {
    this.stateParams = _.pick(this.$transition$.params(), _.identity);
    this.users = _.map(this.whiteboardConfig.whiteboardEntries, (user) => {
      return new this.User(user);
    });
    this.Session.user.usersOnWhiteboard = this.users;
    this.whiteboardOptions = {
      userQuery: { users: this.Session.user.usersOnWhiteboard },
    };
    _.each(this.users, this.initializeWhiteboardSearch);
  };

  public updateWhiteboardProjectReporting (nv, ov) {
    if (nv.users && nv.users.length > 0) {
      var old_users =
        ov.users ||
        _.filter(this.users, (user) => {
          return user.resourceType == "User";
        });
      var diff1 = _.difference(_.pluck(nv.users, "id"), _.pluck(old_users, "id"));
      var diff2 = _.difference(_.pluck(old_users, "id"), _.pluck(nv.users, "id"));

      _.each(diff1, (id) => {
        var add_user = _.findWhere(nv.users, { id: id });
        this.Session.user.usersOnWhiteboard.push(add_user);
        this.initializeWhiteboardSearch(add_user);
      });

      _.each(diff2, (id) => {
        var remove_user = _.where(old_users, { id: id });
        var idx = _.findIndex(this.Session.user.usersOnWhiteboard, { id: id });
        if (idx > -1) {
          this.Session.user.usersOnWhiteboard.splice(idx, 1);
        }
      });
      this.Session.user.save();
    } else if (nv.team) {
      if (
        _.findIndex(this.Session.user.usersOnWhiteboard, (userOrTeam: any) => {
          return userOrTeam.resourceType != "User" && nv.team.id == userOrTeam.id;
        }) > -1
      ) {
        return;
      }

      this.Session.user.usersOnWhiteboard.unshift(nv.team);

      this.whiteboardOptions.userQuery.users = _.filter(this.Session.user.usersOnWhiteboard, (user: any) => {
        return user.resourceType == "User";
      });

      this.initializeWhiteboardSearch(nv.team);
    }
  };

  public removeUser (user) {
    var idx = _.findIndex(this.Session.user.usersOnWhiteboard, { id: user.id });
    if (idx > -1) {
      this.Session.user.usersOnWhiteboard.splice(idx, 1);

      this.Session.user.save();
    }
  };

  public setKpiKey (key, userOrTeam) {
    userOrTeam.$advancedQuery.kpiFilter[key] = !userOrTeam.$advancedQuery.kpiFilter[key];
    this.initializeWhiteboardSearch(userOrTeam);
  };
}