//= ../components.module.js
import { Tracking } from "../../services/trackings.factory";

angular.module("trackings.components").controller("TrackingsCtrl", [
  "$http",
  "TrackingsSerializer",
  function ($http, TrackingsSerializer) {
    var _this = this;
    _this.$onInit = function () {
      _this.loading = true;
      $http.get(_this.action.$url("/trackings.json")).then(function (result) {
        const apiResponse = result.data;
        _this.count = apiResponse.meta.count;
        const trackings: Tracking[] = new TrackingsSerializer().deserialize(apiResponse.data.trackings);
        _this.last = _.chain(trackings).sortBy("createdAt").last().value();
        _this.loading = false;
      });
    };
  },
]);
