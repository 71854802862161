export default class eventConfirmModalCtrl {
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private action: any;
  private resolve: any;
  
  static $inject = ["$filter"];


  constructor (private $filter: ng.IFilterService) {}

  public $onInit () {
    this.action = this.resolve.action;
  };

  public emailMissing (draft) {
    if (this.action.actionType.isTelephoneAction() && this.action.isMsTeamsMeeting) {
      return _.some(draft.attendees,  (attendee) => {
        return _.isEmpty(attendee.email);
      });
    }
  };

  public getMissingEmails (draft) {
    var results = "";

    _.each(draft.attendees,  (attendee) => {
      if (_.isEmpty(attendee.email)) {
        if (attendee.role == "Employee") {
          results = results + this.$filter("translate")("EMPLOYEE_WORK_EMAIL_MISSING", { name: attendee.name });
        } else if (attendee.role == "Candidate") {
          results = results + this.$filter("translate")("CANDIDATE_PRIVATE_EMAIL_MISSING", { name: attendee.name });
        }
      }
    });
    return results;
  };

  public ok () {
    this.modalInstance.close(true);
  };

  public cancel () {
    this.modalInstance.close(false);
  };

}