//= require ./modals.module.js

angular.module("candidates.modals").factory("CandidatesModalMixin", [
  "$uibModal",
  "$filter",
  "$translate",
  "poller",
  "ModalUtilMixin",
  function ($uibModal, $filter, $translate, poller, ModalUtilMixin) {
    function CandidatesModalMixin() {}

    function openQualificationSummaryModal() {
      var _this = this;
      var title = $filter("translate")("SEND_SUMMARY_QUALIFICATION");

      if (!_this.privateEmail && !_this.workEmail) {
        window.alert($filter("translate")("PRESENTATION_ADD_EMAIL_ADDRESS_TO_CANDIDATE_NOTICE"));
        return;
      } else if (!_this.privateEmail) {
        if (!window.confirm($filter("translate")("NO_PRIVATE_EMAIL_CANDIDATE_NOTICE"))) {
          return;
        }
      }

      var modalInstance = ModalUtilMixin.createChooseContactModal("create_qualification_summary_email", title, this);

      return modalInstance.result.then(
        function (qualificationSummaryEmail) {
          qualificationSummaryEmail.callback.then(function (res) {
            var pollingService = poller.get(res.data.url, { delay: 1000 });
            _this.confirm($filter("translate")("EMAIL_SUMMARY_CONFIRMATION"), pollingService).then(
              function (res) {
                pollingService.remove();
              },
              function () {
                pollingService.remove();
              }
            );
          });
        },
        function () {}
      );
    }

    function showDuplicatesModal(candidate, duplicates) {
      var modalInstance = $uibModal.open({
        component: "candidateShowDuplicates",
        size: "lg",
        resolve: {
          duplicates: function () {
            return duplicates;
          },
          candidate: function () {
            return candidate;
          },
        },
      });

      return modalInstance.result;
    }

    CandidatesModalMixin.extended = function (Resource) {
      Resource.include({
        openQualificationSummaryModal: openQualificationSummaryModal,
        showDuplicates: showDuplicatesModal,
      });
    };

    return CandidatesModalMixin;
  },
]);
