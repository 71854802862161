import { SearchToQuicklistButtonCtrl } from "./search-to-quicklist-button.controller";
import searchToQuicklistButtonTemplate from "./search-to-quicklist-button.template.html";

angular.module("quicklists.components").component("searchToQuicklistButton", {
  bindings: {
    advancedQuery: "=",
  },
  template: searchToQuicklistButtonTemplate,
  controller: SearchToQuicklistButtonCtrl,
});
