import LanguageSelectorCtrl from "./language-selector.controller";
import languageSelectorTemplate from "./language-selector.template.html";

angular.module("directives.advanced_search").component("languageSelector", {
  bindings: {
    condition: "=",
  },
  template: languageSelectorTemplate,
  controller: LanguageSelectorCtrl,
});
