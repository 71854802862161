//= require ./services.module.js

angular.module("services").service("DuplicateChecker", [
  "$http",
  "$q",
  "ResourceDependencyInjector",
  function ($http, $q, ResourceDependencyInjector) {
    function DuplicateChecker(personObj) {
      var canceler;

      var person = personObj;
      var resource = ResourceDependencyInjector.inject(person.serviceName || person.constructor.name);

      var oldPerson = _.pick(person, resource.duplicatesWatchFields);

      function getDuplicates(queryArguments) {
        if (canceler) {
          canceler.resolve();
        }
        canceler = $q.defer();

        var query = _.compact(queryArguments).join(" ");

        return $http
          .get("/person/check_duplicates.json", {
            params: {
              q: query.replace(/\//g, " "),
              person_id: person.id,
              person_type: person.resourceType,
            },
            timeout: canceler.promise,
          })
          .then(function (response) {
            // append the query as first autocomplete suggestion
            return _.map(response.data.data, function (duplicate) {
              var _resource = ResourceDependencyInjector.inject(duplicate.resource_type);
              return new _resource(duplicate);
            });
          })
          .catch(angular.noop);
      }

      function duplicatesWatch(queryArguments) {
        if (!_.some(queryArguments)) {
          return;
        }

        getDuplicates(queryArguments).then(function (duplicateReturn) {
          person.$duplicates = {};
          _.each(duplicateReturn, function (duplicate) {
            person.$duplicates[duplicate.resourceType] = person.$duplicates[duplicate.resourceType] || [];
            person.$duplicates[duplicate.resourceType].push(duplicate);
          });

          person.$hasDuplicates = _.keys(person.$duplicates).length > 0;
        });
      }

      this.getDuplicates = getDuplicates;

      this.doCheck = function () {
        var pickedPerson = _.pick(person, resource.duplicatesWatchFields);

        if (!angular.equals(oldPerson, pickedPerson)) {
          duplicatesWatch(_.values(pickedPerson));
          oldPerson = pickedPerson;
        }
      };

      // initial call
      duplicatesWatch(_.values(oldPerson));
    }

    DuplicateChecker.getDuplicates = function (queryArguments, person) {
      var duplicateChecker = new DuplicateChecker(person);
      return duplicateChecker.getDuplicates(queryArguments);
    };

    return DuplicateChecker;
  },
]);
