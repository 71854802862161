import CompanySearchCtrl from "./company-search.controller"
import companySearchTemplate from "./company-search.template.html";

angular.module("companies.components").component("companySearch", {
  bindings: {
    advancedQuery: "=",
    query: "=",
    remote: "=",
    setData: "&",
    savedSearches: "=",
    disableSavedSearch: "@",
  },
  template: companySearchTemplate,
  controller: CompanySearchCtrl,
});
