import employeesPriorityPopoverTemplate from "../../../priorities/components/priority-popover/priority-popover.template.html";

export default class EmployeesShowCtrl {
  public priorityPopoverTemplate: string;
  public projectAttributes: any;
  public activeProject: any;
  public employee: any;
  public deregActiveRowChanged: () => void;
  public deregActiveRowClosed: () => void;
  public deregActiveRowConnector: () => void;
  private initializeActiveRowConnector: (employee: any) => () => void;

  static $inject = [
    "$rootScope",
    "makeCollectionProxy",
    "EmployeesFactory",
    "ProjectsFactory",
    "PrsmTableHelper",
    "DeckControllerMixin"
  ];

  constructor (
    private $rootScope: IExtendedRootScopeService,
    private makeCollectionProxy: any,
    public Employee: any,
    private Project: any,
    private PrsmTableHelper: any,
    private DeckControllerMixin: any,
  ) {
    this.priorityPopoverTemplate = employeesPriorityPopoverTemplate;
    
    this.projectAttributes = this.PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.Project.defaultTableAttributes),
      ["id", "title", "projectManager", "kpis", "editableStatus", "priority", "createdAt"],
      ["id", "title", "editableStatus", "kpis"]
    );
  
    this.deregActiveRowChanged = this.$rootScope.$on("activeRowChanged", (event, newActiveRow) => {
      if (newActiveRow.resourceType == "Project") {
        this.activeProject = newActiveRow;
      }
    });
    this.deregActiveRowClosed = this.$rootScope.$on("activeRowClosed", (event, activeRow) => {
      if (activeRow.resourceType == "Project") {
        this.activeProject = undefined;
      }
    });
  }

  public $onInit() {
    angular.extend(this, this.DeckControllerMixin);

    this.$rootScope.setPageTitle(this.employee.fullNameWithTitle);

    this.employee.tags = _.union(this.employee.tags, this.employee.computerSkills);

    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.employee);
  };

  public $onDestroy() {
    this.deregActiveRowConnector();
    this.deregActiveRowChanged();
    this.deregActiveRowClosed();
  };
}