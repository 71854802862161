import { initContract } from "@ts-rest/core";

import { TDepartments } from "../departments";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const DepartmentsSchema = c.type<TBaseResource<TDepartments[]>>();
const SingleDepartmentSchema = c.type<TBaseResource<TDepartments>>();

export const departmentsContract = c.router({
  query: {
    method: "GET",
    path: "/departments",
    responses: {
      200: DepartmentsSchema,
    },
    summary: "Get all departments",
  },
  getHierarchies: {
    method: "GET",
    path: "/departments/:id/hierarchies",
    responses: {
      200: SingleDepartmentSchema,
    },
    summary: "Get a department by ID",
  },
});
