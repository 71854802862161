//= require ./services.module.js
angular
  .module("services")

  // Try to retrieve a field first try to find it in its highlighted format,
  // if that does not exist, get it from the normal record (unhighlighted)
  .factory("Highlighter", [
    "recursiveAccess",
    "$sce",
    function (recursiveAccess, $sce) {
      function Highlighter() {}

      function highlighted(field) {
        var highlightedText;
        var obj = this;

        if (obj.highlight) {
          highlightedText =
            obj.highlight[field + ".standard"] ||
            obj.highlight[field + ".autocomplete"] ||
            obj.highlight[field + ".highlight"] ||
            obj.highlight[field + ".raw"] ||
            obj.highlight[field + ".de"] ||
            obj.highlight[field + ".en"] ||
            obj.highlight[field] ||
            recursiveAccess(obj, field.split("."));
        } else {
          highlightedText = obj[field];
        }

        highlightedText = _.isArray(highlightedText) ? _.first(highlightedText) : highlightedText;

        return $sce.trustAsHtml(highlightedText);
      }

      Highlighter.extended = function (Resource) {
        Resource.include({
          highlighted: highlighted,
        });
      };

      return Highlighter;
    },
  ]);
