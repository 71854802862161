
export default class {
  private action: any;
  private object: any;
  private options: { [key: string]: any };
  private registerSaveHotkey: (callback: Function) => void;
  private force_validation: boolean;
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private actionForm: ng.IFormController;
  private onSaveError: (error: any) => void; ;
  private resolve: any;
  static $inject = ["$scope", "$rootScope", "$filter", "EditUtilMixin", "ModalUtilMixin", "daterangepickerOptions", "$templateCache"];

  constructor ( private $scope: any,
                private $rootScope: ng.IRootScopeService,
                private $filter: ng.IFilterService,
                private EditUtilMixin: any,
                private ModalUtilMixin: any,
                private daterangepickerOptions: any,
                private $templateCache: ng.ITemplateCacheService,
                ) {
                  this.saveChanges = this.saveChanges.bind(this);
                  this.$scope.$on("modal.closing", (event) => {
                    if (this.action && this.action.unsnappedChanges() || (this.object && this.object.unsnappedChanges())) {
                      if (window.confirm(this.$filter("translate")("WINDOW_CLOSING_CHANGES_WARNING"))) {
                        this.action.rollback(-1);
                        if (this.object) {
                          this.object.rollback(-1);
                        }
                      } else {
                        event.preventDefault();
                      }
                    }
                  });
                }

  
  public $onInit () {

    angular.extend(
      this,
      {
        actionTypes: this.resolve.actionTypes,
        jobCategories: this.resolve.jobCategories,
        jobTypes: this.resolve.jobTypes,
        projectsCount: this.resolve.projectsCount,
        action: _.defaults(this.resolve.action, { separateCandidatesEvent: false, sendInvitationToManagers: true }),
        options: _.defaults(this.resolve.options, {
          projectEditable: this.resolve.projectsCount > 0 && !this.resolve.options.actionTemplate,
        }),
        candidates: this.resolve.candidates,
        dateRangePickerOptions: _.extend(this.daterangepickerOptions, {
          timePicker24Hour: true,
          singleDatePicker: true,
          locale: _.extend(this.daterangepickerOptions.locale, {
            format: "DD.MM.YYYY HH:mm",
          }),
        }),
      },
      this.EditUtilMixin,
      this.ModalUtilMixin
    );

    if (this.action.candidate && this.action.candidate.resourceType === "Candidate") {
      this.object = angular.copy(this.action.candidate);
      this.action.employee = undefined; // workaround for corresponding employee
    } else {
      if (this.action.employee && this.action.employee.resourceType === "Employee") {
        this.object = angular.copy(this.action.employee);
      } else {
        if (this.action.company && this.action.company.resourceType === "Company") {
          this.object = angular.copy(this.action.company);
        }
      }
    }

    this.action.snapshot();
    if (this.object) {
      this.object.snapshot();
    }

    if (this.options.actionTemplate) {
      this.$templateCache.put(
        this.options.actionTemplate,
        "<" +
          this.options.actionTemplate +
          ' object="$ctrl.object"' +
          ' action="$ctrl.action"' +
          ' save-changes="$ctrl.saveChanges"' +
          ' resolves="$ctrl.resolve"' +
          "><" +
          this.options.actionTemplate +
          "/>"
      );
    }

    // perform after everything is rendered
    this.registerSaveHotkey(this.saveChanges);
  };

  public multipleManagers () {
    return !(this.action.actionableType === "Company" || this.action.actionableType === "WorkingRelationship");
  };

  public getCompanyId () {
    if (this.action.actionableType == "Company") {
      return this.action.actionableId;
    } else if (this.action.actionableType == "WorkingRelationship") {
      return this.action.company.id;
    } else if (this.action.project && !this.action.project.initiativeApplicationProject) {
      return this.action.project.company ? this.action.project.company.id : undefined;
    } else {
      return undefined;
    }
  };

  public showEmployeesSelect () {
    return (
      (((this.action.project && this.action.project.employeesCount > 0) || this.action.employees?.length > 0) &&
        !this.action.employee) ||
      this.action.company
    );
  };

  public showInterviewFollowUpOptions () {
    return (
      (this.action.actionType.isInterviewActionType() || this.action.actionType.isTelephoneAction()) &&
      this.action.candidate &&
      this.action.employees.length > 0 &&
      this.options.followUps
    );
  };

  public showContactDetailsEventText () {
    return (
      (this.action.candidate || this.action.employee || this.action.employees) &&
      this.action.project &&
      this.action.actionType.isEvent &&
      !this.action.isMsTeamsMeeting
    );
  };

  public saveChanges (closeAfterCallback) {
    this.force_validation = false;
    if (!_.isFunction(closeAfterCallback)) {
      closeAfterCallback = (scope: any) => {
        this.modalInstance.close(this.action);
      };
    }

    if (this.actionForm.$invalid) {
      if (this.actionForm.individualTeamsLink && this.actionForm.individualTeamsLink.$invalid) {
        window.alert(this.$filter("translate")("INVALID_TEAMS_URL_NOTICE"));
      } else if (
        !_.isEmpty(
          _.compact(
            _.map(this.actionForm.$error.required, function (attr) {
              _.filter(attr, { name: "project" });
            })
          )
        )
      ) {
        window.alert(this.$filter("translate")("SELECT_PROJECT_NOTICE"));
        return;
      } else {
        window.alert(this.$filter("translate")("FILL_MANDATORY_FIELDS_NOTICE"));
      }
      this.force_validation = true;
      return;
    }

    if (
      !this.options.followUps &&
      this.object &&
      !this.object.unsnappedChanges() &&
      this.actionForm["actionDescription-tinymce"].$invalid
    ) {
      window.alert(this.$filter("translate")("RECORD_ACTIVITY_PROCESS_NOTICE"));
      this.force_validation = true;
    } else {
      this.action.snapshot();
      if (this.object && this.object.unsnappedChanges()) {
        this.object.snapshot();
        this.object
          .save()
          .then( (savedObject) => {
            if (this.action.candidate) {
              angular.extend(this.action.candidate, _.omit(savedObject, ["actions", "candidatesProjects"]));
            }

            this.action.snapshot();
            this.object.snapshot();

            closeAfterCallback(this);
          })
          .catch(this.onSaveError.bind(this));
      } else {
        closeAfterCallback(this);
      }
    }
  };

  public finishFollowUp () {
    this.saveChanges( () => {
      this.action
        .$post(this.action.$url("/complete.json"))
        .then( (result) => {
          _.extend(this.action, _.pick(result, ["completedAt", "completedByUser"]));
          this.action.snapshot();
          this.modalInstance.close(this.action);
        })
        .catch( (result) => {
          if (result.status == 409) {
            // conflict if already completed
            this.modalInstance.close(this.action);
          } else {
            throw result;
          }
        });
    });
  };

  public declineFollowUp () {
    this.saveChanges( () => {
      this.action.$post(this.action.$url("/decline.json")).then( (result) => {
        _.extend(this.action, _.pick(result, ["declinedAt", "declinedByUser"]));
        this.action.snapshot();
        this.modalInstance.close(this.action);
      });
    });
  };

  public checkAddressForNull(address) {
    var retVal =
      _.isNull(address.street) && _.isNull(address.houseId) && _.isNull(address.zipCode) && _.isNull(address.city);
    return retVal;
  };

  public setAddress(address) {
    if (!address || this.checkAddressForNull(address)) {
      return this.$filter("translate")("NO_ADDRESS_LISTED");
    } else {
      return address.street + " " + address.houseId + "\n" + address.zipCode + " " + address.city;
    }
  };

  public updateContactDetails() {
    if (this.action && this.action.actionType) {
      if (!this.action.actionType.isTelephoneAction()) {
        const changed = this.action.unsnappedChanges();

        this.action.individualTeamsLink = undefined;
        this.action.isMsTeamsMeeting = false;
        if (!changed) {
          this.action.snapshot();
        }
      }

      switch (this.action.actionType.typeName) {
        case "telephone-interview":
        case "telephone-meeting":
          if (this.action.isMsTeamsMeeting) {
            this.action.contactDetailsEventText = this.$filter("translate")("DIAL_IN_TEAMS");
          } else {
            this.action.contactDetailsEventText = this.$filter("translate")("DIAL_PHONE_NUMBER");
          }

          break;
        case "presence-interview":
        case "personal-meeting":
          if (this.action.project && this.action.project.company) {
            this.action.contactDetailsEventText = this.setAddress(this.action.project.operationalSiteAddress);
          }
          break;
        default:
          this.action.contactDetailsEventText = undefined;
      }
    }
  };

  // WIP this is not being called
  public cancel () {
    this.modalInstance.dismiss("cancel");
  };
}