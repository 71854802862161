import { Language } from "../../../languages/services/languages.service";

export class SavedSearchesCtrl {
  static $inject = ["$filter", "SavedSearchesFactory", "focus"];
  constructor(private $filter: ng.IFilterService, private SavedSearchService: any, private focus: any) {}

  public languageLevelInWords: any;
  public savedSearches: any[];
  public savedSearchCount: number;
  public advancedQuery: any;
  public query: string;
  public saved_search: any;
  public saved_search_name_enabled: boolean;
  public submitted: boolean;
  public statePrefix: string;
  public languages: Language[];

  public $onInit() {
    Language.getAll().then((languages) => (this.languages = languages));
    this.languageLevelInWords = Language.levelInWords;
    this.savedSearches = this.SavedSearchService.getAll(this.advancedQuery.resourceType);
    this.savedSearchCount = _.where(this.savedSearches, { system: false }).length;
  }

  public editSavedSearchName(saved_search: any) {
    if (saved_search) {
      this.saved_search = saved_search;
      _.defaults(this.saved_search, { name: this.query || "" });
    } else {
      this.saved_search = new this.SavedSearchService({ name: this.query || "" });
    }
    this.saved_search_name_enabled = true;
    this.focus("saved_search_name_focus");
    this.submitted = false;
    return false;
  }

  public commitSavedSearchName() {
    if (!this.saved_search || this.saved_search.name.length === 0 || this.submitted === true) {
      this.saved_search_name_enabled = false;
    }
  }

  public abortSaveSearch() {
    this.saved_search_name_enabled = false;
    this.saved_search.name = "";
  }

  public saveSearch($event: ng.IAngularEvent) {
    if ($event) $event.preventDefault();
    this.submitted = true;
    this.saved_search_name_enabled = false;
    if (!_.isEqual(this.saved_search, this.advancedQuery)) {
      this.saved_search = new this.SavedSearchService(angular.copy(this.advancedQuery));
    }
    this.saved_search.id = undefined;
    // this.saved_search.saved_search_id = this.saved_search.id;
    this.saved_search.basicQuery = this.query;
    this.saved_search.save().then((res: any) => {
      if (!this.saved_search.id) {
        var new_search = new this.SavedSearchService(res);
        this.savedSearches.push(new_search);
        this.savedSearchCount = this.savedSearchCount + 1;
        this.SavedSearchService.addToCache(new_search);
        this.saved_search = new_search;
        _.extend(this.advancedQuery, new_search);
      } else {
        this.saved_search = res;
        _.extend(this.advancedQuery, res);
      }
      this.submitted = false;
    });
    return false;
  }

  public deleteSearch(search) {
    if (window.confirm(this.$filter("translate")("CONFIRM_DELETE_SAVED_SEARCH_NOTICE"))) {
      search.saved_search_id = search.id;
      search.delete().then(() => {
        this.SavedSearchService.invalidateCache();
        this.SavedSearchService.getAll(this.advancedQuery.resourceType).then((res: any) => {
          this.savedSearches = res;
          this.savedSearchCount = _.where(this.savedSearches, { system: false }).length;
        });
      });
    }
  }

  public state(params) {
    return this.statePrefix + "(" + params + ")";
  }
}
