//= require ../kpis.module.js

angular.module("components.kpis").factory("kpiDetailDirectivesConfig", [
  "$filter",
  "$translate",
  function ($filter, $translate) {
    function bruttoContactsDetailsStep() {
      this.steps = [
        {
          title: "ZG",
          value: $filter("percentage")(this.kpiIST.kpis.bruttoContacts / (this.kpiSOLL.kpis.bruttoContacts || 1), 0),
          description: "BRUTTO_CONTACTS",
          sort: 3,
        },
      ];
    }

    function nettoContactsDetailsStep() {
      this.steps = [
        {
          title: "ZG",
          value: $filter("percentage")(this.kpiIST.kpis.nettoContacts / (this.kpiSOLL.kpis.nettoContacts || 1), 0),
          description: "NETTO_CONTACTS",
          sort: 3,
        },
      ];
    }

    function candidatesIdentifiedDetailsStep() {
      this.steps = [
        { title: "CV", value: this.kpiIST.kpis.candidatesIdentified, description: "CANDIDATES_IDENTIFIED", sort: 1 },
        { title: "SO", value: this.kpiIST.kpis.cvSendout, description: "SENT", sort: 2 },
        {
          title: "ZV",
          value: $filter("percentage")(
            (this.kpiIST.kpis.cvSendout || 1) / (this.kpiIST.kpis.candidatesIdentified || 1) - 1,
            0
          ),
          description: "SECONDARY_EXPLOITATION",
          sort: 3,
        },
      ];
    }

    function qualificationsDetailsStep() {
      this.steps = [
        { title: "K", value: this.kpiIST.kpis.qualifications, description: "CANDIDATES", sort: 1 },
        { title: "M", value: this.kpiIST.kpis.managerCalls, description: "MANAGER", sort: 2 },
      ];
    }

    function sendoutsDetailsStep() {
      this.steps = [
        {
          title: "IB",
          value: this.kpiIST.kpis.sendoutInitiativeApplications,
          description: "INITIATIVE_SENDOUTS",
          sort: 1,
        },
        { title: "E", value: this.kpiIST.kpis.sendoutEntities, description: "ENTITIES", sort: 2 },
        {
          title: "ZV",
          value: $filter("percentage")(
            (this.kpiIST.kpis.sendouts || 1) / (this.kpiIST.kpis.sendoutEntities || 1) - 1,
            0
          ),
          description: "SECONDARY_EXPLOITATION",
          sort: 3,
        },
      ];
    }

    function projectsIdentifiedDetailsStep() {
      this.steps = [
        { title: "A", value: this.kpiIST.kpis.projectsActive, description: "ACTIVE", sort: 1 },
        { title: "I", value: this.kpiIST.kpis.projectsInactive, description: "INACTIVE", sort: 2 },
        {
          title: "DQ",
          value: $filter("percentage")(this.kpiIST.kpis.deals / (this.kpiIST.kpis.projectsIdentified || 1), 0),
          description: "DEAL_RATE",
          sort: 3,
        },
      ];
    }

    function telephoneInterviewsDetailsStep() {
      this.steps = [
        { title: "O", value: this.kpiIST.kpis.telephoneInterviewsOpen, description: "OPEN", sort: 1 },
        { title: "AG", value: this.kpiIST.kpis.telephoneInterviewsCompleted, description: "COMPLETED", sort: 2 },
        { title: "AB", value: this.kpiIST.kpis.telephoneInterviewsDeclined, description: "CANCELED", sort: 3 },
      ];
    }

    function presenceInterviewsDetailsStep() {
      this.steps = [
        { title: "O", value: this.kpiIST.kpis.presenceInterviewsOpen, description: "OPEN", sort: 1 },
        { title: "AG", value: this.kpiIST.kpis.presenceInterviewsCompleted, description: "COMPLETED", sort: 2 },
        { title: "AB", value: this.kpiIST.kpis.presenceInterviewsDeclined, description: "CANCELED", sort: 3 },
      ];
    }

    function managerCallsDetailsStep() {
      this.steps = [
        { title: "O", value: this.kpiIST.kpis.managerCallsOpen, description: "OPEN", sort: 1 },
        { title: "AG", value: this.kpiIST.kpis.managerCallsCompleted, description: "COMPLETED", sort: 2 },
        { title: "AB", value: this.kpiIST.kpis.managerCallsDeclined, description: "CANCELED", sort: 3 },
      ];
    }

    function closingsDetailsStep() {
      this.steps = [
        { title: "D", value: this.kpiIST.kpis.closingsDeal, description: "Deal", sort: 1 },
        { title: "L", value: this.kpiIST.kpis.closingsLost, description: "Lost", sort: 2 },
      ];
    }

    function dealsDetailsStep() {
      this.steps = [
        { title: "FE", value: this.kpiIST.kpis.projectsPermanent, description: "PERMANENT_SHORT", sort: 1 },
        { title: "FR", value: this.kpiIST.kpis.projectsFreelancer, description: "FREELANCER_SHORT", sort: 2 },
        {
          title: "PC",
          value: this.kpiIST.kpis.projectsProjectConsulting,
          description: "PROJECT_CONSULTING",
          sort: 3,
        },
        {
          title: "MC",
          value: this.kpiIST.kpis.projectsManagementConsulting,
          description: "MANAGEMENT_CONSULTING",
          sort: 4,
        },
      ];
    }

    var config = {
      bruttoContacts: {
        setSteps: bruttoContactsDetailsStep,
      },
      nettoContacts: {
        setSteps: nettoContactsDetailsStep,
      },
      candidatesIdentified: {
        setSteps: candidatesIdentifiedDetailsStep,
      },
      qualifications: {
        setSteps: qualificationsDetailsStep,
      },
      projectsIdentified: {
        setSteps: projectsIdentifiedDetailsStep,
      },
      sendouts: {
        setSteps: sendoutsDetailsStep,
      },
      telephoneInterviews: {
        setSteps: telephoneInterviewsDetailsStep,
      },
      presenceInterviews: {
        setSteps: presenceInterviewsDetailsStep,
      },
      managerCalls: {
        setSteps: managerCallsDetailsStep,
      },
      closings: {
        setSteps: closingsDetailsStep,
      },
      deals: {
        setSteps: dealsDetailsStep,
      },
    };

    return config;
  },
]);
