import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { Region } from "./regions.service";
angular
  .module("regions")
  .factory("RegionSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(Region, data);
        this.serialize = serialize;
      });
    },
  ])

  .service("RegionsFactory", Region);
