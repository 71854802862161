import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TCancelationPeriod } from "../cancelation_periods";

export class CancelationPeriod {
  private id: number;
  private name: string;
  static $inject = [];

  constructor(fields?: Partial<TCancelationPeriod>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TCancelationPeriod>>(fields, camelCase));
  }

  @cached
  public static getAll() {
    return baseClient.cancelationPeriods.query().then((response) => {
      return response.body.data.map((element) => {
        return new CancelationPeriod(element);
      });
    });
  }
}
