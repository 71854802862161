import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TLeadershipExperience } from "../leadership_experiences";

export class LeadershipExperience {
  public id: number;
  private name: string;
  static $inject = [];

  constructor(fields?: Partial<TLeadershipExperience>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TLeadershipExperience>>(fields, camelCase));
  }

  @cached
  public static getAll() {
    return baseClient.leadershipExperiences.query().then((response) => {
      return response.body.data.map((element) => {
        return new LeadershipExperience(element);
      });
    });
  }
}
