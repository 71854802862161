import InvoicesTableBodyControllerCtrl from "./invoices-table-body.controller";
import invoicesTableBodyTemplate from "./invoices-table-body.template.html";

angular.module("invoices.components").component("invoicesTableBody", {
  bindings: {
    invoices: "=data",
    attributes: "=",
    activeRow: "<",
    context: "=",
    statePrefix: "@",
    tableRowIdentifier: "=",
  },
  template: invoicesTableBodyTemplate,
  controller: InvoicesTableBodyControllerCtrl,
});
