export class CandidatesAutoSearchCtrl {
  public advancedQuery: any;
  public query: string;
  public project: any;
  public candidates: any; // makeCollectionProxy
  public attributes: any;
  public oldCandidatesSelected: any[];

  public candidatesLazyLoading: boolean;
  public showAutoSearch: boolean;

  static $inject = ["CandidatesFactory", "SavedSearchesFactory", "makeCollectionProxy", "PrsmTableHelper"];
  constructor(
    private CandidateService: any,
    private SavedSearchService: any,
    private makeCollectionProxy: any,
    private PrsmTableHelper: any
  ) {}

  public $onInit() {
    this.advancedQuery = new this.SavedSearchService();

    this.query = _.chain([
      this.tag_query(this.project.tags),
      this.tag_query(this.project.departments),
      this.tag_query(this.project.computerSkills),
    ])
      .compact()
      .map(function (tagQuery) {
        return "(" + tagQuery + ")";
      })
      .join(" AND ")
      .value();

    _.each(this.project.jobCategories, (jobCategory) => {
      this.advancedQuery.conditions.push({
        field: "job_category",
        modifier: "must",
        query: jobCategory,
      });
    });
    this.advancedQuery.deleteAdvancedSearchCondition(0);

    this.attributes = this.PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.CandidateService.defaultTableAttributes),
      ["id", "name", "tags",  "regions", "kpis", "projects", "responsibleUser", "languages", "documents", "createdAt"],
      ["id", "name", "tags", "regions", "kpis"]
    );

    this.candidatesLazyLoading = false;
    this.showAutoSearch = false;

    this.oldCandidatesSelected = _.filter(this.candidates, (candidate) => {
      return candidate.$selectedForLonglist && !candidate.$selectedBySystem;
    });
  }

  public $doCheck() {
    let candidatesSelected: any = _.filter(this.candidates, (candidate) => {
      return candidate.$selectedForLonglist && !candidate.$selectedBySystem;
    });
    if (!angular.equals(candidatesSelected, this.oldCandidatesSelected)) {
      _.chain(candidatesSelected)
        .difference(this.oldCandidatesSelected)
        .each((candidate) => {
          candidate.addToProject(this.project);
        });
      this.oldCandidatesSelected = angular.copy(candidatesSelected);
    }
  }

  public startAutoSearch() {
    this.showAutoSearch = true;
    this.advancedQuery.basicQuery = this.query;
    this.updateCandidates(this.advancedQuery.collectSearchParams());
  }

  private tag_query(tags) {
    return _.chain(tags)
      .map((tag) => {
        return '"' + tag.name + '"';
      })
      .join(" OR ")
      .value();
  }

  private initSearchResults(candidates) {
    let longlistEntries: any = this.getLonglistedCandidates();
    _.chain(candidates)
      .filter((candidate) => {
        return _.findWhere(longlistEntries, { id: candidate.id });
      })
      .each((candidate) => {
        candidate.$selectedForLonglist = true;
        candidate.$selectedBySystem = true;
      });
    this.candidates = candidates;
    this.candidatesLazyLoading = false;
  }

  private updateCandidates(newParams) {
    if (_.isUndefined(this.candidates)) {
      this.candidatesLazyLoading = true;
      this.makeCollectionProxy(this.CandidateService.query.bind(this.CandidateService), newParams).then(
        (candidates: any) => this.initSearchResults(candidates)
      );
    } else {
      this.candidates.search(newParams).then((candidates: any) => this.initSearchResults(candidates));
    }
  }

  private getLonglistedCandidates() {
    let ret: any = _.chain(this.project.candidatesProjects)
      .reject((cp) => {
        return !cp.candidate || cp.deleted;
      })
      .map((cp) => {
        return cp.candidate;
      })
      .value();
    return ret;
  }
}
