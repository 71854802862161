import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";
import { baseClient, cached } from "../../api-routing/base-client";
import { TRegion } from "../regions";

export class Region {
  private serviceName: string = "RegionsFactory";
  private id: number;
  private name: string;
  static $inject = [];

  constructor(fields?: Partial<TRegion>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TRegion>>(fields, camelCase));
  }

  @cached
  public static getAll() {
    return baseClient.regions.query().then((response) => {
      return response.body.data.map((element) => {
        return new Region(element);
      });
    });
  }
}
