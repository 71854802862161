import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { Graduation } from "./graduations.service";

angular
  .module("graduations")
  .factory("GraduationsSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(Graduation, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("GraduationsFactory", Graduation);
