export default class AdvancedActionsSearchCtrl {
  private followUps: boolean;
  private setData: () => void;
  private closing: () => void;
  private selectableFields: string[] = ["action_type", "responsible_user"];

  constructor () {}

  public $onInit () {
    if (!this.followUps) {
      this.selectableFields.push("completed_by_user");
    }
  };

  public performAdvancedSearch () {
    this.setData();
    this.closing();
  };
}