import { CandidatesAutoSearchCtrl } from "./candidates-auto-search.controller";
import candidatesAutoSearchTemplate from "./candidates-auto-search.template.html";

angular.module("projects.components").component("candidatesAutoSearch", {
  bindings: {
    project: "=",
    savedSearches: "=",
    activeRow: "=",
    initialViewState: "<",
  },
  template: candidatesAutoSearchTemplate,
  controller: CandidatesAutoSearchCtrl,
});
