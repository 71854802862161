export default class CvLastUpdateSelectorCtrl {
  public goldenDaysInWeeksAgo: number; 
  public cvDateSearchUniqID: string;
  public advancedQuery: any;

  constructor () {
    this.goldenDaysInWeeksAgo = parseInt(
      String((new Date().getTime() - new Date(1990, 1, 1).getTime()) / (24 * 3600 * 1000 * 7))
    );
    this.cvDateSearchUniqID = _.uniqueId("cvDateSearchEnabled"); 
  };

  public $onInit() {
    _.defaults(this.advancedQuery, { cvLastUpdateEnabled: false });
  };
};
