import EditContractDraftCtrl from "./edit-contract-draft.controller";
import editContractDraftTemplate from "./edit-contract-draft.template.html";

angular.module("contract_drafts.components").component("editContractDraft", {
  bindings: {
    provision: "<",
    contractDraft: "=",
    saving: "<",
  },
  controller: EditContractDraftCtrl,
  template: editContractDraftTemplate,
});
