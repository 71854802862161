import CompaniesAdvancedSearchCtrl from "./companies-advanced-search.controller";
import companiesAdvancedSearchTemplate from "./companies-advanced-search.template.html";

angular.module("companies.components").component("companiesAdvancedSearch", {
  bindings: {
    advancedQuery: "=",
    closing: "&",
    query: "=",
    remote: "=",
    setData: "&",
  },
  template: companiesAdvancedSearchTemplate,
  controller: CompaniesAdvancedSearchCtrl,
});
