import PopoverTranscludeCtrl from "./popover-transclude.controller";

angular.module("directives.advanced_search").component("popoverTransclude", {
  bindings: {
    directiveName: "=",
    directiveOptions: "=",
  },
  template: "<ng-include src='$ctrl.directiveNameIdentifier'></ng-include>",
  controller: PopoverTranscludeCtrl,
});
