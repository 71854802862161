//= require ../contract_draft.module.js

angular.module("contract_drafts").service("ContractDraftFactory", [
  "RailsResource",
  "railsSerializer",
  "poller",
  "RailsResourceSnapshotsMixin",
  function (RailsResource, railsSerializer, poller, RailsResourceSnapshotsMixin) {
    function ContractDraft() {
      (<any>ContractDraft).__super__.constructor.apply(this, arguments);

      this.pollJobData = function (jobFailedCondition, jobSuccessfulCondition) {
        let _contractDraft = this;
        const pollingService = poller.get(_contractDraft.$url() + ".json", { catchError: true, delay: 1000 });
        pollingService.promise.then(null, null, function (response) {
          if (response.status === 200) {
            // @ts-ignore
            const updatedContractDraft = new ContractDraft(response.data.data);
            _.extend(_contractDraft, updatedContractDraft);
            // _contractDraft.jobStatus = updatedContractDraft.jobStatus;
            if (jobFailedCondition(updatedContractDraft)) {
              pollingService.remove();
            } else if (jobSuccessfulCondition(updatedContractDraft)) {
              console.log("Job was successful");
              pollingService.remove();
            } else if (!updatedContractDraft.generating) {
              _contractDraft.jobStatus = null;
              _contractDraft.snapshot();
              pollingService.remove();
            }
          } else {
            pollingService.remove();
          }
        });
      };

      this.pollCdsJobData = function () {
        const jobSuccessfulCondition = (updatedContractDraft) =>
          !updatedContractDraft.generating && updatedContractDraft.hasCdsId;
        const jobFailedCondition = (updatedContractDraft) => updatedContractDraft.failed;
        this.pollJobData(jobFailedCondition, jobSuccessfulCondition);
      };
    }

    ContractDraft.createContractDraft = function (provision) {
      return this.$get(this.$url("new.json"), provision.contractDraftParams());
    };

    RailsResource.extendTo(ContractDraft);
    (<any>ContractDraft).configure({
      url: "/contract_drafts",
      name: "contract_draft",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.serializeWith("startDate", "DateSerializer");
        this.serializeWith("endDate", "DateSerializer");
        this.serializeWith("monthlyGrossSalary", "MoneySerializer");
        this.serializeWith("internalCost", "MoneySerializer");
        this.serializeWith("internalCostVk", "MoneySerializer");
        this.serializeWith("interCompanyRate", "MoneySerializer");
        this.exclude("$errors");
        this.preserve("$errors");
      }),
    });

    (<any>ContractDraft).extend(RailsResourceSnapshotsMixin);
    return ContractDraft;
  },
]);
