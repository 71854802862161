export default class openActionModalCtrl {
  private dereg: () => void;
  private cpjoin: any;
  private action: any;
  private loading: boolean;
  private lastEdit: any;
  private object: any;
  private options: any;
  private dismiss: () => void;
  private resolve: any;

  static $inject = ["$scope", "$filter", "UsersFactory", "DealsFactory", "dynamicRoute", "$rootScope"];

  constructor(private $scope: any,
              private $filter: ng.IFilterService,
              private User: any,
              private Deal: any,
              private dynamicRoute: any,
              private $rootScope: ng.IRootScopeService,
              ) {


              this.$scope.$watchCollection("$ctrl.action.versions.length",  (nv, ov) => {
                if (ov > 0) {
                  var lastVersion = this.action.versions[this.action.versions.length - 1];
                  this.lastEdit = {
                    user: User.findBy(parseInt(lastVersion.whodunnit)),
                    timestamp: lastVersion.createdAt,
                  };
                }
              });

              }


  public $onInit () {
    angular.extend(this.resolve);
    if (this.cpjoin && !this.cpjoin.expertProfile.isNew()) {
      this.cpjoin.expertProfile.cpjoin = this.cpjoin;
    }

    angular.extend(
      this,
      {
        action: this.resolve.action,
        options: this.resolve.options,
        object: this.resolve.object,
        cpjoin: this.resolve.cpjoin,
        isConvertableToEvent:
          !!this.resolve.action.actionType.getActionTypeOptions(this.resolve.object).convertToEvent &&
          this.resolve.action.id &&
          this.resolve.action.plannedFor &&
          !this.resolve.action.actionType.isEvent,
      },
      this.dynamicRoute
    );
  };

  public editDeal () {
    this.loading = true;
    this.dereg = this.$rootScope.$on("deal-form-opened",  (event) => {
      this.loading = false;
      this.dereg();
    });

    if (this.action.deal) {
      this.Deal.get(this.action.deal.id)
        .then( (deal) => {
          deal.edit();
        })
        .catch( () => {
          this.loading = false;
          this.dereg();
        });
    } else {
      var dealOptions = {
        add_action: { id: this.action.id },
        candidatesProjectId: this.cpjoin.id,
        closing: this.action.isActionType("closing"),
      };

      if (this.action.actionableType == "EmployeesCandidatesProject") {
        _.extend(dealOptions, { employeesCandidatesProjectId: this.action.actionableId });
      }

      this.Deal.createAndEdit(dealOptions).catch( () => {
        this.loading = false;
        this.dereg();
      });
    }
  };

  public cancel () {
    this.dismiss();
  };

  public editAction (options) {
    this.action.actionEditModal(this.object, options).catch(angular.noop);
  };

  public deleteFollowUp () {
    this.action.deleteNote(this.object).then(
      (result) => {
        this.action.snapshot();
        this.cancel();
      },
      () => {
        window.alert(this.$filter("translate")("HISTORY_ENTRIES_NOT_DELETED_NOTICE"));
      }
    );
  };

  public decline () {
    this.action.$post(this.action.$url("/decline.json")).then(
      (result) => {
        _.extend(this.action, _.pick(result, ["declinedAt", "declinedByUser"]));
        this.action.snapshot();
      },
      () => {
        window.alert(this.$filter("translate")("HISTORY_ENTRIES_NOT_DECLINED_NOTICE"));
      }
    );
  };

  public convertIntoEvent () {
    this.action.convertIntoEvent().then( (result) => {
      if (result) {
        this.action.actionEditModal(this.object, this.options).catch(angular.noop);
      }
    });
  };

  public $onDestroy () {
    if (angular.isFunction(this.dereg)) {
      this.dereg();
    }
  };
}
