import JobOfferSearchCtrl from "./job-offers-search.controller";
import jobOffersSearchTemplate from "./job-offers-search.template.html";

angular.module("directives.advanced_search").component("jobOffersSearch", {
  bindings: {
    advancedQuery: "=",
  },
  template: jobOffersSearchTemplate,
  controller: JobOfferSearchCtrl,
});
