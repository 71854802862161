import EmployeesAdvancedSearchCtrl from "./employees-advanced-search.controller";
import employeesAdvancedSearchTemplate from "./employees-advanced-search.template.html";

angular.module("employees.components").component("employeesAdvancedSearch", {
  bindings: {
    advancedQuery: "=",
    query: "=",
    remote: "=",
    setData: "&",
    closing: "&",
  },
  template: employeesAdvancedSearchTemplate,
  controller: EmployeesAdvancedSearchCtrl,
});
