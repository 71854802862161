import briefingTemplateTemplate from "./briefing-template.template.html";

angular.module("action_types.components").component("briefingTemplate", {
  bindings: {
    action: "<",
    object: "<?",
    saveChanges: "<?",
  },
  template: briefingTemplateTemplate,
});
