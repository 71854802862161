export class TextkernelImportFinishedModalCtrl {
  static $inject = ["$q", "Textkernel"];
  constructor(private $q: angular.IQService, private Textkernel: any) {}

  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  public loading: boolean;
  public projects: any[];
  private resolve: {
    projects: any[];
    promises: ng.IPromise<any>[];
  };

  public $onInit() {
    this.projects = this.resolve.projects;
    this.loading = true;

    this.$q.all(this.resolve.promises).finally(() => {
      _.each(this.projects, (cpjoin) => {
        this.Textkernel.addHistoryPoint("tk-projekt-created", cpjoin);
      });

      this.loading = false;
    });
  }

  public ok() {
    this.modalInstance.close(true);
  }

  public cancel() {
    this.modalInstance.close(false);
  }
}
