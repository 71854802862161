export class SendOpenJobOfferModalCtrl {
  public cpjoin: any;
  public jobOffers: any;
  public email: any;
  public user: any;
  public resolve: any;
  public openJobOfferEmail: any;
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = ["Session"];
  
  constructor(private Session: any) {}

  private extend(resolve) : void {
    this.cpjoin = resolve.cpjoin;
    this.jobOffers = resolve.jobOffers;
    this.email = resolve.email;
  }

  public $onInit() : void {
    this.user = this.Session.user;
    this.extend(this.resolve);
    this.openJobOfferEmail = {
      jobOffer: _.first(this.jobOffers),
      email: this.email,
      callback: null,
    };
  };

  public cancel() {
    this.modalInstance.dismiss("cancel");
  };

  public save() {
    this.openJobOfferEmail.callback = this.cpjoin.createMessage(
      "create_open_job_offer_email",
      this.openJobOfferEmail
    );
    this.modalInstance.close(this.openJobOfferEmail);
  };

};
