import { Language } from "../../../languages/services/languages.service";
import candidatesPriorityPopoverTemplate from "../../../priorities/components/priority-popover/priority-popover.template.html";
import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";

export default class CandidatesTableBodyCtrl {
  private draggingHelper: (event: DragEvent) => void;
  private startCallback: () => void;
  private state: any;
  private reduceParams: (params: any) => any;
  private priorityPopoverTemplate: string;
  private languageLevelInWords: any;
  private createAsManager: () => void; 
  private isCandidatesTable: boolean;
  
  static $inject = ["QuicklistsFactory", "CandidatesFactory", "PersonTransformer", "$templateCache"];

  constructor(private Quicklist: any, 
              private Candidate: any,  
              private PersonTransformer: any, 
              private $templateCache: ng.ITemplateCacheService) {
                this.priorityPopoverTemplate = "priority-popover-template";
                this.$templateCache.put(this.priorityPopoverTemplate, candidatesPriorityPopoverTemplate);
            
                this.languageLevelInWords = Language.levelInWords;
                this.Candidate = Candidate;
            
                this.$templateCache.put("info-popover-candidate", "<info-popover resource='candidate'></info-popover>");
            
                this.createAsManager = PersonTransformer.createAsManager;
            
                angular.extend(this, {
                  draggingHelper: Quicklist.draggingHelper,
                  startCallback: Quicklist.startCallback,
                  state: StateTransitionsConfig.state,
                  reduceParams: StateTransitionsConfig.reduceParams,
                });
                this.isCandidatesTable = true;
              }
}