import showActionsListModalCtrl from "./show-actions-list-modal.controller";
import showActionsListModalTemplate from "./show-actions-list-modal.template.html";

angular.module("actions.modals").component("showActionsListModal", {
  bindings: {
    resolve: "<",
    dismiss: "&",
  },
  template: showActionsListModalTemplate,
  controller: showActionsListModalCtrl,
});
