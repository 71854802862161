import LanguageSelectCtrl from "./language-select.controller";
import languageSelectTemplate from "./language-select.template.html";

angular.module("languages.components").component("languageSelect", {
  bindings: {
    ngModel: "=",
  },
  controller: LanguageSelectCtrl,
  template: languageSelectTemplate,
});
