//= require ../modals.module.js

angular.module("quicklists.modals").factory("QuicklistsModalMixin", [
  "$uibModal",
  function ($uibModal) {
    function QuicklistsModalMixin() {}

    function openModal(_this, quicklistsResolve) {
      var modalInstance = $uibModal.open({
        component: "chooseQuicklistModal",
        size: "lg",
        resolve: {
          quicklists: quicklistsResolve,
        },
      });

      modalInstance.result.then(
        function (quicklists) {
          // add to quicklist
          _.chain(quicklists)
            .select(function (quicklist) {
              return quicklist.selected;
            })
            .each(function (quicklist) {
              quicklist.add(_this);
            });

          // delete from quicklist
          _.chain(quicklists)
            .reject(function (quicklist) {
              return quicklist.selected;
            })
            .each(function (quicklist) {
              quicklist.findAndDeleteEntry(_this);
            });
        },
        function () {
          //$log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    QuicklistsModalMixin.extended = function (Resource) {
      Resource.extend({
        openModal: openModal,
      });
    };

    return QuicklistsModalMixin;
  },
]);
