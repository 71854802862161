import CompaniesSiteAddressesFormCtrl from "./companies-site-addresses-form.controller";
import companiesSiteAddressesFormTemplate from "./companies-site-addresses-form.template.html";

angular.module("companies.components").component("companySiteAddressesForm", {
  bindings: {
    siteAddresses: "=",
  },
  template: companiesSiteAddressesFormTemplate,
  controller: CompaniesSiteAddressesFormCtrl,
});
