import ProjectPipelineCtrl from "./project-pipeline.controller";
import projectPipelineTemplate from "./project-pipeline.template.html";

angular.module("project_reporting.views").component("projectPipeline", {
  bindings: {
    projectPipelineConfig: "<",
    $state$: "<",
    $transition$: "<",
  },
  template: projectPipelineTemplate,
  controller: ProjectPipelineCtrl,
});
