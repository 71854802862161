import AmountCoveredLineChartCtrl from "./amount-covered-line-chart.controller";
import amountCoveredLineChartTemplate from "./amount-covered-line-chart.template.html";

angular.module("dashboards.components").component("amountCoveredLineChart", {
  bindings: {
    projectReportingWeeks: "<",
    amountCoveredSollPerYear: "<",
    sales: "<",
  },
  template: amountCoveredLineChartTemplate,
  controller: AmountCoveredLineChartCtrl,
});
