//= require ./services.module.js
angular.module("services").service("SidebarFactory", [
  "sidebarStates",
  function (sidebarStates) {
    function Sidebar(asideName, subDeckOpen) {
      this.open = function () {
        sidebarStates[asideName] = true;
        sidebarStates.deckSub = subDeckOpen;
      };

      this.close = function () {
        sidebarStates[asideName] = false;
        if (subDeckOpen) {
          sidebarStates.deckSub = false;
        }
      };

      this.name = function () {
        return asideName;
      };
    }

    Sidebar.closeAll = function () {
      _.each(_.keys(sidebarStates), function (name) {
        sidebarStates[name] = false;
      });
    };

    return Sidebar;
  },
]);
