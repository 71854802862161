import EmployeesContextMenuCtrl from "./employees-context-menu.controller";
import employeesContextMenuTemplate from "./employees-context-menu.template.html";

angular.module("employees.components").component("employeesContextMenu", {
  bindings: {
    employee: "<",
    statePrefix: "<",
    showTransform: "<",
    options: "<",
    stateArgs: "&",
    initialViewState: "<",
  },
  template: employeesContextMenuTemplate,
  controller: EmployeesContextMenuCtrl,
});
