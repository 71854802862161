import DebriefingTemplateCtrl from "./debriefing-template.controller";
import debriefingTemplateTemplate from "./debriefing-template.template.html";

angular.module("action_types.components").component("debriefingTemplate", {
  bindings: {
    action: "=",
    object: "<?",
    saveChanges: "<?",
  },
  template: debriefingTemplateTemplate,
  controller: DebriefingTemplateCtrl,
});
