export class TransactionBasisListCtrl {
  static $inject = ["$filter"];
  public transactionBasesListAttributes: any;
  constructor(private $filter: angular.IFilterService) {
    this.transactionBasesListAttributes = {
      name: {
        title: $filter("translate")("NAME"),
        show: true,
        fixedWidth: true,
        class: "title",
      },
      startDate: {
        title: $filter("translate")("BEGIN"),
        show: true,
        class: "date",
      },
      endDate: {
        title: $filter("translate")("END"),
        show: true,
        class: "date",
      },
      surchargePercentage: {
        title: $filter("translate")("SURCHARGE_PERCENTAGE"),
        show: false,
        class: "minimal",
      },
      periodOfPayment: {
        title: $filter("translate")("DAYS_FOR_TERM_OF_PAYMENT"),
        show: false,
        class: "minimal",
      },
      brokerage: {
        title: $filter("translate")("COMMISSION_PERCENTAGE"),
        show: true,
        class: "longer",
      },
    };
  }
}
