import { IFilterService } from "angular";

export default class JobOffersCtrl {
  private attributes: any;
  static $inject = ["$filter"];

  constructor(private $filter: IFilterService) {}

  public $onInit () {
    this.attributes = {
      name: {
        // @ts-ignore
        title: this.$filter("translate")("NAME"),
        show: true,
        class: "title",
      },
      responsibleUser: {
        // @ts-ignore
        title: this.$filter("translate")("RESPONSIBLE_USER_TOKEN"),
        show: false,
        class: "responsible",
      },
    };
  };
};