import { IHttpService } from "angular";

export default class CompaniesShowCtrl {
  public deregActiveRowConnector: () => void;
  public deregActionListener: () => void;
  public deregAddEmployeetoCompany: () => void;
  public deregAddProjectToCompany: () => void;
  public openCompanyOrgChart: (company: any) => void;
  public company: any;
  public showContact: boolean | null;
  public companyAttributes: any;
  public contactActionTypes: any;

  public initializeActiveRowConnector: (company: any) => () => void;

  static $inject = ["$state", "$http", "$rootScope", "CompaniesFactory", "EmployeesFactory", "PrsmTableHelper", "DeckControllerMixin", "PersonModals"];

  constructor(
    private $state: angular.ui.IStateService,
    private $http: IHttpService,
    private $rootScope: IExtendedRootScopeService,
    private Company: any,
    private Employee: any,
    private PrsmTableHelper: any,
    private DeckControllerMixin: any,
    private PersonModals: any,
  ) {
    this.companyAttributes = this.PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.Company.defaultTableAttributes),
      ["id", "name", "responsibleUser", "city", "region", "priority", "tags", "createdAt"],
      ["id", "name", "region"]
    );
    this.contactActionTypes = {
      email: "e-mail",
      telephone: "phone-call",
    };

    this.deregActionListener = this.$rootScope.$on("action", (event, object) => {
      if (object.resourceType == "Employee") {
        var employee = _.findWhere(this.company.employees, { id: object.id });
        if (employee) {
          angular.copy(object.actions, employee.actions);
        }
      }
    });
    this.deregAddEmployeetoCompany = this.$rootScope.$on("addEmployee", (event, employee) => {
      this.company.employees.push(employee);
    });
    this.deregAddProjectToCompany = this.$rootScope.$on("addProject", (event, projects) => {
      this.company.projects.push(projects);
    });
  }
  
  public $onInit () {
    angular.extend(this, this.DeckControllerMixin, {
      openCompanyOrgChart: this.PersonModals.openCompanyOrgChart,
    });
    
    if (this.$state.current.name.match(/^root.companies.(edit|show)$/g) && this.company.employeesCount > 0) {
      this.$http.get(this.company.$url("/first_manager")).then((result: any) => {
        var employee = new this.Employee(result.data.data);
        this.$state.go("root.companies.show.employees.show", employee.params());
      });
    }

    this.$rootScope.setPageTitle(this.company.name);
    this.showContact = null;
    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.company);
  };

  public $onDestroy() {
    this.deregActionListener();
    this.deregAddEmployeetoCompany();
    this.deregAddProjectToCompany();
    this.deregActiveRowConnector();
  };
};