import candidatesSearchTemplate from "./candidates-search.template.html";

angular.module("quicklists.components").component("quicklistCandidatesSearch", {
  bindings: {
    query: "<",
    advancedQuery: "<",
    updateEntries: "&",
  },
  template: candidatesSearchTemplate,
});
