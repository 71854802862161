//= require ../project_reportings.module.js

angular.module("project_reporting").filter("sumWeekPercentage", [
  "$filter",
  "ProjectReportingsFactory",
  function ($filter, ProjectReporting) {
    return function (days, attr) {
      var input = ProjectReporting.sumWeek(days, attr) / ProjectReporting.hoursPerWeek;
      return $filter("number")(input * 100, 2) + "%";
    };
  },
]);
