import EmployersTableBodyCtrl from "./employers-table-body.controller";
import employersTableBodyTemplate from "./employers-table-body.template.html";

angular.module("companies.components").component("employersTableBody", {
  bindings: {
    workingRelationships: "=data",
    attributes: "=",
    activeRow: "=",
    tableRowIdentifier: "=",
    statePrefix: "@",
    initialViewState: "<",
  },
  template: employersTableBodyTemplate,
  controller: EmployersTableBodyCtrl,
});
