export default class ChooseComponayModalCtrl {
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private resolve: { person: any }
  private company: any;
  private companies: any[];

  static $inject = ["$filter"];

  constructor (private $filter: ng.IFilterService) {}
  
  public $onInit () {
    angular.extend(this, {
      person: this.resolve.person,
      companies: _.toArray(_.pluck(this.resolve.person.workingRelationships, "employer")),
      company: _.first(_.toArray(_.pluck(this.resolve.person.workingRelationships, "employer"))),
    });

    this.company = _.first(this.companies);

    if (this.companies.length == 1 && this.company && this.company.siteAddresses.length == 1) {
      this.company.companyAddress = _.first(this.company.siteAddresses);
      this.selectCompany();
    }
  };

  public cancel () {
    this.modalInstance.dismiss("cancel");
  };

  public selectCompany () {
    if (this.company.companyAddress) {
      this.modalInstance.close(this.company);
    } else {
      window.alert(this.$filter("translate")("NO_ADDRESS_LISTED"));
      return;
    }
  };
}