//= require ../deals.module.js

angular.module("deals").service("DealsFactory", [
  "RailsResource",
  "RailsResourceSnapshotsMixin",
  "railsSerializer",
  "DealsModalMixin",
  "ModalMixin",
  "moment",
  "$http",
  "RedirectLocation",
  "$filter",
  "deliveryOptions",
  "$state",
  "poller",
  "$log",
  function (
    RailsResource,
    RailsResourceSnapshotsMixin,
    railsSerializer,
    DealsModalMixin,
    ModalMixin,
    moment,
    $http,
    RedirectLocation,
    $filter,
    deliveryOptions,
    $state,
    poller,
    $log
  ) {
    function Deal() {
      (<any>Deal).__super__.constructor.apply(this, arguments);

      this.params = function () {
        return _.extend({ deal_id: this.id }, RedirectLocation.redirectParams());
      };

      this.isEmployeeLeasingEnabled = function () {
        return this.employeeLeasingEnabled && this.provisions.length == 1;
      };

      this.isLegacyCalculation = function () {
        return moment(this.serviceDate).year() < 2020;
      };

      this.isEMailDelivery = function () {
        if (!this.delivery) {
          return false;
        }
        return _.findWhere(deliveryOptions, { id: this.delivery }).name == "DELIVERY_EMAIL";
      };

      this.createMessage = function (actionRoute, emailParams) {
        var params = angular.merge({ id: this.id }, emailParams);
        return $http.post("deals/" + this.id + "/" + actionRoute + ".json", { deal: params });
      };

      this.pollJobData = function (jobFailedCondition, jobSuccessfulCondition) {
        const _deal = this;
        const pollingService = poller.get(_deal.$url() + ".json", { catchError: true, delay: 1000 });
        pollingService.promise.then(null, null, function (response) {
          if (response.status === 200) {
            // @ts-ignore
            const updatedDeal = new Deal(response.data.data);

            _.extend(_deal, _.omit(updatedDeal, "$errors"));
            if (jobFailedCondition(updatedDeal)) {
              $log.debug("The job failed.");
              pollingService.remove();
            }
            if (jobSuccessfulCondition(updatedDeal)) {
              $log.debug("The job executed successfully.");
              _deal.snapshot();
              pollingService.remove();
            }
          } else {
            pollingService.remove();
          }
        });
      };

      this.pollCdsJobData = function () {
        const jobSuccessfulCondition = (updatedDeal) => !updatedDeal.cds_generating || updatedDeal.allCandidatesInCds;
        const jobFailedCondition = (updatedDeal) => updatedDeal.cds_failed;
        this.pollJobData(jobFailedCondition, jobSuccessfulCondition);
      };

      this.pollSapJobData = function () {
        const jobSuccessfulCondition = (updatedDeal) => !updatedDeal.generating || updatedDeal.has_sap_id;
        const jobFailedCondition = (updatedDeal) => updatedDeal.failed;
        this.pollJobData(jobFailedCondition, jobSuccessfulCondition);
      };

      this.candidatesSapIds = function () {
        return _.map(this.provisions, function (provision) {
          return provision.candidate.sapId;
        }).join(", ");
      };

      this.turnClosingToDeal = function () {
        if (window.confirm($filter("translate")("SHOULD_CLOSING_BE_TURNED_INTO_DEAL"))) {
          return (<any>Deal).$post(this.$url("/turn_closing_to_deal.json"), { deal: { id: this.id } });
        } else {
          return Promise.reject();
        }
      }.bind(this);

      this.isFreelancerWorkingOnInternalProject = function () {
        return this.project.isInternalProject && this.jobType.isFreelancer();
      }
    }

    Deal.createAndEdit = function (options) {
      return (<any>Deal)
        .$get((<any>Deal).$url(options, "/new"), { deal: JSON.stringify(options) })
        .then(function (deal) {
          return deal.edit();
        });
    };

    RailsResource.extendTo(Deal);
    (<any>Deal).configure({
      url: "/deals/{{id}}",
      name: "deal",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("action", "ActionsFactory");
        this.rename("documents", "contractDocuments");
        this.resource("documents", "DocumentsFactory");
        this.serializeWith("jobType", "JobTypesSerializer");
        this.resource("researcherCommissions", "ResearcherCommissionsFactory");
        this.resource("project", "ProjectsFactory");

        this.resource("researcher", "UsersFactory");
        this.resource("consultant", "UsersFactory");

        this.serializeWith("internalCost", "MoneySerializer");
        this.serializeWith("assessmentBasis", "MoneySerializer");
        this.serializeWith("amountCoveredIst", "MoneySerializer");
        this.resource("provisions", "ProvFactory");

        this.serializeWith("serviceDate", "DateSerializer");

        this.exclude("$snapshots");
        this.exclude("$errors");
        this.preserve("$errors");
      }),
    });

    (<any>Deal).extend(DealsModalMixin);
    (<any>Deal).extend(ModalMixin);
    (<any>Deal).extend(RailsResourceSnapshotsMixin);

    return Deal;
  },
]);
