export class ProjectsIndexCtrl {
  private loader: () => Promise<any>;
  public projectsLazyLoading: boolean = true;
  public projectExcelLoading: boolean = false;
  public advancedQuery: any;
  public query: string;
  public attributes: any;
  public steps: any[];
  public stateParams: any;
  public projects: any[];

  static $inject = [
    "$state",
    "$http",
    "$rootScope",
    "$filter",
    "SavedSearchesFactory",
    "ProjectsFactory",
    "KPISteps",
    "sidebarStates",
    "PrsmTableAttributeService",
    "makeCollectionProxy",
    "Session",
  ];
  constructor(
    private $state: angular.ui.IStateService,
    private $http: ng.IHttpService,
    private $rootScope: IExtendedRootScopeService,
    private $filter: ng.IFilterService,
    private SavedSearchService: any,
    private ProjectService: any,
    private KPISteps: any,
    public sidebarStates: any,
    private PrsmTableAttributeService: any,
    private makeCollectionProxy: any,
    private Session: any
  ) {}

  public canDownloadProjectList() {
    var currentUser = this.Session.user;
    return currentUser.allowDownloadProjectsExcelXlsx;
  }

  public setKpiKey(key) {
    this.advancedQuery.kpiFilter[key] = !this.advancedQuery.kpiFilter[key];
    this.advancedQuery.basicQuery = this.query;
    this.$state.go("root.projects", this.advancedQuery.collectSearchParams(), { reload: true, inherit: false });
  }

  public $onInit() {
    this.attributes = new this.PrsmTableAttributeService(
      "projects-index-table-attributes",
      this.ProjectService.defaultTableAttributes
    ).getAttributes();

    angular.extend(this, {
      advancedQuery: _.defaults(this.advancedQuery, { kpiFilter: {} }),
      steps: _.map(this.KPISteps, function (value, key) {
        return { key: key, value: value };
      }),
    });

    this.$rootScope.setPageTitle(this.$filter("translate")("PAGE_TITLE_MY_PROJECTS"));

    if (this.stateParams.saved_search_id) {
      this.loader = this.SavedSearchService.results.bind(this.ProjectService);
    } else {
      this.loader = this.ProjectService.query.bind(this.ProjectService);
    }

    this.makeCollectionProxy(this.loader, this.stateParams)
      .then((projects) => {
        this.projects = projects;
      })
      .then(() => {
        this.projectsLazyLoading = false;
      });
  }

  public downloadProjectsExcel() {
    this.projectExcelLoading = true;
    this.$http({
      method: "GET",
      url: "/projects/download_projects_excel",
      responseType: "arraybuffer",
      headers: { Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
      params: this.advancedQuery.collectSearchParams(),
    }).then(
      (response: angular.IHttpResponse<any>) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "projects.xlsx";
        link.click();
        this.projectExcelLoading = false;
      },
      (error) => {
        console.log("Error downloading the file: ", error);
        this.projectExcelLoading = false;
      }
    );
  }
}
