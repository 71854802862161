//= require ./services.module.js
angular
  .module("services")

  // Resolves an object key recursively:
  // obj[keys[0]][keys[1]][keys[2]]...
  .factory("recursiveAccess", [
    function () {
      return function (obj, keys) {
        var cur_obj = obj;
        for (var i = 0; i < keys.length; i++) {
          if (!cur_obj) {
            return null;
          }
          cur_obj = cur_obj[keys[i]];
        }
        return cur_obj;
      };
    },
  ]);
