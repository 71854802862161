import PersonSkillmatrixCtrl from "./person-skillmatrix-form.controller";
import personSkillmatrixFormTemplate from "./person-skillmatrix-form.template.html";

angular.module("directives").component("personSkillmatrixForm", {
  bindings: {
    person: "=ngModel",
  },
  template: personSkillmatrixFormTemplate,
  controller: PersonSkillmatrixCtrl,
});
