//= require ./services.module.js
angular.module("services").factory("focus", [
  "$rootScope",
  "$timeout",
  function ($rootScope, $timeout) {
    return function (name) {
      $timeout(function () {
        $rootScope.$broadcast("focusOn", name);
      });
    };
  },
]);
