import quicklistPriorityPopoverTemplate from "../../../priorities/components/priority-popover/priority-popover.template.html";

export class QuicklistsTableBodyCtrl {
  static $inject = ["$templateCache", "QuicklistsFactory", "CandidatesFactory", "EmployeesFactory", "CompaniesFactory"];

  constructor(
    private $templateCache: ng.ITemplateCacheService,
    private QuicklistService: any,
    public Candidate: any,
    public Employee: any,
    public Company: any
  ) {
    $templateCache.put(this.priorityPopoverTemplate, quicklistPriorityPopoverTemplate);

    this.draggingHelper = QuicklistService.draggingHelper;
    this.startCallback = QuicklistService.startCallback;
  }

  private priorityPopoverTemplate: string = "priority-popover-template";
  public draggingHelper: () => void;
  public startCallback: () => void;
}
