import { initClient, tsRestFetchApi } from "@ts-rest/core";
import { contract } from "../api-routing/contract";

import { getCookie } from "typescript-cookie";
import { CSRF_COOKIE_NAME, CSRF_HEADER_NAME } from "../services/my_csrf.service";

export const baseClient = initClient(contract, {
  baseUrl: "",
  baseHeaders: {},
  api: async (args) => {
    args.headers[CSRF_HEADER_NAME] = getCookie(CSRF_COOKIE_NAME);

    return tsRestFetchApi(args);
  },
});

const requestCache: { [key: string]: Array<any> | null } = {};

export function cached(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const original: any = descriptor.value;
  const targetProperty: string = propertyKey;

  descriptor.value = function (...args) {
    let cacheIdentifier = `${this.$$moduleName}-${targetProperty}-cache`;

    if (requestCache[cacheIdentifier]) {
      return new Promise((resolve, reject) => {
        resolve(requestCache[cacheIdentifier]);
      });
    } else {
      return original.call().then((response) => {
        return (requestCache[cacheIdentifier] = response);
      });
    }
  };
}
