import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { LeadershipExperience } from "./leadership_experiences.service";

angular
  .module("leadership_experiences")
  .factory("LeadershipExperiencesSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(LeadershipExperience, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("LeadershipExperienceFactory", LeadershipExperience);
