//= require ../ui-tinymce-mega.module.js

angular.module("directives.tinymce").constant("tinymceConfig", {
  inline: false,
  menubar: false,
  plugins: "advlist lists charmap paste",
  theme: "modern",
  skin_url: "/tinymce/skins/lightgray",
  min_height: 120,
  browser_spellcheck: true,
});
