import InvoicesListCtrl from "./invoices-list.controller";
import invoicesListTemplate from "./invoices-list.template.html";

angular.module("invoices.components").component("invoicesList", {
  bindings: {
    project: "=",
    preload: "@",
  },
  template: invoicesListTemplate,
  controller: InvoicesListCtrl,
});
