//= require ./routing-options.module.js
import { Region } from "../../regions/services/regions.service";
import { Language } from "../../languages/services/languages.service";
import { Country } from "../../countries/services/countries.service";

angular
  .module("values.routing-options")

  .constant("jobOfferOpts", {
    show: {
      resolve: {
        jobOfferResponse: [
          "$transition$",
          "JobOfferFactory",
          function ($transition$, JobOfferFactory) {
            return JobOfferFactory.get($transition$.params().job_offer_id);
          },
        ],
        scrollspyScope: [
          function () {
            return _.uniqueId("scrollspy");
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "jobOffersShow",
        };
        viewObj["deck-sidebar@" + state] = {
          component: "jobOffersMenu",
        };
        return viewObj;
      },
    },
    new: {
      resolve: {
        jobOfferResponse: [
          "JobOfferFactory",
          "projectResponse",
          function (JobOffer, projectResponse) {
            return JobOffer.$get("job_offers/new", { project_id: projectResponse.id });
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "jobOffersEdit",
        };
        viewObj["deck-sidebar@" + state] = {
          component: "jobOffersMenu",
        };
        return viewObj;
      },
    },
    edit: {
      resolve: {
        jobOfferResponse: [
          "$transition$",
          "JobOfferFactory",
          function ($transition$, JobOfferFactory) {
            return JobOfferFactory.get($transition$.params().job_offer_id);
          },
        ],
        scrollspyScope: [
          function () {
            return _.uniqueId("scrollspy");
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "jobOffersEdit",
        };
        viewObj["deck-sidebar@" + state] = {
          component: "jobOffersMenu",
        };
        return viewObj;
      },
    },
    requirements: {
      regions: [
        function () {
          return Region.getAll();
        },
      ],
      languages: [
        function () {
          return Language.getAll();
        },
      ],
      countries: [
        function () {
          return Country.getAll();
        },
      ],
      objectType: [
        function () {
          return "client";
        },
      ],
    },
  });
