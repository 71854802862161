import DashboardGaugeChartCtrl from "./dashboard-gauge-chart.controller";
import dashboardGaugeChartTemplate from "./dashboard-gauge-chart.template.html";

angular.module("dashboards.components").component("dashboardGaugeChart", {
  bindings: {
    percentage: "=",
  },
  template: dashboardGaugeChartTemplate,
  controller: DashboardGaugeChartCtrl,
});
