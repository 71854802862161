import WhiteboardAdvancedSearchCtrl from "./whiteboard-advanced-search.controller";
import whiteboardAdvancedSearchTemplate from "./whiteboard-advanced-search.template.html";
angular.module("project_reporting.components").component("whiteboardAdvancedSearch", {
  bindings: {
    initFn: "=",
    user: "=",
    advancedQuery: "=",
    closing: "&",
  },
  template: whiteboardAdvancedSearchTemplate,
  controller: WhiteboardAdvancedSearchCtrl,
});
