//= require ./routing-options.module.js

angular
  .module("values.routing-options")

  .constant("savedSearchOpts", {
    requirements: {
      stateParams: [
        "$transition$",
        function ($transition$) {
          // remove undefined params
          return _.pick($transition$.params(), _.identity);
        },
      ],
      // need to reference action_types (force loading before initialize SavedSearchesFactory)
      savedSearchesResponse: [
        "action_types",
        "SavedSearchesFactory",
        function (action_types, SavedSearchesFactory) {
          return SavedSearchesFactory.getAll();
        },
      ],
      savedSearchResponse: [
        "action_types",
        "stateParams",
        "SavedSearchesFactory",
        function (action_types, stateParams, SavedSearch) {
          if (stateParams.saved_search_id) {
            return SavedSearch.loadFromCache(stateParams.saved_search_id);
          } else {
            return new SavedSearch({ basicQuery: stateParams.q });
          }
        },
      ],
      query: [
        "savedSearchResponse",
        function (savedSearchResponse) {
          return savedSearchResponse.basicQuery;
        },
      ],
      advancedQuery: [
        "stateParams",
        "savedSearchResponse",
        "action_types",
        "SavedSearchesFactory",
        function (stateParams, savedSearchResponse, action_types, SavedSearch) {
          return new SavedSearch(_.extend(savedSearchResponse, JSON.parse(stateParams.advanced || "{}")));
        },
      ],
    },
  });
