import { CompaniesTransactionBasis } from "../../../companies_transaction_bases/services/companies_transaction_bases.service";
import { PERIOD_OF_PAYMENT_OPTIONS, TransactionBasis } from "../../services/transaction_bases.service";

import transactionBasisPopoverTemplate from "../../../companies/components/transaction-basis-popover/transaction-basis-popover.template.html";

export class TransactionBasisFormCtrl {
  static $inject = ["$templateCache", "$log"];

  public transactionBasesTypes: TransactionBasis[];
  public periodOfPaymentOptions: any;
  public transactionBases: CompaniesTransactionBasis[];
  public companyId: number;

  constructor(private $templateCache: angular.ITemplateCacheService, private $log: angular.ILogService) {
    var popoverTemplate = "transaction-basis-popover";
    $templateCache.put(popoverTemplate, transactionBasisPopoverTemplate);
  }

  public $onInit() {
    TransactionBasis.getAllForCompanies().then((transactionBases: TransactionBasis[]) => {
      this.transactionBasesTypes = transactionBases;
    });

    this.periodOfPaymentOptions = PERIOD_OF_PAYMENT_OPTIONS;
  }

  public addTransactionBasis() {
    const newCompaniesTransactionBasis = new CompaniesTransactionBasis({
      companyId: this.companyId,
      contractType: _.first(_.sortBy(this.transactionBasesTypes, "id")),
    });

    if (this.companyId) {
      // Save the new companies transaction basis if company is already saved
      newCompaniesTransactionBasis.save().then((savedCompaniesTransactionBases: CompaniesTransactionBasis) => {
        const savedCTB = new CompaniesTransactionBasis(savedCompaniesTransactionBases);
        this.$log.debug("Created CompaniesTransactionBase: ", savedCTB);
        this.transactionBases.push(savedCTB);
      });
    } else {
      this.transactionBases.push(newCompaniesTransactionBasis);
    }
  }

  public deleteTransactionBasis(index) {
    if (this.companyId) {
      this.transactionBases[index].delete().then((isDeleted: boolean) => {
        this.$log.debug("Deleted CompaniesTransactionBase:", isDeleted);
        this.transactionBases.splice(index, 1);
      });
    } else {
      this.transactionBases.splice(index, 1);
    }
  }
}
