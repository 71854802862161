export default class EditContractDraftCtrl {
  private candidateId: any;
  private provision: any;
  private contractDraft: any;
  static $inject = ["ContractDraftFactory", "EditUtilMixin", "deploymentLocations"];

  constructor(private ContractDraft: any, private EditUtilMixin: any, private deploymentLocations: any) {}

  public $onInit () {
    angular.extend(this, this.EditUtilMixin);
    this.candidateId = this.provision.candidate.id;
    this.deploymentLocations = this.deploymentLocations;
  };

  public addContractDraftToCandidate () {
    this.ContractDraft.createContractDraft(this.provision).then((newContractDraft) => {
      this.contractDraft = newContractDraft;
    });
  };

  public contractDraftStatusIn (statuses) {
    return _.contains(statuses, this.contractDraft.status);
  };

  public destroyContractDraft () {
    if (!this.contractDraft.id) {
      this.contractDraft = null;
      return;
    }
    this.contractDraft.delete().then((deletedContractDraft) => {
      this.contractDraft = null;
    });
  };
}