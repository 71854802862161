import AdvancedSearchCtrl from "./advanced-search.controller";
import advancedSearchTemplate from "./advanced-search.template.html";
//= require ../../advanced_search.module.js

angular.module("directives.advanced_search").component("advancedSearch", {
  bindings: {
    directiveName: "@",
    directiveOptions: "=",
  },
  template: advancedSearchTemplate,
  controller: AdvancedSearchCtrl,
});
