import { SendOpenJobOfferModalCtrl } from "./send-open-job-offer-modal.controller";
import sendOpenJobOfferModalTemplate from "./send-open-job-offer-modal.template.html";

angular.module("candidates_projects.modals").component("sendOpenJobOfferModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: sendOpenJobOfferModalTemplate,
  controller: SendOpenJobOfferModalCtrl,
});
