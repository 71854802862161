//= require ../modals.module.js
import { Language } from "../../../languages/services/languages.service";
angular
  .module("users.modals")

  .controller("AccountSettingsModalCtrl", [
    "$rootScope",
    "$sanitize",
    "$sce",
    "$log",
    "$cookies",
    "$filter",
    "$translate",
    "$window",
    "amMoment",
    "csrfCookieName",
    "EditUtilMixin",
    "FileUploader",
    "UsersFactory",
    "PrsmTableHelper",
    "$state",
    "SavedSearchesFactory",
    "Currencies",
    function (
      $rootScope,
      $sanitize,
      $sce,
      $log,
      $cookies,
      $filter,
      $translate,
      $window,
      amMoment,
      csrfCookieName,
      EditUtilMixin,
      FileUploader,
      User,
      PrsmTableHelper,
      $state,
      SavedSearch,
      Currencies
    ) {
      var _this = this;
      _this.currencies = Currencies;
      _this.languages = Language.defaultOptions;

      function initializeUploader() {
        if (!_this.uploader) {
          _this.uploader = new FileUploader({
            url: _this.selectedUser.$url(),
            alias: "upload_users_photo",
            removeAfterUpload: true,
            method: "PUT",
            autoUpload: true,
            //queueLimit: 1,
            headers: {
              "X-CSRF-TOKEN": $cookies.get(csrfCookieName),
            },
            onSuccessItem: function (item, response) {
              $log.debug("Photo upload complete");

              //update user object
              _this.selectedUser.photo = response.data.photo;
            },
            onCompleteAll: function () {
              //$state.go('^.show', _this.candidate.params());
            },
            onErrorItem: function (item, response, status, headers) {
              window.alert($filter("translate")("ERROR_WHILE_SAVING_NOTICE"));
              window.rollbar.error("Users Photo upload failed", {
                status: status,
                file_info: item.file,
                url: item.url,
              });
            },
          });

          _this.uploader.filters.push({
            name: "fileExtensionFilter",
            fn: function (item) {
              return /\/(png|jpg|jpeg|gif)$/.test(item.type);
            },
          });
        } else {
          _this.uploader.headers = {
            "X-CSRF-TOKEN": $cookies.get(csrfCookieName),
          };
          _this.uploader.url = _this.selectedUser.$url();
        }
      }

      _this.$onInit = function () {
        angular.extend(_this, EditUtilMixin, {
          saving: false,
          selectedUser: _this.resolve.user,
        });

        initializeUploader();
      };

      var deregLoginConfirmed = $rootScope.$on("event:auth-loginConfirmed", initializeUploader);

      _this.$onDestroy = function () {
        deregLoginConfirmed();
      };

      _this.clearTableSettings = function () {
        if (window.confirm($filter("translate")("RESET_TABLE_CONFIGURATIONS_NOTICE"))) {
          PrsmTableHelper.clearAttributes();
        }
      };

      _this.isValidHtml = function () {
        if (!_this.selectedUser.signature || _this.selectedUser.signature.length === 0) {
          return true;
        }
        try {
          return $sanitize(_this.selectedUser.signature);
        } catch (e) {
          return false;
        }
      };

      _this.getTrusted = function () {
        return $sce.trustAsHtml(_this.selectedUser.signature);
      };

      _this.saveUser = function () {
        if (_this.saving === true) {
          return;
        }

        _this.saving = true;
        return _this.selectedUser.save().then(function (user) {
          $log.debug("user successfully saved");
          _this.saving = false;

          if ($translate.use() != user.locale) {
            $translate
              .use(user.locale)
              .then(function (result) {
                amMoment.changeLocale(user.locale);

                window.alert($filter("translate")("SWITCH_LANGUAGE_NOTICE"));
                $window.location.reload();
              })
              .catch(function (result) {
                $log.debug(result);
                window.alert($filter("translate")("ERROR_RELOAD_NOTICE"));
              });
          }

          //angular.extend($scope.options.selectedUser, user);
        }, _this.onSaveError.bind(_this));
      };

      _this.cancel = function () {
        _this.modalInstance.dismiss("cancel");
      };

      _this.loadEditUser = function (newUser) {
        User.get(_this.selectedUser.id).then(function (result) {
          _this.selectedUser = result;
          initializeUploader();
        });
      };

      _this.openMyProjects = function () {
        var searchMyProjects = new SavedSearch({
          conditions: [
            { modifier: "must", field: "responsible_user", query: _this.selectedUser.name },
            { modifier: "must", field: "project_status", query: "active" },
          ],
        });
        _this.cancel();
        $state.go("root.projects", searchMyProjects.collectSearchParams(), { reload: true, inherit: false });
      };

      _this.openUnrefreshedProjects = function () {
        var searchMyProjects = new SavedSearch({
          conditions: [
            { modifier: "must", field: "responsible_user", query: _this.selectedUser.name },
            { modifier: "must", field: "project_status", query: "active" },
          ],
          lastUpdateEnabled: true,
          lastUpdateInWeeksAgo: "4",
        });
        _this.cancel();
        $state.go("root.projects", searchMyProjects.collectSearchParams(), { reload: true, inherit: false });
      };
    },
  ]);
