//= require ../candidates_projects.module.js

angular.module("candidates_projects").factory("StatusSerializer", [
  "railsSerializer",
  "CandidatesProjectStatuses",
  function (railsSerializer, CandidatesProjectStatuses) {
    return railsSerializer(function () {
      this.deserialize = function (data) {
        return _.findWhere(CandidatesProjectStatuses.statuses, { id: _.isObject(data) ? data.id : data });
      };
      this.serialize = function (data) {
        return data;
      };
    });
  },
]);
