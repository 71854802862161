import HighschoolSelectCtrl from "./highschools-select.controller";
import highschoolSelectTemplate from "./highschool-select.template.html";

angular.module("highschools.components").component("highschoolSelect", {
  bindings: {
    ngModel: "=",
  },
  controller: HighschoolSelectCtrl,
  template: highschoolSelectTemplate,
});
