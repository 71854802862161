import { initContract } from "@ts-rest/core";

import { TBaseResource } from "../../api-routing";
import { THierarchy } from "../hierarchies";

const c = initContract();

const HierarchiesSchema = c.type<TBaseResource<THierarchy[]>>();
export const hierarchiesContract = c.router({
  query: {
    method: "GET",
    path: "/hierarchies",
    responses: {
      200: HierarchiesSchema,
    },
    summary: "Get all hierarchies",
  },
});
