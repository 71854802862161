import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";

export class ProjectsContextMenuCtrl {
  public loading: boolean;
  public initialViewState: any;
  public company: any;
  public onSaveError: () => void;

  static $inject = ["$rootScope", "ProjectsFactory", "EditUtilMixin"];
  constructor(private $rootScope: ng.IRootScopeService, private ProjectService: any, private EditUtilMixin: any) {}

  public $onInit() {
    angular.extend(this, this.EditUtilMixin, {
      state: StateTransitionsConfig.state,
      transitionOptions: StateTransitionsConfig.transitionOptions(this.initialViewState),
    });
  }

  public deactivateProject(project, index) {
    this.loading = true;
    new this.ProjectService({ id: project.id, projectStatus: "inactive", saveBasics: true }).save().then((result) => {
      this.$rootScope.$broadcast("project_action_changed_" + project.id);
      this.company.projects.splice(index, 1);
      this.loading = false;
    }, this.onSaveError.bind(this));
  }
}
