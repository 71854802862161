import ActionsContextMenuCtrl from "./actions-context-menu.controller";
import actionsContextMenuTemplate from "./actions-context-menu.template.html";

angular.module("actions.components").component("actionsContextMenu", {
  bindings: {
    action: "=",
    statePrefix: "=",
    object: "=",
    showTransform: "=",
    options: "=",
    stateArgs: "&",
  },
  template: actionsContextMenuTemplate,
  controller: ActionsContextMenuCtrl,
});
