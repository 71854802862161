import candidatePriorityPopoverTemplate from "../../../priorities/components/priority-popover/priority-popover.template.html";
import { Language } from "../../../languages/services/languages.service";
import { TransitionService, Transition } from "@uirouter/angularjs";

export default class CandidatesShowCtrl {
  private deregActiveRowChanged: () => void;
  private deregActiveRowClosed: () => void;
  private deregActiveRowConnector: () => void;
  private priorityPopoverTemplate: string;
  private candidate: any;
  private projectsLazyLoading: boolean;
  private isCandidatesTable: boolean;
  private initializeActiveRowConnector: any;
  private stateParams: any;
  private openDocumentId: number;
  private activeProject: any;
  private activeCpjoin: any;
  private openDocument: { openDocumentFullScreen: (documents: any) => void } | undefined;

  static $inject = ["$templateCache", "$rootScope", "CandidatesFactory", "DeckControllerMixin", "$transitions"];

  constructor(private $templateCache: ng.ITemplateCacheService, 
              private $rootScope: IExtendedRootScopeService,
              public Candidate: any,
              private DeckControllerMixin: any,
              private $transitions: TransitionService) {
                this.priorityPopoverTemplate = "priority-popover-template";
                this.$templateCache.put(this.priorityPopoverTemplate, candidatePriorityPopoverTemplate);

                this.$transitions.onSuccess({}, (transition: Transition) => {
                  if (transition) {
                    this.stateParams = _.pick(transition.params(), _.identity);
                    this.openDocumentId = parseInt(transition.params().document_id);
                  }
                });

                this.deregActiveRowChanged = this.$rootScope.$on("activeRowChanged", (event, newActiveRow) => {
                  switch (newActiveRow.resourceType) {
                    case "Project":
                      this.activeProject = newActiveRow;
                      this.activeCpjoin = this.activeProject.getCpjoin(this.candidate);
                      break;
                    case "CandidatesProjects":
                      this.activeProject = newActiveRow.project;
                      this.activeCpjoin = newActiveRow;
                      break;
                  }
                });

                this.deregActiveRowClosed = this.$rootScope.$on("activeRowClosed", (event, activeRow) => {
                  if (_.contains(["Project", "CandidatesProjects"], activeRow.resourceType)) {
                    this.activeProject = undefined;
                    this.activeCpjoin = undefined;
                  }
                });
              }
  
  public $onInit () {
    angular.extend(this, this.DeckControllerMixin, {
      languageLevelInWords: Language.levelInWords,
      openDocument: undefined,
    });

    this.$rootScope.setPageTitle(this.candidate.fullNameReverseWithTitle);
    this.projectsLazyLoading = true;

    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.candidate);
    this.isCandidatesTable = false;
  };

  public $onDestroy () {
    this.deregActiveRowChanged();
    this.deregActiveRowClosed();
    this.deregActiveRowConnector();
  };

  public openDocumentFullScreen(event: Event) {
    event.preventDefault();
    if (!this.openDocument) {
      return;
    }

    this.openDocument.openDocumentFullScreen(this.candidate.documents);
  }
}