//= require ../project_reportings.module.js

angular.module("project_reporting").factory("LonglistEntrySerializer", [
  "railsSerializer",
  "CandidatesProjectsFactory",
  "EmployeesCandidatesProjectsFactory",
  function (railsSerializer, CandidatesProjects, EmployeesCandidatesProjects) {
    return railsSerializer(function () {
      this.deserialize = function (data) {
        if (!data) {
          return undefined;
        }
        if (!data.resource_type) {
          return data;
        }

        switch (data.resource_type) {
          case "CandidatesProjects":
            return new CandidatesProjects(data);
          case "EmployeesCandidatesProject":
            return new EmployeesCandidatesProjects(data);
          default:
            return undefined;
        }
      };
      this.serialize = function (data) {
        return data;
      };
    });
  },
]);
