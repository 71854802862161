export default class WhiteboardAdvancedSearchCtrl {
  private stateParams: any;
  private $transition$: any;
  private whiteboardAttributes: any;
  private teams: any[];
  private allTeams: any[];
  private advancedQuery: any;
  private whiteboardsLazyLoading: boolean;
  private currentLonglistEntries: any[];
  private advancedWhiteboardPresenterSearchOptions: any;
  
  static $inject = ["makeCollectionProxy",
  "ProjectReportingsFactory",
  "UsersFactory",
  "SavedSearchesFactory",
  "$interval",
  "PrsmTableAttributeService"];

  constructor (private makeCollectionProxy: any,
    private ProjectReporting: any, 
    private User: any, 
    private SavedSearch: any, 
    private $interval: ng.IIntervalService, 
    private PrsmTableAttributeService: any) {
      this.whiteboardPresenterQueryFn = this.whiteboardPresenterQueryFn.bind(this);
      this.initializeWhiteboardSearch = this.initializeWhiteboardSearch.bind(this);
      this.setKpiKey = this.setKpiKey.bind(this);
    }

  public $onInit () {
    this.stateParams = _.pick(this.$transition$.params(), _.identity);
    this.whiteboardAttributes = new this.PrsmTableAttributeService(
      "whiteboard-table-attributes",
      _.extend(angular.copy(this.ProjectReporting.whiteboardAttributes), { sales: { show: true } })
    ).getAttributes();

    this.stateParams["teams[]"] = _.chain(this.stateParams["teams[]"]).compact().flatten().value();
    this.teams = this.User.pickTranslatedTeams(this.stateParams["teams[]"]);
    this.allTeams = this.User.translatedTeams();

    var advancedQuery = new this.SavedSearch({
      defaultCondition: { modifier: "must", field: "company_name", query: "" },
      kpiFilter: {},
    });
    advancedQuery.deleteAdvancedSearchCondition(0);
    _.defaults(this, { advancedQuery: advancedQuery });
    this.advancedWhiteboardPresenterSearchOptions = {
      initFn: this.initializeWhiteboardSearch,
      advancedQuery: this.advancedQuery,
    };

    this.$interval(this.initializeWhiteboardSearch, 60000);
  };

  public whiteboardPresenterQueryFn(params) {
    return this.ProjectReporting.$get("/project_reporting/whiteboard_team", params);
  }

  public setKpiKey (key, userOrTeam) {
    // same func signature as in WhiteboardCtrl
    this.advancedQuery.kpiFilter[key] = !this.advancedQuery.kpiFilter[key];
    this.initializeWhiteboardSearch();
  };

  public initializeWhiteboardSearch () {
    this.whiteboardsLazyLoading = true;
    var params = _.extend(
      { advanced: JSON.stringify(this.advancedQuery.beforeStringify()) },
      _.omit(this.stateParams, "user_id"),
      { "teams[]": _.pluck(this.teams, "id") }
    );
    this.makeCollectionProxy(this.whiteboardPresenterQueryFn.bind(this.ProjectReporting), params).then( (result) => {
      this.currentLonglistEntries = result;
      this.whiteboardsLazyLoading = false;
    });
  };
}

