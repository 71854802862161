export class LonglistCtrl {
  static $inject = [
    "$rootScope",
    "CandidatesProjectsFactory",
    "PrsmTableHelper",
    "makeCollectionProxy",
    "SavedSearchesFactory",
  ];

  private oldObjectCandidatesProjects: any[] = [];
  private lazyLoading: boolean = false;
  private longlistObject;
  private object;
  private activeRow;
  private advancedQuery;
  private advancedLonglistSearchOptions;
  private attributes;

  constructor(
    private $rootScope: ng.IRootScopeService,
    private CandidatesProjects: any,
    private PrsmTableHelper: any,
    private makeCollectionProxy: any,
    private SavedSearch: any
  ) {}

  public $doCheck() {
    var newObjectCandidatesProjects = angular.copy(this.object.candidatesProjects);
    if (newObjectCandidatesProjects.length != this.oldObjectCandidatesProjects.length) {
      this.updateLongListObjectAndActiveRow(newObjectCandidatesProjects);
      this.oldObjectCandidatesProjects = newObjectCandidatesProjects;
    }
  }

  public $onInit() {
    if (this.object.resourceType == "Candidate") {
      this.attributes = this.PrsmTableHelper.setVisibleAttributes(
        angular.copy(this.CandidatesProjects.defaultTableAttributes),
        ["id", "projectTitle", "processStatus", "projectStatus", "sendout", "sendoutAt"],
        ["id", "projectTitle", "processStatus", "projectStatus", "sendout"]
      );
    } else if (this.object.resourceType == "Project") {
      this.attributes = this.PrsmTableHelper.setVisibleAttributes(
        angular.copy(this.CandidatesProjects.defaultTableAttributes),
        ["name", "sales", "researcher", "tags", "processStatus", "sendoutAt"],
        ["name", "sales", "researcher", "processStatus", "sendoutAt"]
      );
    }

    this.$rootScope.$on("candidate_add_to_project", () => {
      this.object.candidatesProjectsCount++;
    });

    this.$rootScope.$on("candidate_remove_from_project", () => {
      this.object.candidatesProjectsCount--;
      this.object.candidatesProjects = this.existingCandidatesProjects(this.object.candidatesProjects);
    });

    this.advancedQuery = new this.SavedSearch({
      defaultCondition: { modifier: "must", field: "longlist_status", query: "" },
    });

    // don't show Lost entries
    if (this.object.resourceType == "Project") {
      this.advancedQuery.conditions.push({
        field: "longlist_status",
        modifier: "not",
        query: _.findWhere(this.CandidatesProjects.statuses, { id: 4 }),
      });
    } else if (this.object.resourceType == "Candidate") {
      this.advancedQuery.conditions.push({
        field: "project_status",
        modifier: "must",
        query: "active",
      });
      this.advancedQuery.conditions.push({
        field: "longlist_status",
        modifier: "not",
        query: _.findWhere(this.CandidatesProjects.statuses, { id: 4 }),
      });
    }

    this.advancedQuery.deleteAdvancedSearchCondition(0);

    this.updateObjects();

    this.advancedLonglistSearchOptions = {
      setData: () => { this.updateObjects() },
      advancedQuery: this.advancedQuery,
    };
  }

  /**
   *
   * @param {*} allCandidatesProjects
   * @returns all existing candidates projects
   */
  private existingCandidatesProjects(allCandidatesProjects) {
    const candidatesProjects: any[] = [];
    allCandidatesProjects.forEach((cp) => {
      if (!cp.deleted) {
        candidatesProjects.push(cp);
      }
    });
    return candidatesProjects;
  }

  /**
   * Updates the longlist
   * @param {*} updatedCandidatesProjects
   */
  private updateLongListObjectAndActiveRow(updatedCandidatesProjects) {
    this.longlistObject = _.chain(updatedCandidatesProjects)
      .each((cp) => {
        cp.candidate.sendoutAt = cp.expertProfile && cp.expertProfile.sendoutAt;
        cp.candidate.cp = _.pick(cp, "expertProfile", "status", "id");
      })
      .pluck("candidate")
      .value();
    this.activeRow = this.longlistObject[0];
  }

  /**
   * Updates longlist from search results
   * @param {*} candidatesProjects
   */
  private initSearchResults(candidatesProjects) {
    this.object.candidatesProjects = candidatesProjects;
    this.updateLongListObjectAndActiveRow(this.object.candidatesProjects);
    this.lazyLoading = false;
  }

  private updateObjects() {
    this.lazyLoading = true;

    var params = _.extend(this.object.params(), {
      advanced: JSON.stringify(this.advancedQuery.beforeStringify()),
      additional_pages: Math.min(this.object.candidatesProjectsCount / 20 + 1, 3),
    });

    if (!_.isFunction(this.object.candidatesProjects.search)) {
      this.makeCollectionProxy(this.CandidatesProjects.query.bind(this.CandidatesProjects), params)
        .then((response) => {
          this.initSearchResults(response)
        });
    } else {
      this.object.candidatesProjects.search(params).then((response) => {
        this.initSearchResults(response)
      });
    }
  }
}
