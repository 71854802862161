//= require ./services.module.js

angular.module("services").service("dynamicRoute", [
  "$state",
  function ($state) {
    function dynamicRoute(objRoute, object) {
      var routingState = _.some([".show", ".edit", ".new"], function (methodName) {
        if (!$state.includes("**" + objRoute + methodName + ".**", object.params())) {
          if ($state.href(objRoute + methodName, object.params())) {
            $state.go(objRoute + methodName, object.params());
          } else if ($state.href("root" + objRoute + methodName, object.params())) {
            $state.go("root" + objRoute + methodName, object.params());
          } else {
            return false;
          }
        }

        return true;
      });

      if (routingState && _.isFunction(this.cancel)) {
        this.cancel();
      }
    }

    return {
      dynamicRoute: dynamicRoute,
    };
  },
]);
