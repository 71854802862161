//= require ./services.module.js
angular.module("services").factory("PersonTransformer", [
  "DuplicateChecker",
  "ResourceDependencyInjector",
  "$filter",
  "$translate",
  function (DuplicateChecker, ResourceDependencyInjector, $filter, $translate) {
    var confirmMessages = {
      Employee: "TRANSFORM_EMPLOYEE",
      Candidate: "TRANSFORM_CANDIDATE",
    };

    var classNames = {
      Employee: "Candidate",
      Candidate: "Employee",
    };

    var filter = {
      Employee: { employeeId: null },
      Candidate: { candidateId: null },
    };

    function transformPerson(person) {
      if (!window.confirm($filter("translate")(confirmMessages[person.resourceType]))) {
        return;
      }

      var args = [person.firstName, person.lastName];

      DuplicateChecker.getDuplicates(args, person).then(function (result) {
        var res = _.where(
          result,
          _.extend({ resourceType: classNames[person.resourceType] }, filter[person.resourceType])
        );
        if (res.length > 0) {
          person.showDuplicates(person, res);
        } else {
          var resourceClass = ResourceDependencyInjector.inject(classNames[person.resourceType]);
          resourceClass
            .$post(person.$url("/transform.json"))
            .then(resourceClass.transformResultHandlerOk)
            .catch(resourceClass.transformResultHandlerError);
        }
      });
    }

    return {
      createAsCandidate: transformPerson,
      createAsManager: transformPerson,
    };
  },
]);
