//= require ../candidates.module.js

angular.module("candidates").factory("CandidatesHotkeys", [
  "hotkeys",
  "$state",
  "$rootScope",
  function (hotkeys, $state, $rootScope) {
    return {
      registerHotkeys: function () {
        $(window).keydown(function (event) {
          if (event.ctrlKey && event.keyCode == 75) {
            // ctrl+k
            event.preventDefault();
          }
        });

        if (!hotkeys.get("mod+k s")) {
          hotkeys.add({
            combo: "mod+k s",
            description: "Nach Kandidaten suchen",
            callback: function (event, hotkey) {
              event.preventDefault();
              $rootScope.$broadcast("candidatesSearch");
            },
          });
        }

        if (!hotkeys.get("mod+k c")) {
          hotkeys.add({
            combo: "mod+k c",
            description: "Einen Kandidaten erstellen",
            callback: function (event, hotkey) {
              event.preventDefault();
              $state.go("root.candidates.new");
            },
          });
        }
      },
      deregisterHotkeys: function () {
        if ($state.current.name.indexOf("root.candidates") > -1) {
          hotkeys.del("mod+k c");
          hotkeys.del("mod+k s");
        }
      },
    };
  },
]);
