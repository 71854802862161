import { ProjectSelectCtrl } from "./project-select.controller";
import projectSelectTemplate from "./project-select.template.html";

angular.module("projects.components").component("projectSelect", {
  bindings: {
    object: "=",
    model: "=",
    onChange: "&",
  },
  template: projectSelectTemplate,
  controller: ProjectSelectCtrl,
});
