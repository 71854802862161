import { IFilterService } from "angular";

export default class CompanySelectCtrl {
  public cancelers: ng.IDeferred<any>[] = [];
  public foundCompanies: any[] = [];
  public exclude: number;

  static $inject = ["$q", "$rootScope", "$filter", "Autocompletes", "CompaniesFactory"];

  constructor(
    private $q: ng.IQService,
    private $rootScope: IExtendedRootScopeService,
    private $filter: IFilterService,
    public Autocompletes: any,
    public Company: any,
  ) { }

  public searchCompanies(viewValue) {
    if (viewValue.length) {
      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      this.Autocompletes.autocomplete(viewValue, "company_name", _.last(this.cancelers)).then((results) => {
        this.foundCompanies = _.chain(results)
          .filter({ resource_type: "company" })
          .map((result) => {
            return _.extend(result, {
              resource_type: this.$filter("translate")("COMPANY"),
            });
          })
          .uniq((resource) => {
            return resource.company.id;
          })
          .filter((obj) => {
            return this.exclude != obj.company.id;
          })
          .value();
      });
    } else {
      this.foundCompanies = [];
    }
  };

  public triggerCompanySelectBroadcast($item, $model) {
    this.$rootScope.$broadcast("companyChanged", $item);
  };
};