import CandidatesContextMenuCtrl from "./candidates-context-menu.controller";
import candidatesContextMenuTemplate from "./candidates-context-menu.template.html";

angular.module("candidates.components").component("candidatesContextMenu", {
  bindings: {
    candidate: "=",
    cpjoin: "=?",
    statePrefix: "=",
    isLonglistEntry: "=",
    context: "=",
    stateArgs: "&",
    isCandidatesTable: "=",
    initialViewState: "<",
  },
  template: candidatesContextMenuTemplate,
  controller: CandidatesContextMenuCtrl,
});
