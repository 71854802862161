//= require ../prsm-table.module.js

angular.module("directives.prsmTable").factory("PrsmTableHelper", [
  "$window",
  "Session",
  function ($window, Session) {
    function setVisibleAttributes(defaultTableAttributes, allAttributesList, visibleAttributesList) {
      return _.chain(defaultTableAttributes)
        .pick(allAttributesList)
        .each(function (value, key) {
          value.show = _.includes(visibleAttributesList, key);
        })
        .value();
    }

    function setAttributes(key, attributes) {
      $window.localStorage.setItem("user@" + Session.user.id + "-" + key, JSON.stringify(attributes));
      return angular.copy(attributes);
    }

    function getAttributes(key, defaultTableAttributes) {
      var attributes = $window.localStorage.getItem("user@" + Session.user.id + "-" + key);
      if (attributes) {
        return JSON.parse(attributes);
      } else {
        return setAttributes(key, defaultTableAttributes);
      }
    }

    function clearAttributes() {
      $window.localStorage.clear();
    }

    return {
      setVisibleAttributes: setVisibleAttributes,
      getAttributes: getAttributes,
      setAttributes: setAttributes,
      clearAttributes: clearAttributes,
    };
  },
]);
