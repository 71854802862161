import ActionsTableBodyCtrl from "./actions-table-body.controller";
import actionsTableBodyTemplate from "./actions-table-body.template.html";

angular.module("actions.components").component("actionsTableBody", {
  bindings: {
    actionList: "=data",
    attributes: "=",
    activeRow: "=",
    statePrefix: "@",
    stateArgs: "&",
    context: "=",
    tableRowIdentifier: "=",
  },
  template: actionsTableBodyTemplate,
  controller: ActionsTableBodyCtrl,
});
