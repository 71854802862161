//= require ../deals.module.js

angular.module("deals").constant("internalJobTypes", [
  { name: "SALES_CONSULTANT", id: 1 },
  { name: "RESEARCH_CONSULTANT_JOB_TYPE", id: 2 },
  { name: "MANAGEMENT_CONSULTING", id: 3 },
  { name: "PROJECT_CONSULTING", id: 4 },
  { name: "ADVISOR", id: 5 },
  { name: "SUPERVISORY_BOARD", id: 6 },
]);
