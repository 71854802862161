import { ProjectsIndexCtrl } from "./projects-index-view.controller";
import projectsIndexViewTemplate from "./projects-index-view.template.html";

angular.module("projects.views").component("projectsIndexView", {
  bindings: {
    savedSearches: "<savedSearchesResponse",
    stateParams: "<",
    query: "<",
    advancedQuery: "<",
    $state$: "<",
  },
  template: projectsIndexViewTemplate,
  controller: ProjectsIndexCtrl,
});
