//= require ../dashboard-settings.module.js

angular.module("dashboards.settings").value("dashboardSettings2021", [
  {
    identifier: "fullVWS",
    width: "12",
    directive: "dashboard-kpis-2021-card",
  },
  {
    identifier: "fullAccounts",
    width: "12",
    directive: "dashboard-accounts-2021-card",
  },
  {
    identifier: "fullCVs",
    width: "12",
    directive: "dashboard-cv-2021-card",
  },
  {
    identifier: "fullSendouts",
    width: "12",
    directive: "dashboard-sendouts-2021-card",
  },
  {
    identifier: "fullInterviews",
    width: "12",
    directive: "dashboard-interviews-2021-card",
  },
  {
    identifier: "fullDeals",
    width: "12",
    directive: "dashboard-deals-2021-card",
  },
  {
    identifier: "fullAmountCovered",
    width: "12",
    directive: "dashboard-amount-covered-2021-card",
  },
  {
    identifier: "AmountCoveredConsultants",
    width: "12",
    directive: "dashboard-amount-covered-consultants-2021-card",
  },
]);
