import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { DrivingLicence } from "./driving_licences.service";

angular
  .module("driving_licences")
  .factory("DrivingLicencesSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(DrivingLicence, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("DrivingLicencesFactory", DrivingLicence);
