export default class DateRangeSelectorCtrl {
  public dateRangeUniqID: string;

  constructor() {
    this.dateRangeUniqID = _.uniqueId("dateRangeEnabled");
  }

  public open($event: ng.IAngularEvent, paramName: string) {
    $event.preventDefault();
    $event.stopPropagation();

    this[paramName] = true;
  };
};