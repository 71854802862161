import { ProjectsShowCtrl } from "./projects-show-view.controller";
import projectsShowViewTemplate from "./projects-show-view.template.html";

angular.module("projects.views").component("projectsShowView", {
  bindings: {
    project: "<projectResponse",
    savedSearches: "<savedSearchesResponse",
    scrollspyScope: "<",
    $state$: "<",
  },
  template: projectsShowViewTemplate,
  controller: ProjectsShowCtrl,
});
