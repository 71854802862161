import { Graduation } from "../../graduations/services/graduations.service";
import { Priority } from "../../priorities/services/priority.service";
import { DrivingLicence } from "../../driving_licences/services/driving_licences.service";
import { LeadershipExperience } from "../../leadership_experiences/services/leadership_experiences.service";
import { CancelationPeriod } from "../../cancelation_periods/services/cancelation_periods.service";
import { MaritalStatus } from "../../marital_statuses/services/marital_status.service";
import { Region } from "../../regions/services/regions.service";
import { Language } from "../../languages/services/languages.service";
import { Country } from "../../countries/services/countries.service";

angular
  .module("values.routing-options")

  .constant("candidateOpts", {
    show: {
      resolve: {
        candidateResponse: [
          "$transition$",
          "CandidatesFactory",
          function ($transition$, CandidatesFactory) {
            var params = $transition$.params();
            return CandidatesFactory.get(params.project_candidate_id || params.candidate_id || params.id);
          },
        ],
        projectCandidateResponse: [
          "$transition$",
          "CandidatesFactory",
          function ($transition$, CandidatesFactory) {
            var params = $transition$.params();

            if (params.project_candidate_id) {
              return CandidatesFactory.get(params.project_candidate_id);
            } else {
              return undefined;
            }
          },
        ],
        candidate: [
          "candidateResponse",
          "projectCandidateResponse",
          function (candidateResponse, projectCandidateResponse) {
            return projectCandidateResponse || candidateResponse;
          },
        ],
        documentView: [
          function () {
            return false;
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "candidatesShowView",
        };
        viewObj["deck-sidebar@" + state] = {
          component: "candidatesMenuView",
        };
        viewObj["deck-sub@" + state] = {
          component: "candidatesDocumentsView",
        };
        return viewObj;
      },
    },
    new: {
      resolve: {
        candidateResponse: [
          "CandidatesFactory",
          function (CandidatesFactory) {
            return CandidatesFactory.$get("candidates/new");
          },
        ],
        projectCandidateResponse: [
          "$transition$",
          "CandidatesFactory",
          function ($transition$, CandidatesFactory) {
            var params = $transition$.params();

            if (params.project_candidate_id) {
              return CandidatesFactory.get(params.project_candidate_id);
            } else {
              return undefined;
            }
          },
        ],
        candidate: [
          "candidateResponse",
          "projectCandidateResponse",
          function (candidateResponse, projectCandidateResponse) {
            return projectCandidateResponse || candidateResponse;
          },
        ],
        documentView: [
          function () {
            return false;
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "candidatesEditView",
        };
        viewObj["deck-sidebar@" + state] = {
          component: "candidatesMenuView",
        };
        return viewObj;
      },
    },
    edit: {
      resolve: {
        candidateResponse: [
          "$transition$",
          "CandidatesFactory",
          function ($transition$, CandidatesFactory) {
            var params = $transition$.params();

            return CandidatesFactory.get(params.candidate_id || params.id);
          },
        ],
        projectCandidateResponse: [
          "$transition$",
          "CandidatesFactory",
          function ($transition$, CandidatesFactory) {
            var params = $transition$.params();

            if (params.project_candidate_id) {
              return CandidatesFactory.get(params.project_candidate_id);
            } else {
              return undefined;
            }
          },
        ],
        candidate: [
          "candidateResponse",
          "projectCandidateResponse",
          function (candidateResponse, projectCandidateResponse) {
            return projectCandidateResponse || candidateResponse;
          },
        ],
        deregCandidateDiff: [
          "$rootScope",
          "candidate",
          function ($rootScope, candidate) {
            return $rootScope.$on("showCandidateDiff", function (event, candidateDiffData) {
              candidate.snapshot();
              candidate.extendByDiff(candidateDiffData);
            });
          },
        ],
        documentView: [
          function () {
            return false;
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "candidatesEditView",
        };
        viewObj["deck-sidebar@" + state] = {
          component: "candidatesMenuView",
        };
        viewObj["deck-sub@" + state] = {
          component: "candidatesDocumentsView",
        };
        return viewObj;
      },
    },
    requirements: {
      languages: [
        function () {
          return Language.getAll();
        },
      ],
      countries: [
        function () {
          return Country.getAll();
        },
      ],
      candidatesResponse: [
        function () {
          return undefined;
        },
      ],
      maritalStatuses: [
        function () {
          return MaritalStatus.getAll();
        },
      ],
      leadershipExperiences: [
        function () {
          return LeadershipExperience.getAll();
        },
      ],
      drivingLicences: [
        function () {
          return DrivingLicence.getAll();
        },
      ],
      cancelationPeriods: [
        function () {
          return CancelationPeriod.getAll();
        },
      ],
      graduations: [
        function () {
          return Graduation.getAll();
        },
      ],
      priorities: [
        function () {
          return Priority.getAll();
        },
      ],
      regions: [
        function () {
          return Region.getAll();
        },
      ],
      objectType: [
        function () {
          return "candidate";
        },
      ],
      scrollspyScope: [
        function () {
          return _.uniqueId("scrollspy");
        },
      ],
    },
  });
