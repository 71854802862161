export default class UiTinymceMegaCtrl {
  public uiTinymceMegaId: string;
  public uiTinymceConfig: any;
  public uiTinymceMinLength: string;
  public ngModelCtrl: ng.INgModelController;

  static $inject = ["tinymceConfig"];

  constructor(
    private tinymceConfig: any
  ) {
    if (!this.uiTinymceMegaId || this.uiTinymceMegaId.length === 0) {
      this.uiTinymceMegaId = _.uniqueId("tinymce_");
    }

    this.uiTinymceConfig = tinymceConfig;
  }

  public $onInit() {
    if (_.isEmpty(this.uiTinymceMinLength)) {
      return true;
    }

    var minLength = parseInt(this.uiTinymceMinLength);

    // add a parser that will process each time the value is
    // parsed into the model when the user updates it.
    this.ngModelCtrl.$parsers.unshift((value) => {
      var valid = String(value).replace(/<[^>]+>/gm, "").length >= minLength;
      this.ngModelCtrl.$setValidity("uiTinymceMinLength", valid);

      return valid ? value : null;
    });

    // add a formatter that will process each time the value
    // is updated on the DOM element.
    this.ngModelCtrl.$formatters.unshift((value) => {
      var valid = String(value).replace(/<[^>]+>/gm, "").length >= minLength;
      this.ngModelCtrl.$setValidity("uiTinymceMinLength", valid);

      // return the value or nothing will be written to the DOM.
      return value;
    });
  };
};