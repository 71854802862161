import { ITemplateCacheService } from "angular";
import personDuplicatesPopoverTemplate from "./person-duplicates-popover.template.html";

export default class PersonDuplicateButtonCtrl {
    public personDuplicatesPopover: string;
    public person: any;
    public $doCheck: () => void;
    static $inject = ["DuplicateChecker", "$templateCache"];

    constructor(
        private DuplicateChecker: any,
        private $templateCache: ITemplateCacheService
    ) {
        this.personDuplicatesPopover = "person-duplicates-popover";
        this.$templateCache.put(this.personDuplicatesPopover, personDuplicatesPopoverTemplate);
    }

    public $onInit() {
        var duplicatesChecker = new this.DuplicateChecker(this.person);
        this.$doCheck = duplicatesChecker.doCheck;
    };
};