import { QuicklistsTableBodyCtrl } from "../quicklists-table-body.controller";
import quicklistsCandidatesTableBodyTemplate from "./quicklists-candidates-table-body.template.html";

angular.module("quicklists.components").component("quicklistsCandidatesTableBody", {
  bindings: {
    candidates: "=data",
    quicklist: "=list",
    attributes: "=",
    tableRowIdentifier: "=",
    activeRow: "=",
  },
  template: quicklistsCandidatesTableBodyTemplate,
  controller: QuicklistsTableBodyCtrl,
});
