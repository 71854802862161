import AddressSelectCtrl from "./address-select.controller";
import addressSelectTemplate from "./address-select.template.html";

angular.module("directives").component("addressSelect", {
  bindings: {
    object: "=ngModel",
    addressList: "=",
    fieldName: "@",
    label: "@",
  },
  template: addressSelectTemplate,
  controller: AddressSelectCtrl,
  controllerAs: "ASCtrl",
});
