//= require ./services.module.js
angular.module("services").factory("Autocompletes", [
  "$http",
  "$q",
  function ($http, $q) {
    return {
      address: function (query, scope, canceler) {
        if (!canceler) {
          canceler = $q.defer();
        }
        return $http
          .get("/autocomplete/address", {
            params: {
              q: query.replace(/\//g, " "),
              scope: scope,
            },
            timeout: canceler.promise,
          })
          .then(
            function (response) {
              // append the query as first autocomplete suggestion
              return [{ resource_type: "Query", highlight: [query] }].concat(
                <any>_.map(response.data, function (obj) {
                  return _.pick(obj, ["display_name", "address"]);
                })
              );
            },
            function () {
              // Error handling (return code 404)
              return [{ resource_type: "Query", highlight: [query] }];
            }
          )
          .catch(angular.noop);
      },
      autocomplete: function (query, scope, canceler, filters, filter_args, query_params?) {
        if (!canceler) {
          canceler = $q.defer();
        }
        return $http
          .get("/autocomplete", {
            params: {
              q: query.replace(/\//g, " "),
              scope: scope,
              "filters[]": filters,
              filter_args: filter_args,
              size: query_params?.size
            },
            timeout: canceler.promise,
          })
          .then(function (response) {
            // append the query as first autocomplete suggestion
            return [{ resource_type: "Query", highlight: [query] }].concat(response.data.data);
          })
          .catch(angular.noop);
      },
    };
  },
]);
