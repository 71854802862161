import { TransactionBasisListCtrl } from "./transaction-basis-list.controller";
import transactionBasisListTemplate from "./transaction-basis-list.template.html";

angular.module("transaction_bases.components").component("transactionBasisList", {
  bindings: {
    transactionBases: "=",
  },
  template: transactionBasisListTemplate,
  controller: TransactionBasisListCtrl,
});
