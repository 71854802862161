import nationalitiesSelectCtrl from "./nationalities-select.controller";
import nationalitiesSelectTemplate from "./nationalities-select.template.html";

angular.module("countries.components").component("nationalitiesSelect", {
  bindings: {
    nationalities: "=ngModel",
  },
  controller: nationalitiesSelectCtrl,
  template: nationalitiesSelectTemplate,
});


