import { IRootScopeService, IFilterService } from 'angular';
export default class ActionsContextMenuCtrl {

  private action: any;
  private versionsOld: number;
  private deregDealFormOpened: () => void;
  private cpjoin: any;
  private loading: boolean;
  private lastEdit: any;
  private object: any;

  static $inject = ["$state", "$rootScope", "$filter", "CandidatesProjectsFactory", "UsersFactory", "DealsFactory"];

  constructor(private $state: angular.ui.IStateService,
              private $rootScope: IRootScopeService,
              private $filter: IFilterService, 
              private CandidatesProjects: any, 
              private User: any, 
              private Deal: any,
              public $dismiss: any) {}
  
    public $onInit () {
      this.versionsOld = this.action.versions.length;

      angular.extend(this.action, {
        isConvertableToEvent:
          !!this.action.actionType.getActionTypeOptions(this.object).convertToEvent &&
          this.action.id &&
          this.action.plannedFor &&
          !this.action.actionType.isEvent,
      });
    };

    public editDeal () {
      this.CandidatesProjects.get({ candidate_id: this.action.candidate.id, project_id: this.action.project.id }).then(
        (result: any) => {
          this.cpjoin = result;

          this.loading = true;
          this.deregDealFormOpened = this.$rootScope.$on("deal-form-opened", (event: any) => {
            this.loading = false;
            this.deregDealFormOpened();
          });

          if (this.action.deal) {
            this.Deal.get(this.action.deal.id)
              .then((deal: any) => {
                deal.edit();
              })
              .catch(() => {
                this.loading = false;
                this.deregDealFormOpened();
              });
          } else {
            var dealOptions = {
              add_action: { id: this.action.id },
              candidatesProjectId: this.cpjoin.id,
              closing: this.action.isActionType("closing"),
            };

            if (this.action.actionableType === "EmployeesCandidatesProject") {
              _.extend(dealOptions, { employeesCandidatesProjectId: this.action.actionableId });
            }

            this.Deal.createAndEdit(dealOptions).catch(() => {
              this.loading = false;
              this.deregDealFormOpened();
            });
          }
        }
      );
    };

    public openExpertProfile () {
      this.$state.go("root.candidates.show.expert_profile.new", {
        candidate_id: this.action.candidate.id,
        project_id: this.action.project.id,
      });
    };

    public cancel () {
      this.$dismiss("cancel");
    };

    public $doCheck () {
      if (this.versionsOld > 0) {
        var lastVersion = this.action.versions[this.action.versions.length - 1];
        this.lastEdit = {
          user: this.User.findBy(parseInt(lastVersion.whodunnit)),
          timestamp: lastVersion.createdAt,
        };
        this.versionsOld = this.action.versions.length;
      }
    };

    public editAction (options) {
      this.action.actionEditModal(this.object, options).catch(angular.noop);
    };

    public deleteFollowUp () {
      this.action.deleteNote(this.object).then(
        (result: any) => {
          this.action.snapshot();
          this.cancel();
        },
        () => {
          window.alert(this.$filter("translate")("HISTORY_ENTRIES_NOT_DELETED_NOTICE"));
        }
      );
    };

    public decline () {
      this.action.$post(this.action.$url("/decline.json")).then(
         (result: any) => {
          _.extend(this.action, _.pick(result, ["declinedAt", "declinedByUser"]));
          this.action.snapshot();
        },
        () => {
          window.alert(this.$filter("translate")("HISTORY_ENTRIES_NOT_DECLINED_NOTICE"));
        }
      );
    };
    public $onDestroy () {
      if (angular.isFunction(this.deregDealFormOpened)) this.deregDealFormOpened();
    };
}