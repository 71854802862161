import { initContract } from "@ts-rest/core";

import { THighschool } from "../highschools";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const HighschoolsSchema = c.type<TBaseResource<THighschool[]>>();
export const highschoolsContract = c.router({
  query: {
    method: "GET",
    path: "/highschools",
    responses: {
      200: HighschoolsSchema,
    },
    summary: "Get all highschools",
  },
});
