import LocationPreferencesFormCtrl from "./location-preferences-form.controller";
import locationPreferencesFormTemplate from "./location-preferences-form.template.html";

angular.module("locations.components").component("locationPreferencesForm", {
  bindings: {
    employee: "=",
  },
  template: locationPreferencesFormTemplate,
  controller: LocationPreferencesFormCtrl,
});
