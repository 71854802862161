import { Priority } from "../../services/priority.service";

export class PrioritySelectCtrl {
  static $inject = [];
  constructor() {}

  public priorities: Priority[];

  public $onInit() {
    Priority.getAll().then((priorities) => (this.priorities = priorities));
  }
}
