//= require ../projects.module.js

angular.module("projects").factory("ProjectsHotkeys", [
  "hotkeys",
  "$state",
  "$rootScope",
  function (hotkeys, $state, $rootScope) {
    return {
      registerHotkeys: function () {
        $(window).keydown(function (event) {
          if (event.ctrlKey && event.keyCode == 80) {
            // ctrl+p
            event.preventDefault();
          }
        });

        hotkeys.add({
          combo: "mod+p s",
          description: "Nach Projekten suchen",
          callback: function (event, hotkey) {
            event.preventDefault();
            $rootScope.$broadcast("projectsSearch");
          },
        });

        hotkeys.add({
          combo: "mod+p c",
          description: "Ein Projekt erstellen",
          callback: function (event, hotkey) {
            event.preventDefault();
            $state.go("root.projects.new");
          },
        });
      },
      deregisterHotkeys: function () {
        if ($state.current.name.indexOf("root.projects") > -1) {
          hotkeys.del("mod+p c");
          hotkeys.del("mod+p s");
        }
      },
    };
  },
]);
