export class AdvancedLonglistSearchCtrl {
  public advancedQuery: any;
  private setData: () => void;
  private closing: () => void;

  constructor() {}

  public performAdvancedSearch() {
    this.setData();
    this.closing();
  }

  $onInit() {
    this.advancedQuery.name = undefined;
    this.advancedQuery.id = undefined;
  }
}
