import DocumentsFullscreenModeModalCtrl from "./documents-fullscreen-mode.controller";
import documentsFullscreenModeTemplate from "./documents-fullscreen-mode.template.html";

angular.module("documents.modals").component("documentsFullscreenMode", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: documentsFullscreenModeTemplate,
  controller: DocumentsFullscreenModeModalCtrl,
});
