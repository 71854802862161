import { ActionType } from "../../../action_types/services/action_types.service";
export class SendoutsEpTableBodyCtrl {
  public state: (statePrefix: any, state: any, params: any) => string;

  static $inject = ["$rootScope", "ActionsFactory"];

  constructor(private $rootScope: IExtendedRootScopeService, private Action: any) {}

  private loading: boolean = false;
  private cpjoinActionChangedListener: () => void;

  public sendoutActions: any[];
  public cpjoin: any;
  public sendouts: any[];

  private initializeLegacySendoutsList() {
    this.sendoutActions = _.filter(this.cpjoin.actions(), (action: any) => {
      return (
        action.completedAt &&
        action.actionType.isSendoutAction() &&
        !_.find(this.sendouts, function (sendout) {
          return sendout.action.id === action.id;
        })
      );
    });

    this.loading = false;
  }

  private initializeSendoutsList() {
    this.sendouts = (this.cpjoin.expertProfile || {}).$sendouts;

    if (this.cpjoin.expertProfile && !this.cpjoin.expertProfile.isInitiativeProfile()) {
      this.loading = true;

      // Must load actions seperatly from the API
      _.once(() => {
        this.Action.lazyLoadingActions
          .bind(this)(this.cpjoin, {
            conditions: _.map(ActionType.filter(["sendout", "profile-saved"]), function (actionType: ActionType) {
              return { modifier: "or", field: "action_type", query: _.pick(actionType, "typeName", "id") };
            }),
          })
          .then(() => {
            if (this.cpjoin.actions().isMoreAvailable()) {
              this.cpjoin.actions().getMore().then(this.initializeLegacySendoutsList.bind(this));
            } else {
              this.initializeLegacySendoutsList();
            }
          });
      })();
    }
  }

  public $onInit() {
    this.cpjoinActionChangedListener = this.$rootScope.$on(
      "cpjoin_action_changed_" + this.cpjoin.id,
      this.initializeSendoutsList.bind(this)
    );

    this.initializeSendoutsList();
  }

  public $onDestroy() {
    this.cpjoinActionChangedListener();
  }
}
