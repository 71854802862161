import { Language } from "../../services/languages.service";

export default class LanguageSelectCtrl {
  private languages: any[] = [];
  private user: any;
  public ngModel: { locale: string };

  static $inject = ["Session"];

  constructor (private Session: any) {
    this.user = Session.user;
  }

  public $onInit () {
    this.languages = Language.defaultOptions;
    this.ngModel.locale = _.first(        
      _.select(this.languages, (language) => {
        return language.key == this.user.locale;
      })
    ).key;
  };
}