export default class DealNotificationsCtrl{ 
  private deal: any;

  constructor() {}
  
  public showSapTransmissionErrors () {
    return (
      !this.deal.generating &&
      ((!this.deal.hasSapId && this.deal.needsSapSalesOrderSync) ||
        (!this.deal.needsSapSalesOrderSync && this.deal.dealToSapCpiJobErrorMessages.length > 0))
    );
  };
}


