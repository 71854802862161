import DealEditModalCtrl from "./deal-edit-modal.controller";
import dealEditModalTemplate from "./deal-edit-modal.template.html";

angular.module("deals.modals").component("dealEditModal", {
  bindings: {
    resolve: "<",
    dismiss: "&",
  },
  controller: DealEditModalCtrl,
  template: dealEditModalTemplate,
});
