import { ITemplateCacheService } from "angular";

export default class DashboardCardsCtrl {
    public dashboardSettingsCopy: any; 
    public expandQuicklist: any;
    static $inject = ["dashboardSettings2021", "$templateCache", "$cookies"];

    constructor(
        private dashboardSettings: any,
        private $templateCache: ITemplateCacheService,
        private $cookies: angular.cookies.ICookiesService,
    ) {}

    public $onInit() {
        this.dashboardSettingsCopy = this.dashboardSettings;
        this.expandQuicklist = this.$cookies.get("expandQuicklist") != "false";

        _.each(this.dashboardSettingsCopy, (dashboardSetting) => {
            this.$templateCache.put(
            dashboardSetting.directive,
            "<" + dashboardSetting.directive + "></" + dashboardSetting.directive + ">"
            );
        });
    };
};