//= require ./modals.module.js

angular.module("documents.modals").factory("DocumentsModalMixin", [
  "$uibModal",
  "$rootScope",
  "$state",
  function ($uibModal, $rootScope, $state) {
    function DocumentsModalMixin() {}

    function textkernelCandidateDiff() {
      if (!this.isTextkernelAvailable()) {
        return false;
      }
      var doc = this;

      var modalInstance = $uibModal.open({
        component: "textkernelDiff",
        size: "lg",
        resolve: {
          doc: [
            function () {
              return doc;
            },
          ],
          ObjClass: [
            "CandidatesFactory",
            function (Candidate) {
              return Candidate;
            },
          ],
        },
      });

      return modalInstance.result.then(
        function (candidateDiff) {
          $state.go("root.candidates.edit", { candidate_id: candidateDiff.id, document_id: doc.id }).then(function () {
            $rootScope.$broadcast("showCandidateDiff", candidateDiff);
          });
        },
        function () {
          //$log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    function textkernelProjectDiff() {
      if (!this.isTextkernelAvailable()) {
        return false;
      }
      var doc = this;

      var modalInstance = $uibModal.open({
        component: "textkernelDiff",
        size: "lg",
        resolve: {
          doc: [
            function () {
              return doc;
            },
          ],
          ObjClass: [
            "ProjectsFactory",
            function (Project) {
              return Project;
            },
          ],
        },
      });

      return modalInstance.result.then(
        function (projectDiff) {
          $state.go("root.projects.edit", { project_id: projectDiff.id, document_id: doc.id }).then(function () {
            $rootScope.$broadcast("showProjectDiff", projectDiff);
          });
        },
        function () {
          //$log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    function openDocumentFullScreen(documents) {
      var openDocument = this;

      var modalInstance = $uibModal.open({
        component: "documentsFullscreenMode",
        size: "xl",
        resolve: {
          documents: [
            function () {
              return documents;
            },
          ],
          openDocument: [
            function () {
              return openDocument;
            },
          ],
        },
      });

      return modalInstance.result.then(
        function () {},
        function () {
          //$log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    DocumentsModalMixin.extended = function (Resource) {
      Resource.include({
        textkernelCandidateDiff: textkernelCandidateDiff,
        textkernelProjectDiff: textkernelProjectDiff,
        openDocumentFullScreen: openDocumentFullScreen,
      });
    };

    return DocumentsModalMixin;
  },
]);
