import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TPriority } from "../priorities";

export class Priority {
  private serviceName: string = "PrioritiesFactory";
  private id: number;
  public name: string;
  public $selected: boolean;

  static $inject = [];

  constructor(fields?: Partial<TPriority>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TPriority>>(fields, camelCase));
  }

  @cached
  public static getAll() {
    return baseClient.priorities.query().then((response) => {
      return response.body.data.map((element) => {
        return new Priority(element);
      });
    });
  }
}
