export default class ProjectSheetCtrl {
  private dayAttrs: any;
  private sumDay: any;
  private steps: any;

  static $inject = ["ProjectReportingsFactory", "KPISteps"];

  constructor (private ProjectReporting: any, private KPISteps: any) {

    this.dayAttrs = ProjectReporting.ProjectDayAttrs;
    this.sumDay = ProjectReporting.sumDay;
    this.steps = KPISteps;
  }
}