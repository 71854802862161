import HistoryAdvancedSearchCtrl from "./history-advanced-search.controller";
import historyAdvancedSearchTemplate from "./history-advanced-search.template.html";

angular.module("actions.components").component("historyAdvancedSearch", {
  bindings: {
    advancedQuery: "=",
    setData: "=",
    followUps: "@",
    closing: "&",
  },
  template: historyAdvancedSearchTemplate,
  controller: HistoryAdvancedSearchCtrl,
});
