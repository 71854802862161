import { TextkernelLonglistConfirmModalCtrl } from "./textkernel-longlist-confirm.controller";
import textkernelLonglistConfirmTemplate from "./textkernel-longlist-confirm.template.html";

angular.module("textkernel.modals").component("textkernelLonglistConfirm", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: textkernelLonglistConfirmTemplate,
  controller: TextkernelLonglistConfirmModalCtrl,
});
