export class ProjectSearchCtrl {
  public foundProjects: any[] = [];
  public loading: boolean = false;
  private cancelers: ng.IDeferred<any>[] = [];
  public advancedQuery: any;
  public query: string;
  public setData: (advancedQuery: any) => void;
  public remote: boolean;
  public advancedProjectSearchOptions: {
    remote: boolean;
    setData: (advancedQuery: any) => void;
    query: string;
    advancedQuery: any;
  };

  static $inject = ["$state", "$q", "ProjectsFactory", "Autocompletes"];
  constructor(
    private $state: angular.ui.IStateService,
    private $q: ng.IQService,
    private ProjectService: any,
    private Autocompletes: any
  ) {}

  public $onInit() {
    this.advancedQuery.resourceType = "ProjectSearch";

    this.advancedProjectSearchOptions = {
      remote: this.remote,
      setData: this.setData,
      query: this.query,
      advancedQuery: this.advancedQuery,
    };
  }

  public searchProjects(query) {
    if (query.length) {
      this.loading = true;
      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      this.Autocompletes.autocomplete(query, "project_title", _.last(this.cancelers)).then((results) => {
        this.foundProjects = _.map(results, (result) => {
          switch (result.resource_type) {
            case "project":
              result.name = result.project.title;
              return _.extend(new this.ProjectService(result.project), {
                resource_type: "Projekt",
                fn: (id: number, query: string) => {
                  this.advancedQuery.basicQuery = query;
                  this.$state.go("root.projects.show", this.advancedQuery.collectSearchParams({ project_id: id }), {
                    reload: true,
                    inherit: false,
                  });
                },
              });
            default:
              result.name = result.highlight[0];
              return _.extend(result, {
                resource_type: "Query",
                fn: (id: number, query: string) => {
                  this.search(query);
                },
              });
          }
        });
        this.loading = false;
      });
    } else {
      this.foundProjects = [];
    }
  }

  public search(query: string, routeFn?: any) {
    this.advancedQuery.basicQuery = query;

    if (!this.remote) {
      this.$state.go("root.projects", this.advancedQuery.collectSearchParams(), { reload: true, inherit: false });
    } else {
      this.setData(this.advancedQuery.collectSearchParams());
    }
  }

  public onTypeAheadSelect(item, query) {
    if (item && item.id) {
      query = '"' + item.name + '"';
    }

    _.invoke(this.cancelers, "resolve");

    if (!this.remote && item && item.id) {
      item.fn(item.id, query);
    } else {
      this.search(query);
    }
  }
}
angular.module("projects.components").controller("ProjectSearchCtrl", [
  "$state",
  "$q",
  "ProjectsFactory",
  "Autocompletes",
  function ($state, $q, Project, Autocompletes) {
    var _this = this;
    _this.foundProjects = [];
    _this.loading = false;
    _this.cancelers = [];

    _this.$onInit = function () {
      _this.advancedQuery.resourceType = "ProjectSearch";

      _this.advancedProjectSearchOptions = {
        remote: _this.remote,
        setData: _this.setData(),
        query: _this.query,
        advancedQuery: _this.advancedQuery,
      };
    };
  },
]);
