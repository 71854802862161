//= require ./services.module.js
angular
  .module("services")

  .factory("UrlSerializer", [
    "railsSerializer",
    "$sce",
    function (railsSerializer, $sce) {
      return railsSerializer(function () {
        this.deserialize = function (url) {
          if (_.isString(url)) {
            return $sce.trustAsResourceUrl(url);
          } else {
            return url;
          }
        };
        this.serialize = function (url) {
          return url;
        };
      });
    },
  ]);
