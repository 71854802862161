export default class CandidateSearchCtrl {
  private advancedQuery: any;
  private loading: boolean;
  private remote: boolean;
  private setData: () => (params: any) => void;
  private advancedCandidateSearchOptions: any;
  private query: string;
  private cancelers: ng.IDeferred<any>[] = [];
  private searchedSkills: any;
  private scores: any;

  static $inject = ["$q","SavedSearchesFactory", "Session", "Autocompletes", "$log", "$state"];

  constructor(private $q: ng.IQService, 
              private SavedSearchesFactory: any, 
              private Session: any, 
              private Autocompletes: any, 
              private $log: ng.ILogService, 
              private $state: angular.ui.IStateService) {}

  public $onInit() {
    if (this.advancedQuery) {
      this.advancedQuery.resourceType = "CandidateSearch";
      this.loading = false;
    }

    this.advancedCandidateSearchOptions = {
      query: this.query,
      setData: this.setData(),
      remote: this.remote,
      advancedQuery: this.advancedQuery,
    };

    this.cancelers = [];
  };

  public searchSkills (value) {
    if (value.length) {
      this.loading = true;

      this.searchedSkills = [{ resource_type: "Query", name: value }];
      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      this.Autocompletes.autocomplete(value, "candidate_name", _.last(this.cancelers)).then( (results) => {
        this.searchedSkills = _.chain(results)
          .groupBy("id")
          .map( (result) => {
            switch (result[0].resource_type) {
              case "candidate":
                return {
                  resource_type: "Kandidat",
                  id: result[0].candidate.id,
                  name: result[0].candidate.name,
                  highlights: _.pluck(result, "highlight"),
                  scores: _.pluck(result, "score"),
                };
              default:
                return {
                  resource_type: "Query",
                  name: result[0].highlight[0],
                  highlights: _.pluck(result, "highlight"),
                };
            }
          })
          .sortBy((result) => {
            return _.reduce(
              result.scores || [],
              (memo, num) => memo + num,
              0
            );
          })
          .value()
          .reverse();

        if (!_.isEmpty(this.searchedSkills)) {
          this.searchedSkills.splice(0, 0, this.searchedSkills.pop());
        }
        this.loading = false;
      });
    } else {
      this.searchedSkills = [];
    }
  };

  public search (item, query) {
    if (item && item.id) {
      query = item.name;
    }

    _.invoke(this.cancelers, "resolve");

    this.advancedQuery.basicQuery = query;
    var params = this.advancedQuery.collectSearchParams({ candidate_id: item ? item.id : undefined });

    if (!this.remote) {
      var route = "root.candidates";
      if (item && item.id) {
        route = "root.candidates.show";
        params.q = '"' + query.replace(/\//g, " ") + '"';
      }

      this.$state.go(route, params, { reload: true, inherit: false });
    } else {
      this.setData()(params);
    }
  };
}

