import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";
import _ from "underscore";

import { baseClient, cached } from "../../api-routing/base-client";
import { TActionType } from "../action_types";

import { CancelationPeriod } from "../../cancelation_periods/services/cancelation_periods.service";
import { Graduation } from "../../graduations/services/graduations.service";
import { JobCategory } from "../../job_categories/services/job_categories.service";
import { JobType } from "../../job_types/services/job_types.service";

export class ActionType {
  private id: number;
  public completeDescription: string;
  private system: boolean;
  public typeName: string;
  static $inject = [];

  constructor(fields?: Partial<TActionType>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TActionType>>(fields, camelCase));
  }

  public isSendoutAction() {
    return _.contains(["profile-sent", "sendout", "profile-saved"], this.typeName);
  }

  public isTelephoneAction() {
    return _.contains(["telephone-meeting", "telephone-interview"], this.typeName);
  }

  public isInterviewActionType() {
    return _.contains(["telephone-interview", "presence-interview"], this.typeName);
  }

  public isDealActionType() {
    return _.contains(["closing", "deal"], this.typeName);
  }

  public getActionTypeOptions(object) {
    let type = object && ActionTypeOptions[object.resourceType] ? object.resourceType : "default";

    return ActionTypeOptions[type][this.typeName] || {};
  }

  @cached
  public static getAll() {
    return baseClient.actionTypes.query().then((response) => {
      return response.body.data.map((element) => {
        return new ActionType(element);
      });
    });
  }

  public static async getAllWithoutVerbose() {
    let actionTypes: ActionType[] = await ActionType.getAll();
    return _.filter(actionTypes, (actionType: ActionType) => {
      return !actionType.system;
    });
  }

  public static async filter(arrOfTypeNames) {
    let actionTypes = await ActionType.getAll();
    return _.chain(actionTypes)
      .filter((actionType: ActionType) => {
        return _.contains(arrOfTypeNames, actionType.typeName);
      })
      .value();
  }
}

export const ActionTypeOptions = {
  EmployeesProjects: {
    qualification: {
      actionTemplate: "qualification-employees-projects-template",
    },
  },
  Employee: {
    qualification: {
      actionTemplate: "qualification-employees-projects-template",
    },
  },
  default: {
    qualification: {
      actionTemplate: "qualification-template",
      resolves: function () {
        return [Graduation.getAll(), CancelationPeriod.getAll()];
      },
    },
    "initial-interview": {
      actionTemplate: "first-call-template",
      resolves: function () {
        return [JobCategory.getAll(), JobType.getAll()];
      },
    },
    briefing: {
      actionTemplate: "briefing-template",
      convertToEvent: true,
    },
    "briefing-appointment": {
      actionTemplate: "briefing-template",
    },
    debriefing: {
      actionTemplate: "debriefing-template",
      convertToEvent: true,
    },
    "debriefing-appointment": {
      actionTemplate: "debriefing-template",
    },
    "phone-call": {
      convertToEvent: true,
    },
    sendout: {
      hasTracking: true,
    },
    "pitch-sendout": {
      hasTracking: true,
    },
    "project-summary-sent": {
      hasTracking: true,
    },
    "skill-matrix-sent": {
      hasTracking: true,
    },
  },
};
