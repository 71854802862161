import { ITemplateCacheService } from "angular";

export default class PopoverTranscludeCtrl {
  public directiveNameIdentifier: string;
  public directiveName: string;
  public directiveOptions: {
    closing?: () => void;
    context?: any;
    setData?: (data: any) => void;
    remote?: any;
    advancedQuery?: any;
    initFn?: () => void;
    user?: any;
    followUps?: string;
  };
  
  static $inject = ["$templateCache"];

  constructor (
    private $templateCache: ITemplateCacheService
  ) {}

  public $onInit() {
    this.directiveNameIdentifier = "prsm-table-body-" + this.directiveName;
    this.$templateCache.put(
      this.directiveNameIdentifier,
      "<" +
        this.directiveName +
        ' closing="$ctrl.directiveOptions.closing()"' +
        ' query="$ctrl.directiveOptions.context"' +
        ' set-data="$ctrl.directiveOptions.setData"' +
        ' remote="$ctrl.directiveOptions.remote"' +
        ' advanced-query="$ctrl.directiveOptions.advancedQuery"' +
        ' init-fn="$ctrl.directiveOptions.initFn"' +
        ' user="$ctrl.directiveOptions.user"' +
        ' follow-ups="' +
        this.directiveOptions.followUps +
        '"' +
        '"></' +
        this.directiveName +
        ">"
    );
  };
};