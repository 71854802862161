import CandidatesIndexViewCtrl from "./candidates-index.controller";
import candidatesIndexViewTemplate from "./candidates-index-view.template.html";

angular.module("candidates.views").component("candidatesIndexView", {
  bindings: {
    savedSearches: "<savedSearchesResponse",
    stateParams: "<",
    query: "<",
    advancedQuery: "<",
    $state$: "<",
  },
  template: candidatesIndexViewTemplate,
  controller: CandidatesIndexViewCtrl,
});
