export default class DocumentsDeckViewCtrl {

  private openDocument: any;
  private documents: any[];
  private openDocumentId: string; 

  constructor() {}

  public openDocumentFullScreen (event) {
    event.preventDefault();
    if (!this.openDocument) {
      return;
    }

    this.openDocument.openDocumentFullScreen(this.documents);
  };

  public openDocuments () {
    var cvs = _.chain(this.documents)
      .where({ extension: "pdf" })
      .sortBy("createdAt")
      .reverse()
      .sortBy((doc) => {
        return doc.fileTypeId();
      })
      .filter((doc) => {
        return doc.fileTypeId() > 0;
      })
      .value();

    var docId = parseInt(this.openDocumentId) || (_.first(cvs) ? _.first(cvs).id : undefined);
    if (this.documents.length && docId) {
      this.openDocument = _.first(_.where(this.documents, { id: docId }));
    } else {
      this.openDocument = undefined;
    }
  }

  public $onInit () {
    this.openDocuments();
  };
}