//= require ../modals.module.js

angular.module("actions.modals").factory("ActionModals", [
  "$uibModal",
  function ($uibModal) {
    function selectActionModal(queryUrl, params, options) {
      var modalInstance = $uibModal.open({
        component: "selectActionModal",
        size: "lg",
        resolve: {
          options: function () {
            return _.isObject(options) ? options : {};
          },
          actions: [
            "ActionsFactory",
            function (Action) {
              return Action.$http({
                url: "actions/kpi_route",
                params: _.extend({ follow_ups: true, key: queryUrl }, params),
                headers: { Accept: "application/json" },
              });
            },
          ],
        },
      });

      return modalInstance.result;
    }

    function showActionsListModal(actions, step) {
      var modalInstance = $uibModal.open({
        component: "showActionsListModal",
        size: "lg",
        resolve: {
          actions: [
            function () {
              return _.isArray(actions) ? actions : [];
            },
          ],
          step: [
            function () {
              return _.isObject(step) ? step : {};
            },
          ],
        },
      });

      return modalInstance.result.then(angular.noop, angular.noop);
    }

    return {
      selectAction: selectActionModal,
      showActionsList: showActionsListModal,
    };
  },
]);
