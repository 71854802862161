import { ProjectsEditCtrl } from "./projects-edit-view.controller";
import projectsEditViewTemplate from "./projects-edit-view.template.html";

angular.module("projects.views").component("projectsEditView", {
  bindings: {
    project: "<projectResponse",
    leadershipExperiences: "<",
    transactionBases: "<transactionBasesResponse",
    languages: "<",
    jobTypes: "<",
    users: "<",
    deregProjectDiff: "&",
    scrollspyScope: "<",
  },
  template: projectsEditViewTemplate,
  controller: ProjectsEditCtrl,
});
