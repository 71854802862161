import quicklistsTableBodyTemplate from "./quicklists-table-body.template.html";

angular.module("quicklists.components").component("quicklistsTableBody", {
  bindings: {
    quicklists: "=data",
    attributes: "=",
    tableRowIdentifier: "=",
  },
  template: quicklistsTableBodyTemplate,
});
