import usersHelpSidebarTemplate from "./users-help-sidebar.template.html";
import UsersHelpSidebarCtrl from "./users-help-sidebar.controller";

angular.module("users.components").component("usersHelpSidebar", {
  template: usersHelpSidebarTemplate,
  bindings: {
    initialViewState: "<",
  },
  controller: UsersHelpSidebarCtrl,
});
