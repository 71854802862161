import CompaniesShowCtrl from "./show.controller";
import showTemplate from "./show.template.html";

angular.module("companies.views").component("companiesShow", {
  bindings: {
    company: "<companyResponse",
    scrollspyScope: "<",
    $state$: "<",
  },
  template: showTemplate,
  controller: CompaniesShowCtrl,
});
