import { IFilterService } from "angular";

export default class EmployeeShowDuplicatesModalCtrl {
  public employee: any;
  public duplicates: any[];
  public selectedCandidate: any;
  public modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = ["$filter", "$translate", "CandidatesFactory", "resolve"];

  constructor (
    private $filter: IFilterService,
    private $translate: angular.translate.ITranslateService,
    private Candidate: any,
    private resolve: any
  ) {

  }

  public $onInit() {
    this.employee = this.resolve.employee;
    this.duplicates = this.resolve.duplicates;

    this.selectedCandidate = undefined;

    _.each(this.duplicates, (duplicate) => {
      duplicate.match = _.map(duplicate.highlight, (filterObj, filterKey) => {
        return { key: this.getAttrName(filterKey), value: _.flatten(filterObj) };
      });
    });
  };

  public cancel() {
    this.modalInstance.dismiss("cancel");
  };

  public connectToCandidate(candidate) {
    this.cancel();
    (<any>this.Candidate)
      .$post(this.employee.$url("/transform.json"), { candidate_id: candidate.id })
      .then(<any>this.Candidate.transformResultHandlerOk)
      .catch(<any>this.Candidate.transformResultHandlerError);
  };

  public createNewCandidate() {
    this.cancel();

    (<any>this.Candidate)
      .$post(this.employee.$url("/transform.json"))
      .then(<any>this.Candidate.transformResultHandlerOk)
      .catch(<any>this.Candidate.transformResultHandlerError);
  };
       
    public getAttrName(scopeDescriptor) {
      switch (scopeDescriptor) {
        case "fullNameWithTitle.standard":
          return "DUPL_ATTR_NAME";
        case "workEmail":
          return "DUPL_ATTR_EMAIL_WORK";
        case "workPhone":
          return "DUPL_ATTR_PHONE_WORK";
        case "workMobilePhone":
          return "DUPL_ATTR_MOBILE_WORK";
        case "privateEmail":
          return "DUPL_ATTR_EMAIL_PRIVATE";
        case "privatePhone":
          return "DUPL_ATTR_PHONE_PRIVATE";
        case "privateMobilePhone":
          return "DUPL_ATTR_MOBILE_PRIVATE";
      }
    };
};
