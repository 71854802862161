import EmployeeSearchCtrl from "./employee-search.controller";
import employeeSearchTemplate from "./employee-search.template.html";

angular.module("employees.components").component("employeeSearch", {
  bindings: {
    savedSearches: "=",
    disableSavedSearch: "@",
    advancedQuery: "=",
    query: "=",
    remote: "=",
    setData: "&",
  },
  template: employeeSearchTemplate,
  controller: EmployeeSearchCtrl,
});
