import { StateTransitionsConfig } from "../../../../services/state-transitions-config.factory";

export default class PopoverCtrl {
  public lazyLoading: boolean;
  public params: any;
  public step: any;
  public kpis: any;

  static $inject = ["makeCollectionProxy", "ActionsFactory", "ActionModals"];

  constructor(
    private makeCollectionProxy: any, 
    private Action: any, 
    private ActionModals: any, 
  ) {
    this.lazyLoading = true;

    angular.extend(this, {
      state: StateTransitionsConfig.state,
      reduceParams: StateTransitionsConfig.reduceParams,
    });
  }

  public $onInit () {
    this.params = _.extend(
      _.isFunction(this.step.model.params) ? this.step.model.params() : this.step.model.params,
      {
        key: this.step.key,
        limit: 5,
      }
    );

    var loader = (params) => {
      return this.Action.$http(
        _.extend({ url: "actions/kpi_route", headers: { Accept: "application/json" } }, { params: params })
      );
    };
    
    this.makeCollectionProxy(loader, this.params)
    .then((result) => {
      angular.extend(this, {
        kpis: result,
        lazyLoading: false,
      });
    })
    .then(() => {
      this.lazyLoading = false;
    });
  };

  public showMore() {
    this.step.isOpen = false;
    this.ActionModals.showActionsList(this.kpis, this.step).then(angular.noop, angular.noop);
  };
};