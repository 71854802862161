import CountriesSelectCtrl from "./countries-select.controller";
import countriesSelectTemplate from "./countries-select.template.html";

angular.module("countries.components").component("countriesSelect", {
  bindings: {
    ngModel: "=",
  },
  template: countriesSelectTemplate,
  controller: CountriesSelectCtrl,
});
