import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";

export default class CandidatesContextMenuCtrl {
  private transitionOptions: { [key: string]: any };
  private initialViewState: any;
  private isCandidatesTable: boolean;
  public candidate: any;
  public createAsManager: (person: any) => void;

  static $inject = ["ProjectsFactory", "$state", "PersonTransformer"];

  constructor(
    private Project: any,
    private $state: angular.ui.IStateService,
    private PersonTransformer: any,
  ) {
    this.createAsManager = this.PersonTransformer.createAsManager;
  }

  public $onInit() {
    angular.extend(this, {
      state: StateTransitionsConfig.state,
      transitionOptions: StateTransitionsConfig.transitionOptions(this.initialViewState),
      reduceParams: StateTransitionsConfig.reduceParams,
    });
    // set state to transition from to initial state
  };

  public openInitiativExpertProfile = () => {
    if (this.isCandidatesTable) {
      this.Project.$get("projects/initiative_application.json", { candidate_id: this.candidate.id }).then(
         (result) => {
          this.$state.go("^.projects.show", { project_id: result.id });
        },
         (not_found) => {
          this.$state.go("^.expert_profile.new", {
            candidate_id: this.candidate.id,
            cpjoin_id: undefined,
            employee_id: undefined,
            initiative_expert_profile: true,
          });
        }
      );
    } else {
      this.Project.$get("projects/initiative_application.json", { candidate_id: this.candidate.id }).then(
        (result) => {
          this.$state.go(".projects.show", { project_id: result.id });
        },
        (not_found) => {
          this.$state.go(".expert_profile.new", {
            candidate_id: this.candidate.id,
            cpjoin_id: undefined,
            employee_id: undefined,
            initiative_expert_profile: true,
          });
        }
      );
    }
  };
}