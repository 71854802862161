import skillsDescriptionPopover from "../popovers/skills-description-popover.html";
import coreCompetenciesDescriptionPopover from "../popovers/core-competencies-description-popover.html";
import exclusionCriterionDescriptionPopover from "../popovers/exclusion-criterion-description-popover.html";
import openApplicationsDescriptionPopover from "../popovers/open-applications-description-popover.html";
import wantedJobDescriptionPopover from "../popovers/wanted-job-description-popover.html";
import jobChangeMotivationDescriptionPopover from "../popovers/job-change-motivation-description-popover.html";
import jobTitlePopover from "../popovers/job-title-popover.html";
import { Language } from "../../../languages/services/languages.service";
import { IStateService } from "angular-ui-router";
import { MaritalStatus } from "../../../marital_statuses/services/marital_status.service";
import { LeadershipExperience } from "../../../leadership_experiences/services/leadership_experiences.service";

export default class CandidatesEditViewCtrl {
  private skillsDescriptionPopover: string;
  private coreCompetenciesDescriptionPopover: string;
  private exclusionCriterionDescriptionPopover: string;
  private openApplicationsDescriptionPopover: string;
  private wantedJobDescriptionPopover: string;
  private jobChangeMotivationDescriptionPopover: string;
  private jobTitlePopover: string;
  private deregActiveRowConnector: () => void;
  private deregDocumentsChanged: () => void;
  private saving: boolean;
  private candidate: any;
  private onSaveError: (error: any) => void;
  private contactForm: ng.IFormController;
  private maritalStatuses: MaritalStatus[];
  private leadershipExperiences: LeadershipExperience[];
  private languageSkills: any;
  private languages: Language[];
  private initializeActiveRowConnector: (candidate: any) => () => void;
  private registerSaveHotkey: (callback: () => void) => void;
  private deregCandidateDiff: () => void;
  

  static $inject = ["$rootScope", "$state", "$templateCache", "EditUtilMixin", "DeckControllerMixin", "$filter"];

  constructor(private $rootScope: IExtendedRootScopeService,
              private $state: IStateService,
              private $templateCache: ng.ITemplateCacheService, 
              private EditUtilMixin: any, 
              private DeckControllerMixin: any, 
              private $filter: ng.IFilterService) {

                this.skillsDescriptionPopover = "skills-description-popover";
                this.coreCompetenciesDescriptionPopover = "core-competencies-description-popover";
                this.exclusionCriterionDescriptionPopover = "exclusion-criterion-description-popover";
                this.openApplicationsDescriptionPopover = "open-applications-description-popover";
                this.wantedJobDescriptionPopover = "wantedJobDescription-popover";
                this.jobChangeMotivationDescriptionPopover = "job-change-motivation-description-popover";
                this.jobTitlePopover = "job-title-popover";

                $templateCache.put(this.skillsDescriptionPopover, skillsDescriptionPopover);
                $templateCache.put(this.coreCompetenciesDescriptionPopover, coreCompetenciesDescriptionPopover);
                $templateCache.put(this.exclusionCriterionDescriptionPopover, exclusionCriterionDescriptionPopover);
                $templateCache.put(this.openApplicationsDescriptionPopover, openApplicationsDescriptionPopover);
                $templateCache.put(this.wantedJobDescriptionPopover, wantedJobDescriptionPopover);
                $templateCache.put(this.jobChangeMotivationDescriptionPopover, jobChangeMotivationDescriptionPopover);
                $templateCache.put(this.jobTitlePopover, jobTitlePopover);

                // needs update after init trusted documents urls
                this.deregDocumentsChanged = this.$rootScope.$on("candidateDocumentsChanged", () => {
                  this.candidate.snapshot();
                });
  }

  
  public $onInit () {
    angular.extend(this, this.EditUtilMixin, this.DeckControllerMixin, {
      languageLevelInWords: Language.levelInWords,
      languageLevelIDs: _.pluck(Language.levels, "id"),
    });

    if (this.candidate.maritalStatus) {
      this.candidate.maritalStatus = _.find(this.maritalStatuses, (maritalStatus) => {
        return maritalStatus.id == this.candidate.maritalStatus.id;
      });
    }

    if (this.candidate.leadershipExperience) {
      this.candidate.leadershipExperience = _.find(this.leadershipExperiences, (leadershipExperience) => {
        return leadershipExperience.id === this.candidate.leadershipExperience.id;
      });
    }

    this.languageSkills = _.map(this.languages, (language) => {
      var languageSkill = _.find(this.candidate.languageSkills, (langSkill) => {
        return langSkill.language.id == language.id;
      });
      if (languageSkill) {
        return languageSkill;
      } else {
        return { id: null, level: null, language: language };
      }
    });

    if (this.candidate.id) {
      this.$rootScope.setPageTitle(this.candidate.fullNameReverseWithTitle);
    } else {
      this.$rootScope.setPageTitle(this.$filter("translate")("NEW_CANDIDATE"));
    }

    // perform after everything is rendered
    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.candidate);
    this.registerSaveHotkey(this.saveCandidate);
  };

  public save(route, params) {
    if (!this.saving) {
      this.saving = true;
      this.candidate.save().then( (savedCandidate) => {
        this.candidate.snapshot();
        this.$state.go(route || "^.show", _.extend(params || {}, savedCandidate.params()));

        this.saving = false;
      }, this.onSaveError.bind(this));
    }
  }

  public saveCandidate (route?: any, params?: any) {
    if (!this.contactForm.$valid) {
      window.alert(this.$filter("translate")("INVALID_CONTACT_FORM"));
    } else {
      if (this.candidate.$hasDuplicates) {
        this.candidate.confirm(this.$filter("translate")("DUPLICATE_WARNING_CANDIDATE")).then( (res) => {
          if (res) {
            this.save(route, params);
          } else {
            return;
          }
        });
      } else {
        this.save(route, params);
      }
    }
  };

  public $onDestroy () {
    this.deregCandidateDiff();
    this.deregDocumentsChanged();
    this.deregActiveRowConnector();
  };
}
