import openActionModalCtrl from "./open-action-modal.controller";
import openActionModalTemplate from "./open-action-modal.template.html";

angular.module("actions.modals").component("openActionModal", {
  bindings: {
    resolve: "<",
    dismiss: "&",
  },
  template: openActionModalTemplate,
  controller: openActionModalCtrl,
});
