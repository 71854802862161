import { Country } from "../../../countries/services/countries.service";

export default class CountriesSelectCtrl {
  countries: Country[] = []; 

  constructor() {
    Country.getAll().then((countries) => {
      this.countries = countries;
    });
  }
}