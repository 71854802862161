import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TMaritalStatus } from "../marital_status";

export class MaritalStatus {
  public id: number;
  private name: string;
  static $inject = [];

  constructor(fields?: Partial<TMaritalStatus>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TMaritalStatus>>(fields, camelCase));
  }

  @cached
  public static getAll() {
    return baseClient.maritalStatuses.query().then((response) => {
      return response.body.data.map((element) => {
        return new MaritalStatus(element);
      });
    });
  }
}
