import { SendoutsListCtrl } from "./sendouts-list.controller";
import sendoutsListTemplate from "./sendouts-list.template.html";

angular.module("sendouts.components").component("sendoutsList", {
  bindings: {
    object: "=",
    initialViewState: "<",
  },
  template: sendoutsListTemplate,
  controller: SendoutsListCtrl,
});
