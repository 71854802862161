import { IFilterService, ITemplateCacheService } from "angular";
import employeesJobTitlePopover from "../../../candidates/views/popovers/job-title-popover.html";

export default class EmployeesEditCtrl {
  public jobTitlePopover: string;
  public employee: any;
  public saving: boolean;
  public contactForm: ng.IFormController;
  public onSaveError: (error: any) => void;
  public deregActiveRowConnector: () => void;
  private registerSaveHotkey: (callback: () => void) => void;
  private initializeActiveRowConnector: (employee: any) => () => void;
  private onStateChanged: (object: any, onChange?: any) => () => void;

  static $inject = [
    "$rootScope",
    "$state",
    "$templateCache",
    "EditUtilMixin",
    "DeckControllerMixin",
    "$filter"
  ];

  constructor (
    private $rootScope: IExtendedRootScopeService,
    private $state: angular.ui.IStateService,
    private $templateCache: ITemplateCacheService,
    private EditUtilMixin: any,
    private DeckControllerMixin: any,
    private $filter: IFilterService,
  ) {
    this.jobTitlePopover = employeesJobTitlePopover;
    this.$templateCache.put(this.jobTitlePopover, employeesJobTitlePopover);
  }

  public $onInit() {
    angular.extend(this, this.EditUtilMixin, this.DeckControllerMixin);

    if (this.employee.id) {
      this.$rootScope.setPageTitle(this.employee.fullNameWithTitle);
    } else {
      this.$rootScope.setPageTitle(this.$filter("translate")("NEW_MANAGER"));
    }

    this.onStateChanged(this.employee);

    this.employee.tags = _.union(this.employee.tags, this.employee.computerSkills);

    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.employee);
    this.registerSaveHotkey(this.saveEmployee);
  };

  public save(route, params) {
    if (!this.saving) {
      this.saving = true;
      this.employee.save().then((savedEmployee) => {
        this.employee.snapshot();

        if (!this.employee.id) {
          this.$rootScope.$broadcast("addEmployee", savedEmployee);
        }

        this.$state.go(route || "^.show", params || savedEmployee.params());
      }, this.onSaveError.bind(this));
    }
  }

  public saveEmployee(route?: any, params?: any) {
    if (!this.contactForm.$valid) {
      window.alert(this.$filter("translate")("WRONG_CONTACT_DATA_NOTICE"));
    } else {
      if (this.employee.$hasDuplicates) {
        this.employee.confirm(this.$filter("translate")("DUPLICATES_FOUND_MANAGER_NOTICE")).then((res) => {
          if (res) {
            this.save(route, params);
          } else {
            return;
          }
        });
      } else {
        this.save(route, params);
      }
    }
  };

  public $onDestroy() {
    this.deregActiveRowConnector();
  };
};