import { ActionType } from "../../../action_types/services/action_types.service";
import { JobCategory } from "../../../job_categories/services/job_categories.service";
import { JobType } from "../../../job_types/services/job_types.service";

angular.module("actions.modals").factory("ActionsModalMixin", [
  "$uibModal",
  "$q",
  "$rootScope",
  "$filter",
  "EditUtilMixin",
  function ($uibModal, $q, $rootScope, $filter, EditUtilMixin) {
    function ActionsModalMixin() {}

    function actionEditModal(object, options) {
      var action = this;
      options = _.extend(action.actionType.getActionTypeOptions(object), _.isObject(options) ? options : {});

      if (action.id && action.actionType) {
        _.defaults(options, { actionTypeEditable: false });
      }

      // try to catch possible error cases
      if (object.resourceType == "CandidatesProjects" && !(object.project && object.candidate)) {
        window.rollbar.error("createNewAction without object raises error", {
          object: object,
          action: action,
          options: options,
        });
        window.alert($filter("translate")("ERROR_RELOAD_NOTICE"));
        return;
      }

      var modalInstance = $uibModal.open({
        component: "actionEditModal",
        size: "lg",
        resolve: {
          actionTypes: ActionType.getAllWithoutVerbose,
          action: [
            "ActionsFactory",
            "CandidatesFactory",
            function (Action, Candidate) {
              function editedAction(action, object) {
                switch (object.resourceType) {
                  case "Candidate":
                    action.candidate = object;
                    break;
                  case "Project":
                    action.project = object;
                    if (object.initiativeApplicationProject) {
                      return Candidate.get(object.candidate).then(function (result) {
                        action.candidate = result;
                        return action;
                      });
                    }
                    break;
                  case "CandidatesProjects":
                    action.project = object.project;
                    return Candidate.get(object.candidate).then(function (result) {
                      action.candidate = result;
                      return action;
                    });
                  case "Employee":
                    action.employee = object;
                    break;
                  case "EmployeesProjects":
                    action.employee = object.employee;
                    action.project = object.project;
                    break;
                  case "EmployeesCandidatesProject":
                    action.employees = object.employees;
                    action.project = object.project;
                    action.candidate = object.candidate;
                    break;
                  case "Company":
                    action.company = object;
                    break;
                }
                return action;
              }
              if (action.id) {
                return Action.get(action.id).then(function (resultAction) {
                  return editedAction(action, object);
                });
              } else {
                return editedAction(action, object);
              }
            },
          ],
          options: function () {
            return options;
          },
          projectsCount: function () {
            if (object && object.resourceType == "Candidate") {
              return object.candidatesProjectsCount;
            } else if (object && object.resourceType == "Employee") {
              return object.projectsCount;
            } else {
              return 0;
            }
          }.bind(object),
          candidates: function () {
            if (object && object.resourceType == "Project") {
              return _.map(this.candidatesProjects || [], function (cp) {
                return cp.candidate;
              });
            } else {
              return [];
            }
          }.bind(object),
          jobCategories: [
            function () {
              if (options.actionTemplate) {
                return JobCategory.getAll();
              } else {
                return undefined;
              }
            },
          ],
          jobTypes: [
            function () {
              if (options.actionTemplate) {
                return JobType.getAll();
              } else {
                return undefined;
              }
            },
          ],
          templateResolve: [
            function () {
              if (options.actionTemplate && options.resolves) {
                return $q.all(options.resolves());
              }
            },
          ],
        },
      });

      var deferred = $q.defer();

      return modalInstance.result.then(
        function (action) {
          if (action) {
            return action.saveCustomNote(this).catch(
              function (error) {
                EditUtilMixin.onSaveError(error);

                action.actionEditModal(this, options).catch(angular.noop);
              }.bind(this)
            );
          } else {
            $rootScope.$broadcast(object.resourceType.toLowerCase() + "_action_changed_" + object.id);

            deferred.resolve();
            return deferred.promise;
          }
        }.bind(object),
        function () {
          deferred.reject();
          return deferred.promise;
        }
      );
    }

    function openActionModal(object, options) {
      var action = this;

      var modalInstance = $uibModal.open({
        component: "openActionModal",
        size: "lg",
        resolve: {
          users: [
            "UsersFactory",
            function (User) {
              return User.getAll();
            },
          ],
          action: [
            "ActionsFactory",
            function (Action) {
              return Action.get(action.id);
            },
          ],
          object: function () {
            return object;
          },
          options: function () {
            return _.isObject(options) ? options : {};
          },
          cpjoin: [
            "CandidatesProjectsFactory",
            function (CandidatesProjects) {
              if (action.candidate && action.project) {
                return CandidatesProjects.get({ candidate_id: action.candidate.id, project_id: action.project.id });
              } else {
                return undefined;
              }
            },
          ],
        },
      });

      return modalInstance.result.then(angular.noop, angular.noop);
    }

    function openEventConfirmModal(action) {
      var modalInstance = $uibModal.open({
        component: "eventConfirmModal",
        resolve: {
          action: function () {
            return action;
          },
        },
        size: "lg",
      });
      return modalInstance.result;
    }

    ActionsModalMixin.extended = function (Resource) {
      Resource.include({
        actionEditModal: actionEditModal,
        openActionModal: openActionModal,
        eventConfirm: openEventConfirmModal,
      });
    };

    return ActionsModalMixin;
  },
]);
