import urUserSelectBoxTemplate from "./ur-user-select-box.template.html";
import UserSelectBoxCtrl from "./ur-user-select-box.controller";

angular.module("users.components").component("urUserSelectBox", {
  bindings: {
    selectedUser: "=urModel",
    filter: "<",
    required: "=",
    onChanges: "&",
  },
  template: urUserSelectBoxTemplate,
  controller: UserSelectBoxCtrl,
});
