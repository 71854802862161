export default class LoginCtrl {
  private loginSuccess: boolean = false;
  private pollingService: any;
  private backendNotice: string | null = null;
  private microsoftLoginUrlWithRedirect: string = '';
  private loginError: boolean;
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = [  "$rootScope",
                      "$state",
                      "$log",
                      "AUTH_EVENTS",
                      "AuthService",
                      "Keepalive",
                      "Idle",
                      "Session",
                      "poller",
                      "UserSessionsFactory"];

  constructor(private $rootScope: IExtendedRootScopeService,
              private $state: angular.ui.IStateService,
              private $log: ng.ILogService,
              private AUTH_EVENTS: any,
              private AuthService: any,
              private Keepalive: any,
              private Idle: any,
              private Session: any,
              private poller: any,
              private UserSession: any,) { 

              this.backendNotice = window.backendNotice;
              this.microsoftLoginUrlWithRedirect = this.$rootScope.microsoft_login_url;
              if (
                $state.params.redirect_to &&
                $state.params.redirect_to != encodeURIComponent($state.href("login", {}, { absolute: true, inherit: false }))
              ) {
                this.microsoftLoginUrlWithRedirect += "?redirect_to=" + $state.params.redirect_to;
              }
            
            }

  public externalLogin () {
    if (this.pollingService) {
      this.pollingService.remove();
    }
    this.pollingService = this.poller.get("/user_session.json", {
      delay: 1000,
      catchError: true,
      neverOverwrite: true,
      argumentsArray: [
        {
          ignoreAuthModule: true,
        },
      ],
    });

    this.pollingService.promise.then(null, null, (result) => {
      if (result.status != 200) {
        return;
      }

      this.pollingService.remove();
      this.AuthService.updateAfterExternalLogin().then(
        (user_session) => {
          this.loginSuccess = true;
          this.loginError = false;

          if (this.modalInstance.dismiss) {
            this.modalInstance.dismiss("cancel");
          }
        },
        (result) => {
          this.$log.debug("LoginCtrl login failed");

          if (result.data.message) {
            this.backendNotice = result.data.message;
          }
          this.loginError = true;
        }
      );
    });
  };
}