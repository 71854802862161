import { Money } from "../../../money/services/money.service";

export default class SalaryRangeSelectorCtrl {
  public salaryRangeUniqId: string;
  public advancedQuery: any;
  
  constructor() {
    this.salaryRangeUniqId = _.uniqueId("salaryRangeEnabled");
  }

  public $onInit() {
    _.defaults(this.advancedQuery, {
      salaryRangeEnabled: false,
      salaryRangeMax: new Money({ inCents: true }),
      salaryRangeMin: new Money({ inCents: true }),
    });
  };
};