import kpiOperativeEditModalCtrl from "./kpi-operative-edit-modal.controller";
import kpiOperativeEditModalTemplate from "./kpi-operative-edit-modal.template.html";

angular.module("project_reporting.modals").component("kpiOperativeEditModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: kpiOperativeEditModalTemplate,
  controller: kpiOperativeEditModalCtrl,
});
