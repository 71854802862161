export class QuicklistsSidebarCtrl {
  static $inject = ["$rootScope", "$q", "$filter", "$timeout", "EditUtilMixin", "QuicklistsFactory"];
  constructor(
    private $rootScope: IExtendedRootScopeService,
    private $q: ng.IQService,
    private $filter: ng.IFilterService,
    private $timeout: ng.ITimeoutService,
    private EditUtilMixin: any,
    private QuicklistService: any
  ) {}

  private deregUpdatedQuicklist: () => void;
  private deregActiveRowChanged: () => void;

  public newQuicklist: any;
  public quicklists: any[];

  public newQuicklistName: string;
  public createQuicklist: boolean;

  public activeRow: any;
  public descriptor: string;

  public $onInit() {
    this.deregUpdatedQuicklist = this.$rootScope.$on("updatedQuicklist", (event: ng.IAngularEvent) => {
      this.QuicklistService.getAll().then((quicklists) => {
        this.quicklists = quicklists;
        _.each(this.quicklists, (quicklist) => {
          quicklist.listItems =
            angular.copy(quicklist.candidates) ||
            angular.copy(quicklist.managers) ||
            angular.copy(quicklist.companies) ||
            [];
        });
      });
    });

    this.createQuicklist = false;
    this.newQuicklist = new this.QuicklistService({ name: this.$filter("translate")("NEW_QUICKLIST") });

    this.deregActiveRowChanged = this.$rootScope.$on(
      "activeRowChanged",
      (event: ng.IAngularEvent, newActiveRow: any) => {
        if (this.activeRow && this.activeRow.resourceType == newActiveRow.resourceType) {
          this.activeRow = newActiveRow;
        }
        if (!this.activeRow && newActiveRow.resourceType == this.descriptor) {
          this.activeRow = newActiveRow;
        }
      }
    );
  }

  public onItemDrop(e: JQueryEventObject, ui: any) {
    this.newQuicklist.save().then((result: any) => {
      this.quicklists.push(result);
      result.add(this.newQuicklist.listItem);
      this.newQuicklist = new this.QuicklistService({ name: this.$filter("translate")("NEW_QUICKLIST") });
    }, this.EditUtilMixin.onSaveError.bind(this));
  }

  public onOver(e: JQueryEventObject, ui: any, quicklist: any) {
    if (!quicklist.isDroppableQuicklist(angular.element(ui.draggable).scope())) {
      angular.element(e.target).addClass("disabled");
    }
  }

  public onOut(e: JQueryEventObject, ui: any) {
    angular.element(e.target).removeClass("disabled");
  }

  public onDrop(e: JQueryEventObject, ui: any, quicklist: any) {
    var newlistItems = _.chain(quicklist.listItems).compact().indexBy("id").values().value();

    _.each(newlistItems, function (listItem: any) {
      if (!quicklist.add(listItem)) {
        quicklist.listItems = _.without(quicklist.listItems, listItem);
      }
    });
  }

  public beforeDrop(e: JQueryEventObject, ui: any, quicklist: any) {
    var deferred = this.$q.defer();
    if (quicklist.isDroppableQuicklist(angular.element(ui.draggable).scope())) {
      deferred.resolve();
    } else {
      deferred.reject();
    }
    return deferred.promise;
  }

  public isDataDropArea(quicklist: any) {
    return !quicklist.isReadOnly();
  }

  public openNewQuicklist(e: JQueryEventObject) {
    this.createQuicklist = true;
    this.$timeout(() => {
      $("#newQuicklistName").focus();
    }, 0);
    return false;
  }

  public createNewQuicklist() {
    new this.QuicklistService({ name: this.newQuicklistName }).create().then(this.handleResult.bind(this));
  }

  public $onDestroy() {
    this.deregUpdatedQuicklist();
    this.deregActiveRowChanged();
  }

  private handleResult(newQuicklist: any) {
    this.createQuicklist = false;
    this.quicklists.unshift(newQuicklist);
    this.newQuicklistName = "";
  }
}
