import companiesSearchTemplate from "./companies-search.template.html";

angular.module("quicklists.components").component("quicklistCompaniesSearch", {
  bindings: {
    query: "<",
    advancedQuery: "<",
    updateEntries: "&",
  },
  template: companiesSearchTemplate,
});
