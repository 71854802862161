import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { Tag } from "./tags.service";

angular
  .module("tags")
  .factory("TagSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(Tag, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("TagFactory", Tag);
