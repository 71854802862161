import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { Tracking } from "./trackings.factory";

angular.module("trackings").factory("TrackingsSerializer", [
  "railsSerializer",
  function (railsSerializer) {
    return railsSerializer(function () {
      this.deserialize = (data) => deserialize(Tracking, data);
      this.serialize = serialize;
    });
  },
]);
