export default class ShowContractDraftCtrl {
  private contractDraft: any;
  private candidateId: any;
  
  static $inject = ["ContractDraftFactory", "EditUtilMixin", "deploymentLocations",];

  constructor(private ContractDraft: any, private EditUtilMixin: any, private deploymentLocations: any){}

  public $onInit () {
    angular.extend(this, this.EditUtilMixin);
    this.contractDraft = new this.ContractDraft(this.contractDraft);
    this.candidateId = _.isUndefined(this.contractDraft.candidate) ? null : this.contractDraft.candidate.id;
    if (this.contractDraft.deploymentLocation) {
      this.contractDraft.deploymentLocation = this.deploymentLocations.find(
        (item) => item.slug === this.contractDraft.deploymentLocation
      ).name;
    } else {
      this.contractDraft.deploymentLocation = undefined;
    }
  };
}