import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TTag } from "../tags";

const TagCategories = {
  SkillTag: "KNOWLEDGE",
  ComputerSkill: "TAG_COMPUTER_SKILLS",
  Course: "FIELDS_OF_STUDY",
  Graduation: "DEGREES",
  Department: "DEPARTMENTS",
};

export class Tag {
  private serviceName: string = "TagsFactory";
  private id: number;
  private type: string;
  private static $cacheInvalid = false;

  static $inject = [];

  constructor(fields?: Partial<TTag>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TTag>>(fields, camelCase));
  }

  public humanReadableType(): string {
    return TagCategories[this.type];
  }

  public static invalidateCache(invalid = true): void {
    Tag.$cacheInvalid = invalid;
  }

  @cached
  public static getAll() {
    return baseClient.tags.query().then((response) => {
      return response.body.data.map((element) => {
        return new Tag(element);
      });
    });
  }
}
