import CompaniesDocumentsListCtrl from "./companies-documents-list.controller";
import CompaniesDocumentsListTemplate from "./companies-documents-list.template.html";

angular.module("deals.components").component("companiesDocumentsList", {
  bindings: {
    resource: "<",
    documents: "<",
  },
  template: CompaniesDocumentsListTemplate,
  controller: CompaniesDocumentsListCtrl,
});
