import TextkernelDiffModalCtrl from "./textkernel-diff-modal.controller";
import textkernelDiffModalTemplate from "./textkernel-diff-modal.template.html";

angular.module("documents.modals").component("textkernelDiff", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: textkernelDiffModalTemplate,
  controller: TextkernelDiffModalCtrl,
});
