import { ProjectsAdvancedSearchCtrl } from "./projects-advanced-search.controller";
import projectsAdvancedSearchTemplate from "./projects-advanced-search.template.html";

angular.module("projects.components").component("projectsAdvancedSearch", {
  bindings: {
    advancedQuery: "=",
    query: "=",
    remote: "=",
    setData: "&",
    closing: "&",
  },
  template: projectsAdvancedSearchTemplate,
  controller: ProjectsAdvancedSearchCtrl,
});
