import AdvancedSearchSelectorCtrl from "./advanced-search-selector.controller";
import advancedSearchSelectorTemplate from "./advanced-search-selector.template.html";

angular.module("directives.advanced_search").component("advancedSearchSelector", {
  bindings: {
    advancedQuery: "=",
    selectedFields: "=",
  },
  template: advancedSearchSelectorTemplate,
  controller: AdvancedSearchSelectorCtrl,
});
