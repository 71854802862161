import MoneyInputCtrl from "./money-input.controller";
import moneyInputTemplate from "./money-input.template.html";

angular.module("components.money").component("moneyInput", {
  bindings: {
    field: "=ngModel",
    placeholder: "@",
    id: "@",
    step: "@",
    disabled: "<",
  },
  template: moneyInputTemplate,
  controller: MoneyInputCtrl
});
