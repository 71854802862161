import CandidateSelectCtrl from "./candidate-select.controller";
import candidateSelectTemplate from "./candidate-select.template.html";

angular.module("candidates.components").component("candidateSelect", {
  bindings: {
    ngModel: "=",
  },
  template: candidateSelectTemplate,
  controller: CandidateSelectCtrl,
});
