//= require ./services.module.js
angular.module("services").factory("DeckControllerMixin", [
  "$rootScope",
  "$timeout",
  "$transitions",
  function ($rootScope, $timeout, $transitions) {
    function DeckControllerMixin() {}

    DeckControllerMixin.initializeActiveRowConnector = function (resource) {
      $rootScope.$broadcast("activeRowChanged", resource);

      var deregFinishedLazyLoading = $rootScope.$on("finishedLazyLoading", function () {
        $rootScope.$broadcast("activeRowChanged", resource);
      });

      var transitionStartListener = $transitions.onStart({}, function () {
        $rootScope.$broadcast("activeRowClosed", resource);
      });

      return function () {
        deregFinishedLazyLoading();
      };

      $rootScope.$on("$destroy", function () {
        transitionStartListener();
      });
    };

    return DeckControllerMixin;
  },
]);
