import EmployeesProjectListCtrl from "./employees-project-list.controller";
import employeesProjectListTemplate from "./employees-project-list.template.html";

angular.module("employees.components").component("employeesProjectList", {
  bindings: {
    project: "=",
    activeRow: "=",
    initialViewState: "<",
  },
  template: employeesProjectListTemplate,
  controller: EmployeesProjectListCtrl,
});
