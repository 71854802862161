import { IFilterService } from 'angular';
import companySiteAddressesInfoPopover from "../../views/popovers/company-site-addresses-info-popover.html";
export default class CompaniesSiteAddressesFormCtrl {
  static $inject = ["$filter", "$translate", "$templateCache"];
  public siteAddressesInfoPopover: string;
  public siteAddresses: any[];

  constructor(
    private $filter: IFilterService,
    private $translate: angular.translate.ITranslateService,
    private $templateCache: ng.ITemplateCacheService) { }

  public $onInit() {
    this.siteAddressesInfoPopover = companySiteAddressesInfoPopover;
    this.$templateCache.put(this.siteAddressesInfoPopover, companySiteAddressesInfoPopover);
  };

  public addSiteAddress() {
    this.siteAddresses.push({});
  };

  public deleteSiteAddress (index) {
    this.siteAddresses.splice(index, 1);
  };
};
