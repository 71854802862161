import { IFilterService, ITemplateCacheService } from "angular";
import freeDatePickerPopTemplate from "./free-date-picker-pop.template.html";

export default class freeDatePickerCtrl {
  public currentFreeDateOption: any;
  public currentFreeDateDate: Date | undefined;
  public freeDatePickerPopover: string;
  public freeDateText: string;
  public freeDate: {
    option?: any,
    date?: Date,
  };

  static $inject = ["$filter", "$templateCache", "freeDateOptions"];

  constructor (
    private $filter: IFilterService,
    private $templateCache: ITemplateCacheService,
    private freeDateOptions: any,
  ) {
    this.freeDatePickerPopover = "free-date-picker-popover";
    this.$templateCache.put(this.freeDatePickerPopover, freeDatePickerPopTemplate); 
  }

  public $onInit() {
    _.defaults(this, { required: false, disabled: false, uniqName: _.uniqueId("freeDatePicker") });
    this.currentFreeDateOption = this.freeDate.option;
    this.currentFreeDateDate = this.freeDate.date;
    this.setFreeDateText();
  };

  public setFreeDateText() {
    // @ts-ignore
    this.freeDateText = this.$filter("translate")(this.$filter("freeDateTextFormat")(this.freeDate));
  };

  public $doCheck() {
    if (this.currentFreeDateOption != this.freeDate.option) {
      this.currentFreeDateOption = this.freeDate.option;
      this.freeDate.date = undefined;
      this.currentFreeDateDate = undefined;
      this.setFreeDateText();
    } else if (this.currentFreeDateDate != this.freeDate.date) {
      this.currentFreeDateDate = this.freeDate.date;
      this.freeDate.option = undefined;
      this.currentFreeDateOption = undefined;
      this.setFreeDateText();
    }
  };
};