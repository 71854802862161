import { Region } from "../../../regions/services/regions.service";
import { LOCATION_DISTANCES, LocationDistance } from "../../../locations/values/location-distances.constant";
export default class LocationPreferencesFormCtrl {
  public employee: any;
  public regions: Region[];
  public locationDistances: LocationDistance[];

  public locations: any[];
  static $inject = ["LocationsFactory"];

  constructor(private Location: any) {}

  public autocompleteLocation(searchQuery) {
    var deferred = this.Location.autocomplete(searchQuery);
    deferred.promise.then((locations) => {
      this.locations = _.pluck(locations, "displayName");
    });
  }

  public addLocationPreference() {
    this.employee.locationPreferences.push({ distance: 5 });
  }

  public $onInit() {
    Region.getAll().then((regions) => (this.regions = regions));
    this.locationDistances = LOCATION_DISTANCES;

    if (this.employee.locationPreferences && this.employee.locationPreferences.length === 0) {
      this.addLocationPreference();
      this.employee.snapshot();
    }
  }
}
