import CompanyOrgChartModalCtrl from "./company-org-chart-modal.controller";
import companyOrgChartModalTemplate from "./company-org-chart-modal.template.html";

angular.module("persons.modals").component("companyOrgChart", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: companyOrgChartModalTemplate,
  controller: CompanyOrgChartModalCtrl,
});
