import MessageStatusCtrl from "./message-status.controller";
import messageStatusTemplate from "./message-status.template.html";

angular.module("outlook.delivery.components").component("messageStatus", {
  bindings: {
    pollingService: "=",
    ready: "=",
  },
  controller: MessageStatusCtrl,
  template: messageStatusTemplate,
});
