export default class DebriefingTemplateCtrl {
  private projectStatuses: string[];
  private action: { candidate?: { id: number }; project?: { id: number } };
  private loading: boolean;
  private cpjoin: any;

  static $inject = ["CandidatesProjectsFactory"];

  constructor(private CandidatesProjects: any) {}

  public $onInit () {
    this.projectStatuses = this.CandidatesProjects.statuses;

    if (this.action.candidate && this.action.project) {
      this.loading = true;
      this.CandidatesProjects.get({ candidate_id: this.action.candidate.id, project_id: this.action.project.id }).then(
        (cpjoin) => {
          this.cpjoin = cpjoin;
          this.loading = false;
        }
      );
    }
  };
}
