import { TransactionBasisFormCtrl } from "./transaction-basis-form.controller";
import transactionBasisFormTemplate from "./transaction-basis-form.template.html";

angular.module("transaction_bases.components").component("transactionBasisForm", {
  bindings: {
    transactionBases: "=",
    companyId: "<",
  },
  template: transactionBasisFormTemplate,
  controller: TransactionBasisFormCtrl,
});
