//= require ./services.module.js
angular
  .module("services")

  .factory("makeCollectionProxy", [
    "CollectionProxy",
    function (CollectionProxy) {
      return function (queryFunction, params) {
        params = _.defaults(params || {}, { additional_pages: 0 });
        var cleanParams = _.omit(params, "additional_pages");
        var pages = parseInt(params.additional_pages || "0", 10) + 1;
        var paramsWithPages = _.extend(cleanParams, { pages: pages, page: 0 });
        return queryFunction(paramsWithPages).then(function (result) {
          return new CollectionProxy(result, queryFunction, paramsWithPages);
        });
      };
    },
  ]);
