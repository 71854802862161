//= require ./services.module.js
angular
  .module("services")

  .factory("KPISerializer", [
    "railsSerializer",
    "ActionsFactory",
    function (railsSerializer, Action) {
      return railsSerializer(function () {
        this.deserialize = function (data) {
          return data && data.action ? { date: data.date, action: new Action(data.action) } : undefined;
        };
        this.serialize = function (data) {
          return data;
        };
      });
    },
  ]);
