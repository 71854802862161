import { initContract } from "@ts-rest/core";

import { TMaritalStatus } from "../marital_status";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const MaritalStatusSchema = c.type<TBaseResource<TMaritalStatus[]>>();
export const maritalStatusesContract = c.router({
  query: {
    method: "GET",
    path: "/marital_statuses",
    responses: {
      200: MaritalStatusSchema,
    },
    summary: "Get all marital statuses",
  },
});
