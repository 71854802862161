//= require ../components.module.js

import JobOffersCtrl from "./job-offers.controller";
import jobOffersTemplate from "./job-offers.template.html";

angular.module("job_offers.components").component("jobOffers", {
  bindings: {
    jobOffers: "=",
  },
  template: jobOffersTemplate,
  controller: JobOffersCtrl,
});
