import WhiteboardCtrl from "./whiteboard.controller";
import whiteboardTemplate from "./whiteboard.template.html";

angular.module("project_reporting.views").component("whiteboard", {
  bindings: {
    whiteboardConfig: "<",
    $transition$: "<",
  },
  template: whiteboardTemplate,
  controller: WhiteboardCtrl,
});
