import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TJobTypes } from "../job_types";

export class JobType {
  private id: number;
  private name: string;
  private slug: string;

  static $inject = [];

  constructor(fields?: Partial<TJobTypes>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TJobTypes>>(fields, camelCase));
  }

  public isPermanent() {
    return this.slug === "permanent";
  }

  public isProjectConsulting() {
    return this.slug === "project-consulting";
  }

  public isFreelancer() {
    return this.slug === "freelancer";
  }

  public isQuickCheck() {
    return this.slug === "quick-check";
  }

  public isManagementConsulting() {
    return this.slug === "management-consulting";
  }

  @cached
  public static getAll() {
    return baseClient.jobTypes.query().then((response) => {
      return response.body.data.map((element) => {
        return new JobType(element);
      });
    });
  }
}
