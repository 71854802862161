import actionTypesJobChangeMotivationDescriptionPopover from "../../../candidates/views/popovers/job-change-motivation-description-popover.html";
interface IExtendedScope extends angular.IScope {
  action: any;
  $close: (action: any) => void;
}

export default class QualificationTemplateCtrl {
  public jobChangeMotivationDescriptionPopover: string;

  constructor() {
    this.jobChangeMotivationDescriptionPopover = actionTypesJobChangeMotivationDescriptionPopover;
  }

  private saveChanges(callback: (scope: IExtendedScope) => void): void {
  }

  public sendQualificationSummaryEmail(): void {
    this.saveChanges((scope: IExtendedScope) => {
      scope.action.candidate.openQualificationSummaryModal();
      scope.$close(scope.action);
    });
  }
}