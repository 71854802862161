//= require ./modals.module.js

angular.module("companies.modals").factory("CompaniesModalMixin", [
  "$uibModal",
  "QuicklistsFactory",
  function ($uibModal, Quicklist) {
    function CompaniesModalMixin() {}

    function showContactDetails() {
      var modalInstance = $uibModal.open({
        component: "companyContactModal",
        resolve: {
          company: function () {
            return this;
          }.bind(this),
        },
      });
    }
    function openQuicklistModal() {
      var _this = this;
      var quicklistType = this.resourceType + "Quicklist";
      Quicklist.openModal(_this, [
        function () {
          return Quicklist.getAll().then(function (quicklists) {
            quicklists = _.filter(quicklists, function (quicklist) {
              return quicklist.type == quicklistType || !quicklist.type;
            });
            return _.map(quicklists, function (quicklist) {
              var entries = quicklist.entryIds;
              return _.extend(quicklist, {
                selected: !_.isUndefined(
                  _.find(entries, function (entryId) {
                    return entryId == _this.id;
                  })
                ),
              });
            });
          });
        },
      ]);
    }

    CompaniesModalMixin.extended = function (Resource) {
      Resource.include({
        showContactDetails: showContactDetails,
        openQuicklistModal: openQuicklistModal,
      });
    };

    return CompaniesModalMixin;
  },
]);
