//= require ../filters.module.js

angular.module("filters").filter("calltoPhoneNumber", [
  "$filter",
  "$log",
  function ($filter, $log) {
    var angularPhoneNumberFilter = $filter("phoneNumber");
    return function (phoneNumber) {
      try {
        if (angularPhoneNumberFilter(phoneNumber.replace(/\D+/g, "")).length === 0) {
          throw "phoneNumber invalid";
        }
        return "callto:" + angularPhoneNumberFilter(phoneNumber.replace(/(?!^\+\D*)\D*/g, ""));
      } catch (e) {
        $log.debug("Error: " + e + " - " + phoneNumber);
        return false;
      }
    };
  },
]);
