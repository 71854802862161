import LoginCtrl from "../../views/login/login-page.controller";
import loginFormModalTemplate from "./login-form-modal.template.html";

angular.module("login.modals").component("loginForm", {
  template: loginFormModalTemplate,
  controller: LoginCtrl,
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
});
