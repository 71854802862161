//= require ../candidates_projects.module.js

angular
  .module("candidates_projects")

  .constant("KPISteps", {
    longlist: { title: "LO", name: "LONGLIST", description: "LONGLIST", sort: 1 },
    qualifications: { title: "Q", name: "QUALIFICATION", description: "QUALIFICATION", sort: 2 },
    shortlist: { title: "SL", name: "SHORTLIST", description: "SHORTLIST", sort: 3 },
    sendouts: { title: "SO", name: "SENDOUTS", description: "SENDOUTS", sort: 4 },
    firstInterviews: {
      title: "1. IV",
      name: "FIRST_INTERVIEWS",
      description: "FIRST_INTERVIEWS_COUNTING_KPI",
      sort: 5,
    },
    secondInterviews: {
      title: "2. IV",
      name: "SECOND_INTERVIEWS",
      description: "SECOND_INTERVIEWS_COUNTING_KPI",
      sort: 6,
    },
    closings: { title: "C", name: "CLOSINGS", description: "CLOSINGS", sort: 7 },
    deals: { title: "D", name: "DEALS", description: "DEALS", sort: 8 },
  })

  .constant("DashboardKPISteps", {
    candidatesIdentified: { title: "CV", description: "CANDIDATE_IDENTIFIED_KPI", sort: 3 },
    longlist: { title: "LO", name: "LONGLIST", description: "LONGLIST", sort: 4 },
    qualifications: { title: "CQ", name: "QUALIFICATION", description: "QUALIFICATION", sort: 5 },
    shortlist: { title: "SL", name: "SHORTLIST", description: "SHORTLIST", sort: 6 },
    sendouts: { title: "SO", name: "SENDOUTS", description: "SENDOUTS", sort: 8 },
    firstInterviews: { title: "1. IV", description: "FIRST_INTERVIEW_KPI", sort: 11 },
    secondInterviews: { title: "2. IV", description: "SECOND_INTERVIEW_KPI", sort: 12 },
    closings: { title: "C", name: "CLOSINGS", description: "CLOSINGS", sort: 13 },
    deals: { title: "D", name: "DEALS", description: "DEALS", sort: 14 },
  })

  .constant("ManagerKPISteps", {
    managerCalls: { title: "MQ", description: "MANAGER_CALL_KPI", sort: 1 },
    managerEvents: { title: "MA", description: "MANAGER_EVENT_KPI", sort: 2 },
    projectsIdentified: { title: "P", description: "PROJECT_CREATED_KPI", sort: 3 },
  });
