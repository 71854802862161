import EmployeeSelectCtrl from "./employees-select.controller";
import employeeSelectTemplate from "./employee-select.template.html";

angular.module("employees.components").component("employeeSelect", {
  bindings: {
    model: "=",
    companyId: "@",
    projectId: "<",
    exclude: "@",
    required: "@",
    selectMultiple: "=",
  },
  template: employeeSelectTemplate,
  controller: EmployeeSelectCtrl,
});
