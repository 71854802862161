import CompaniesEditCtrl from "./edit.controller";
import companiesEditTemplate from "./edit.template.html";

angular.module("companies.views").component("companiesEdit", {
  bindings: {
    company: "<companyResponse",
    regions: "<",
    scrollspyScope: "<",
  },
  template: companiesEditTemplate,
  controller: CompaniesEditCtrl,
});
