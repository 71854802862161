import PersonDuplicateButtonCtrl from "./person-duplicate-button.controller";
import personDuplicateButtonTemplate from "./person-duplicate-button.template.html";

angular.module("directives").component("personDuplicateButton", {
  bindings: {
    person: "<ngModel",
  },
  template: personDuplicateButtonTemplate,
  controller: PersonDuplicateButtonCtrl,
});
