import { ProjectSearchCtrl } from "./project-search.controller";
import projectSearchTemplate from "./project-search.template.html";

angular.module("projects.components").component("projectSearch", {
  bindings: {
    savedSearches: "=",
    disableSavedSearch: "@",
    advancedQuery: "=",
    query: "=",
    remote: "=",
    setData: "&",
  },
  template: projectSearchTemplate,
  controller: ProjectSearchCtrl,
});
