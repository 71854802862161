import AmountCoveredConsultantPieChartCtrl from "./amount-covered-consultant-pie-chart.controller";
import amountCoveredConsultantPieChartTemplate from "./amount-covered-consultant-pie-chart.template.html";

angular.module("dashboards.components").component("amountCoveredConsultantPieChart", {
  bindings: {
    consultantData: "<",
    showSum: "<",
  },
  template: amountCoveredConsultantPieChartTemplate,
  controller: AmountCoveredConsultantPieChartCtrl,
});
