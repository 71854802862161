import { IFilterService } from "angular";
import { IStateService } from "angular-ui-router";
export default class CompaniesEditCtrl {
  public company: any;
  public contactForm: ng.IFormController;
  public saving: boolean;
  public onSaveError: (error: any) => void;
  public deregActiveRowConnector: () => void;
  public onStateChanged: (company: any) => void;
  public initializeActiveRowConnector: (company: any) => () => void;
  public registerSaveHotkey: (saveCompany: any) => void;

  static $inject = ["$rootScope", "$state", "$filter", "EditUtilMixin", "DeckControllerMixin"];

  constructor(
    private $rootScope: IExtendedRootScopeService,
    private $state: IStateService,
    private $filter: IFilterService,
    private EditUtilMixin: any,
    private DeckControllerMixin: any,
  ) {}

  public $onInit () {
    angular.extend(this, this.EditUtilMixin, this.DeckControllerMixin);

    if (this.company.id) {
      this.$rootScope.setPageTitle(this.company.name);
    } else {
      this.$rootScope.setPageTitle(this.$filter("translate")("NEW_COMPANY"));
    }

    this.onStateChanged(this.company);
    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.company);
    this.registerSaveHotkey(this.saveCompany);
  };

  public saveCompany(route, params) {
    if (!this.contactForm.$valid) {
      window.alert(this.$filter("translate")("WRONG_CONTACT_DATA_NOTICE"));
    } else {
      if (!this.saving) {
        this.saving = true;
        this.company.save().then((newCompany) => {
          this.$rootScope.$broadcast("resetObjectChangedState", newCompany);
          this.company.snapshot();

          this.$state.go(route || "^.show", params || { company_id: newCompany.id });
        }, this.onSaveError.bind(this));
      }
    }
  };

  public $onDestroy () {
    this.deregActiveRowConnector();
  };
};