import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { CurrencyTypes, MoneyTypes } from "../money";
import { CURRENCIES, DEFAULT_CURRENCY } from "../values/currency";

export class Money {
  private serviceName: string = "MoneyFactory";
  private currency: CurrencyTypes = _.findWhere(CURRENCIES, { isoCode: DEFAULT_CURRENCY.isoCode }) as CurrencyTypes;
  private cents: number | undefined | null = null;
  private inCents: boolean = true;

  static $inject = [];

  constructor(fields?: Partial<MoneyTypes>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<MoneyTypes>>(fields, camelCase));
  }
}
