import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { Language } from "./languages.service";

angular
  .module("languages")
  .factory("LanguagesSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(Language, data);
        this.serialize = serialize;
      });
    },
  ])

  .service("LanguagesFactory", Language);
