export class EmployeesProjectSearchCtrl {
  public advancedQuery: any;
  public query: string;
  public project: any;
  public employees: any; // makeCollectionProxy
  public attributes: any;
  private oldAdvancedQuery: any;

  public employeesLazyLoading: boolean;
  public showAutoSearch: boolean;

  static $inject = ["EmployeesFactory", "SavedSearchesFactory", "makeCollectionProxy", "PrsmTableHelper"];
  constructor(
    private EmployeeService: any,
    private SavedSearchService: any,
    private makeCollectionProxy: any,
    private PrsmTableHelper: any
  ) {
    this.employeesLazyLoading = false;
    this.showAutoSearch = false;
    this.oldAdvancedQuery = this.advancedQuery;
  }

  public $onInit() {
    this.advancedQuery = new this.SavedSearchService();

    _.each(this.project.tags, (tag) => {
      this.advancedQuery.conditions.push({
        field: "tags",
        modifier: "must",
        query: tag.name,
      });
    });
    this.advancedQuery.deleteAdvancedSearchCondition(0);

    this.attributes = angular.extend(
      this.PrsmTableHelper.setVisibleAttributes(
        angular.copy(this.EmployeeService.defaultTableAttributes),
        ["id", "name", "company", "priority", "tags", "responsibleUser", "createdAt"],
        ["id", "name", "company", "tags"]
      ),
      {
        projectlistContextMenu: {
          class: "menu",
          show: true,
        },
      }
    );
  }

  private initSearchResults(employees) {
    _.chain(employees)
      .filter((employee) => {
        return _.findWhere(this.project.employees, { id: employee.id });
      })
      .each((employee) => {
        employee.$isOnProjectList = true;
      });

    this.employees = employees;
    this.employeesLazyLoading = false;
  }

  public updateEmployees(newParams, oldParams) {
    var query = newParams.q || this.query || "";
    var params = { advanced: JSON.stringify(newParams || this.advancedQuery), q: query.replace(/\//g, " ") };

    if (_.isUndefined(this.employees)) {
      this.employeesLazyLoading = true;
      this.makeCollectionProxy(this.EmployeeService.query.bind(this.EmployeeService), params).then(
        this.initSearchResults.bind(this)
      );
    } else {
      this.employees.search(params).then(this.initSearchResults.bind(this));
    }
  }

  public $doCheck() {
    if (!angular.equals(this.oldAdvancedQuery, this.advancedQuery)) {
      this.updateEmployees(this.advancedQuery, this.oldAdvancedQuery);
      this.oldAdvancedQuery = angular.copy(this.advancedQuery);
    }
  }

  public startAutoSearch() {
    this.showAutoSearch = true;
  }

  public contextMenuOptions = {
    addToProject: (employee: any) => {
      employee.addToProject(this.project);
    },
  };
}
