import { DeepMapKeys, MapFn, Opts } from "deep-map-keys/lib/deep-map-keys";

class DeepMapKeysMega extends DeepMapKeys {
  constructor(mapFn: MapFn, opts: Opts) {
    super(mapFn, opts);
  }

  private excludedTypes(value: any) {
    return _.isDate(value);
  }

  public map(value: any): any {
    return _.isArray(value)
      ? this["mapArray"](value)
      : _.isObject(value) && !this.excludedTypes(value)
      ? this["mapObject"](value)
      : value;
  }
}

export function deepMapKeysMega<T>(object: any, mapFn: MapFn, options?: Opts): T {
  options = _.isEmpty(options) ? {} : options;

  if (!mapFn) {
    throw new Error("mapFn is required");
  } else if (!_.isFunction(mapFn)) {
    throw new TypeError("mapFn must be a function");
  } else if (!_.isObject(options)) {
    throw new TypeError("options must be an object");
  }

  return new DeepMapKeysMega(mapFn, options).map(object);
}
