//= require ./routing-options.module.js

angular
  .module("values.routing-options")

  .constant("expertProfileOpts", {
    show: {
      resolve: {
        candidatesProjectsResponse: [
          "$transition$",
          "CandidatesProjectsFactory",
          function ($transition$, CandidatesProjectsFactory) {
            var params = $transition$.params();
            if (params.cpjoin_id) {
              return CandidatesProjectsFactory.get(params.cpjoin_id);
            }
          },
        ],
        candidateResponse: [
          "$transition$",
          "CandidatesFactory",
          function ($transition$, CandidatesFactory) {
            var params = $transition$.params();
            return CandidatesFactory.get(params.candidate_id);
          },
        ],
        expertProfileResponse: [
          "$transition$",
          "ExpertProfileFactory",
          function ($transition$, ExpertProfileFactory) {
            var params = $transition$.params();
            if (params.expert_profile_id) {
              return ExpertProfileFactory.get(params.expert_profile_id);
            }
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "expertProfilesEdit",
        };
        return viewObj;
      },
    },
    new: {
      resolve: {
        candidatesProjectsResponse: [
          "$transition$",
          "CandidatesProjectsFactory",
          function ($transition$, CandidatesProjectsFactory) {
            var params = $transition$.params();
            if (params.candidate_id && params.project_id) {
              return CandidatesProjectsFactory.get({
                candidate_id: params.candidate_id,
                project_id: params.project_id,
              });
            } else if (params.cpjoin_id) {
              return CandidatesProjectsFactory.get(params.cpjoin_id);
            }
          },
        ],
        expertProfileResponse: [
          "$transition$",
          "ExpertProfileFactory",
          "candidatesProjectsResponse",
          function ($transition$, ExpertProfileFactory, candidatesProjectsResponse) {
            var params = $transition$.params();
            return ExpertProfileFactory.get("new", {
              cpjoin_id: candidatesProjectsResponse?.id,
              candidate_id: params.candidate_id,
              employee_id: params.employee_id,
            });
          },
        ],
        candidateResponse: [
          "$transition$",
          "CandidatesFactory",
          function ($transition$, CandidatesFactory) {
            var params = _.pick($transition$.params(), _.identity);
            return CandidatesFactory.get(params.candidate_id);
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "expertProfilesEdit",
        };
        return viewObj;
      },
    },
    edit: {
      resolve: {
        candidateResponse: [
          "$transition$",
          "CandidatesFactory",
          function ($transition$, CandidatesFactory) {
            var params = $transition$.params();
            return CandidatesFactory.get(params.candidate_id);
          },
        ],
        expertProfileResponse: [
          "$transition$",
          "ExpertProfileFactory",
          function ($transition$, ExpertProfileFactory) {
            var params = $transition$.params();
            if (params.expert_profile_id) {
              return ExpertProfileFactory.get(params.expert_profile_id);
            }
          },
        ],
        candidatesProjectsResponse: [
          "$transition$",
          "CandidatesProjectsFactory",
          "expertProfileResponse",
          function ($transition$, CandidatesProjectsFactory, expertProfileResponse) {
            var params = $transition$.params();
            return CandidatesProjectsFactory.get({
              id: params.cpjoin_id,
              project_id: expertProfileResponse.project?.id,
              candidate_id: expertProfileResponse.candidate?.id,
            });
          },
        ],
        scrollspyScope: [
          function () {
            return _.uniqueId("scrollspy");
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          component: "expertProfilesEdit",
        };
        return viewObj;
      },
    },
    requirements: {
      objectType: [
        function () {
          return "project";
        },
      ],
      stateParams: [
        "$transition$",
        function ($transition$) {
          return _.pick($transition$.params(), _.identity);
        },
      ],
    },
    urlParameters: {
      queryParams: "cpjoin_id&candidate_id&employee_id&initiative_expert_profile&project_id",
      paramName: ":expert_profile_id",
      url: "/expert_profile",
    },
  });
