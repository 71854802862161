import CompaniesTableBodyCtrl from "./companies-table-body.controller";
import companiesTableBodyTemplate from "./companies-table-body.template.html";

angular.module("companies.components").component("companiesTableBody", {
  bindings: {
    companies: "=data",
    attributes: "=",
    activeRow: "=",
    statePrefix: "@",
    stateArgs: "&",
    context: "=",
    tableRowIdentifier: "=",
    initialViewState: "<",
  },
  template: companiesTableBodyTemplate,
  controller: CompaniesTableBodyCtrl,
});
