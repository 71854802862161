import { Language } from "../../../languages/services/languages.service";
export default class SendoutModalCtrl {
  public resolve: {managers: any, expertProfiles: any};
  private managers: any[];
  private expertProfile: any;
  private managerContactEntries: any[];
  private availableManagerContactEntries: any[];
  private sendoutEmail: { employees: any[]; callback: () => void };
  public modalInstance: angular.ui.bootstrap.IModalInstanceService;
  
  constructor() {}

  public extend(resolve) {
    this.managers = resolve.managers;
    this.expertProfile = resolve.expertProfile;
  }

  public $onInit () {
    this.extend(this.resolve);

    this.managerContactEntries = _.each(this.managers, (manager) => {
      manager.$hasContactAddress = !_.isEmpty(manager.workEmail || manager.privateEmail);
    });

    this.availableManagerContactEntries = _.where(this.managerContactEntries, { $hasContactAddress: true });

    this.sendoutEmail = {
      employees: this.managerContactEntries,
      callback: null,
    };
  };

  public cancel () {
    this.modalInstance.dismiss("cancel");
  };

  public save () {
    this.sendoutEmail.callback = this.expertProfile.createMessage("create_sendout_email", this.sendoutEmail);
    this.modalInstance.close(this.sendoutEmail);
  };
}