//= require ../modals.module.js

angular.module("persons.modals").factory("PersonModals", [
  "$uibModal",
  "$q",
  function ($uibModal, $q) {
    function orgCompanyChartModal(company) {
      var modalInstance = $uibModal.open({
        component: "companyOrgChart",
        size: "xl",
        resolve: {
          company: [
            function () {
              return company;
            },
          ],
        },
      });

      return modalInstance.result.then(angular.noop, angular.noop);
    }

    return {
      openCompanyOrgChart: orgCompanyChartModal,
    };
  },
]);
