export default class WhiteboardAdvancedSearchCtrl {
  public steps: { key: string; value: any }[];

  static $inject = ["KPISteps"];

  constructor (private KPISteps: any) {
    this.steps = _.chain(this.KPISteps)
      .pick(["sendouts", "firstInterviews", "secondInterviews", "closings", "deals"])
      .map((value, key) => ({ key, value }))
      .value();
  }
}