export default class datePickerCtrl {
  public opened: boolean;

  constructor() {
    _.defaults(this, { required: false, disabled: false, uniqName: _.uniqueId("datePicker") });
  }

  public open($event: ng.IAngularEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.opened = true;
  };
}