//= require ./services.module.js
angular.module("services").factory("daterangepickerOptions", [
  "moment",
  "$filter",
  "$translate",
  function (moment, $filter, $translate) {
    return {
      applyClass: "btn-green",
      cancelClass: "btn-green",
      buttonClass: "btn",
      timePicker: true,
      showISOWeekNumbers: true,
      alwaysShowCalendars: false,
      opens: "left",
      locale: {
        applyLabel: $filter("translate")("START_SEARCH_BUTTON"),
        fromLabel: $filter("translate")("FROM"),
        format: "DD.MM.YYYY",
        toLabel: $filter("translate")("UNTIL"),
        cancelLabel: $filter("translate")("CANCEL"),
        customRangeLabel: $filter("translate")("VARIABLE_PERIOD"),
        weekLabel: $filter("translate")("WEEK_TOKEN"),
        daysOfWeek: moment.weekdaysShort(),
        monthNames: moment.monthsShort(),
        firstDay: 1,
      },
    };
  },
]);
