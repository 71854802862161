import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { FunctionalArea } from "./functional_areas.service";

angular
  .module("functional_areas")
  .factory("FunctionalAreasSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(FunctionalArea, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("FunctionalAreasFactory", FunctionalArea);
