export default class ChooseLocalesModalCtrl {
  private resolve: { selectSubsidiary: any; dialogTitle: string; languages: any };
  private language: string;
  private user: any;
  private subsidiary: string;
  private subsidiaries: any[];
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = ["entourageSubsidiaries", "Session", "EditUtilMixin"];

  constructor(private entourageSubsidiaries: any, private Session: any, private EditUtilMixin: any) {}
  
  public $onInit () {
    angular.extend(this, this.EditUtilMixin, {
      user: this.Session.user,
      selectSubsidiary: this.resolve.selectSubsidiary,
      subsidiaries: this.entourageSubsidiaries,
      dialogTitle: this.resolve.dialogTitle,
    });

    this.language = _.first(
      _.select(this.resolve.languages, (language) => {
        return language.key == this.user.locale;
      })
    ).key

    if (this.user.nationalAffiliate) {
      this.subsidiary = _.first(
        _.select(this.subsidiaries, (subsidiary) => {
          return subsidiary.slug == this.user.nationalAffiliate.slug;
        })
      ).slug;
    } else {
      this.subsidiary = _.first(this.subsidiaries).slug;
    }
  };

  public cancel () {
    this.modalInstance.dismiss("cancel");
  };

  public selectLocales () {
    var params = {
      lang: this.language,
      subsidiarySlug: this.subsidiary,
    };
    this.modalInstance.close(params);
  };
}