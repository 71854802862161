import jobOfferContextMenuTemplate from "./job-offer-context-menu.template.html";
import JobOffersContextMenuCtrl from "./job-offers-context-menu.controller";
//= require ../components.module.js

angular.module("job_offers.components").component("jobOffersContextMenu", {
  bindings: {
    jobOffer: "=",
    statePrefix: "=",
  },
  template: jobOfferContextMenuTemplate,
  controller: JobOffersContextMenuCtrl,
});
