//= require ./modals.module.js

angular.module("expert_profiles.modals").factory("ExpertProfilesModalMixin", [
  "$uibModal",
  "$rootScope",
  "$filter",
  "poller",
  "SendoutsFactory",
  function ($uibModal, $rootScope, $filter, poller, Sendout) {
    function ExpertProfilesModalMixin() {}

    function sendoutModal(scope, resolves, sendoutFinished) {
      var modalInstance = $uibModal.open({
        component: "sendoutModal",
        size: "lg",
        resolve: resolves,
      });

      return modalInstance.result.then(
        function (sendoutEmail) {
          sendoutEmail.callback.then(function (sendoutCreatedRes) {
            var pollingService = poller.get(sendoutCreatedRes.data.url, { delay: 1000 });
            scope.confirm($filter("translate")("EMAIL_EXPERT_PROFILE_SENT_CONFIRMATION"), pollingService).then(
              function (res) {
                if (res) {
                  scope
                    .sendDocument("send_sendout", { sendout_id: sendoutCreatedRes.data.sendout_id })
                    .then(function () {
                      Sendout.get(sendoutCreatedRes.data.sendout_id).then(function (sendoutRes) {
                        scope.$sendouts.unshift(sendoutRes);

                        sendoutFinished();
                      });
                    });
                }
                pollingService.remove();
              },
              function () {
                pollingService.remove();
              }
            );
          });
        },
        function () {}
      );
    }

    function openInitiativeSendoutModal() {
      var _this = this;
      var resolves = {
        managers: function () {
          return [_this.employee];
        },
        expertProfile: function () {
          return _this;
        },
      };

      var sendoutFinished = function () {
        $rootScope.$broadcast("candidate_action_changed_" + _this.candidate.id);
      };

      return sendoutModal(_this, resolves, sendoutFinished);
    }

    function openLonglistSendoutModal(cpjoin) {
      var _this = this;
      var resolves = {
        managers: function () {
          return cpjoin.project.employees;
        },
        expertProfile: function () {
          return _this;
        },
      };

      var sendoutFinished = function () {
        $rootScope.$broadcast("candidate_action_changed_" + cpjoin.candidate.id);
        $rootScope.$broadcast("cpjoin_action_changed_" + cpjoin.id);
      };

      return sendoutModal(_this, resolves, sendoutFinished);
    }

    ExpertProfilesModalMixin.extended = function (Resource) {
      Resource.include({
        openLonglistSendoutModal: openLonglistSendoutModal,
        openInitiativeSendoutModal: openInitiativeSendoutModal,
      });
    };

    return ExpertProfilesModalMixin;
  },
]);
