interface IExtendedScope extends angular.IScope {
  multipleRequiredScopes: any;
}
angular.module("directives").directive("multipleRequired", [
  function () {
    function validateMultipleRequiredFields(ctrl, value, identifier) {
      return (
        !ctrl.$isEmpty(value) ||
        _.some(this.multipleRequiredScopes[identifier], function (ctrlObj) {
          return !(ctrlObj.$isEmpty(ctrlObj.$viewValue) || ctrlObj == ctrl);
        })
      );
    }

    return {
      require: "ngModel",
      link: function (scope: IExtendedScope, element, attrs, ctrl) {
        var identifier = attrs.multipleRequired;

        if (_.isEmpty(scope.multipleRequiredScopes)) {
          scope.multipleRequiredScopes = {};
        }

        if (_.isArray(scope.multipleRequiredScopes[identifier])) {
          scope.multipleRequiredScopes[identifier].push(ctrl);
        } else {
          scope.multipleRequiredScopes[identifier] = [ctrl];
        }

        ctrl.$validators.multipleRequired = function (modelValue, viewValue) {
          var valid = validateMultipleRequiredFields.bind(this)(ctrl, viewValue, identifier);

          // test and set the validity after update.
          _.each(this.multipleRequiredScopes[identifier], function (ctrlObj) {
            ctrlObj.$setValidity("multipleRequired", valid);

            if (valid && ctrlObj.$isEmpty(ctrlObj.$viewValue)) {
              ctrlObj.$setViewValue(null);
              ctrlObj.$setUntouched();
            }
          });

          return valid;
        }.bind(scope);
      },
    };
  },
]);
