import { IFilterService } from "angular";

export default class DashboardInterviews2021CardCtrl {
  public loading: boolean;
  public data: {
    fullTimeEquivalent: number,
    kpis: {
      kpiIST: {
        firstInterviewDealsRatio: number,
        firstInterviews: number,
        interviewDealsRatio: number,
        interviews: number,
        interviewsRatio: number,
        presenceInterviewDealsRatio: number,
        presenceInterviews: number,
        secondInterviewDealsRatio: number,
        secondInterviews: number,
        telephoneInterviewDealsRatio: number,
        telephoneInterviews: number,
      }
    }
  };
  public labels: string[];
  public readonly projectReportingRequestObj: any;

  static $inject = ["$filter", "$translate", "ProjectReportingRequestObj"];

  constructor(
    private $filter: IFilterService,
    private $translate: angular.translate.ITranslateService,
    private ProjectReportingRequestObj: any
  ) {
    this.labels = [
      this.$filter("translate")("COMPLETED"), 
      this.$filter("translate")("CANCELED"), 
      this.$filter("translate")("OPEN")
    ];
    this.loading = true;

    this.projectReportingRequestObj = new this.ProjectReportingRequestObj(
      this,
      "dashboardInterviews2021Card",
      (result) => {
        this.data = result;
      }
    );
  }

  public $onDestroy() {
    this.projectReportingRequestObj.deregisterEventListeners();
  };
};