import { TagsListInlineCtrl } from "./tags-list-inline.controller";
import tagsListInlineTemplate from "./tags-list-inline.template.html";

angular.module("tags.components").component("tagsListInline", {
  bindings: {
    tags: "<",
  },
  template: tagsListInlineTemplate,
  controller: TagsListInlineCtrl,
});
