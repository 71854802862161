import documentKpisCtrl from "./document-kpis.controller";
import documentKpisTemplate from "./document-kpis.template.html";

angular.module("documents.components").component("documentKpis", {
  bindings: {
    model: "=ngModel",
  },
  template: documentKpisTemplate,
  controller: documentKpisCtrl,
});
