export default class EmployeesProjectListCtrl {
  public attributes: any;
  public advancedQuery: any;
  public advancedEmployeesProjectSearchOptions: any;
  public contextMenuOptions: any;
  public project: any;
  public lazyLoading: boolean;
  public activeRow: any;
  public query: string;
  public remote: boolean;
  public setData: () => (newParams: any, oldParams: any) => void;
  
  static $inject = [
    "$state",
    "EmployeesFactory",
    "PrsmTableHelper",
    "makeCollectionProxy",
    "SavedSearchesFactory"
  ];

  constructor (
    private $state: angular.ui.IStateService,
    private Employee: any,
    private PrsmTableHelper: any,
    private makeCollectionProxy: any,
    private SavedSearch: any,
  ) {}
  
  public $onInit() {
    this.attributes = this.PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.Employee.defaultTableAttributes),
      ["name", "company", "priority", "tags", "processStatus", "sales", "researcher"],
      ["name", "company", "processStatus", "sales", "researcher"]
    );

    this.advancedQuery = new this.SavedSearch({
      defaultCondition: { modifier: "must", field: "company_name", query: "" },
    });

    this.advancedQuery.deleteAdvancedSearchCondition(0);
    this.advancedEmployeesProjectSearchOptions = {
      advancedQuery: this.advancedQuery,
      setData: this.updateEmployees,
      remote: true,
    };

    this.contextMenuOptions = {
      removeFromProject: (employee) => {
        employee.removeFromProject(this.project);
      },
      createInitiativeExpertProfile: (employee) => {
        this.$state.go(".expert_profile.new", {
          project_id: this.project.id,
          candidate_id: this.project.candidate.id,
          cpjoin_id: undefined,
          employee_id: employee.id,
          initiative_expert_profile: true,
        });
      },
    };

    // $scope.$watchCollection("project.employees", function (nv, ov) {
    //   this.activeRow = this.project.employees[0];
    // });

    this.updateActiveRow();
    this.updateEmployees();
  };

  public initSearchResults(employees) {
    this.project.employees = employees;
    this.lazyLoading = false;
  };

  public updateActiveRow() {
    this.activeRow = this.project.employees[0];
  };

  public updateEmployees(newParams?, oldParams?) {
    var query = (newParams && newParams.q) || this.query || "";
    var params = _.extend(this.project.params(), {
      candidate_id: this.project.candidate.id,
      advanced: JSON.stringify(newParams || this.advancedQuery.beforeStringify()),
      q: query.replace(/\//g, " "),
      additional_pages: this.project.employeesCount / 20 + 1,
    });

    if (_.isUndefined(this.project.employees) || _.isArray(this.project.employees)) {
      this.lazyLoading = true;
      this.makeCollectionProxy(this.Employee.query.bind(this.Employee), params).then((result) => this.initSearchResults(result));
    } else {
      this.project.employees.search(params).then((result) => this.initSearchResults(result));
    }
  };
};