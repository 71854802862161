//= require ../../directives.module.js

interface IExtendedScope extends angular.IScope {
  $select: any;
}

angular.module("directives").directive("uiSelectKeepSearchQuery", [
  function () {
    return {
      require: "ui-select",
      link: function (scope: IExtendedScope, $element, $attributes, selectController) {
        scope.$on("uis:activate", function () {
          if (!scope.$select.selected) {
            return;
          }
          if (_.isString(scope.$select.selected)) {
            scope.$select.search = scope.$select.selected;
          } else {
            scope.$select.search = scope.$select.selected.name || "";
          }
        });
      },
    };
  },
]);
