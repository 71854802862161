import { IStateService } from "angular-ui-router";
export default class CandidatesProjectSearchCtrl {
  private foundCandidates: any[] = [];
  private loading: boolean = false;
  public cancelers: ng.IDeferred<any>[] = [];
  private project: any;
  private advancedQuery: any;
  private remote: boolean;
  private setData: () => (collectedparams: any) => void 


  static $inject = ["UsersFactory", "Session", "CandidatesFactory", "$state", "$q", "Autocompletes"];
  constructor(public User: any,
              private Session: any,
              public Candidate: any,
              private $state: IStateService,
              public $q: ng.IQService,
              public Autocompletes: any ) {}

  public searchCandidates (query) {
    if (query.length) {
      this.loading = true;
      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      this.Autocompletes.autocomplete(query, "candidate_name", _.last(this.cancelers)).then( (results) => {
        this.foundCandidates = _.map(results,  (result) => {
          result.name = result.highlight[0];
          switch (result.resource_type) {
            case "candidate":
              return _.extend(new this.Candidate(result.candidate), {
                resource_type: "Kandidat",
                fn: (id, query) => {
                  this.advancedQuery.basicQuery = query;
                  this.$state.go("root.candidates.show", this.advancedQuery.collectSearchParams({ candidate_id: id }), {
                    reload: true,
                    inherit: false,
                  });
                },
              });
            default:
              return _.extend(result, {
                resource_type: "Query",
                fn:  (id, query) => {
                  this.search(id, query);
                },
              });
          }
        });
        this.loading = false;
      });
    } else {
      this.foundCandidates = [];
    }
  };

  public search (query, routeFn) {
    this.advancedQuery.basicQuery = query;

    if (!this.remote) {
      this.$state.go("root.candidates", this.advancedQuery.collectSearchParams(), { reload: true, inherit: false });
    } else {
      this.setData()(this.advancedQuery.collectSearchParams());
    }
  };

  public addCandidateToProject (item) {
    if (!item || !item.id) {
      return;
    }

    this.$state.go("^.show", { candidate_id: item.id });
    this.project.addCandidate(item);
  };
}