import CandidatesShowCtrl from "./candidates-show.controller";
import candidatesShowViewTemplate from "./candidates-show-view.template.html";

angular.module("candidates.views").component("candidatesShowView", {
  bindings: {
    candidate: "<",
    savedSearches: "<savedSearchesResponse",
    documentView: "<",
    $transition$: "<",
    scrollspyScope: "<",
    $state$: "<",
  },
  template: candidatesShowViewTemplate,
  controller: CandidatesShowCtrl,
});
