//= require ../modals.module.js

angular.module("users.modals").factory("UsersModalMixin", [
  "$uibModal",
  function ($uibModal) {
    function UsersModalMixin() {}

    function accountSettingsModal() {
      var modalInstance = $uibModal.open({
        component: "accountSettings",
        windowClass: "account-settings-modal",
        resolve: {
          user: [
            "UsersFactory",
            function (User) {
              return User.get(this.id);
            }.bind(this),
          ],
          users: [
            "UsersFactory",
            function (User) {
              return User.getAll();
            },
          ],
          teams: [
            "UsersFactory",
            function (User) {
              return User.translatedTeams();
            },
          ],
        },
      });

      return modalInstance.result.then(angular.noop).catch(angular.noop);
    }

    UsersModalMixin.extended = function (Resource) {
      Resource.include({
        accountSettingsModal: accountSettingsModal,
      });
    };

    return UsersModalMixin;
  },
]);
