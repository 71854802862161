import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";

export default class CompaniesContextMenuCtrl {
    initialViewState: any;
    constructor() {}
  
    public $onInit() {
      angular.extend(this, {
        state: StateTransitionsConfig.state,
        transitionOptions: StateTransitionsConfig.transitionOptions(this.initialViewState),
      });
    };
};