import { Cancelable } from "underscore";

export class ChooseQuicklistModalCtrl {
  public attributes: any;
  private resolve: {
    quicklists: any[];
  };
  public quicklists: any[];
  public filteredQuicklists: any[];
  private throttled: ((nv: any) => void) & Cancelable;
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  public filterQuicklistName: string;

  static $inject = ["$filter", "Session"];

  constructor(private $filter: ng.IFilterService, private Session: any) {
    this.attributes = {
      name: {
        title: $filter("translate")("NAME"),
        show: true,
      },
      createdAt: {
        title: $filter("translate")("CREATED_AT"),
        show: false,
        class: "date",
      },
      menu: {
        long: true,
      },
    };

    this.throttled = _.throttle(this.updateFilteredQuicklists, 100);
  }

  private updateFilteredQuicklists(nv: any) {
    var tmp_quicklists = _.filter(this.quicklists, (quicklist: any) => {
      return quicklist.owner.id === this.Session.user.id || quicklist.readOnly === false;
    });
    this.filteredQuicklists = this.$filter("filter")(tmp_quicklists, nv);
  }

  public $onInit() {
    this.quicklists = this.resolve.quicklists;
  }

  public $doCheck() {
    this.throttled(this.filterQuicklistName);
  }

  public cancel() {
    this.modalInstance.dismiss("cancel");
  }

  public saveChanges() {
    this.modalInstance.close(this.quicklists);
  }
}
