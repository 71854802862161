import DocumentsListCtrl from "./documents-list.controller";
import documentsListTemplate from "./documents-list.template.html";

angular.module("documents.components").component("documentsList", {
  bindings: {
    resource: "=",
    documents: "<",
    documentsCount: "=",
    selectLabel: "@",
    cancel: "=",
    remove: "=",
    disableSearch: "=",
    chooseFileType: "<",
  },
  template: documentsListTemplate,
  controller: DocumentsListCtrl,
});
