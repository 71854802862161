import { initContract } from "@ts-rest/core";

import { TTag } from "../tags";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const TagsSchema = c.type<TBaseResource<TTag[]>>();
export const tagsContract = c.router({
  query: {
    method: "GET",
    path: "/tags",
    responses: {
      200: TagsSchema,
    },
    summary: "Get all tags",
  },
});
