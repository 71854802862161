interface IExtendedScope extends angular.IScope {
  popoverClass?: string;
  $parent: any;
  popoverAppendToBody: any;
}

interface $parent {
  $ctrl: any;
}

angular.module("directives").directive("outsideClickDropdown", [
  "$document",
  "$timeout",
  function ($document, $timeout) {
    return {
      scope: {
        popoverClass: "@",
        popoverAppendToBody: "@",
      },
      link: function (scope: IExtendedScope, element) {
        // second version to support directives
        if (scope.$parent.$ctrl) {
          scope.$parent.$ctrl.dropdownIsOpen = false;
        } else {
          scope.$parent.dropdownIsOpen = false;
        }

        var outsideClickListener = function (event) {
          var e;

          if (scope.popoverAppendToBody) {
            e = angular.element("." + scope.popoverClass);
          } else {
            e = angular.element(element);
          }
          if (e[0] && event.target.offsetParent && !e[0].contains(event.target)) {
            if (scope.$parent.$ctrl) {
              scope.$parent.$ctrl.closing();
            } else {
              scope.$parent.closing();
            }
          }
        };

        // second version to support directives
        if (scope.$parent.$ctrl) {
          scope.$parent.$ctrl.closing = function () {
            scope.$evalAsync(function () {
              scope.$parent.$ctrl.dropdownIsOpen = false;
            });
          };
        } else {
          scope.$parent.closing = function () {
            scope.$evalAsync(function () {
              scope.$parent.dropdownIsOpen = false;
            });
          };
        }

        if (scope.$parent.$ctrl) {
          scope.$watch("$parent.$ctrl.dropdownIsOpen", function (nv, ov) {
            if (nv) {
              $timeout(function () {
                $document.on("click", outsideClickListener);
              }, 1);
            } else {
              $document.off("click", outsideClickListener);
            }
          });
        } else {
          scope.$watch("$parent.dropdownIsOpen", function (nv, ov) {
            if (nv) {
              $timeout(function () {
                $document.on("click", outsideClickListener);
              }, 1);
            } else {
              $document.off("click", outsideClickListener);
            }
          });
        }
      },
    };
  },
]);
