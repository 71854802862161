import { initContract } from "@ts-rest/core";

import { TBaseResource } from "../../api-routing";
import { TCancelationPeriod } from "../cancelation_periods";

const c = initContract();

const CancelationPeriodsSchema = c.type<TBaseResource<TCancelationPeriod[]>>();
export const cancelationPeriodsContract = c.router({
  query: {
    method: "GET",
    path: "/cancelation_periods",
    responses: {
      200: CancelationPeriodsSchema,
    },
    summary: "Get all cancelation periods",
  },
});
