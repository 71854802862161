import { EmployeesProjectSearchCtrl } from "./employees-project-search.controller";
import employeesProjectSearchTemplate from "./employees-project-search.template.html";

angular.module("projects.components").component("employeesProjectSearch", {
  bindings: {
    project: "=",
    savedSearches: "=",
    activeRow: "=",
  },
  template: employeesProjectSearchTemplate,
  controller: EmployeesProjectSearchCtrl,
});
