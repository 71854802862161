//= require ../prsm-table.module.js

angular.module("directives.prsmTable").service("PrsmTableAttributeService", [
  "PrsmTableHelper",
  function (PrsmTableHelper) {
    let attributes;
    let identifier;

    function PrsmTableAttribute(identifier, defaultAttributes) {
      this.identifier = identifier;

      this.loadFromStorage = function (defaultAttributes) {
        return PrsmTableHelper.getAttributes(this.identifier, defaultAttributes);
      };

      this.getAttributes = function () {
        return this.attributes;
      };

      this.attributes = this.loadFromStorage(defaultAttributes);

      this.attributes.onChange = function () {
        PrsmTableHelper.setAttributes(this.identifier, this.attributes);
      }.bind(this);
    }

    return PrsmTableAttribute;
  },
]);
