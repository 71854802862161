import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TTransactionBases } from "../transaction_bases";

export class TransactionBasis {
  private serviceName: string = "TransactionBasesFactory";
  public id: number;
  public name: string;
  public slug?: string;

  static $inject = [];

  constructor(fields?: Partial<TTransactionBases>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TTransactionBases>>(fields, camelCase));
  }

  public isAGBContract() {
    return this.slug === "gtc";
  }

  public isFullContract() {
    return this.slug === "framework-contract-fixed";
  }

  public isFreelancerContract() {
    return this.slug === "framework-contract-free";
  }

  public isProjectConsultingContract() {
    return this.slug === "framework-contract-employee-leasing-el";
  }

  public isServiceContract() {
    return this.slug === "framework-contract-for-work-and-services";
  }

  public isIndividualContract() {
    return this.slug === "individual-contract";
  }

  @cached
  public static getAll() {
    return baseClient.transactionBases.query().then((response) => {
      return response.body.data.map((element) => {
        return new TransactionBasis(element);
      });
    });
  }

  @cached
  public static getAllForCompanies() {
    return baseClient.transactionBases.query({ query: { for_companies: true } }).then((response) => {
      return response.body.data.map((element) => {
        return new TransactionBasis(element);
      });
    });
  }
}

export const PERIOD_OF_PAYMENT_OPTIONS = [
  { name: "PAYMENT_PERIOD_0_DAYS", value: 0 },
  { name: "PAYMENT_PERIOD_14_DAYS", value: 14 },
  { name: "PAYMENT_PERIOD_30_DAYS", value: 30 },
  { name: "PAYMENT_PERIOD_60_DAYS", value: 60 },
  { name: "PAYMENT_PERIOD_90_DAYS", value: 90 },
];
