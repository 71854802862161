import { ITemplateCacheService } from "angular";
import { KPISteps, kpiIST, kpiSOLL } from "../../../../types/kpis"
import openPopoverTemplate from "../popover/open-popover.template.html";

export default class DashboardKpis2021CardCtrl {
  public KPISteps: Partial<KPISteps>;
  public kpiIST: Partial<kpiIST>;
  public kpiSOLL: Partial<kpiSOLL>;
  public loading: boolean;
  public steps: any[];
  public data: {
    fullTimeEquivalent: number,
    kpis: {
      kpiIst: any,
      kpiSoll: any,
      kpiSollPerYear: any
    },
  };
  public params: any;
  public enableKpiKeySelect: boolean;
  public selectedKpiKey: any;
  public readonly projectReportingRequestObj: any;
  
  static $inject = ["DashboardKPISteps", "ProjectReportingRequestObj", "$templateCache"];

  constructor(
    private DashboardKPISteps: any,
    private ProjectReportingRequestObj: any,
    private $templateCache: ITemplateCacheService,
  ) {
    this.$templateCache.put("open-popover-template", openPopoverTemplate);

    this.projectReportingRequestObj = new this.ProjectReportingRequestObj(this, "dashboardKpisCard", (result) => {
      this.kpiIST = {
        kpis: result.kpis.kpiIst,
        params: this.params,
      };
      this.kpiSOLL.kpis = result.kpis.kpiSoll;

      this.data = result;

      this.setSteps();
    });
  }

  public $onInit() {
    this.KPISteps = this.DashboardKPISteps;
    this.kpiIST = {};
    this.kpiSOLL = {};

    this.loading = true;
  };

  public setSteps() {
    this.steps = _.chain(this.KPISteps)
      .mapObject((kpiStep, key) => {
        return _.extend(kpiStep, {
          key: key,
          isOpen: false,
          model: this.kpiIST,
          ist: this.kpiIST.kpis[key],
          soll: this.kpiSOLL.kpis[key],
          sollPerYear: this.data.kpis.kpiSollPerYear[key],
        });
      })
      .omit("bruttoContacts", "nettoContacts", "longlist", "shortlist")
      .values()
      .value();
  };

  public setKpiKey (stepkey) {
    if (this.enableKpiKeySelect) {
      this.selectedKpiKey = stepkey;
    }
  };

  public $onDestroy () {
    this.projectReportingRequestObj.deregisterEventListeners();
  };
};