export default class ConfirmModalCtrl {
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private resolve: { message: string, ready: boolean, pollingService: any };
  private message: string;
  private ready: boolean;
  private pollingService: any;

  constructor() {}

  public $onInit () {
    this.message = this.resolve.message;
    this.ready = !this.resolve.pollingService;
    this.pollingService = this.resolve.pollingService;
  };

  public ok () {
    this.modalInstance.close(true);
  };
  public cancel () {
    this.modalInstance.close(false);
  };
}