import AdvancedActionsSearchCtrl from "./advanced-actions-search.controller";
import advancedActionsSearchTemplate from "./advanced-actions-search.template.html";
//= require ../components.module.js

angular.module("actions.components").component("advancedActionsSearch", {
  bindings: {
    advancedQuery: "=",
    setData: "=",
    followUps: "@",
    closing: "&",
  },
  template: advancedActionsSearchTemplate,
  controller: AdvancedActionsSearchCtrl,
});
