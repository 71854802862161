import { initContract } from "@ts-rest/core";

import { TGraduation } from "../graduations";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const GraduationsSchema = c.type<TBaseResource<TGraduation[]>>();
export const graduationsContract = c.router({
  query: {
    method: "GET",
    path: "/graduations",
    responses: {
      200: GraduationsSchema,
    },
    summary: "Get all graduations",
  },
});
