//= require ../dashboard-settings.module.js

angular.module("dashboards.settings").service("ProjectReportingRequestObj", [
  "$q",
  "$rootScope",
  "ProjectReportingsFactory",
  "ChartColors2021",
  "$filter",
  "$translate",
  function ($q, $rootScope, ProjectReporting, ChartColors, $filter, $translate) {
    function ProjectReportingRequestObj(controller, cardIdentifier, callback) {
      var canceler = $q.defer();

      function projectReportingRequest(params) {
        canceler.resolve();
        canceler = $q.defer();

        return ProjectReporting.$http({
          url: "project_reporting/time_range",
          params: _.extend(params, { card: cardIdentifier }),
          headers: { Accept: "application/json" },
          timeout: canceler.promise,
        }).then(callback, function (result) {
          controller.loading = false;
          if (result.status === 0) return;

          window.rollbar.error("Dashboard-Card loading error", { params: _.extend(controller.params, result.config) });
          controller.loadingError = $filter("translate")("LOADING_ERROR_NOTICE");
        });
      }

      this.updateCard = function (event, params) {
        controller.params = params;

        controller.loading = true;
        controller.loadingError = undefined;
        projectReportingRequest(angular.copy(params)).then(function () {
          controller.loading = false;
        });
      };

      controller.reload = function () {
        this.updateCard(undefined, controller.params);
      }.bind(this);

      controller.checkObjectIsEmpty = function (obj) {
        return !_.chain(obj).values().any().value();
      };

      controller.colors = ChartColors;

      const deregisterDashboardQueryChanged = $rootScope.$on("dashboard-query-changed", this.updateCard);
      const deregisterDashboardQueryTimeout = $rootScope.$on("dashboard-query-timeout", function () {
        canceler.resolve();
      });

      this.deregisterEventListeners = function () {
        deregisterDashboardQueryChanged();
        deregisterDashboardQueryTimeout();
      };
    }

    return ProjectReportingRequestObj;
  },
]);
