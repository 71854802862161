import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";

export class LonglistTableBodyControllerCtrl {
  static $inject = ["CandidatesProjectsFactory", "QuicklistsFactory", "CandidatesFactory"];

  public projectStatuses: any[];
  public state: any;
  public reduceParams: any;
  public draggingHelper: any;
  public startCallback: any;

  constructor(private CandidatesProjects: any, private Quicklist: any, public Candidate: any) {
    this.projectStatuses = CandidatesProjects.statuses;

    angular.extend(this, {
      draggingHelper: Quicklist.draggingHelper,
      startCallback: Quicklist.startCallback,
      state: StateTransitionsConfig.state,
      reduceParams: StateTransitionsConfig.reduceParams,
    });
  }
}
