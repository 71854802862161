export class ProjectSelectCtrl {
  private params: any;

  public foundProjects: any[] = [];
  public loading: boolean = false;
  private cancelers: ng.IDeferred<any>[] = [];
  public object: any;

  public onChange: ($item: any) => void;

  static $inject = ["$q", "$rootScope", "Autocompletes", "ProjectsFactory"];

  constructor(
    private $q: ng.IQService,
    private $rootScope: ng.IRootScopeService,
    private Autocompletes: any,
    private ProjectService: any
  ) {}

  public searchProjects(viewValue) {
    this.loading = true;
    _.invoke(this.cancelers, "resolve");
    this.cancelers.push(this.$q.defer());

    let resourceTypeParams: { [key: string]: any[] } = {
      "Employee": [
          viewValue,
          "project_by_employee",
          _.last(this.cancelers),
          "employee",
          { employee_id: this.object.id },
        ],
        "Candidate": [
          viewValue,
          "project_by_candidate",
          _.last(this.cancelers),
          "candidate",
          { candidate_id: this.object.id },
        ],
        "Company": [
          viewValue,
          "project_by_company",
          _.last(this.cancelers),
          "company",
          { company_id: this.object.id },
        ]
      };
    this.params = resourceTypeParams[this.object.resourceType];

    this.Autocompletes.autocomplete.apply(this, this.params).then((results) => {
      if (!results) {
        return;
      }

      results.splice(0, 1); // remove query string from results list

      this.foundProjects = _.chain(results)
        .map((result) => {
          switch (result.resource_type) {
            case "candidates_projects":
              return _.extend(new this.ProjectService(result.candidates_project.project), {
                resource_type: "Project",
              });
            case "project":
              return _.extend(new this.ProjectService(result), {
                resource_type: "Project",
              });
            default:
              result.name = result.highlight[0];
              return _.extend(result, {
                resource_type: "Query",
              });
          }
        })
        .value();
      this.loading = false;
    });
  }

  public triggerProjectSelectBroadcast($item, $model) {
    this.$rootScope.$broadcast("projectChanged", $item);

    if (_.isFunction(this.onChange)) {
      this.onChange($item);
    }
  }
}
