import { OrganisationUnit } from "../../services/organisation_units.service";

export default class OrganisationUnitsSelectorCtrl {
  private organisationUnits: OrganisationUnit[];

  constructor () {}

  public $onInit () {
    OrganisationUnit.getAll().then( (organisationUnits) => {
      this.organisationUnits = organisationUnits;
    });
  };
}