import CompaniesTransactionSearchCtrl from "./companies-transaction-search.controller";
import companiesTransactionSearchTemplate from "./companies-transaction-search.template.html";

angular.module("directives.advanced_search").component("companiesTransactionSearch", {
  bindings: {
    advancedQuery: "=",
    advancedSearch: "=",
    selectedFields: "<",
  },
  template: companiesTransactionSearchTemplate,
  controller: CompaniesTransactionSearchCtrl,
});
