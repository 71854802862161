export default class TextkernelDiffModalCtrl {

  private pollingService: any;
  private loading: boolean;
  private doc: any;
  private resolve: { doc: Document, ObjClass: any };
  private resultDiff: any;
  private hasResults: boolean;
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  
  static $inject = ["$filter", "poller", "DocumentsFactory"];

  constructor ( private $filter: ng.IFilterService, 
                private poller: any, 
                private Document: any) {}

  
  public $onInit (): void {
    this.loading = true;
    this.doc = this.resolve.doc;
    this.pollingService = this.poller.get(this.doc.$url() + ".json", { catchError: true, delay: 2000 });

    this.pollingService.promise.then(null, null, (response) => {
      if (response.status === 200) {
        var doc = new this.Document(response.data.data);
        if (doc.textkernelFailed) {
          // Error while generating
          this.cancel();
          window.alert(this.$filter("translate")("ERROR_WHILE_CREATING_PROFILE"));
        } else if (doc.textkernelComplete) {
          this.pollingService.remove();

          return this.resolve.ObjClass.$get(this.doc.$url("/load_textkernel_diff.json"), {
            document: JSON.stringify(this.doc),
          }).then(
            (result) => {
              this.loading = false;

              this.resultDiff = result;
              this.hasResults = !!_.keys(this.resultDiff.changes).length;
            },
            () => {
              window.alert(this.$filter("translate")("ERROR_WHILE_FETCHING_DATA_NOTICE"));
              this.cancel();
            }
          );
        } else if (!doc.textkernelGenerating) {
          this.doc.$put(this.doc.$url("/extract_with_textkernel.json")).then( () => {
            this.pollingService.restart();
          });

          this.pollingService.stop();
        }
      } else {
        this.cancel();
      }
    });
  };

  public mapAttribute (attr) {
    return this.resolve.ObjClass.mapAttribute(attr);
  };

  public importData () {
    this.modalInstance.close(this.resultDiff);
  };

  public cancel () {
    this.modalInstance.dismiss();
  };

  public $onDestroy (){
    this.pollingService.remove();
  };
}