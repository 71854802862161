import { QuicklistsTableBodyCtrl } from "../quicklists-table-body.controller";
import quicklistsEmployeesTableBodyTemplate from "./quicklists-employees-table-body.template.html";

angular.module("quicklists.components").component("quicklistsEmployeesTableBody", {
  bindings: {
    employees: "=data",
    quicklist: "=list",
    attributes: "=",
    tableRowIdentifier: "=",
    activeRow: "=",
  },
  template: quicklistsEmployeesTableBodyTemplate,
  controller: QuicklistsTableBodyCtrl,
});
