// RailsResource
// interface SavedSearch {
//   [key: string]: any;
//   getAll(type?: string): any;
// }

angular.module("saved_searches.components").service("SavedSearchesFactory", [
  "RailsResource",
  "railsSerializer",
  "savedSearchesSingleton",
  "ResourceDependencyInjector",
  function (RailsResource, railsSerializer, savedSearchesSingleton, ResourceDependencyInjector) {
    function SavedSearch() {
      (<any>SavedSearch).__super__.constructor.apply(this, arguments);

      this.params = function () {
        return {
          saved_search_id: this.id,
          q: null,
          advanced: null,
          sort_params: null,
        };
      };

      this.addAdvancedSearchCondition = function () {
        this.conditions.push(angular.copy(this.defaultCondition));
      };

      this.deleteAdvancedSearchCondition = function (index) {
        this.conditions.splice(index, 1);
        if (this.conditions.length === 0) {
          this.addAdvancedSearchCondition();
        }
      };

      this.isDefault = function () {
        return (
          !this.id &&
          _.isEqual(this.conditions, [this.defaultCondition]) &&
          this.locationDistance == 50 &&
          this.cvLastUpdateInWeeksAgo == "24" &&
          this.sort_params == -1
        );
      };

      this.beforeStringify = function () {
        var _this = _.omit(this, "user");
        _this.conditions = _.chain(_this.conditions)
          .map(function (c) {
            return _.omit(c, "suggestions");
          })
          .value();
        return _this;
      };

      this.collectSearchParams = function (options) {
        var query = _.isString(this.basicQuery) ? this.basicQuery.replace(/\/|\;/g, " ") : undefined;
        return _.defaults(options || {}, {
          additional_pages: 0,
          saved_search_id: undefined,
          q: query,
          advanced: JSON.stringify(this.beforeStringify()).replace(/\/|\;/g, " "),
        });
      };

      this.getNumberOfConditions = async function () {
        var numCond = _.compact([
          this.locationSearchEnabled,
          this.jobOffersSearchEnabled,
          this.dateRangeEnabled,
          this.lastUpdateEnabled,
          this.salaryRangeEnabled,
          this.cvDateSearchEnabled,
          this.selectedTransactionBasis,
          this.includeGeoflex,
          this.entourageCandidatesSearchEnabled,
        ]).length;

        if (!_.isEmpty(this.priorities) && this.priorities.length != 4) numCond++;

        var defaultSelectorFields = await ResourceDependencyInjector.inject("defaultSelectorFields");

        var genericConditions = _.chain(this.conditions)
          .filter(function (condition) {
            if (_.isFunction(defaultSelectorFields[condition.field].isEmpty)) {
              return !defaultSelectorFields[condition.field].isEmpty(condition);
            } else {
              return condition.query && (condition.query.length > 0 || !_.isUndefined(condition.query.id));
            }
          })
          .size()
          .value();

        return numCond + genericConditions;
      }.bind(this);

      _.defaults(this, {
        serviceName: "SavedSearchesFactory",
        defaultCondition: SavedSearch.defaultCondition,
        locationDistance: 50,
        locationSearchEnabled: false,
        dateRangeEnabled: false,
        cvLastUpdateInWeeksAgo: "24",
        conditions: [],
        sort_params: -1,
        sendout: { show: false, range: {} },
        first_interview: { show: false, range: {} },
        second_interview: { show: false, range: {} },
        closing: { show: false, range: {} },
        deal: { show: false, range: {} },
        out: { show: false, range: {} },
      });

      if (!this.conditions.length) {
        this.addAdvancedSearchCondition();
      }
    }

    SavedSearch.getAll = function (type?) {
      if (savedSearchesSingleton.data && !(<any>SavedSearch).$invalidateCache) {
        return type ? _.where(savedSearchesSingleton.data, { resourceType: type }) : savedSearchesSingleton.data;
      } else {
        return (<any>SavedSearch).query().then(function (response) {
          savedSearchesSingleton.data = response;
          (<any>SavedSearch).$invalidateCache = false;
          return type ? _.where(savedSearchesSingleton.data, { resourceType: type }) : savedSearchesSingleton.data;
        });
      }
    };

    SavedSearch.invalidateCache = function () {
      (<any>SavedSearch).$invalidateCache = true;
    };

    SavedSearch.loadFromCache = function (id) {
      var cache = SavedSearch.getAll();
      var search = _.findWhere(cache, { id: parseInt(id) });
      if (search) {
        return search;
      } else {
        return (<any>SavedSearch).get(parseInt(id));
      }
    };

    SavedSearch.addToCache = function (savedSearch) {
      return SavedSearch.getAll().push(savedSearch);
    };

    RailsResource.extendTo(SavedSearch);
    (<any>SavedSearch).configure({
      url: "/saved_searches",
      name: "saved_search",
      idAttribute: "saved_search_id",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.exclude("user");
        this.exclude("defaultCondition");
        this.serializeWith("dateRangeStart", "DateSerializer");
        this.serializeWith("dateRangeEnd", "DateSerializer");
        this.serializeWith("salaryRangeMin", "MoneySerializer");
        this.serializeWith("salaryRangeMax", "MoneySerializer");
        this.serializeWith("conditions", "AdvancedSearchConditionSerializer");
      }),
    });

    SavedSearch.results = function (queryParams) {
      return this.$get(
        (<any>SavedSearch).$url(queryParams) + "/results",
        (<any>SavedSearch).getHttpConfig(queryParams).params
      );
    };

    SavedSearch.defaultCondition = {
      modifier: "must",
      field: "tags",
      query: "",
      languageId: null,
      languageLevel: null,
    };

    return SavedSearch;
  },
]);
