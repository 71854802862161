export default class ProjectPipelineCtrl {
  private lazyLoadingQueue: any[] = [];
  private attributes: any;
  private sessionUserId: number;
  private allUsers: any[];
  private pipelineOptions: any;
  private stateParams: any;
  private $transition$: any;
  private users: any[];
  private projectPipelineConfig: any;
  private loading: boolean;
  private teams: any[];

  static $inject = ["$q",
  "makeCollectionProxy",
  "CompaniesFactory",
  "ProjectsFactory",
  "ProjectReportingsFactory",
  "UsersFactory",
  "SavedSearchesFactory",
  "Session",
  "PrsmTableHelper",
  "PrsmTableAttributeService"];

  constructor (
    private $q: ng.IQService,
    private makeCollectionProxy: any,
    private Company: any,
    private Project: any,
    private ProjectReporting: any,
    private User: any,
    private SavedSearch: any,
    private Session: any,
    private PrsmTableHelper: any,
    private PrsmTableAttributeService: any,
  ) {
    this.pipelineQueryFn = this.pipelineQueryFn.bind(this);
    this.initializePipelineSearch = this.initializePipelineSearch.bind(this);
    this.initializeLazyLoading = this.initializeLazyLoading.bind(this);
    this.serializeCompanyGroup = this.serializeCompanyGroup.bind(this);
    this.serializeCompany = this.serializeCompany.bind(this);
    this.serializeProject = this.serializeProject.bind(this);
    this.longlistQueryFn = this.longlistQueryFn.bind(this);
    this.setKpiKey = this.setKpiKey.bind(this);

    this.sessionUserId = Session.user.id;
    this.attributes = {};
    this.allUsers = _.filter(User.getAll(), { isSelectable: true });
    this.pipelineOptions = {
      userQuery: { users: Session.user.usersOnWhiteboard },
    };
  }

  public $onInit () {
    this.stateParams = _.pick(this.$transition$.params(), _.identity);
    this.users = _.map(this.projectPipelineConfig.whiteboardEntries, (user) => {
      return new this.User(user);
    });
    this.Session.user.usersOnWhiteboard = this.users;
    _.each(this.users, (user) => this.initializePipelineSearch(user));
  };

  public serializeProject(project) {
    var project_kpis = _.chain(project.kpis)
      .mapObject( (key, value) => {
        return key?.value;
      })
      .value();
    project.kpis = project_kpis;
    return new this.Project(project);
  };

  public serializeCompany(company) {
    var company_kpis = _.chain(company.kpis)
      .mapObject( (key, value) => {
        return key?.value;
      })
      .value();

    company.kpis = company_kpis;
    company.projects = _.map(company.projects, this.serializeProject);
    return new this.Company(company);
  };

  public serializeCompanyGroup(cg) {
    var company_group_kpis = _.chain(cg.kpis)
      .mapObject( (key, value) => {
        return key?.value;
      })
      .pick("sendouts", "interviews", "closings", "deals")
      .value();

    cg.kpis = company_group_kpis;
    cg.companies = _.map(cg.companies, this.serializeCompany);
    return new this.Company(cg);
  };

  public pipelineQueryFn(params) {
    return this.ProjectReporting.$get("/project_reporting/project_pipeline", params);
  };

  public updatePipelineProjectReporting (nv, ov) {
    if (nv.users && nv.users.length > 0) {
      var old_users =
        ov.users ||
        _.filter(this.users, (user: any) => {
          return user.resourceType == "User";
        });
      var diff1 = _.difference(_.pluck(nv.users, "id"), _.pluck(old_users, "id"));
      var diff2 = _.difference(_.pluck(old_users, "id"), _.pluck(nv.users, "id"));

      _.each(diff1, (id) => {
        var add_user = _.findWhere(nv.users, { id: id });
        this.Session.user.usersOnWhiteboard.push(add_user);
        this.initializePipelineSearch(add_user);
      });

      _.each(diff2, (id) => {
        var remove_user = _.where(old_users, { id: id });
        var idx = _.findIndex(this.Session.user.usersOnWhiteboard, { id: id });
        if (idx > -1) {
          this.Session.user.usersOnWhiteboard.splice(idx, 1);
        }
      });
      this.Session.user.save();
    } else if (nv.team) {
      if (
        _.findIndex(this.Session.user.usersOnWhiteboard, (userOrTeam: any) => {
          return userOrTeam.resourceType != "User" && nv.team.id == userOrTeam.id;
        }) > -1
      ) {
        return;
      }

      this.Session.user.usersOnWhiteboard.unshift(nv.team);

      this.pipelineOptions.userQuery.users = _.filter(this.Session.user.usersOnWhiteboard, (user: any) => {
        return user.resourceType == "User";
      });

      this.initializePipelineSearch(nv.team);
    }
  };

  public setTableAttributes(userOrTeam) {
    var defaultAttributes = this.PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.ProjectReporting.whiteboardAttributes),
      [
        "researcher",
        "candidate",
        "processStatus",
        "longlist",
        "qualification",
        "shortlist",
        "sendout",
        "firstInterview",
        "secondInterview",
        "closing",
        "deal",
      ],
      ["candidate", "processStatus", "sendout", "firstInterview", "secondInterview", "closing", "deal"]
    );

    this.attributes[userOrTeam.id] = new this.PrsmTableAttributeService(
      "project-pipeline-table-userOrTeam-" + userOrTeam.id + "-attributes",
      defaultAttributes
    ).getAttributes();
  };

  public initializeLazyLoading(userOrTeam, params) {
    this.loading = true;
    var advancedQuery = new this.SavedSearch({
      defaultCondition: { modifier: "must", field: "company_name", query: "" },
      out: { show: true, range: {} },
      deal: { show: true, range: {} },
      kpiFilter: {},
    });

    advancedQuery.deleteAdvancedSearchCondition(0);

    var advancedPipelineSearchOptions: any = {
      initFn: this.initializePipelineSearch,
      advancedQuery: advancedQuery,
    };

    this.setTableAttributes(userOrTeam);

    advancedPipelineSearchOptions.user = userOrTeam;

    _.defaults(userOrTeam, {
      $attributes: this.attributes[userOrTeam.id],
      $advancedQuery: advancedQuery,
      $advancePipelinedSearchOptions: advancedPipelineSearchOptions,
    });

    userOrTeam.$pipelinesLazyLoading = true;

    params = _.extend(
      { advanced: JSON.stringify(userOrTeam.$advancedQuery.beforeStringify()) },
      _.omit(this.stateParams, "user_id"),
      params
    );

    this.makeCollectionProxy(this.pipelineQueryFn.bind(this.ProjectReporting), params).then((result) => {
      userOrTeam.$currentLonglistEntries = result;
      userOrTeam.companyGroups = _.map(result.meta.companyGroups, this.serializeCompanyGroup);

      userOrTeam.$pipelinesLazyLoading = false;
      this.loading = false;
    });
  };

  public userOrTeamParams(userOrTeam) {
    if (userOrTeam.resourceType == "User") {
      return { user_id: userOrTeam.id };
    } else {
      this.stateParams["teams[]"] = _.chain(this.stateParams["teams[]"]).compact().flatten().value();
      this.teams = this.User.pickTranslatedTeams(this.stateParams["teams[]"]);

      return { "teams[]": [userOrTeam.id] };
    }
  };

  public initializePipelineSearch(userOrTeam) {
    this.initializeLazyLoading(userOrTeam, this.userOrTeamParams(userOrTeam));
  };

  public removeUser (user) {
    var idx = _.findIndex(this.Session.user.usersOnWhiteboard, { id: user.id });
    if (idx > -1) {
      this.Session.user.usersOnWhiteboard.splice(idx, 1);

      this.Session.user.save();
    }
  };

  public setKpiKey (key, userOrTeam) {
    userOrTeam.$advancedQuery.kpiFilter[key] = !userOrTeam.$advancedQuery.kpiFilter[key];
    this.initializePipelineSearch(userOrTeam);
  };

  public longlistQueryFn(params) {
    return this.ProjectReporting.$get("/project_reporting/pipeline_longlist", params);
  }

  public lazyLoadChunk(projectsChunk, userOrTeam) {
    this.$q.all(
      _.map(projectsChunk, (project) => {
        return this.loadProjectList(project, userOrTeam);
      })
    ).then( () => {
      if (this.lazyLoadingQueue.length > 0) {
        this.lazyLoadChunk(this.lazyLoadingQueue.pop(), userOrTeam);
      }
    });
  }

  public loadAllCompanyLonglists (projects, userOrTeam, isExtended) {
    if (isExtended) {
      this.lazyLoadingQueue = _.union(_.chunk(projects, 5), this.lazyLoadingQueue);
      this.lazyLoadChunk(this.lazyLoadingQueue.pop(), userOrTeam);
    }
  };

  public loadProjectList (project, userOrTeam) {
    if (project.kpis.sendout <= 0) {
      return;
    }
    project.$pipelinesLazyLoading = true;

    var advancedQuery = new this.SavedSearch({
      defaultCondition: { modifier: "must", field: "company_name", query: "" },
      conditions: [{ modifier: "must", field: "project_title", query: project.name }],
      out: { show: true, range: {} },
      deal: { show: false, range: {} },
      kpiFilter: {},
    });

    var params = _.extend(
      { advanced: JSON.stringify(advancedQuery.beforeStringify()) },
      _.omit(this.stateParams, "user_id"),
      this.userOrTeamParams(userOrTeam)
    );
    return this.makeCollectionProxy(this.longlistQueryFn.bind(this.ProjectReporting), params).then( (result) => {
      project.$longlistEntries = result;
      project.$pipelinesLazyLoading = false;
    });
  };
}
