import { ProjectsTableBodyControllerCtrl } from "./projects-table-body.controller";
import projectsTableBodyTemplate from "./projects-table-body.template.html";

angular.module("projects.components").component("projectsTableBody", {
  bindings: {
    selectedProjects: "=data",
    attributes: "=",
    activeRow: "<",
    context: "=",
    statePrefix: "@",
    tableRowIdentifier: "=",
    initialViewState: "<",
  },
  template: projectsTableBodyTemplate,
  controller: ProjectsTableBodyControllerCtrl,
});
