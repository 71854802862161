import AdvancedCandidateSearchCtrl from "./advanced-candidate-search.controller";
import advancedCandidateSearchTemplate from "./advanced-candidate-search.template.html";

angular.module("candidates.components").component("advancedCandidateSearch", {
  bindings: {
    advancedQuery: "=",
    query: "=",
    remote: "=",
    setData: "&",
    closing: "&",
  },
  template: advancedCandidateSearchTemplate,
  controller: AdvancedCandidateSearchCtrl,
});
