import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { Department } from "./departments.service";

angular
  .module("departments")
  .factory("DepartmentsSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(Department, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("DepartmentsFactory", Department);
