//= require ./values.module.js

angular.module("values").value("sidebarStates", {
  showAside01: false,
  showAside02: false,
  showAside03: false,
  showAside04: false,
  deckPrimary: false,
  deckSub: false,
  deckSecondary: false,
});
