//= require ./application.js

angular.module("app").controller("RootViewCtrl", [
  "SidebarService",
  function (SidebarService) {
    var _this = this;

    _this.$onInit = function () {
      _this.state = { name: "root.home" };
      _this.expandSidebarStates = SidebarService.getSidebarStates();
    };
  },
]);
