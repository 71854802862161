import UiTinymceMegaCtrl from "./ui-tinymce-mega.controller";
import uiTinymceMegaTemplate from "./ui-tinymce-mega.template.html";

angular.module("directives.tinymce").component("uiTinymceMega", {
  require: { ngModelCtrl: "ngModel" },
  bindings: {
    uiTinymceMegaId: "@?",
    uiTinymceMinLength: "@",
    ngModel: "=",
    disabled: "<",
  },
  template: uiTinymceMegaTemplate,
  controller: UiTinymceMegaCtrl,
});
