import { IFilterService } from "angular";

export default class ContactDetailsCtrl {
  public object: any;
  public showContact: boolean | null;
  public contactActionTypes: {
    email: string,
    telephone: string
  };
  public callback: () => void;
  
  static $inject = ["$filter", "UsersFactory"];

  constructor (
    private $filter: IFilterService,
    private User: any
  ) {
    this.showContact = null;

    this.contactActionTypes = {
      email: "e-mail",
      telephone: "phone-call",
    };
  }

  public triggerAction(actionType) {
    if (this.callback) {
      this.callback();
    } else {
      this.object.createNewAction(actionType);
    }
  };

  public click2Call(actionTypeName, number) {
    this.User.$get(this.User.$url("click2Call"), { destination: number }).then(
      (res) => {
        this.triggerAction(actionTypeName);
      },
      (result) => {
        switch (result.status) {
          case 500:
            window.alert(this.$filter("translate")("CONNECTION_ERROR"));
            break;
          case 400:
            window.alert(this.$filter("translate")("TELEPHONE_END_POINT_WRONG_NOTICE"));
            break;
        }
      }
    );
  };
};