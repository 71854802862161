export default class CompanyContactModalCtrl {
  company: any;
  resolve: {
    company: any
  };
  dismiss: (status: string) => () => void;

  constructor() {}

 public $onInit() {
    this.company = this.resolve.company;
  };

  public cancel() {
    this.dismiss("cancel");
  };

};