angular.module("projects", [
  "projects.components",
  "projects.modals",
  "projects.views",
  "companies.components",
  "rails",
  "ui.router",
  "users",
  "languages",
]);
