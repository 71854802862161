import ContactModalCtrl from "./contact-modal.controller";
import contactModalTemplate from "./contact-modal.template.html";

angular.module("persons.modals").component("contactModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: contactModalTemplate,
  controller: ContactModalCtrl,
});
