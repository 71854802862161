import DocumentsDeckViewCtrl from "./documents-deck-view.controller";
import documentsDeckViewTemplate from "./documents-deck-view.template.html";

angular.module("documents.components").component("documentsDeckView", {
  bindings: {
    documents: "<",
    openDocument: "=",
    openDocumentId: "<",
  },
  template: documentsDeckViewTemplate,
  controller: DocumentsDeckViewCtrl,
});
