export default class DashboardGaugeChartCtrl {
  public data: number[];
  public datasetOverride: {
    value: number,
    minValue: number,
    data: number[],
    backgroundColor: string[],
  };
  public percentage: number;
  public colors: string[];
  public options: {
    responsive: boolean,
    aspectRatio: number,
    maintainAspectRatio: boolean,
    layout: {
      padding: {
        left: number,
        right: number,
        top: number,
        bottom: number,
      },
    },
    title: {
      display: boolean,
      text: string,
    },
    legend: {
      display: boolean,
    },
  };

  static $inject = ["ChartColors2021"];

  constructor(private ChartColors: any) {}

  public $onInit() {
    this.data = [25, 50, 75, 100];
    this.datasetOverride = {
      value: _.max([_.min([this.percentage, 100]), 0]),
      minValue: 0,
      data: this.data,
      backgroundColor: this.ChartColors,
    };

    var defaultOptions = {
      needle: {
        radiusPercentage: 2,
        widthPercentage: 3.2,
        lengthPercentage: 80,
        color: "rgba(0, 0, 0, 1)", // black
      },
      valueLabel: {
        display: false,
        //formatter: function(value, context) { return Math.round(value) + ' %'; }
      },
    };

    this.colors = this.ChartColors;
    this.options = _.extend(defaultOptions, {
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      title: {
        display: true,
        text: _.max([0, Math.round(this.percentage)]) + " %",
      },
      legend: {
        display: false,
      },
    });
  };

  public isEmpty() {
    return !_.isNumber(this.percentage);
  };
};