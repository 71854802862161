//= require ../textkernel.module.js

import { ActionType } from "../../action_types/services/action_types.service";

angular.module("textkernel").factory("Textkernel", [
  "TextkernelModalMixin",
  "ProjectsFactory",
  "CandidatesFactory",
  "UsersFactory",
  "ActionsFactory",
  "$q",
  "Session",
  function (TextkernelModal, Project, Candidate, User, Action, $q, Session) {
    var trustedOrigins = ["https://staging.textkernel.de", "https://sso.textkernel.de", "https://home.textkernel.de"];

    function addTrackingPoint(name) {
      (<any>User).$post(User.$url("tk_tracking_point.json"), { name: name });
    }

    async function addHistoryPoint(actionType, object) {
      let actionTypes: ActionType[] = await ActionType.getAll();
      var actionTypeObj = _.findWhere(actionTypes, { typeName: actionType });

      if (actionType && object) {
        var attributes = {
          actionable_type: object.resourceType,
          actionable_id: object.id,
          action_type: actionTypeObj,
          user: Session.user,
        };

        new Action(attributes).saveCustomNote(object);
      }
    }

    function addJobToLonglist(data) {
      var promises;
      var fromCandidate;

      if (data.hasOwnProperty("jobs") && data.hasOwnProperty("candidateId")) {
        data.jobs = decodeURIComponent(data.jobs).split(",");
        fromCandidate = true;

        promises = _.map(data.jobs, function (jobId) {
          return new Project({ id: jobId }).addCandidate(new Candidate({ id: data.candidateId }));
        });
      }

      if (data.hasOwnProperty("candidates") && data.hasOwnProperty("jobId")) {
        data.candidates = decodeURIComponent(data.candidates).split(",");

        promises = _.map(data.candidates, function (candidateId) {
          return new Project({ id: data.jobId }).addCandidate(new Candidate({ id: candidateId }));
        });
      }

      promises = _.map(promises, function (promise) {
        return promise.catch(function (res) {
          return res;
        });
      });

      new Project().textkernelLonglistConfirm(promises, fromCandidate);
    }

    function addJob(data) {
      if (data.hasOwnProperty("jobs") && data.hasOwnProperty("candidateId")) {
        data.jobs = decodeURIComponent(data.jobs).split(",");

        var projects = [];

        var promises = _.map(data.jobs, function (tkJobId) {
          return (<any>Project).$post(Project.$url("tk_create.json"), { tk_id: tkJobId }).then(function (project) {
            projects.push(project);
            return project.addCandidate(new Candidate({ id: data.candidateId }));
          });
        });

        new Project().textkernelImportFinished(projects, promises);
      }
    }

    function getSearchUrl(object) {
      return object.$url("/textkernel_search");
    }

    function receiveMessage(event) {
      // Do we trust the sender of this message?  (might be
      // different from what we originally opened, for example).

      if (_.indexOf(trustedOrigins, event.originalEvent.origin) < 0) return;

      var data = _.chain(event.originalEvent.data.split("&"))
        .map(function (item) {
          if (item) {
            return item.split("=");
          }
        })
        .compact()
        .object()
        .value();

      switch (data.action) {
        case "TK_AddToLonglist":
          addJobToLonglist(data);
          break;
        case "TK_AddJob":
          addJob(data);
          break;
      }
    }

    return {
      receiveMessage: receiveMessage,
      getSearchUrl: getSearchUrl,
      addTrackingPoint: addTrackingPoint,
      addHistoryPoint: addHistoryPoint,
    };
  },
]);
