//= require ../expert_profiles.module.js

angular.module("expert_profiles").service("ExpertProfileFactory", [
  "RailsResource",
  "RailsResourceSnapshotsMixin",
  "ModalMixin",
  "railsSerializer",
  "$state",
  "$http",
  "ExpertProfilesModalMixin",
  function (
    RailsResource,
    RailsResourceSnapshotsMixin,
    ModalMixin,
    railsSerializer,
    $state,
    $http,
    ExpertProfilesModalMixin
  ) {
    function ExpertProfile() {
      (<any>ExpertProfile).__super__.constructor.apply(this, arguments);

      this.params = function () {
        return {
          project_id: this.project.id,
          expert_profile_id: this.id,
          cpjoin_id: this.candidatesProjectsId || this.cpjoin.id,
          candidate_id: this.candidate.id,
        };
      };

      this.getShareLink = function (employee_id) {
        return $http.get("/expert_profile/" + this.id + "/url_with_authtoken", {
          params: { employee_id: employee_id },
        });
      };

      this.createMessage = function (actionRoute, emailParams) {
        var params = angular.merge({ id: this.id }, emailParams);
        return $http.post("expert_profile/" + this.id + "/" + actionRoute + ".json", { expert_profile: params });
      };

      this.sendDocument = function (actionRoute, emailParams) {
        return $http.post("expert_profile/" + this.id + "/" + actionRoute + ".json", emailParams);
      }.bind(this);

      this.name = function () {
        if (!this.project) {
          (<any>ExpertProfile).handleCorruptedProfile(this);
        }
        return (
          this.title ||
          this.jobTitle ||
          (this.project && this.project.title) ||
          (this.candidate && "Profil - " + this.candidate.fullNameWithTitle)
        );
      }.bind(this);

      this.copyTo = function (ep) {
        _.extend(
          ep,
          _.chain(this)
            .pick(
              "education",
              "coreCompetencies",
              "skills",
              "workExperience",
              "placeOfResidence",
              "nationality",
              "comment",
              "attachedDocuments",
              "wantedSalary",
              "firstInterviewDate",
              "jobStartDate"
            )
            .mapObject(function (val, key) {
              if (_.isString(val)) {
                return val.replace(/\\"/g, '"');
              } else {
                return val;
              }
            })
            .value()
        );
      };

      this.isInitiativeProfile = function () {
        return this.type == "InitiativeExpertProfile";
      };

      this.needsCandidatesAgreement = function () {
        return !(
          this.anonymize ||
          this.agreementSigned ||
          this.candidate.isEntourageEmployee ||
          this.project.isInternalProject
        );
      };

      _.defaults(this, {
        serviceName: "ExpertProfileFactory",
        document: undefined,
        workExperience: undefined,
        comment: undefined,
        coreCompetencies: undefined,
        createdAt: undefined,
        firstInterviewDate: undefined,
        id: undefined,
        jobStartDate: undefined,
        jobTitle: undefined,
        wantedSalary: undefined,
        skills: undefined,
        attachedDocuments: [],
        anonymize: false,
      });
    }

    ExpertProfile.handleCorruptedProfile = _.throttle(function (profiles, item) {
      window.rollbar.error("Corrupted expert profile detected", { item: item, expert_profiles: profiles });
    }, 2000);

    RailsResource.extendTo(ExpertProfile);
    (<any>ExpertProfile).configure({
      url: "/expert_profile/{{id}}",
      name: "expert_profile",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.exclude("$snapshots");

        this.rename("$sendouts", "sendouts");
        this.resource("$sendouts", "SendoutsFactory");

        this.resource("attachedDocuments", "DocumentsFactory");
        this.resource("candidate", "CandidatesFactory");
        this.resource("project", "ProjectsFactory");
        this.resource("employee", "EmployeesFactory");
      }),
    });
    (<any>ExpertProfile).extend(RailsResourceSnapshotsMixin);
    (<any>ExpertProfile).extend(ExpertProfilesModalMixin);
    (<any>ExpertProfile).extend(ModalMixin);
    return ExpertProfile;
  },
]);
