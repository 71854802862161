import { IFilterService, IRootScopeService } from 'angular';
export default class DashboardAmountCoveredConsultants2021CardCtrl {
  public loading: boolean;
  public canceler: ng.IDeferred<any>;
  public labelNames: string[];
  public showSum: boolean;
  public labels: Array<{ color: string, name: string }>;
  public consultantsData: any[];
  public params: any;
  public loadingError: any;
  private deregisterDashboardQueryChanged: () => void;
  private deregisterDashboardQueryTimeout: () => void;

  static $inject = ["$rootScope", "$q", "$filter", "$translate", "ProjectReportingsFactory", "ChartColors2021"]

  constructor(
    private $rootScope: IRootScopeService,
    private $q: ng.IQService,
    private $filter: IFilterService,
    private $translate: angular.translate.ITranslateService,
    private ProjectReporting: any,
    private ChartColors: string[] = [],
  ) {
    this.canceler = this.$q.defer();
    this.labelNames = [
      this.$filter("translate")("CANDIDATES_LEAD"),
      this.$filter("translate")("CLIENT_LEAD"),
      this.$filter("translate")("CANDIDATES_PROCESS"),
      this.$filter("translate")("OVERALL_PROCESS"),
    ];

    this.deregisterDashboardQueryChanged = this.$rootScope.$on("dashboard-query-changed", this.updateCard.bind(this));
    this.deregisterDashboardQueryTimeout = this.$rootScope.$on("dashboard-query-timeout", () => {
      this.canceler.resolve();
    });
  }

  public $onInit() {
    this.showSum = true;

    this.labels = _.map(this.labelNames, (value, key) => {
      return { color: this.ChartColors[key], name: value };
    });
  };

  public orderedConsultantsData(consultantsData) {
    return _.chain(consultantsData)
      .each((consultantData: any) => {
        consultantData.deals.sumProvisions = _.reduce(
          consultantData.deals,
          (memo, provision: any) => {
            return memo + provision.cents || 0;
          },
          0
        );
      })
      .sortBy((consultantData: any) => {
        return consultantData.deals.sumProvisions * -1;
      })
      .value();
  };

  public consultantsRequest (params) {
    if (this.canceler) this.canceler.resolve();

    this.canceler = this.$q.defer();

    return this.ProjectReporting.$http({
      url: "project_reporting/consultants",
      params: params,
      headers: { Accept: "application/json" },
      timeout: this.canceler.promise,
    }).then(
      (result) => {
        this.consultantsData = this.orderedConsultantsData(result.consultants);
      },
      (result) => {
        this.loading = false;
        if (result.status === 0) return;

        window.rollbar.error("Dashboard-Card loading error", { params: _.extend(this.params, result.config) });
        this.loadingError = this.$filter("translate")("LOADING_ERROR_NOTICE");
      }
    );
  };

  public updateCard(event, params) {
    this.params = params;

    this.loading = true;
    this.loadingError = undefined;
    this.consultantsRequest(angular.copy(params)).then(() => {
      this.loading = false;
    });
  }

  public reload() {
    this.updateCard(undefined, this.params);
  };

  public $onDestroy() {
    this.deregisterDashboardQueryChanged();
    this.deregisterDashboardQueryTimeout();
  };
};