import { Language } from "../../../languages/services/languages.service";

export class PitchModalCtrl {
  public user: any;
  public project: any;
  public employee: any;
  public documents: any[];
  public languages: { key: string; name: string }[];
  public availableManagerContactEntries: any[];
  public resolve: {
    managers: any[];
    project: any;
  };
  public pitchEmail: {
    employee: any;
    callback: null;
    locale: string | undefined;
    document: any;
  };
  public managerContactEntries: any[];
  public modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = ["$filter", "Session"];
  constructor(private $filter: ng.IFilterService, private Session: any) {}

  public $onInit() {
    this.user = this.Session.user;
    this.managerContactEntries = _.each(this.resolve.managers, (manager: any) => {
      manager.$hasContactAddress = !_.isEmpty(manager.workEmail || manager.privateEmail);
    });
    this.availableManagerContactEntries = _.where(this.managerContactEntries, { $hasContactAddress: true });
    this.languages = Language.defaultOptions;
    this.employee = this.availableManagerContactEntries[0];
    this.project = this.resolve.project;
    this.documents = this.project.documents;

    this.pitchEmail = {
      employee: _.first(this.employee),
      callback: null,
      locale: _.first(this.languages)?.key,
      document: _.first(this.documents),
    };
  }

  public cancel() {
    this.modalInstance.dismiss("cancel");
  }

  public save() {
    if (_.isUndefined(this.pitchEmail.employee)) {
      window.alert(this.$filter("translate")("SELECT_MANAGER_NOTICE"));
    } else {
      this.pitchEmail.callback = this.project.createMessage("create_pitch_email", this.pitchEmail);
      this.modalInstance.close(this.pitchEmail);
    }
  }
}
