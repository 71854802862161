import WhiteboardPresenterCtrl from "./whiteboard_presenter.controller";
import whiteboardPresenterTemplate from "./whiteboard_presenter.template.html";

angular.module("project_reporting.views").component("whiteboardPresenter", {
  bindings: {
    $transition$: "<",
  },
  template: whiteboardPresenterTemplate,
  controller: WhiteboardPresenterCtrl,
});
