export default class DashboardDeals2021CardCtrl {
  public loading: boolean;
  public data: {
    fullTimeEquivalent: number,
    kpis: {
      deals: number[],
      kpiIST: {
        amountCoveredPerDeal: {cents: number, currency: any[]},
        callingtimePerDeal: number,
        deals: number,
        dealsPerMonth: number,
        interviewsPerDeal: number,
        sendoutDealRatio: number,
        sendoutInterviewRatio: number  
      }
    }
  };
  public readonly projectReportingRequestObj: any;
  public labels: string[];

  static $inject = ["ProjectReportingRequestObj"];

  constructor(private ProjectReportingRequestObj: any)
  {
    this.loading = true;

    this.projectReportingRequestObj = new this.ProjectReportingRequestObj(
      this,
      "dashboardDeals2021Card",
      (result) => {
        this.data = angular.copy(result);
        this.data.kpis.deals = _.values(result.kpis.deals);
        this.labels = _.keys(result.kpis.deals);
      }
    );  
  }

  public isEmpty(obj) {
    return !_.chain(obj)
      .flatten()
      .any((value) => {
        return value > 0;
      })
      .value();
  };

  public $onDestroy() {
    this.projectReportingRequestObj.deregisterEventListeners();
  };
};