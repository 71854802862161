import ChooseInfoPresentationModalCtrl from "./choose-info-presentation-modal.controller";
import chooseInfoPresentationModalTemplate from "./choose-info-presentation-modal.template.html";

angular.module("persons.modals").component("chooseInfoPresentation", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: chooseInfoPresentationModalTemplate,
  controller: ChooseInfoPresentationModalCtrl,
});
