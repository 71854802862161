import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { Highschool } from "./highschools.factory";

angular
  .module("highschools")
  .factory("HighschoolsSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(Highschool, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("HighschoolsFactory", Highschool);
