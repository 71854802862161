//= require ./services.module.js

angular.module("services").service("MgPaginateService", [
  function () {
    var defaults = {
      itemsPerPage: 5,
      currentPage: 1,
      data: [],
      totalItems: 0,
    };

    function MgPaginate() {
      var dataSet: any = _.clone(defaults);
      var range: any = {};

      function updateRange() {
        range = {
          upper: Math.min(dataSet.itemsPerPage * dataSet.currentPage, dataSet.data?.length),
          lower: (dataSet.currentPage - 1) * dataSet.itemsPerPage + 1,
          total: dataSet.totalItems,
        };
      }

      this.onChange = function () {
        updateRange();
      };

      this.currentPageChanged = function () {
        if (_.isFunction(dataSet.data.isMoreAvailable)) {
          // data is collection proxy

          if (this.getData().isLoading()) {
            dataSet.currentPage = dataSet.currentPage - 1;
            return;
          }

          // if last page, load next pages
          if (this.isLastPage() && !this.getData().isLoading() && this.getData().isMoreAvailable()) {
            this.getData().getMore().then(updateRange);
          } else {
            updateRange();
          }
        } else {
          // data is simple array
          updateRange();
        }
      }.bind(this);

      this.setTotalItems = function (totalItems) {
        dataSet.totalItems = totalItems;
        updateRange();
      };

      this.setCurrentPage = function (currentPage) {
        dataSet.currentPage = currentPage;
        updateRange();
      };

      this.setItemsPerPage = function (itemsPerPage) {
        dataSet.itemsPerPage = itemsPerPage;
        updateRange();
      };

      this.getItemsPerPage = function () {
        return dataSet.itemsPerPage;
      };

      this.setData = function (data) {
        dataSet.data = data;
        updateRange();
      };

      this.getData = function () {
        return dataSet.data;
      };

      this.getRange = function () {
        return range;
      };

      this.getDataSet = function () {
        return dataSet;
      };

      this.dataSetEmpty = function () {
        return range.total == 0;
      };

      this.hidePagination = function () {
        return range.total <= dataSet.itemsPerPage;
      };

      this.isLastPage = function () {
        return dataSet.currentPage >= Math.floor(dataSet.data.length / dataSet.itemsPerPage);
      };
    }

    return MgPaginate;
  },
]);
