import { ActionType } from "./action_types.service";

angular
  .module("action_types")
  .factory("ActionTypeSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = function (data) {
          return _.isArray(data) ? data.map((element) => new ActionType(element)) : new ActionType(data);
        };
        this.serialize = function (data) {
          return data;
        };
      });
    },
  ])
  .service("ActionTypesFactory", ActionType);
