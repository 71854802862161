import { FileUploader, FileUploaderFactory } from "angular-file-upload";

export default class ExternalCvUploaderCtrl {
  private uploader: FileUploader;
  private translation: any;
  private documents: any[];
  private resource: any;
  
  static $inject = ["FileUploader"];

  constructor (private FileUploader: FileUploaderFactory) {
    this.translation = _.extend(
      {
        heading: "Dokumente",
        choose_file: "Datei auswählen",
        or_drag_drop: "oder hier reinziehen.",
        file_type: "nur PDF",
      },
      this.translation
    );

    this.uploader.filters.push({
      name: "fileExtensionFilter",
      fn: (item) => {
        return /\/(pdf)$/.test(item.type);
      },
    });
  }

  public remove (item) {
    if (item.$clone) {
      item.$clone.remove();
    }

    item.remove();
  };

  public initializeUploader() {
    this.uploader = new this.FileUploader({
      autoUpload: false,
    });
    this.uploader.onSuccessItem = (item: any, response: any) => {
      var updatedResource = new this[response.data.type](response.data);
      this.documents = updatedResource.documents;

      if (this.resource) {
        this.resource.documents = this.documents;
      }
    };
    this.uploader.onAfterAddingFile =  (item: any) => {
      var $clone = angular
        .element("#uploadDialog")
        .clone()
        .appendTo("#fileInputList")
        .attr("name", "externalCvs[]");

      item.$clone = $clone;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => {
      // window.alert("Leider trat beim Speichern ein Fehler auf. Probieren Sie es bitte ein weiteres mal. Sollte dieser Fehler öfter auftreten, wenden Sie sich bitte an die Administratoren.");
      window.rollbar.error("External CV document upload failed", {
        status: status,
        file_info: item.file,
        url: item.url,
      });
    };
  }

  public $onInit () {
    this.initializeUploader();
  };
}

