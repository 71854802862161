import paginationTemplate from "../../../../directives/components/pagination/pagination.template.html";
import { IRootScopeService, ITimeoutService, ITemplateCacheService } from 'angular'; 
export default class FollowUpsCtrl {
  private object: any;
  private actionsPage: any;
  private identifier: string;
  private oldActionList: any[] = [];
  private paginationService: any;
  private paginationTemplateUrl: string = 'paginationTemplate';
  private paginationId: string;
  private dereg: () => void;
  private followUps: boolean = true;
  private advancedQuery: any;
  private advancedActionsSearchOptions: any;
  private actionAttributes: any;

  static $inject = ["ActionsFactory", "SavedSearchesFactory", "$rootScope", "$timeout", "PrsmTableHelper", "dynamicRoute", "MgPaginateService", "$templateCache"];

  constructor(
    private Action: any,
    private SavedSearch: any,
    private $rootScope: IRootScopeService,
    private $timeout: ITimeoutService,
    private PrsmTableHelper: any,
    private dynamicRoute: any,
    private MgPaginateService: any,
    private $templateCache: ITemplateCacheService,
    private id: any = _.uniqueId()
  ){
    this.paginationTemplateUrl = "paginationTemplate";
    $templateCache.put(this.paginationTemplateUrl, paginationTemplate);

    angular.extend(this, dynamicRoute);

    this.paginationId = "pagination-id-" + this.id;

    this.paginationService = new this.MgPaginateService();

    this.advancedQuery = new SavedSearch({
      defaultCondition: { modifier: "must", field: "action_type", query: "" },
      systemDisabled: false,
    });
    this.advancedQuery.deleteAdvancedSearchCondition(0);

    this.advancedActionsSearchOptions = {
      setData: this.initializeActionsSearch.bind(this),
      advancedQuery: this.advancedQuery,
      followUps: this.followUps,
    };

    this.actionAttributes = PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.Action.defaultTableAttributes),
      [
        "id",
        "type",
        "responsibleUser",
        "consultant",
        "researcher",
        "project",
        "deal",
        "amountCovered",
        "status",
        "countableDate",
        "candidate",
        "company",
        "employees",
        "description",
      ],
      ["type", "countableDate", "status", "project", "candidate", "company", "employees", "description"]
    );
  }

  public $doCheck () {
    if (!this.object) return;
    if (!_.isFunction(this.object.followUps)) return;

    var actionList = this.getActions();
    if (actionList && !angular.equals(actionList, this.oldActionList)) {
      if (actionList.meta) {
        this.actionsPage = this.getActions();
        this.paginationService.setData(this.actionsPage);
        this.paginationService.setTotalItems(this.actionsPage.meta.totalCount);
      }

      if (actionList.length !== this.oldActionList.length) {
        this.$rootScope.$broadcast("action", this.object);
      }

      this.oldActionList = this.getActions();
    }
  };

  public getActions () {
    if (_.isFunction(this.object.followUps)) {
      return this.object.followUps();
    }
  };

  public createAction (options) {
    options = options || {};
    _.defaults(options, { actionTypeEditable: true });
    this.Action.createNewAction(this.object, undefined, options).catch(angular.noop);
  };

  public initializeActionsSearch () {
    if (this.dereg) {
      this.dereg();
    }
    this.dereg = this.$rootScope.$on("FollowUps_" + this.identifier + this.object.id, (event: any) => {
      this.$timeout(() => {
        this.Action.lazyLoadingActions.bind(this)(this.object, this.advancedQuery);
      }, 1);
    });

    this.dereg = this.$rootScope.$on(this.identifier + this.object.id, (event: any) => {
      this.$timeout(() => {
        this.Action.lazyLoadingActions.bind(this)(this.object, this.advancedQuery);
      }, 1);
    });

    this.$rootScope.$broadcast("FollowUps_" + this.identifier + this.object.id);
  };

  public initializePagination () {
    this.advancedQuery.followUps = this.followUps;
    this.initializeActionsSearch();
  };

  public $postLink () {
    this.followUps = true;
    this.initializePagination();
  };

  public $onDestroy () {
    this.dereg();
  };
}