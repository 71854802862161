import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { JobType } from "./job_types.service";

angular
  .module("job_types")
  .factory("JobTypesSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(JobType, data);
        this.serialize = serialize;
      });
    },
  ])

  .service("JobTypesFactory", JobType);
