import { Transition } from "@uirouter/angularjs"
import { IFilterService } from "angular";

export default class EmployeesIndexCtrl {
  public expandUserSidebar: string;
  public attributes: any;
  public employeesLazyLoading: boolean;
  public employees: any[];
  private $transition$: Transition;
  
  static $inject = [
    "makeCollectionProxy",
    "PrsmTableAttributeService",
    "EmployeesFactory",
    "SavedSearchesFactory",
    "$cookies",
    "$rootScope",
    "$filter"
  ];

  constructor (
    private makeCollectionProxy: any,
    private PrsmTableAttributeService: any,
    private Employee: any,
    private SavedSearch: any,
    private $cookies: angular.cookies.ICookiesService,
    private $rootScope: IExtendedRootScopeService,
    private $filter: IFilterService,
  ) {
    this.expandUserSidebar = this.$cookies.get("expandUserSidebar");
  }

  public $onInit() {
    var params = this.$transition$.params();

    this.$rootScope.setPageTitle(this.$filter("translate")("MANAGER"));

    this.attributes = new this.PrsmTableAttributeService(
      "employees-index-table-attributes",
      _.omit(this.Employee.defaultTableAttributes, "sales", "researcher", "processStatus")
    ).getAttributes();

    this.employeesLazyLoading = true;
    var loader;
    if (params.saved_search_id) {
      loader = this.SavedSearch.results.bind(this.Employee);
    } else {
      loader = this.Employee.query.bind(this.Employee);
    }

    this.makeCollectionProxy(loader, params)
      .then((employees) => {
        this.employees = employees;
      })
      .then(() => {
        this.employeesLazyLoading = false;
      });
  };
};