import OpenSkillmatrixEditCtrl from "./open-skillmatrix-edit-modal.controller";
import openSkillmatrixEditModalTemplate from "./open-skillmatrix-edit-modal.template.html";

angular.module("persons.modals").component("openSkillmatrixEdit", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: openSkillmatrixEditModalTemplate,
  controller: OpenSkillmatrixEditCtrl,
});
