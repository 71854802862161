//= require ../dashboards.module.js

angular.module("dashboards").service("DashboardsFactory", [
  "RailsResource",
  "railsSerializer",
  function (RailsResource, railsSerializer) {
    function Dashboard() {
      (<any>Dashboard).__super__.constructor.apply(this, arguments);
    }

    RailsResource.extendTo(Dashboard);
    (<any>Dashboard).configure({
      url: "/dashboard",
      name: "dashboard",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.nestedAttribute("candidates");
        this.resource("candidates", "CandidatesFactory");
      }),
    });

    return Dashboard;
  },
]);
