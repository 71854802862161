export class SearchToQuicklistButtonCtrl {
  static $inject = ["$rootScope", "$filter", "QuicklistsFactory", "poller"];

  constructor(
    private $rootScope: IExtendedRootScopeService,
    private $filter: ng.IFilterService,
    private QuicklistService: any,
    private poller: any
  ) {}

  public creatingQuicklist: boolean;
  public advancedQuery: any;

  public createQuicklist() {
    if (!this.creatingQuicklist) {
      var savedSearch = angular.copy(this.advancedQuery);
      savedSearch.system = true;

      this.creatingQuicklist = true;
      savedSearch.save().then(
        (result: any) => {
          var quicklistName = window.prompt(this.$filter("translate")("NAME_QUICKLIST"), result.name);
          if (quicklistName !== null) {
            new this.QuicklistService({ name: quicklistName, saved_search_id: result.id })
              .create()
              .then((resultList: any) => {
                var pollingService = this.poller.get(resultList.$url() + ".json", {
                  catchError: true,
                  delay: 1000,
                });

                pollingService.promise.then(null, null, (response: any) => {
                  if (response.status === 200) {
                    var quicklist = new this.QuicklistService(response.data.data);
                    if (!quicklist.generating) {
                      pollingService.remove();

                      this.creatingQuicklist = false;

                      this.QuicklistService.clearCache();
                      this.$rootScope.$broadcast("updatedQuicklist");
                    }
                  } else {
                    pollingService.remove();
                  }
                });
              });
          } else {
            this.creatingQuicklist = false;
          }
        },
        (res: any) => {
          this.creatingQuicklist = false;
        }
      );
    }
  }
}
