//= require ./services.module.js
angular
  .module("services")

  .factory("DateSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = function (data) {
          return data ? new Date(data) : null;
        };
        this.serialize = function (data) {
          return data ? data.toString() : null;
        };
      });
    },
  ]);
