import teamSelectTemplate from "./team-select.template.html";
import TeamSelectCtrl from "./team-select.controller";

angular.module("users.components").component("teamSelect", {
  bindings: {
    parentOptions: "=ngModel",
    required: "=",
    deactivateAllActive: "@",
    onChanges: "&",
  },
  template: teamSelectTemplate,
  controller: TeamSelectCtrl,
});
