import WhiteboardsTableBodyCtrl from "./whiteboard-table-body.controller";
import whiteboardTableBodyTemplate from "./whiteboard-table-body.template.html";
angular.module("project_reporting.components").component("whiteboardTableBody", {
  bindings: {
    currentLonglistEntries: "=data",
    attributes: "=",
    activeRow: "=",
    statePrefix: "@",
    tableRowIdentifier: "=",
  },
  template: whiteboardTableBodyTemplate,
  controller: WhiteboardsTableBodyCtrl,
});
