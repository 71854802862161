//= require ./services.module.js
angular
  .module("services")

  .factory("SanitizeSerializer", [
    "railsSerializer",
    "$sce",
    "$sceDelegate",
    function (railsSerializer, $sce, $sceDelegate) {
      return railsSerializer(function () {
        this.deserialize = function (data) {
          var trustedData;
          if (_.isString(data)) {
            try {
              trustedData = $sce.getTrustedHtml(data);
            } catch (e) {
              trustedData = String(data).replace(/<[^>]+>/gm, "");
            }
            return trustedData;
          } else {
            return data;
          }
        };
        this.serialize = function (data) {
          return $sceDelegate.valueOf(data);
        };
      });
    },
  ]);
