import { Money } from "../../services/money.service";
import { CURRENCIES } from "../../values/currency";

export default class MoneyInputCtrl {
    public disabled: boolean | undefined;
    
    constructor () {
        _.defaults(this, { currencies: CURRENCIES, step: "0.5", field: new Money() });
    }

    public $onInit() {
        if (this.disabled == undefined) {
          this.disabled = false;
        }
    };
};