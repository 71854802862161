import { Country } from "../../../countries/services/countries.service";
import { TAddress } from "../../../locations/types/address";
export default class AddressFormCtrl {
  public locations: any[];
  public address: Partial<TAddress>;
  static $inject = ["LocationsFactory"];

  constructor(
    private Location: any
  ) {  }

  public async selectAddress($item) {
    if (!$item) {
      return;
    }

    this.address = this.address == null ? {} : this.address;
    _.extend(this.address, {
      address: $item.displayName,
      street: $item.address.road || $item.address.name,
      houseId: $item.address.house_number,
      zipCode: $item.address.postcode,
      city: $item.address.city,
      country: await Country.findByName($item.address.country),
    });
  };

  public autocompleteLocation(searchQuery) {
    var deferred = this.Location.autocomplete(searchQuery, "address");

    deferred.promise.then((locations) => {
      this.locations = _.rest(locations); // remove first element with query string
    });
  };
};