import { IQService } from "angular";

export default class EmployeeSearchCtrl {
  public foundEmployees: any[];
  public loading: boolean;
  public cancelers: ng.IDeferred<any>[] = [];
  public advancedEmployeeSearchOptions: {
    query: string,
    advancedQuery: any,
    setData: () => void,
    remote: string
  };
  public query: string;
  public advancedQuery: any;
  public remote: string;
  public setData: () => (data?: any) => void;

  static $inject = ["$state", "$q", "EmployeesFactory", "Autocompletes"];

  constructor(
    private $state: angular.ui.IStateService,
    private $q: IQService,
    private  Employees: any,
    private  Autocompletes: any,
  ) {
    this.foundEmployees = [];
    this.loading = false;
    this.cancelers = [];
  }

  public $onInit() {
    this.advancedQuery.resourceType = "EmployeeSearch";

    this.advancedEmployeeSearchOptions = {
      query: this.query,
      advancedQuery: this.advancedQuery,
      setData: this.setData(),
      remote: this.remote,
    };
  };

  public searchEmployees(viewValue) {
    if (viewValue.length) {
      this.loading = true;
      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      this.Autocompletes.autocomplete(viewValue, "employee_name", _.last(this.cancelers)).then((results) => {
        this.foundEmployees = _.map(results, (result) => {
          switch (result.resource_type) {
            case "employee":
              result.name = result.employee.full_name;
              return _.extend(result, {
                resource_type: "Manager",
              });
            default:
              result.name = result.highlight[0];
              return _.extend(result, {
                resource_type: "Query",
              });
          }
        });
        this.loading = false;
      });
    } else {
      this.foundEmployees = [];
    }
  };

  public search(item: any, query: string) {
    if (item && item.id) {
      query = item.name;
    }

    _.invoke(this.cancelers, "resolve");

    this.advancedQuery.basicQuery = query;
    var params = this.advancedQuery.collectSearchParams({ employee_id: item ? item.id : undefined });

    if (!this.remote) {
      var route = "root.employees";
      if (item && item.id) {
        route = "root.employees.show";
        params.q = '"' + query.replace(/\//g, " ") + '"';
      }

      this.$state.go(route, params, { reload: true, inherit: false });
    } else {
      this.setData()(params);
    }
  };
};