import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";

export default class EmployeesContextMenuCtrl {
  public initialViewState: {
    name?: string
  };
  public createAsCandidate: (person: any) => void;

  static $inject = ["PersonTransformer"];

  constructor (
    private PersonTransformer: any
  ) {}

  public $onInit() {
    angular.extend(this, {
      state: StateTransitionsConfig.state,
      transitionOptions: StateTransitionsConfig.transitionOptions(this.initialViewState),
    });
    this.createAsCandidate = this.PersonTransformer.createAsCandidate;
  };
};
