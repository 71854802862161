import { Money } from "./money.service";

angular.module("components.money").factory("MoneySerializer", [
  "railsSerializer",
  function (railsSerializer) {
    return railsSerializer(function () {
      this.deserialize = function (data) {
        if (data && !data.inCents) {
          data.cents = Number((data.cents / 100.0).toFixed(2));
          data.inCents = true;
        }
        return new Money(data || { inCents: true });
      };
      this.serialize = function (data) {
        if (data && data.cents && data.inCents) {
          data.cents = Math.round(data.cents * 100);
        }
        return data;
      };
    });
  },
]);
