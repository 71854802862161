//= require ../login.module.js

angular
  .module("login")
  // this is a service that you can call to check whether you're logged in and to actually log in the user
  .factory("AuthService", [
    "$http",
    "$log",
    "UserSessionsFactory",
    "Session",
    "authService",
    "LoginModalMixin",
    function ($http, $log, UserSession, Session, HttpAuthInterceptorAuthService, LoginModalMixin) {
      var authService: any = {};

      function loginCallback(user_session) {
        $log.debug("AuthService logging in user");
        Session.create({ user: user_session.data });
        HttpAuthInterceptorAuthService.loginConfirmed();
        return user_session;
      }

      authService.login = function (credentials) {
        return new UserSession(credentials).create().then(loginCallback);
      };

      authService.updateAfterExternalLogin = function () {
        return new UserSession().get().then(loginCallback);
      };

      authService.logout = function () {
        $log.debug("AuthService logging out");
        return new UserSession().delete().then(
          function (response) {
            Session.destroy();
          },
          function (response) {
            Session.destroy();
          }
        );
      };

      authService.isAuthenticated = function () {
        return !!Session.user;
      };

      angular.extend(authService, LoginModalMixin);

      return authService;
    },
  ]);
