
export default class SelectActionModalCtrl {

  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private actions: any[];
  private action: any;
  private options: any;
  private resolve: any;

  static $inject = ["$filter"];

  constructor(private $filter: ng.IFilterService) {}

  private extend(resolve: any): void {
    this.actions = _.chain(resolve.actions)
      .each((action) => {
        if (action.successors) {
          action.firstSuccessor = _.first(action.successors);
        }
      })
      .filter((action) => {
        return this.selectedAction(action);
      })
      .value();
    this.action = _.first(this.actions);
    this.options = _.defaults(resolve.options, {
      showManager: true,
      showCandidate: false,
      historyTypeTitle: this.$filter("translate")("HISTORY_ENTRY"),
    });
  }

  public $onInit(): void {
    this.extend(this.resolve);
  }

  public selectedAction(action: any): boolean {
    return !!(action.predecessor || action.firstSuccessor);
  }

  public cancel(): void {
    this.modalInstance.dismiss("cancel");
  }

  public selectAction(): void {
    if (this.action) {
      this.modalInstance.close(this.action);
    }
  }
}
