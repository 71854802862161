import { initContract } from "@ts-rest/core";

import { TLanguage } from "../languages";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const LanguagesSchema = c.type<TBaseResource<TLanguage[]>>();
export const languagesContract = c.router({
  query: {
    method: "GET",
    path: "/languages",
    responses: {
      200: LanguagesSchema,
    },
    summary: "Get all languages",
  },
});
