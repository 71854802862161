import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TLanguage } from "../languages";

export class Language {
  private serviceName: string = "LanguagesFactory";
  public id: number;
  private name: string;
  private key: string;
  public slug: string;

  static defaultOptions = [
    { name: "GERMAN", key: "de" },
    { name: "ENGLISH", key: "en" },
  ];

  static levels = [
    { name: "GOOD", id: 0 },
    { name: "VERY_GOOD", id: 1 },
    { name: "FLUENT", id: 2 },
    { name: "NATIVE_SPEAKER", id: 3 },
    { name: "A1", id: 4 },
    { name: "A2", id: 5 },
    { name: "B1", id: 6 },
    { name: "B2", id: 7 },
    { name: "C1", id: 8 },
    { name: "C2", id: 9 },
    { name: "BUSINESS_FLUENT", id: 10 },
    { name: "ADVANCED", id: 11 },
    { name: "BASIC_KNOWLEDGE", id: 12 },
  ];

  static levelInWords(level: number): string {
    const foundLevel = Language.levels.find((l) => l.id === level);
    return foundLevel ? foundLevel.name : "UNKNOWN";
  }

  static $inject = [];

  constructor(fields?: Partial<TLanguage>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TLanguage>>(fields, camelCase));
  }

  @cached
  public static getAll() {
    return baseClient.languages.query().then((response) => {
      return response.body.data.map((element) => {
        return new Language(element);
      });
    });
  }
}
