import OrganisationUnitsSelectorCtrl from "./organisation-units-selector.controller";
import organisationUnitsSelectorTemplate from "./organisation-units-selector.template.html";

angular.module("organisation_units.components").component("organisationUnitsSelector", {
  bindings: {
    selectedOrgUnit: "=ngModel",
    disabled: "<",
    title: "@",
  },
  template: organisationUnitsSelectorTemplate,
  controller: OrganisationUnitsSelectorCtrl,
});
