import CandidatesEditViewCtrl from "./candidates-edit.controller";
import candidatesEditViewTemplate from "./candidates-edit-view.template.html";

angular.module("candidates.views").component("candidatesEditView", {
  bindings: {
    candidate: "<",
    languages: "<",
    maritalStatuses: "<",
    leadershipExperiences: "<",
    jobTypes: "<",
    drivingLicences: "<",
    deregCandidateDiff: "&",
    scrollspyScope: "<",
  },
  template: candidatesEditViewTemplate,
  controller: CandidatesEditViewCtrl,
});
