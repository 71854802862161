export class SendoutsListCtrl {
  static $inject = ["SavedSearchesFactory", "makeCollectionProxy", "CandidatesProjectsFactory", "PrsmTableHelper"];
  constructor(
    private SavedSearch: any,
    private makeCollectionProxy: any,
    private CandidatesProjects: any,
    private PrsmTableHelper: any
  ) {}

  public lazyLoading: boolean;
  public object: any;
  public advancedQuery: any;
  public attributes: any;
  public advancedSendoutsListSearchOptions: any;

  private initSearchResults(sendouts) {
    this.object.sendouts = sendouts;
    this.lazyLoading = false;
  }

  public updateSendouts() {
    this.lazyLoading = true;

    var params = _.extend(this.object.params(), {
      advanced: JSON.stringify(this.advancedQuery),
      additional_pages: Math.min(this.object.sendoutsCount / 20 + 1, 3),
    });

    if (!_.isFunction(this.object.sendouts?.search)) {
      this.makeCollectionProxy(this.CandidatesProjects.query.bind(this.CandidatesProjects), params).then(
        this.initSearchResults.bind(this)
      );
    } else {
      this.object.sendouts.search(params).then(this.initSearchResults.bind(this));
    }
  }

  public $onInit() {
    this.attributes = this.PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.CandidatesProjects.defaultTableAttributes),
      ["name", "projectTitle", "sendoutAt"],
      ["name", "projectTitle", "sendoutAt"]
    );

    this.advancedQuery = new this.SavedSearch({
      defaultCondition: { modifier: "must", field: "project_status", query: "" },
    });

    this.advancedQuery.conditions.push({
      field: "project_status",
      modifier: "must",
      query: "active",
    });

    this.advancedQuery.deleteAdvancedSearchCondition(0);
    this.updateSendouts();
    this.advancedSendoutsListSearchOptions = {
      setData: this.updateSendouts.bind(this),
      advancedQuery: this.advancedQuery,
    };
  }
}
