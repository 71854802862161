export class EditSalesModalCtrl {
  public project: any;
  public resolve: {
    project: any;
  };
  public modalInstance: angular.ui.bootstrap.IModalInstanceService;
  public filterTeams: () => boolean;

  static $inject = [];
  constructor() {}

  public $onInit() {
    this.project = this.resolve.project;
    this.project.snapshot();

    this.filterTeams = this._filterTeams.bind(this);
  }

  private _filterTeams(user: any): boolean {
    return _.isMatch(user, { team: this.project.projectManager.team });
  }

  public cancel() {
    this.modalInstance.dismiss("cancel");
  }

  public save() {
    this.modalInstance.close(this.project);
  }
}
