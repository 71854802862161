export class TextkernelLonglistConfirmModalCtrl {
  static $inject = ["$q", "Textkernel"];
  constructor(private $q: angular.IQService, private Textkernel: any) {}

  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  public loading: boolean;
  public errors: any[];
  public fromCandidate: any;
  public candidatesProjects: any[];
  private resolve: {
    fromCandidate: any;
    promises: ng.IPromise<any>[];
  };

  public $onInit() {
    this.fromCandidate = this.resolve.fromCandidate;
    this.loading = true;

    this.$q.all(this.resolve.promises).then((results) => {
      results = _.partition(results, (result) => {
        return _.isNumber(result.status);
      });

      this.errors = results[0];
      this.candidatesProjects = results[1];

      _.each(this.candidatesProjects, (cpjoin) => {
        this.Textkernel.addHistoryPoint("tk-longlist", cpjoin);
      });

      this.loading = false;
    });
  }

  public ok() {
    this.modalInstance.close(true);
  }

  public cancel() {
    this.modalInstance.close(false);
  }
}
