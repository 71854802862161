import { LonglistCtrl } from "./longlist.controller";

import longlistTemplate from "./longlist.template.html";

angular.module("candidates_projects.components").component("longlist", {
  bindings: {
    object: "=",
    activeRow: "=",
    initialViewState: "<",
  },
  template: longlistTemplate,
  controller: LonglistCtrl,
});
