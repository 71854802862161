export default class GenericDeckCtrl {
  public sidebar: any;
  
  static $inject = ["sidebarStates"];

  constructor(
    private sidebarStates: any
  ) {}

  public getDeckCssHelper(deck) {
    var twoDecksExist = this.sidebarStates.deckSecondary;
    if (twoDecksExist) {
      return `${deck} mg-deck_half`;
    } else {
      return `${deck} mg-deck_full`;
    }
  };

  public getDeckClass() {
    return this.sidebar.name() == "deckPrimary"
      ? this.getDeckCssHelper("mg-deck-primary")
      : this.getDeckCssHelper("mg-deck-secondary");
  };
};