export class DocumentExportModalCtrl {
  public pollingService: any;
  public project: any;
  public resolve: {
    project: any;
  };
  public loading: boolean;

  public modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = ["$rootScope", "poller", "DocumentsFactory"];
  constructor(private $rootScope: ng.IRootScopeService, private poller: any, private DocumentService: any) {
    this.$rootScope.$on("modal.closing", () => {
      this.pollingService.remove();
    });
  }

  public $onInit() {
    this.project = this.resolve.project;
    this.loading = true;

    this.pollingService = this.poller.get(this.project.$url("/get_job_offer_doc.json"), {
      catchError: true,
      delay: 2000,
    });

    this.pollingService.promise.then(null, null, (response: any) => {
      if (response.status === 200) {
        if (_.isEmpty(response.data)) {
          return;
        }
        this.loading = false;

         const doc: any = new this.DocumentService(response.data.data);

        this.pollingService.remove();
        this.modalInstance.close(doc);
      } else {
        this.pollingService.remove();
        this.cancel();
      }
    });
  }

  public cancel() {
    this.modalInstance.dismiss("cancel");
  }
}
