export class ProjectsIAEditCtrl {
  public project: any;
  private deregActiveRowConnector: () => void;

  private onStateChanged: (project: any) => void;
  private registerSaveHotkey: (callback: (...params) => void) => void;
  private initializeActiveRowConnector: (project: any) => () => void;
  private onSaveError: () => void;

  public saving: boolean;

  static $inject = ["$state", "$rootScope", "$log", "EditUtilMixin", "DeckControllerMixin"];
  constructor(
    private $state: angular.ui.IStateService,
    private $rootScope: IExtendedRootScopeService,
    private $log: ng.ILogService,
    private EditUtilMixin: any,
    private DeckControllerMixin: any
  ) {}

  public $onInit() {
    angular.extend(this, this.EditUtilMixin, this.DeckControllerMixin);

    this.project.projectStatus =
      _.find(this.project.$class.statuses, (status: any) => {
        return status == this.project.projectStatus;
      }) || this.project.$class.statuses[0];

    this.onStateChanged(this.project);

    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.project);
    this.registerSaveHotkey(this.saveProject);
  }

  public saveProject(route, params) {
    if (!this.saving) {
      this.saving = true;
      this.project.save().then((project: any) => {
        this.$log.debug("project successfully saved");

        if (!this.project.id) {
          this.$rootScope.$broadcast("addProject", project);
        }

        this.project.snapshot();
        this.$state.go(route || "^.show", params || project.params());
      }, this.onSaveError.bind(this));
    }
  }

  public $onDestroy() {
    this.deregActiveRowConnector();
  }
};
