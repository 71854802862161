//= require ../outlook_delivery.module.js

angular.module("outlook.delivery").service("OutlookDelivery", [
  "$log",
  "$filter",
  "$translate",
  "Session",
  function ($log, $filter, $translate, Session) {
    function OutlookDelivery() {}

    function UnauthorizedError() {
      $log.debug("401 - not authorized");
      window.alert($filter("translate")("NOT_AUTHENTICATED_NOTICE"));
    }

    function BadRequest() {
      $log.debug("400 BAD request");
    }

    function ServiceUnavailable() {
      $log.debug("503 Service Unavailable");
    }

    OutlookDelivery.handleErrors = function (status) {
      // status exists?
      if (_.isFunction(OutlookDelivery.ERROR_CODES[status])) {
        OutlookDelivery.ERROR_CODES[status]();
      } else {
        $log.debug(status + " - UndefinedErrorCode");
      }
    };

    OutlookDelivery.ERROR_CODES = {
      401: UnauthorizedError,
      400: BadRequest,
      503: ServiceUnavailable,
    };

    return OutlookDelivery;
  },
]);
