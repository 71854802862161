import CompanySelectCtrl from "./company-select.controller"
import companySelectTemplate from "./company-select.template.html";

angular.module("companies.components").component("companySelect", {
  bindings: {
    model: "=",
    exclude: "@",
  },
  template: companySelectTemplate,
  controller: CompanySelectCtrl,
});
