import CandidatesTableBodyCtrl from "./candidates-table-body.controller";
import candidatesTableBodyTemplate from "./candidates-table-body.template.html"

angular.module("candidates.components").component("candidatesTableBody", {
  bindings: {
    candidateList: "=data",
    attributes: "=",
    activeRow: "=",
    statePrefix: "@",
    stateArgs: "&",
    context: "=",
    tableRowIdentifier: "=",
    initialViewState: "<",
  },
  template: candidatesTableBodyTemplate,
  controller: CandidatesTableBodyCtrl,
});
