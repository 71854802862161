import { ProjectsContextMenuCtrl } from "./projects-context-menu.controller";
import projectsContextMenuTemplate from "./projects-context-menu.template.html";

angular.module("projects.components").component("projectsContextMenu", {
  bindings: {
    project: "=",
    statePrefix: "=",
    context: "=",
    cpjoin: "=",
    company: "=",
    initialViewState: "<",
  },
  template: projectsContextMenuTemplate,
  controller: ProjectsContextMenuCtrl,
});
