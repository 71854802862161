import CompaniesContextMenuCtrl from "./companies-context-menu.controller";
import companiesContextMenuTemplate from "./companies-context-menu.template.html";

angular.module("companies.components").component("companiesContextMenu", {
  bindings: {
    company: "<",
    statePrefix: "=",
    initialViewState: "<",
    options: "<",
    stateArgs: "&",
  },
  template: companiesContextMenuTemplate,
  controller: CompaniesContextMenuCtrl,
});