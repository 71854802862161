import EmployeesTableBodyCtrl from "./employees-table-body.controller";
import employeesTableBodyTemplate from "./employees-table-body.template.html";

angular.module("employees.components").component("employeesTableBody", {
  bindings: {
    employees: "=data",
    attributes: "=",
    activeRow: "=",
    statePrefix: "@",
    tableRowIdentifier: "=",
    contextMenuOptions: "=",
    initialViewState: "<",
  },
  template: employeesTableBodyTemplate,
  controller: EmployeesTableBodyCtrl,
});
