import { initContract } from "@ts-rest/core";

import { TFunctionalArea } from "../functional_areas";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const FunctionalAreasSchema = c.type<TBaseResource<TFunctionalArea[]>>();
export const functionalAreasContract = c.router({
  query: {
    method: "GET",
    path: "/functional_areas",
    responses: {
      200: FunctionalAreasSchema,
    },
    summary: "Get all functional areas",
  },
});
