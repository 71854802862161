import CourseSelectCtrl from "./course-select.controller";
import courseSelectTemplate from "./course-select.template.html";
//= require ../components.module.js

angular.module("courses.components").component("courseSelect", {
  bindings: {
    ngModel: "=",
  },
  controller: CourseSelectCtrl,
  template: courseSelectTemplate,
});
