export default class OpenSkillmatrixEditCtrl {
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private saving: boolean;
  private resolve: { person: any };
  private onSaveError: () => void;

  static $inject = ["$http", "EditUtilMixin"];

  constructor (private $http: ng.IHttpService, private EditUtilMixin: any) {}
  
  public $onInit () {
    angular.extend(this, this.EditUtilMixin, {
      saving: false,
    });
  };

  public cancel () {
    this.modalInstance.dismiss("cancel");
  };

  public save () {
    if (this.saving) {
      return false;
    }

    this.saving = true;
    return this.$http
      .post(this.resolve.person.$url("/update_skillmatrix_taggings.json"), {
        skillmatrix_elements: _.chain(this.resolve.person.skillmatrixElements)
          .pluck("tags")
          .flatten()
          .select( (tag) => {
            return !_.isEmpty(tag.tagging);
          })
          .map( (tagObj) => {
            return [tagObj.tag.id, tagObj];
          })
          .object()
          .value(),
      })
      .then( (result) => {
        this.modalInstance.close();
      }, this.onSaveError.bind(this));
  };
}