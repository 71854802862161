import { Country } from "../../../countries/services/countries.service";
import { TCountry } from "../../countries";

type PersonsNationality = {
  id: number | null;
  nationality: TCountry;
};

export default class nationalitiesSelectCtrl {
  private personsNationalities: PersonsNationality[];
  private nationalities: PersonsNationality[];

  constructor () {}

  public $onInit() {
    Country.getAll().then((countries: Country[]) => {
      var allNationalities: TCountry[] = Country.nationalities(countries);

      this.personsNationalities = _.map(allNationalities, (nationality: TCountry) => {
        var citizenship: PersonsNationality = _.find(this.nationalities, (persNational: PersonsNationality) => {
          return persNational.nationality.id == nationality.id;
        });

        if (citizenship) {
          return citizenship;
        } else {
          return { id: null, nationality: nationality } as PersonsNationality;
        }
      });
    });
  }
}
