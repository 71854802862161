import companiesChildrenTableBodyTemplate from "./companies-children-table-body.template.html";

angular.module("companies.components").component("companiesChildrenTableBody", {
  bindings: {
    companies: "=data",
    attributes: "=",
    activeRow: "=",
    tableRowIdentifier: "=",
  },
  template: companiesChildrenTableBodyTemplate,
});
