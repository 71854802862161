import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { TBaseResource } from "../../api-routing";
import { TCompaniesTransactionBases } from "../companies_transaction_bases";

const c = initContract();

const CompaniesTransactionBasisSchema = c.type<TBaseResource<TCompaniesTransactionBases[]>>();
const SingleCompaniesTransactionBasisSchema = c.type<TBaseResource<TCompaniesTransactionBases>>();

const SingleCompaniesTransactionBasisBodySchema = z.object({
  companies_transaction_basis: z.object({
    company_id: z.coerce.number(),
  }),
});

export const companiesTransactionBasisContract = c.router({
  query: {
    method: "GET",
    path: "/companies_transaction_basis",
    responses: {
      200: CompaniesTransactionBasisSchema,
    },
    summary: "Get all companies transaction bases",
  },
  update: {
    method: "POST",
    path: "/companies_transaction_basis/:id",
    responses: {
      200: SingleCompaniesTransactionBasisSchema,
    },
    body: SingleCompaniesTransactionBasisBodySchema,
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    summary: "Update a companies transaction bases",
  },
  create: {
    method: "POST",
    path: "/companies_transaction_basis",
    responses: {
      200: SingleCompaniesTransactionBasisSchema,
    },
    body: SingleCompaniesTransactionBasisBodySchema,
    summary: "Create a companies transaction bases",
  },
  delete: {
    method: "DELETE",
    path: "/companies_transaction_basis/:id",
    responses: {
      200: SingleCompaniesTransactionBasisSchema,
    },
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: SingleCompaniesTransactionBasisBodySchema,
    summary: "Delete a transaction bases",
  },
});
