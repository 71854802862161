export default class UserSelectBoxCtrl {
  private users: any;
  private filter: () => boolean;

  static $inject = ["UsersFactory"];

  constructor(private User: any) {}

  $onInit() {
    if (this.filter) {
      this.users = _.filter(this.User.getAll(), this.filter);
    } else {
      this.users = this.User.getAll();
    }
  }
}
