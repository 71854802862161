import LocationSearchCtrl from "./location-search.controller";
import locationSearchTemplate from "./location-search.template.html";

angular.module("directives.advanced_search").component("locationSearch", {
  bindings: {
    advancedQuery: "=",
  },
  template: locationSearchTemplate,
  controller: LocationSearchCtrl,
});
