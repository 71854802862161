import LoginCtrl from "./login-page.controller";
import loginPageViewTemplate from "./login-page.template.html";

angular.module("login.views").component("loginPageView", {
  bindings: {
    $transition$: "<",
  },
  template: loginPageViewTemplate,
  controller: LoginCtrl,
});
