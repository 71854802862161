//= require ../modals.module.js

angular.module("projects.modals").factory("ProjectsModalMixin", [
  "$uibModal",
  "$filter",
  "poller",
  function ($uibModal, $filter, poller) {
    function ProjectsModalMixin() {}

    function pitchModal(_this, resolves) {
      var modalInstance = $uibModal.open({
        component: "pitchModal",
        size: "lg",
        resolve: resolves,
      });

      return modalInstance.result.then(
        function (pitchEmail) {
          pitchEmail.callback.then(function (pitchCreatedRes) {
            var pollingService = poller.get(pitchCreatedRes.data.url, { delay: 1000 });
            _this.confirm($filter("translate")("CONFIRM_SENT_PITCH"), pollingService).then(
              function (res) {
                if (res) {
                  _this.sendDocument("send_pitch", { params: pitchCreatedRes.data });
                }
                pollingService.remove();
              },
              function () {
                pollingService.remove();
              }
            );
          });
        },
        function () {}
      );
    }

    function openChooseCandidateModal(options) {
      options = options || {};
      _.defaults(options, { project: this, excludes: [], autocomplete: false, cpjoins: this.candidatesProjects });
      var _this = this;

      var modalInstance = $uibModal.open({
        component: "candidateSearchModal",
        size: "lg",
        resolve: {
          options: function () {
            return options;
          },
        },
      });

      return modalInstance.result;
    }

    function openActiveProcessesModal(_this, resolves) {
      var modalInstance = $uibModal.open({
        component: "activeProcessesModal",
        size: "lg",
        resolve: resolves,
      });

      return modalInstance.result.then(
        function (projectActiveProcessesEmail) {
          projectActiveProcessesEmail.callback.then(
            function (res) {
              var pollingService = poller.get(res.data.url, { delay: 1000 });
              _this.confirm($filter("translate")("CONFIRM_SENT_CURRENT_PROJECTS"), pollingService).then(
                function (res) {
                  if (res) {
                    _this.sendDocument("send_active_processes");
                  }
                  pollingService.remove();
                },
                function () {
                  pollingService.remove();
                }
              );
            },
            function () {}
          );
        },
        function () {}
      );
    }

    function openPitchModal() {
      var _this = this;
      var resolves = {
        managers: function () {
          return _this.employees;
        },
        project: _this,
      };

      return pitchModal(_this, resolves);
    }

    function editSalesModal(callback) {
      var project = this;

      var modalInstance = $uibModal.open({
        component: "editSalesModal",
        size: "lg",
        resolve: {
          project: [
            "ProjectsFactory",
            function (Project) {
              return Project.get(project.id);
            },
          ],
        },
      });

      return modalInstance.result.then(
        function (editedProject) {
          if (editedProject.unsnappedChanges()) {
            project.updateSales(editedProject.projectManager);
          }
          callback(true);

          return true;
        },
        function () {
          callback(false);

          return false;
        }
      );
    }

    function openInitiativeActiveProcessesModal() {
      var _this = this;
      var resolves = {
        managers: function () {
          return _this.employees;
        },
        project: _this,
      };

      return openActiveProcessesModal(_this, resolves);
    }

    function getDocumentExportModal(project) {
      var modalInstance = $uibModal.open({
        component: "documentExportModal",
        size: "sm",
        resolve: {
          project: function () {
            return project;
          },
        },
      });

      return modalInstance.result;
    }

    ProjectsModalMixin.extended = function (Resource) {
      Resource.include({
        openChooseCandidateModal: openChooseCandidateModal,
        openInitiativeActiveProcessesModal: openInitiativeActiveProcessesModal,
        getDocumentExportModal: getDocumentExportModal,
        editSales: editSalesModal,
        openPitch: openPitchModal,
      });
    };

    return ProjectsModalMixin;
  },
]);
