import AddressBlockCtrl from "./address-block.controller";
import urAddressBlockTemplate from "./ur-address-block.template.html";

angular.module("directives").component("urAddressBlock", {
  bindings: {
    address: "=address",
    region: "=region",
    label: "@",
  },
  template: urAddressBlockTemplate,
  controller: AddressBlockCtrl,
});
