import GenericSelectorCtrl from "./generic-selector.controller";
import genericSelectorTemplate from "./generic-selector.template.html";

angular.module("directives.advanced_search").component("genericSelector", {
  bindings: {
    condition: "=",
  },
  template: genericSelectorTemplate,
  controller: GenericSelectorCtrl,
});
