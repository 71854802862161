import { QuicklistsCompaniesListCtrl } from "./quicklist-companies-list.controller";
import companiesListTemplate from "./companies-list.template.html";

angular.module("quicklists.components").component("quicklistCompaniesList", {
  bindings: {
    quicklist: "<",
    quicklistEntriesLazyLoading: "<",
  },
  template: companiesListTemplate,
  controller: QuicklistsCompaniesListCtrl,
});
