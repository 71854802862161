//= require ../filters.module.js

angular.module("filters").filter("stripWhitespace", [
  "$filter",
  function ($filter) {
    return function (value) {
      return !value ? "" : value.replace(/\s/g, "");
    };
  },
]);
