export default class ChooseInfoPresentationModalCtrl {

  private infoPresentation: string;
  private resolve: { infoPresentations: any[] };
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;

  constructor () {}
  
  public $onInit () {
    this.infoPresentation = _.first(this.resolve.infoPresentations);
  };

  public cancel () {
    this.modalInstance.dismiss("cancel");
  };

  public selectInfoPresentation () {
    this.modalInstance.close(this.infoPresentation);
  };
}