import JobOffersEditCtrl from "./edit.controller";
import jobEditTemplate from "./edit.template.html";

angular.module("job_offers.views").component("jobOffersEdit", {
  bindings: {
    jobOffer: "<jobOfferResponse",
    regions: "<",
    countries: "<",
    languages: "<",
    jobTypes: "<",
    scrollspyScope: "<",
  },
  template: jobEditTemplate,
  controller: JobOffersEditCtrl,
});
