import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { JobCategory } from "./job_categories.service";

angular
  .module("jobCategories")
  .factory("JobCategoriesSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(JobCategory, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("JobCategoriesFactory", JobCategory);
