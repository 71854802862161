import { EditResearcherModalCtrl } from "./edit-researcher-modal.controller";
import editResearcherModalTemplate from "./edit-researcher-modal.template.html";

angular.module("candidates_projects.modals").component("editResearcherModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: editResearcherModalTemplate,
  controller: EditResearcherModalCtrl,
});
