//= require ./modals.module.js
import { OrganisationUnit } from "../../organisation_units/services/organisation_units.service";
import { CancelationPeriod } from "../../cancelation_periods/services/cancelation_periods.service";

angular.module("deals.modals").factory("DealsModalMixin", [
  "$uibModal",
  "$rootScope",
  "$filter",
  "$translate",
  "poller",
  function ($uibModal, $rootScope, $filter, $translate, poller) {
    function DealsModalMixin() {}

    function editModal() {
      var deal = this;
      if (!deal.id) {
        deal.candidatesProjectId = _.first(deal.provisions).joinedProjectModelId;
      }

      var modalInstance = $uibModal.open({
        component: "dealEditModal",
        size: "lg",
        resolve: {
          deal: [
            function () {
              return deal;
            },
          ],
          probations: [
            function () {
              return CancelationPeriod.getAll();
            },
          ],
          organisationUnits: [
            function () {
              return OrganisationUnit.getAll();
            },
          ],
        },
      });

      function broadcastChanges() {
        _.each(deal.provisions, function (provision) {
          $rootScope.$broadcast("candidate_action_changed_" + provision.candidate.id);
        });
        $rootScope.$broadcast("project_action_changed_" + deal.project.id);
      }

      return modalInstance.result.then(function (deal) {
        if (deal.unsnappedChanges()) {
          deal.save().then(
            function () {
              deal.snapshot();
            },
            null,
            function () {
              // finally
              broadcastChanges();
            }
          );
        }
      });
    }

    function openReconciliationModal(candidateOrEmployees) {
      var _this = this;
      var title = $filter("translate")("SEND_CONTRACT_RECONCILIATION_MODAL");

      var route;
      var params;
      if (_.isArray(candidateOrEmployees)) {
        route = "create_reconciliation_employees_email";
        params = { employees: candidateOrEmployees };
      } else {
        route = "create_reconciliation_candidate_email";
        params = { candidate: candidateOrEmployees };
      }

      return _this.createMessage(route, params).then(function (res) {
        var pollingService = poller.get(res.data.url, { delay: 1000 });
        _this.confirm($filter("translate")("CONTRACT_RECONCILIATION_SENT_CONFIRMATION"), pollingService).then(
          function (res) {
            pollingService.remove();
          },
          function () {
            pollingService.remove();
          }
        );
      });
    }

    function openAcknowledgementCandidateModal(candidate) {
      var _this = this;
      var title = $filter("translate")("SEND_ACKNOWLEDGEMENT_EMAIL_MODAL");

      return candidate.createMessage("create_acknowledgement_candidate_email").then(function (res) {
        var pollingService = poller.get(res.data.url, { delay: 1000 });
        candidate.confirm($filter("translate")("ACKNOWLEDGEMENT_EMAIL_SENT_CONFIRMATION"), pollingService).then(
          function (res) {
            if (res === true) {
              _this.acknowledgementEmailSentAt = new Date();
              _this.save();
            }
            pollingService.remove();
          },
          function () {
            pollingService.remove();
          }
        );
      });
    }

    DealsModalMixin.extended = function (Resource) {
      Resource.include({
        edit: editModal,
        openReconciliationModal: openReconciliationModal,
        openAcknowledgementCandidateModal: openAcknowledgementCandidateModal,
      });
    };

    return DealsModalMixin;
  },
]);
