//= require ../job_offers.module.js

angular.module("job_offers").service("JobOfferFactory", [
  "RailsResource",
  "RailsResourceSnapshotsMixin",
  "railsSerializer",
  "$state",
  "$http",
  "JobOfferMainCategories",
  function (RailsResource, RailsResourceSnapshotsMixin, railsSerializer, $state, $http, JobOfferMainCategories: any) {
    function JobOffer() {
      (<any>JobOffer).__super__.constructor.apply(this, arguments);

      (<any>JobOffer).CURRENCIES = [
        { name: "EUR", value: 1 },
        { name: "GBP", value: 2 },
        { name: "CHF", value: 3 },
        { name: "USD", value: 4 },
      ];

      (<any>JobOffer).COUNTRY_CODES = [
        { name: "GERMANY", key: "de", url: "https://theentourage.de/jobs" },
        { name: "SWITZERLAND", key: "ch", url: "https://theentourage.ch/jobs" },
        { name: "ITALY", key: "it", url: "https://theentourage.de/it/jobs" },
      ];

      this.params = function () {
        return { project_id: this.project.id, job_offer_id: this.id };
      };

      this.isInCategory = function () {
        return (
          this.internal ||
          !_.chain(this.project.jobCategories)
            .filter(function (jobCat) {
              return _.findIndex(JobOfferMainCategories, jobCat.name);
            })
            .isEmpty()
            .value()
        );
      };
    }

    RailsResource.extendTo(JobOffer);
    (<any>JobOffer).configure({
      url: "/job_offers",
      name: "job_offer",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.serializeWith("createdAt", "DateSerializer");
        this.serializeWith("startDate", "DateSerializer");
        this.serializeWith("endDate", "DateSerializer");
        this.resource("project", "ProjectsFactory");
        this.serializeWith("region", "RegionSerializer");
        this.resource("responsibleUser", "UsersFactory");
        this.serializeWith("jobType", "JobTypesSerializer");

        this.resource("country", "CountriesFactory");
        this.serializeWith("language", "LanguagesSerializer");
        this.serializeWith("country", "CountrySerializer");

        this.exclude("$snapshots");
      }),
    });
    (<any>JobOffer).extend(RailsResourceSnapshotsMixin);
    return JobOffer;
  },
]);
