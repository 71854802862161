import TrafficLightCtrl from "./traffic-light.controller";
import trafficLightTemplate from "./traffic-light.template.html";

angular.module("directives").component("trafficLight", {
  bindings: {
    urgency: "=",
    typeClass: "=",
    object: "=",
  },
  template: trafficLightTemplate,
  controller: TrafficLightCtrl,
});
