import { IHttpService } from "angular";

export default class PersonSkillmatrixCtrl {
  public ratings: Array<{name: string, value: number}>;
  public loading: boolean;
  public person: any;

  static $inject = ["$http"];

  constructor (
    private $http: IHttpService
  ) {
    this.ratings = [
      { name: "No experience", value: 1 },
      { name: "I had contact with", value: 2 },
      { name: "Half/half", value: 3 },
      { name: "I know a lot about it", value: 4 },
      { name: "I am an expert", value: 5 },
    ];
    this.loading = true;
  }

  public $onInit() {
    this.$http.get(this.person.$url("skillmatrix_taggings.json")).then((result: any) => {
      angular.extend(this.person, {
        skillmatrixElements: result.data.data.skillmatrix_elements,
      });

      this.loading = false;
    });
  };
};