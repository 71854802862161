export default class AdvancedDocumentsSearchCtrl {
  private setData: () => void;
  private closing: () => void;
  private selectableFields: string[] = ["file_type"];

  constructor() {}

  public performAdvancedSearch () {
    this.setData();
    this.closing();
  };
}