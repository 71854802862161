import ConfirmModalCtrl from "./confirm-modal.controller";
import confirmModalTemplate from "./confirm-modal.template.html";

angular.module("modals.modals").component("confirmModal", {
  template: confirmModalTemplate,
  controller: ConfirmModalCtrl,
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
});
