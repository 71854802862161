//= require ../employees.module.js

angular.module("employees").service("EmployeesFactory", [
  "RailsResource",
  "RailsResourceSnapshotsMixin",
  "ModalMixin",
  "EmployeesModalMixin",
  "PersonModalMixin",
  "railsSerializer",
  "$state",
  "$http",
  "$rootScope",
  "$sce",
  "ActionsFactory",
  "Highlighter",
  "ActionModals",
  "$filter",
  "$translate",
  function (
    RailsResource,
    RailsResourceSnapshotsMixin,
    ModalMixin,
    EmployeesModalMixin,
    PersonModalMixin,
    railsSerializer,
    $state,
    $http,
    $rootScope,
    $sce,
    Action,
    Highlighter,
    ActionModals,
    $filter,
    $translate
  ) {
    function Employee() {
      (<any>Employee).__super__.constructor.apply(this, arguments);

      this.params = function () {
        return { employee_id: this.id };
      }.bind(this);

      this.getDefaultEmailAddress = function () {
        return this.workEmail;
      };

      this.autocompleteText = function () {
        return this.fullName + " (" + this.company.name + ")";
      };

      this.mapSearchScopesToHumanReadable = Employee.mapSearchScopesToHumanReadable;

      this.createMessage = function (actionRoute, emailParams) {
        var params = angular.merge({ id: this.id }, emailParams);
        return $http.post("employees/" + this.id + "/" + actionRoute + ".json", { employee: params });
      };

      this.sendDocument = function (actionRoute, emailParams) {
        (<any>Employee).$post("employees/" + this.id + "/" + actionRoute + ".json", emailParams).then(
          function (resEmployee) {
            $rootScope.$broadcast("employee_action_changed_" + this.id);
          }.bind(this)
        );
      }.bind(this);

      this.saveCustomNote = function (action) {
        Action.saveCustomNote(this, action);
      }.bind(this);

      this.createNewAction = function (actionTypeIdentifier, project) {
        if (project) {
          $http.get("projects/" + project.id + "/employees_projects.json", { params: { employee_id: this.id } }).then(
            function (resEP) {
              var employeesProject = resEP.data.data;
              // @ts-ignore
              employeesProject.employee = new Employee(employeesProject.employee);

              Action.createNewAction(employeesProject, actionTypeIdentifier).then(
                function (res) {
                  $rootScope.$broadcast("project_action_changed_" + project.id);
                  $rootScope.$broadcast("employee_action_changed_" + this.id);
                }.bind(this)
              );
            }.bind(this)
          );
        } else {
          Action.createNewAction(this, actionTypeIdentifier);
        }
      }.bind(this);

      this.completeAction = function (type, project, options) {
        var employee = this;

        ActionModals.selectAction(type, angular.extend(employee.params(), project.params()), options).then(function (
          action
        ) {
          var cp = _.find(project.candidatesProjects, function (cpjoin) {
            return cpjoin.candidate.id == action.candidate.id;
          });
          employee.openContactModal(action.actionEditModal.bind(action, cp, { followUps: true }));
        },
        angular.noop);
      }.bind(this);

      this.addToProject = function (project) {
        (<any>Employee).$post("employees/" + this.id + "/project/" + project.id).then(
          function (resEmployee) {
            // @ts-ignore
            project.employees.push(new Employee(new Employee(resEmployee)));
          }.bind(this)
        );
      };

      this.removeFromProject = function (project) {
        (<any>Employee).$delete("employees/" + this.id + "/project/" + project.id).then(
          function (resEmployee) {
            var idx = _.findIndex(project.employees, _.matcher({ id: resEmployee.id }));
            if (idx > -1) {
              project.employees.splice(idx, 1);
            }
          }.bind(this)
        );
      };

      this.filterDepartments = function () {
        return { job_category_ids: { job_category_ids: _.pluck(this.jobCategories, "id") } };
      }.bind(this);

      this.name = this.fullName;
      this.resourceType = "Employee";
      this.serviceName = "EmployeesFactory";
    }

    Employee.mapSearchScopesToHumanReadable = function (scopeDescriptor) {
      switch (scopeDescriptor) {
        case "fullNameWithTitle.standard":
        case "fullNameReverseWithTitle.standard":
          return "MAP_READ_EXACT_NAME";
        case "fullNameReverseWithTitle.highlight":
          return "MAP_READ_INEXACT_NAME";
        case "address":
          return "MAP_READ_ADDRESS";
        case "privateEmail":
          return "MAP_READ_EMAIL_PRIVATE";
        case "workEmail":
          return "MAP_READ_EMAIL_WORK";
        case "tags.name":
          return "MAP_READ_TAG";
        case "jobCategories.name":
          return "MAP_READ_JOB_CATEGORIES";
        case "allActions.description.de":
          return "MAP_READ_HISTORY";
        case "jobTitle":
          return "MAP_READ_CURRENT_POSITION";
        case "responsibleUser.fullName.notAnalyzed":
        case "responsibleUser.symbole":
          return "MAP_READ_RESPONSIBLE_USER";
        case "company.name":
        case "company.name.standard":
          return "MAP_READ_COMPANY";
        case "id":
          return "MAP_READ_ID";
        case "workPhone":
          return "MAP_READ_TELEPHONE_WORK";
        case "fax":
          return "MAP_READ_FAX_WORK";
        case "workMobilePhone":
          return "MAP_READ_MOBILE_WORK";
        case "privatePhone":
          return "MAP_READ_TELEPHONE_PRIVATE";
        case "privateMobilePhone":
          return "MAP_READ_MOBILE_PRIVATE";
        default:
          return undefined;
      }
    };

    Employee.transformResultHandlerOk = function (result) {
      if (result) {
        $state.go("root.employees.show", result.params());
        if (result.notTransmitted) {
          window.alert(
            $filter("translate")("MANAGER_ATTRIBUTES_NOT_TRANSMITTED_NOTICE") +
              _.map(result.notTransmitted, function (val, key) {
                return key + ": " + val;
              })
          );
        }
      }
    };

    Employee.transformResultHandlerError = function (result) {
      if (result.status != 409) {
        window.rollbar.error("Generating employee from candidate failed", { status: result.status });
      } else {
        window.alert($filter("translate")("MANAGER_WAS_CREATED_ALREADY_NOTICE"));
        window.rollbar.error("Employee exists already", { status: result.status });
      }
    };

    Employee.duplicatesWatchFields = [
      "firstName",
      "lastName",
      "privatePhone",
      "privateMobilePhone",
      "privateEmail",
      "workPhone",
      "workMobilePhone",
      "workEmail",
    ];

    Employee.requirements = {
      yellow: ["REQ_YELLOW_NAME", "REQ_YELLOW_TELEPHONE", "REQ_YELLOW_EMAIL", "REQ_YELLOW_TAG", "REQ_YELLOW_JOB_TITLE"],
      green: ["REQ_GREEN_TAGS", "REQ_GREEN_PROJECT"],
    };

    Employee.defaultTableAttributes = {
      id: {
        title: "ID",
        show: true,
        pinned: true,
        menu: true,
        class: "id",
        sortField: "id",
      },
      name: {
        title: "MANAGER",
        show: true,
        fixedWidth: true,
        class: "title",
        sortField: "full_name_reverse_with_title.not_analyzed",
      },
      projects: {
        title: "PROJECTS",
        show: true,
        class: "tag",
        sortField: "projects_count",
      },
      company: {
        title: "COMPANY",
        show: true,
        class: "title",
        sortField: "company.name.not_analyzed",
      },
      priority: {
        title: "PRIORITY",
        show: false,
        class: "priority",
        sortField: "priority",
      },
      tags: {
        title: "TAGS",
        class: "tags",
        show: true,
      },
      supervisor: {
        title: "SUPERVISOR",
        show: false,
        class: "title",
        sortField: "supervisor.full_name_with_title.not_analyzed",
      },
      city: {
        title: "ORIGIN",
        show: false,
        class: "city",
        sortField: "private_city",
      },
      processStatus: {
        title: "PROCESS",
        class: "select",
        show: true,
        sortField: "status",
      },
      sales: {
        title: "Sales",
        class: "responsible",
        show: false,
        sortField: "consultant.full_name.not_analyzed",
      },
      researcher: {
        title: "Researcher",
        class: "responsible",
        show: false,
        sortField: "researcher.full_name.not_analyzed",
      },
      responsibleUser: {
        title: "RESPONSIBLE_USER_TOKEN",
        show: true,
        class: "responsible",
        sortField: "responsible_user.full_name.not_analyzed",
      },
      createdAt: {
        title: "CREATED_AT",
        show: false,
        class: "date",
        sortField: "created_at",
      },
    };

    RailsResource.extendTo(Employee);
    (<any>Employee).configure({
      url: "/employees",
      name: "employee",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("projects", "ProjectsFactory");
        this.resource("candidate", "CandidatesFactory");
        this.resource("actions", "ActionsFactory");
        this.resource("workingRelationships", "WorkingRelationshipsFactory");
        this.serializeWith("tags", "TagSerializer");

        this.rename("faxWork", "fax");

        this.resource("employeesCandidatesProject", "EmployeesCandidatesProjectsFactory");

        this.serializeWith("birthdate", "DateSerializer");
        this.serializeWith("priority", "PrioritySerializer");
        this.serializeWith("nextReminderWillBeAt", "DateSerializer");
        this.serializeWith("budget", "MoneySerializer");
        this.serializeWith("comment", "SanitizeSerializer");
        this.serializeWith("urgencyDescription", "SanitizeSerializer");
        this.serializeWith("requirementsDescription", "SanitizeSerializer");
      }),
    });
    (<any>Employee).extend(RailsResourceSnapshotsMixin);
    (<any>Employee).extend(ModalMixin);
    (<any>Employee).extend(EmployeesModalMixin);
    (<any>Employee).extend(PersonModalMixin);
    (<any>Employee).extend(Highlighter);
    return Employee;
  },
]);
