import actionEditModalCtrl from "./action-edit-modal.controller";
import actionEditModalTemplate from "./action-edit-modal.template.html";

angular.module("actions.modals").component("actionEditModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: actionEditModalTemplate,
  controller: actionEditModalCtrl,
});
