angular.module("candidates.modals", [
  "rails",
  "ui.router",
  "candidates_projects",
  "action_types",
  "languages",
  "saved_searches",
  "filters",
  "angularFileUpload",
]);
