import CvLastUpdateSelectorCtrl from "./cv-last-update-selector.controller";
import cvLastUpdateSelectorTemplate from "./cv-last-update-selector.template.html";

angular.module("directives.advanced_search").component("cvLastUpdateSelector", {
  bindings: {
    advancedQuery: "=",
    cvLastUpdateSelectable: "=",
  },
  template: cvLastUpdateSelectorTemplate,
  controller: CvLastUpdateSelectorCtrl,
});
