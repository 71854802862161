//= require ../filters.module.js

angular.module("filters").filter("freeDateTextFormat", [
  "$filter",
  function ($filter) {
    return function (freeDateObject) {
      if (freeDateObject.option) {
        return freeDateObject.option.name;
      } else if (freeDateObject.date) {
        return $filter("germanDateFormat")(freeDateObject.date);
      } else {
        return undefined;
      }
    };
  },
]);
