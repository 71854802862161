import ExpertProfilesEditCtrl from "./edit.controller";
import editTemplate from "./edit.template.html";

angular.module("expert_profiles.views").component("expertProfilesEdit", {
  bindings: {
    candidate: "<candidateResponse",
    expertProfile: "<expertProfileResponse",
    cp: "<candidatesProjectsResponse",
    stateParams: "<",
    scrollspyScope: "<",
  },
  template: editTemplate,
  controller: ExpertProfilesEditCtrl,
});
