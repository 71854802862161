import { QuicklistsEditCtrl } from "./edit.controller";
import quicklistsEditTemplate from "./edit.template.html";

angular.module("quicklists.views").component("quicklistEditView", {
  bindings: {
    quicklist: "<quicklistResponse",
    users: "<",
    scrollspyScope: "<",
  },
  template: quicklistsEditTemplate,
  controller: QuicklistsEditCtrl,
});
