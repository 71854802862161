import moneyOutputTemplate from "./money-output.template.html";

angular.module("components.money").component("moneyOutput", {
  bindings: {
    currency: "=",
    amount: "@",
    model: "=ngModel",
  },
  template: moneyOutputTemplate,
});
