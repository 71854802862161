export class ProjectsListCtrl {
  public lazyLoading: boolean;
  public object: any;
  public advancedQuery: any;
  public advancedProjectsListSearchOptions: {
    advancedQuery: any;
    setData: (advancedQuery: any) => void;
  };
  public attributes: any;

  static $inject = ["ProjectsFactory", "SavedSearchesFactory", "makeCollectionProxy", "PrsmTableHelper"];
  constructor(
    private ProjectService: any,
    private SavedSearchService: any,
    private makeCollectionProxy: any,
    private PrsmTableHelper: any
  ) {}

  private initSearchResults(projects) {
    this.object.projects = projects;
    this.lazyLoading = false;
  }

  public updateProjects() {
    this.lazyLoading = true;

    var params = _.extend(this.object.params(), this.advancedQuery.collectSearchParams(), {
      advanced: JSON.stringify(this.advancedQuery),
      additional_pages: Math.min(this.object.projectsCount / 20 + 1, 3),
    });

    if (!_.isFunction(this.object.projects?.search)) {
      this.makeCollectionProxy(this.ProjectService.query.bind(this.ProjectService), params).then(
        this.initSearchResults.bind(this)
      );
    } else {
      this.object.projects.search(params).then(this.initSearchResults.bind(this));
    }
  }

  public $onInit() {
    this.attributes = this.PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.ProjectService.defaultTableAttributes),
      ["id", "title", "projectManager", "kpis", "editableStatus", "priority", "createdAt"],
      ["id", "title", "kpis", "priority", "editableStatus"]
    );

    this.advancedQuery = new this.SavedSearchService({
      defaultCondition: { modifier: "must", field: "project_status", query: "" },
    });
    this.advancedQuery.conditions.push({
      field: "project_status",
      modifier: "must",
      query: "active",
    });
    this.advancedQuery.deleteAdvancedSearchCondition(0);
    this.updateProjects();
    this.advancedProjectsListSearchOptions = {
      setData: this.updateProjects.bind(this),
      advancedQuery: this.advancedQuery,
    };
  }
}
