import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { TBaseResource } from "../../api-routing";
import { TTransactionBases } from "../transaction_bases";

const c = initContract();

const TransactionBasisSchema = c.type<TBaseResource<TTransactionBases[]>>();
export const transactionBasisContract = c.router({
  query: {
    method: "GET",
    path: "/transaction_basis",
    responses: {
      200: TransactionBasisSchema,
    },
    query: z.object({
      for_companies: z.boolean().optional(),
    }),
    summary: "Get all transaction bases",
  },
});
