const accountSettingsTemplate = require("./account-settings.template.html");
//= require ../modals.module.js

angular.module("users.modals").component("accountSettings", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: accountSettingsTemplate,
  controller: "AccountSettingsModalCtrl",
});
