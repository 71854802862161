import { deepMapKeysMega } from "../../api-routing/deep-map-keys-mega";
import { snakeCase } from "change-case-all";

import { TCompaniesTransactionBases } from "../companies_transaction_bases";
import { CompaniesTransactionBasis } from "./companies_transaction_bases.service";

angular
  .module("companies_transaction_bases")
  .factory("CompaniesTransactionBasesSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = function (data) {
          return _.isArray(data)
            ? data.map((element) => new CompaniesTransactionBasis(element))
            : new CompaniesTransactionBasis(data);
        };
        this.serialize = function (data) {
          return deepMapKeysMega<Partial<TCompaniesTransactionBases>>(data, snakeCase);
        };
      });
    },
  ])
  .service("CompaniesTransactionBasesFactory", CompaniesTransactionBasis);
