import employeesSearchTemplate from "./employees-search.template.html";

angular.module("quicklists.components").component("quicklistEmployeesSearch", {
  bindings: {
    query: "<",
    advancedQuery: "<",
    updateEntries: "&",
  },
  template: employeesSearchTemplate,
});
