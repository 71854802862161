import { ProjectsCandidateSearchCtrl } from "./projects-candidate-search.controller";
import projectsCandidateSearchTemplate from "./projects-candidate-search.template.html";

angular.module("projects.components").component("projectsCandidateSearch", {
  bindings: {
    candidate: "<",
    query: "<?",
    remote: "=",
    setData: "&",
  },
  template: projectsCandidateSearchTemplate,
  controller: ProjectsCandidateSearchCtrl,
});
