import PrsmTableCtrl from "./prsm-table.controller";
import prsmTableTemplate from "./prsm-table.template.html";

angular.module("components.prsmTable").component("prsmTable", {
  bindings: {
    attributes: "=",
    data: "=",
    bodyDirective: "@",
    activeRow: "<",
    statePrefix: "@",
    stateArgs: "&",
    enableHotkeys: "=?",
    descriptor: "@",
    path: "@",
    pathAppendix: "@",
    collapseHeader: "@",
    context: "=?",
    lazyLoading: "<",
    list: "=",
    tableRowIdentifier: "@",
    enableInfiniteScrolling: "@",
    contextMenuOptions: "=",
    initialViewState: "<",
  },
  template: prsmTableTemplate,
  controller: PrsmTableCtrl,
});
