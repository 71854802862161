//= require ../components.module.js

angular.module("employees.components").controller("EmployeesListCtrl", [
  "SavedSearchesFactory",
  "makeCollectionProxy",
  "EmployeesFactory",
  "PrsmTableHelper",
  function (SavedSearch, makeCollectionProxy, Employee, PrsmTableHelper) {
    var _this = this;
    // _this.$on('addEmployee', function(event, employee){ _this.company.employees.push(employee); });
    // _this.$on('addProject', function(event, projects){ _this.company.projects.push(projects); });

    _this.$onInit = function () {
      _this.attributes = PrsmTableHelper.setVisibleAttributes(
        angular.copy(Employee.defaultTableAttributes),
        ["id", "name", "projects", "jobTitle", "tags", "supervisor", "responsibleUser", "createdAt"],
        ["id", "name", "projects", "jobTitle"]
      );

      _this.advancedQuery = new SavedSearch({
        defaultCondition: { modifier: "must", field: "company_name", query: "" },
      });
      _this.advancedQuery.conditions.push({
        field: "company_name",
        modifier: "must",
        query: _this.object.name,
      });
      _this.advancedQuery.deleteAdvancedSearchCondition(0);

      _this.updateEmployees(_this.advancedQuery.collectSearchParams());

      _this.advancedEmployeesListSearchOptions = {
        setData: _this.updateEmployees,
        advancedQuery: _this.advancedQuery,
        companyChart: _this.companyChart,
      };
    };

    function initSearchResults(employees) {
      _this.object.employees = employees;
      _this.lazyLoading = false;
    }

    _this.updateEmployees = function () {
      _this.lazyLoading = true;

      var params = _.extend(_this.object.params(), {
        advanced: JSON.stringify(_this.advancedQuery),
        additional_pages: Math.min(_this.object.employeesCount / 20 + 1, 3),
      });

      if (!_.isFunction(_this.object.employees.search)) {
        makeCollectionProxy(Employee.query.bind(Employee), params).then(initSearchResults);
      } else {
        _this.object.employees.search(params).then(initSearchResults);
      }
    };
  },
]);
