import { initContract } from "@ts-rest/core";

import { TOrganisationUnit } from "../organisation_units";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const OrganisationUnitsSchema = c.type<TBaseResource<TOrganisationUnit[]>>();
export const organisationUnitsContract = c.router({
  query: {
    method: "GET",
    path: "/organisation_units",
    responses: {
      200: OrganisationUnitsSchema,
    },
    summary: "Get all organisation units",
  },
});
