import CompaniesDocumentsCtrl from "./documents.controller";
import documentsTemplate from "./documents.template.html";

angular.module("companies.views").component("companiesDocuments", {
  bindings: {
    company: "<companyResponse",
    scrollspyScope: "<",
  },
  template: documentsTemplate,
  controller: CompaniesDocumentsCtrl,
});
