export default class DashboardCv2021CardCtrl {
  public loading: boolean;
  public data: any;
  public readonly projectReportingRequestObj: any;

  static $inject = ["ProjectReportingRequestObj"];

  constructor(private ProjectReportingRequestObj: any)
  {
    this.loading = true;

    this.projectReportingRequestObj = new this.ProjectReportingRequestObj(this, "dashboardCv2021Card", (result) => {
      this.data = result;
    });
  }

  public isEmpty(obj) {
    return !_.chain(obj)
      .flatten()
      .any((value) => {
        return value > 0;
      })
      .value();
  };

  public $onDestroy() {
    this.projectReportingRequestObj.deregisterEventListeners();
  };
};