import DashboardPieChartCtrl from "./dashboard-pie-chart.controller";
import dashboardPieChartTemplate from "./dashboard-pie-chart.template.html";

angular.module("dashboards.components").component("dashboardPieChart", {
  bindings: {
    data: "=",
    labels: "=",
    isEmpty: "&",
    options: "=?",
  },
  template: dashboardPieChartTemplate,
  controller: DashboardPieChartCtrl,
});
