//= require ./modals.module.js

angular.module("employees.modals").factory("EmployeesModalMixin", [
  "$uibModal",
  "$filter",
  "$translate",
  "poller",
  "$http",
  "ModalUtilMixin",
  "EmployeesCandidatesProjectsFactory",
  function ($uibModal, $filter, $translate, poller, $http, ModalUtilMixin, EmployeesCandidatesProjects) {
    function EmployeesModalMixin() {}

    function openRulesOfEngagementModal() {
      var _this = this;
      var title = $filter("translate")("SEND_TERMS_AND_CONDITIONS");

      if (!_this.workEmail && !_this.privateEmail) {
        window.alert($filter("translate")("ADD_EMAIL_TO_SEND_BUSINESS_CONDITIONS_NOTICE"));
        return;
      } else if (!_this.workEmail) {
        if (!window.confirm($filter("translate")("SEND_TO_PRIVATE_MANAGER_EMAIL_CONFIRMATION"))) {
          return;
        }
      }

      var modalInstance = ModalUtilMixin.createChooseContactModal("create_rules_of_engagement_email", title, this);

      return modalInstance.result.then(
        function (rulesOfEngagementEmail) {
          rulesOfEngagementEmail.callback.then(function (res) {
            var pollingService = poller.get(res.data.url, { delay: 1000 });
            _this.confirm($filter("translate")("SENT_BUSINESS_CONDITIONS_EMAIL_CONFIRMATION"), pollingService).then(
              function (res) {
                if (res) {
                  _this.sendDocument("send_rules_of_engagement");
                }
                pollingService.remove();
              },
              function () {
                pollingService.remove();
              }
            );
          });
        },
        function () {}
      );
    }

    function sendContractOfferModal(cpjoin) {
      var _this = this;
      var title = $filter("translate")("SEND_CONTRACT_OFFER");

      if (!_this.workEmail && !_this.privateEmail) {
        window.alert($filter("translate")("ADD_EMAIL_FOR_MANAGER_CONTRACT_OFFER_NOTICE"));
        return;
      } else if (!_this.workEmail) {
        if (!window.confirm($filter("translate")("SEND_TO_PRIVATE_MANAGER_EMAIL_CONFIRMATION"))) {
          return;
        }
      }

      var modalInstance = ModalUtilMixin.createChooseContactModal("create_contract_offer_email", title, this, this, {
        cpjoin_id: cpjoin.id,
      });

      return modalInstance.result.then(
        function (employeesContractOfferEmail) {
          employeesContractOfferEmail.callback.then(
            function (res) {
              var pollingService = poller.get(res.data.url, { delay: 1000 });
              _this.confirm($filter("translate")("SENT_CONTRACT_OFFER_NOTICE"), pollingService).then(
                function (res) {
                  pollingService.remove();
                },
                function () {
                  pollingService.remove();
                }
              );
            },
            function () {}
          );
        },
        function () {}
      );
    }

    function editResearcherModal() {
      var employeesCandidatesProject = this.employeesCandidatesProject;
      var employee = this;

      var modalInstance = $uibModal.open({
        component: "editResearcherModal",
        size: "lg",
        resolve: {
          candidatesProject: [
            function () {
              return angular.copy(employeesCandidatesProject); // workaround to prevent code duplication
            },
          ],
          project: [
            "ProjectsFactory",
            function (Project) {
              return Project.get(employeesCandidatesProject.project.id);
            },
          ],
        },
      });

      return modalInstance.result.then(
        function (editedECPCandidatesProject) {
          return $http
            .post(employee.$url() + "/employees_candidates_projects/" + editedECPCandidatesProject.id + ".json", {
              employees_candidates_projects: editedECPCandidatesProject,
            })
            .then(
              function (updatedEmployeesCandidatesProject) {
                var ecp = new EmployeesCandidatesProjects(updatedEmployeesCandidatesProject.data.data);
                return _.extend(employee.employeesCandidatesProject, ecp);
              },
              function () {
                // Error
              }
            );
        },
        function () {
          return false;
        }
      );
    }

    function showDuplicatesModal(employee, duplicates) {
      var modalInstance = $uibModal.open({
        component: "employeesShowDuplicates",
        size: "lg",
        resolve: {
          duplicates: function () {
            return duplicates;
          },
          employee: function () {
            return employee;
          },
        },
      });

      return modalInstance.result;
    }

    EmployeesModalMixin.extended = function (Resource) {
      Resource.include({
        openRulesOfEngagementModal: openRulesOfEngagementModal,
        sendContractOfferModal: sendContractOfferModal,
        editResearcher: editResearcherModal,
        showDuplicates: showDuplicatesModal,
      });
    };

    return EmployeesModalMixin;
  },
]);
