//= require ../users.module.js
import { Language } from "../../languages/services/languages.service";
angular.module("users").factory("UsersFactory", [
  "$filter",
  "RailsResource",
  "railsSerializer",
  "usersSingleton",
  "UsersModalMixin",
  "TextkernelModalMixin",
  "ProjectReportingsFactory",
  function (
    $filter,
    RailsResource,
    railsSerializer,
    usersSingleton,
    UsersModalMixin,
    TextkernelModalMixin,
    ProjectReporting
  ) {
    function User() {
      (<any>User).__super__.constructor.apply(this, arguments);
      this.name = this.fullName;
      this.resourceType = "User";

      this.params = function () {
        return { user_id: this.id };
      };

      this.isResearcher = function () {
        return this.type == "ResearchConsultantUser";
      };

      this.isSales = function () {
        return this.type == "SalesConsultantUser";
      };

      this.getType = function () {
        return this.type.replace(/ConsultantUser$/g, "");
      };

      this.preferredLanguage = function () {
        return _.findWhere(Language.defaultOptions, { key: this.locale }).key || "de";
      };

      this.getTeamName = function () {
        if (this.team) {
          return $filter("translate")(User.teams[this.team].identifer);
        } else {
          return undefined;
        }
      };

      this.loadKpisPerYear = function (params) {
        return ProjectReporting.$get("project_reporting/kpis_per_year.json", params);
      };
    }

    User.getAll = function () {
      if (usersSingleton.data) {
        return usersSingleton.data;
      } else {
        return (<any>User).query({ get_all: true }).then(function (response) {
          return (usersSingleton.data = response);
        });
      }
    };

    User.findBy = function (id) {
      var users = User.getAll();
      return _.find(users, function (user) {
        return user.id == id;
      });
    };

    User.translatedTeams = function () {
      return _.chain(User.teams)
        .values()
        .each(function (team) {
          return _.extend(team, { name: $filter("translate")(team.identifier) });
        })
        .value();
    };

    User.pickTranslatedTeams = function (teamIdentifiers) {
      return _.where(User.translatedTeams(), { id: teamIdentifiers });
    };

    User.teams = {
      med_tec: { identifier: "TEAM_MED_TEC", id: "med_tec" },
      pharma: { identifier: "TEAM_PHARMA", id: "pharma" },
      ch: { identifier: "TEAM_CH", id: "ch" },
      italy: { identifier: "TEAM_ITALY", id: "italy" },
    };

    RailsResource.extendTo(User);
    (<any>User).configure({
      url: "/users",
      name: "user",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("projects", "ProjectsFactory");
        this.resource("employees", "EmployeesFactory");

        this.resource("projectsOneMonthInactive", "ProjectsFactory");
      }),
    });
    (<any>User).extend(UsersModalMixin);
    (<any>User).extend(TextkernelModalMixin);

    User.prototype.current = function () {
      return this.$get(this.$url() + "/current");
    };
    return User;
  },
]);
