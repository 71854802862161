export default class CandidateSelectCtrl{ 
  public candidates: any[];

  static $inject = ["$rootScope", "CandidatesFactory", "Autocompletes"];
  constructor(
    private $rootScope: ng.IRootScopeService,
    public Candidate: any,
    private Autocompletes: any) {
      _.defaults(this, { placeholder: "CANDIDATES" });
    }

  public autocomplete (text) {
    if (!text.length) {
      this.candidates = [];
      return false;
    }

    return this.Autocompletes.autocomplete(text, "candidate_name").then( (res) => {
      this.candidates = _.chain(res)
        .map( (entry) => {
          return entry.candidate ? new this.Candidate(entry.candidate) : null;
        })
        .compact()
        .uniq()
        .value();
    });
  }
}


