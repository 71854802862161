export default class InvoicesListCtrl {
  private attributes: any;
  private invoices: any[];
  private project: any;
  private lazyLoading: boolean;
  private advancedQuery: any;
  private preload: boolean;

  static $inject = ["SavedSearchesFactory", "makeCollectionProxy", "InvoicesFactory",];

  constructor (private SavedSearch: any, private makeCollectionProxy: any, private Invoice: any) {}

  public $onInit () {
    this.attributes = angular.copy(this.Invoice.defaultTableAttributes);
    this.invoices = this.project.invoices;

    if (this.preload) {
      this.advancedQuery = new this.SavedSearch();
      this.updateInvoices(this.advancedQuery.collectSearchParams());
    };
  };

  private initSearchResults (invoices) {
    this.invoices = invoices;
    this.lazyLoading = false;
  };

  private updateInvoices (params) {
    this.lazyLoading = true;

    var params = _.extend(this.project.params(), {
      advanced: JSON.stringify(this.advancedQuery),
      additional_pages: Math.min(this.project.invoicesCount / 20 + 1, 3),
    });

    this.makeCollectionProxy(_.partial(this.Invoice.query.bind(this.Invoice), _, this.project.params()), params).then(
      this.initSearchResults.bind(this),
    );
  };
}