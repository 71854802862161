import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { OrganisationUnit } from "./organisation_units.service";

angular
  .module("organisation_units")
  .factory("OrganisationUnitSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(OrganisationUnit, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("OrganisationUnitsFactory", OrganisationUnit);
