import ChooseContactModalCtrl from "./choose-contact-modal.controller";
import chooseContactModalTemplate from "./choose-contact-modal.template.html";

angular.module("modals.modals").component("chooseContactModal", {
  template: chooseContactModalTemplate,
  controller: ChooseContactModalCtrl,
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
});
