export default class DashboardPieChartCtrl {
  public colors: string[];
  public data: number[];
  public options: 
  {
    responsive: boolean,
    aspectRatio: number,
    maintainAspectRatio: boolean,
    layout: {
      padding: {
        left: number,
        right: number,
        top: number,
        bottom: number,
      },
    },
    legend: {
      display: boolean,
      position: string,
      labels: {
        boxWidth: number,
        fontSize: number,
        padding: number,
        usePointStyle: boolean,
      },
    },
  };

  static $inject = ["ChartColors2021"];

  constructor(private ChartColors: string[]) {}

  public $onInit() {
    this.colors = _.map(_.range(this.data.length), (index) => {
      return this.ChartColors[index % this.ChartColors.length];
    });

    this.options = _.defaults(this.options, {
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 12,
          fontSize: 10,
          padding: 16,
          usePointStyle: true,
        },
      },
    });
  };

  public isEmpty() {
    return (
      _.reduce(
        this.data,
        (memo, num) => {
          return memo + num;
        },
        0
      ) === 0
    );
  };
};
