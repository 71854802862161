import { CompaniesTransactionBasis } from "../../../companies_transaction_bases/services/companies_transaction_bases.service";
import { TransitionService } from "@uirouter/angularjs";

import documentPaginationTemplate from "../../../directives/components/pagination/pagination.template.html";
import { FileUploader, FileUploaderFactory } from "angular-file-upload";

export default class DocumentsListCtrl {
  private paginationService: any;
  private paginationTemplateUrl: string;
  private documents: any[];
  private resource: any;
  private advancedQuery: any;
  private disableSearch: boolean;
  private chooseFileType: boolean;
  private cancel: boolean;
  private lazyLoading: boolean;
  private remove: ((item: any) => void); 
  private uploader: FileUploader;
  private advancedDocumentsSearchOptions: any;
  private setData: () => void;
  public paginationId: string;
  public CompaniesTransactionBases: any;
  public documentFileTypes: any[];
  public attributes: {
    title: { show: boolean },
    fileType: { show: boolean },
    actions: { show: boolean },
    menu: { show: boolean },
  };

  static $inject = ["$rootScope",
                    "$transitions",
                    "$log",
                    "$cookies",
                    "$filter",
                    "csrfCookieName",
                    "FileUploader",
                    "CandidatesFactory",
                    "CompaniesFactory",
                    "ProjectsFactory",
                    "DealsFactory",
                    "hotkeys",
                    "DocumentsFactory",
                    "makeCollectionProxy",
                    "SavedSearchesFactory",
                    "MgPaginateService",
                    "$templateCache"];

  constructor ( private $rootScope: ng.IRootScopeService,
                private $transitions: TransitionService,
                private $log: ng.ILogService,
                private $cookies: angular.cookies.ICookiesService,
                private $filter: ng.IFilterService,
                private csrfCookieName: any,
                private FileUploader: FileUploaderFactory,
                public Candidate: any,
                public Company: any,
                public Project: any,
                public Deal: any,
                private hotkeys: any,
                private Document: any,
                private makeCollectionProxy: any,
                private SavedSearch: any,
                private MgPaginateService: any,
                private $templateCache: ng.ITemplateCacheService) {
                  this.paginationTemplateUrl = "paginationTemplate";
                  $templateCache.put(this.paginationTemplateUrl, documentPaginationTemplate);

                  this.paginationService = new MgPaginateService();
                }
  
  public documentFileTypeUpdate (document) {
    new this.Document({ id: document.id, file_type: document.fileType.id }).save();
  };

  public openDocumentFullScreen (openDocument: any, event: Event) {
    event.preventDefault();
    if (!openDocument) {
      return;
    }
    new this.Document(openDocument).openDocumentFullScreen(this.documents);
  };

  public removeDocument (index, item) {
    const sliceItem = () => {
      var _index = _.findIndex(this.documents, _.matcher({ id: item.id }));

      if (_index == -1) return;
      this.documents.splice(_index, 1);
      this.paginationService.setData(this.documents);
      this.paginationService.setTotalItems(this.documents.length);
    }

    if (this.remove) {
      this.remove(item);
      sliceItem();
    } else {
      if (window.confirm(this.$filter("translate")("DELETE_DOCUMENT_CONFIRMATION"))) {
        new this.Document(item).delete().then(
          (result) => {
            sliceItem();
          },
          (error) => {
            window.alert(
              `${this.$filter("translate")("ERROR_WHITE_DELETING_NOTICE")} ${this.$filter("translate")(
                "TRY_AGAIN_OR_CONTACT_HELPDESK"
              )}`
            );
          }
        );
      }
    }
  };

  public moveDocument (oldIndex, newIndex, item) {
    if (newIndex < 0 || newIndex == this.documents.length) {
      return false;
    }

    this.documents.splice(oldIndex, 1);
    this.documents.splice(newIndex, 0, item);

    _.each(this.documents, (doc, index) => {
      doc.sort = index;
    });
  };

  public documentLimitExceeded () {
    if (this.resource?.contractType?.type === "TransactionBasis" && this.resource.documents.length == 1) {
      return true;
    } else {
      return false;
    }
  };

  public getSelectableDocumentFileTypes () {
    if (this.resource?.type === "Deal") {
      return _.filter(this.Document.fileTypes, (fileType) => {
        return _.contains(
          ["PURCHASE_ORDER", "CLIENT_CONTRACT", "PROJECT_CONTRACT", "SERVICE_CONTRACT", "DOCUMENT"],
          fileType.name
        );
      });
    } else {
      return this.Document.fileTypes;
    }
  };

  public $onInit () {
    angular.extend(this, {
      paginationId: "documents-list-".concat("-", _.uniqueId()),
      CompaniesTransactionBases: CompaniesTransactionBasis,
      documentFileTypes: this.getSelectableDocumentFileTypes(),
      attributes: {
        title: { show: true },
        fileType: { show: this.chooseFileType },
        actions: { show: this.cancel },
        menu: { show: !this.cancel },
      },
    });

    if (this.resource) {
      this.initializeUploader();

      if (!this.hotkeys.get("ctrl+u")) {
        this.hotkeys.add({
          combo: "ctrl+u",
          description: "Hochladen eines Dokuments",
          callback: (event, hotkey) => {
            event.preventDefault();
            $("input#uploadDialog:first").trigger("click");
          },
        });

        var dereg = this.$transitions.onSuccess({}, () => {
          this.hotkeys.del("ctrl+u");
          dereg();
        });
      }

      this.$rootScope.$on("event:auth-loginConfirmed", this.initializeUploader);
    }

    if (!this.disableSearch) {
      this.advancedQuery = new this.SavedSearch({
        defaultCondition: { modifier: "must", field: "file_type", query: "" },
      });
      if (this.resource.resourceType === "Candidate") {
        this.advancedQuery.conditions.push({
          field: "file_type",
          modifier: "or",
          query: _.findWhere(this.Document.fileTypes, { name: "CV_ANONYMOUS" }),
        });
        this.advancedQuery.conditions.push({
          field: "file_type",
          modifier: "or",
          query: _.findWhere(this.Document.fileTypes, { name: "CV_ORIGINAL" }),
        });
        this.advancedQuery.conditions.push({
          field: "file_type",
          modifier: "or",
          query: _.findWhere(this.Document.fileTypes, { name: "CV_EXTERNAL" }),
        });
      }

      this.advancedQuery.deleteAdvancedSearchCondition(0);

      var initSearchResults = (documents) => {
        this.documents = documents;
        this.paginationService.setData(this.documents);
        this.paginationService.setTotalItems(this.documents.length);
  
        this.$rootScope.$broadcast("candidateDocumentsChanged");
  
        this.lazyLoading = false;
      };

      var updateObjects = (params) => {
        this.lazyLoading = true;
  
        var params = _.extend(this.resource.params(), {
          advanced: JSON.stringify(this.advancedQuery.beforeStringify()),
          additional_pages: Math.min(this.documents?.length / 20 + 1, 3),
        });
  
        this.makeCollectionProxy(this.Document.query.bind(this.Document), params).then(initSearchResults);
      };

      
      this.advancedDocumentsSearchOptions = {
        setData: updateObjects,
        advancedQuery: this.advancedQuery,
      };

      updateObjects(this.advancedQuery.collectSearchParams());
      
    } else {
      this.paginationService.setData(this.documents);
      this.paginationService.setTotalItems(this.documents?.length);
    }
  };

  public initializeUploader() {
    if (!this.uploader) {
      this.uploader = new this.FileUploader({
        url: this.resource.$url(),
        alias: "document_file",
        removeAfterUpload: true,
        method: "PUT",
        autoUpload: true,
        //queueLimit: 1,
        headers: {
          "X-CSRF-TOKEN": this.$cookies.get(this.csrfCookieName),
        }as any,
      });
      this.uploader.onSuccessItem = (item: any, response: any) => {
        this.$log.debug("Document upload complete");
        var updatedResource = new this[response.data.type](response.data);
        this.$rootScope.$broadcast("resetObjectChangedState", updatedResource);
        this.documents = updatedResource.documents;
        this.paginationService.setData(this.documents);
        this.paginationService.setTotalItems(this.documents.length);

        new this.Document(_.last(this.documents)).textkernelDiff();

        if (this.resource) {
          // todo  or skip line 140 ??
          this.resource.type = updatedResource.type;
          this.resource.documents = this.documents;
          this.resource.snapshot();
        }

        if (updatedResource.actions) {
          this.resource.actions = updatedResource.actions;
        } else {
          this.$rootScope.$broadcast(this.resource.type.toLowerCase() + "_action_changed_" + this.resource.id);
        }
      };
      this.uploader.onCompleteAll= () => {
        //$state.go('^.show', $scope.candidate.params());
      };
      this.uploader.onErrorItem = (item, response, status, headers) => {
        window.alert(this.$filter("translate")("ERROR_WHILE_SAVING_NOTICE"));
        window.rollbar.error("document upload failed", { status: status, file_info: item.file, url: item.url });
      };

      this.uploader.filters.push({
        name: "fileExtensionFilter",
        fn: (item) => {
          return /\/(pdf)$/.test(item.type);
        },
      });
    } else {
      this.uploader.headers = {
        "X-CSRF-TOKEN": this.$cookies.get(this.csrfCookieName),
      } as any;
    }
  };
}