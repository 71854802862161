import { ActionType } from "../../../action_types/services/action_types.service";
import { Department } from "../../../departments/services/departments.service";
import { JobCategory } from "../../../job_categories/services/job_categories.service";
import { Hierarchy } from "../../../hierarchies/services/hierarchies.service";
import { Region } from "../../../regions/services/regions.service";
import { JobType } from "../../../job_types/services/job_types.service";

angular.module("directives.advanced_search").factory("defaultSelectorFields", [
  "ProjectsFactory",
  "CandidatesProjectsFactory",
  "CandidatesFactory",
  "DocumentsFactory",
  "UsersFactory",
  "$filter",
  function (Project, CandidatesProjects, Candidate, Document, User, $filter) {
    let getUserName = function (entry) {
      return _.contains(["sales_consultant_user", "research_consultant_user", "user"], entry.resource_type)
        ? entry.user.full_name
        : entry.highlight[0];
    };

    let promises = [
      JobCategory.getAll(),
      Department.getAll(),
      Hierarchy.getAll(),
      Region.getAll(),
      JobType.getAll(),
      ActionType.getAll(),
    ];

    return Promise.all(promises).then(([jobCategories, departments, hierarchies, regions, jobTypes, actionTypes]) => {
      return {
        tags: {
          name: "Tags",
          autocomplete: true,
          skipUniq: true,
          getName: function (entry) {
            return (entry.tag && entry.tag.name) || entry.highlight[0];
          },
        },
        candidate_name: {
          name: $filter("translate")("CANDIDATE"),
          autocomplete: true,
          getName: function (entry) {
            return (entry.candidate && entry.candidate.name) || entry.highlight[0];
          },
        },
        candidate_email: { name: $filter("translate")("EMAIL_ADDRESS_SEARCH"), autocomplete: true },
        candidate_phone_number: { name: $filter("translate")("TELEPHONE_NUMBER"), autocomplete: true },
        profile_source: {
          name: $filter("translate")("PROFILE_SOURCE"),
          staticList: _.where(Candidate.profileSources, { selectable: true }),
        },
        language: {
          name: $filter("translate")("LANGUAGE"),
          isEmpty: function (condition) {
            return !(condition.languageId || condition.languageLevel);
          },
        },
        job_category: {
          name: $filter("translate")("MARKET"),
          staticList: _.map(jobCategories, function (e) {
            return _.pick(e, "id", "name", "slug");
          }),
        },
        department: {
          name: $filter("translate")("DEPARTMENT"),
          staticList: _.map(departments, function (e) {
            return _.pick(e, "id", "name", "slug");
          }),
        },
        hierarchy: {
          name: $filter("translate")("HIERARCHY"),
          staticList: _.map(hierarchies, function (e) {
            return _.pick(e, "id", "name", "slug");
          }),
        },
        region: { name: $filter("translate")("REGION"), staticList: regions },
        job_type: { name: $filter("translate")("PROJECT_TYPE"), staticList: jobTypes },
        employee_name: {
          name: $filter("translate")("MANAGER"),
          autocomplete: true,
          getName: function (entry) {
            return (entry.employee && entry.employee.full_name) || entry.highlight[0];
          },
        },
        company_name: {
          name: $filter("translate")("COMPANY"),
          autocomplete: true,
          getName: function (entry) {
            return (entry.company && entry.company.name) || entry.highlight[0];
          },
        },
        responsible_user: {
          name: $filter("translate")("CONSULTANT"),
          autocomplete: true,
          getName: getUserName,
        },
        completed_by_user: {
          name: $filter("translate")("FILTER_COMPLETED_BY_USER"),
          autocomplete: true,
          getName: getUserName,
        },
        responsible_researcher: {
          name: $filter("translate")("RESEARCH_CONSULTANT_SEARCH"),
          autocomplete: true,
          getName: getUserName,
        },
        project_status: {
          name: $filter("translate")("PROJECT_STATUS_SEARCH"),
          staticList: Project.statuses,
        },
        project_title: {
          name: $filter("translate")("PROJECT"),
          autocomplete: true,
          getName: function (entry) {
            return (entry.project && entry.project.title) || entry.highlight[0];
          },
        },
        employee_email: { name: $filter("translate")("EMAIL_ADDRESS_SEARCH"), autocomplete: true },
        employee_phone_number: { name: $filter("translate")("TELEPHONE_NUMBER"), autocomplete: true },
        longlist_status: {
          name: $filter("translate")("STATUS_PROJECT"),
          staticList: CandidatesProjects.statuses,
        },
        action_type: {
          name: $filter("translate")("HISTORY_TYPES"),
          staticList: _.chain(<ActionType[]>actionTypes)
            .map(function (entry) {
              return _.extend({ name: entry.completeDescription }, entry);
            })
            .value(),
        },
        file_type: {
          name: $filter("translate")("FILE_TYPE"),
          staticList: Document.fileTypes,
        },
        job_offer: {
          name: $filter("translate")("JOB_OFFERS"),
          autocomplete: true,
          getName: function (entry) {
            return entry.resource_type == "job_offer" ? entry.job_offer.name : entry.highlight[0];
          },
        },
        team: { name: $filter("translate")("TEAM"), staticList: User.translatedTeams() },
      };
    });
  },
]);
