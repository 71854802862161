import { IAngularEvent } from "angular";
import { Tag } from "../../services/tags.service";
export class TagSearchCtrl {
  static $inject = ["$rootScope", "$filter", "Autocompletes"];
  constructor(
    private $rootScope: IExtendedRootScopeService,
    private $filter: ng.IFilterService,
    private Autocompletes: any
  ) {}

  private listenOnToUpdateDeregistration: () => void;
  private typeTagSearchChangedDeregistration: ng.IAngularEvent;
  public type: string;
  public initialLoad: boolean;
  public tagSuggestions: Tag[];
  private filters: () => Record<string, Object>;
  public listenOnToUpdate: string;

  public $onInit() {
    _.defaults(this, { placeholder: this.$filter("translate")("TAGS"), filters: angular.noop });

    if (this.initialLoad) {
      this.autocomplete("");

      if (this.listenOnToUpdate) {
        this.listenOnToUpdateDeregistration = this.$rootScope.$on(this.listenOnToUpdate, () => {
          this.autocomplete("");
        });
      }
    }
  }

  public autocomplete(text) {
    if (!text.length && !this.initialLoad) {
      this.tagSuggestions = [];
      return false;
    }

    let scope: string;

    if (this.type !== undefined) {
      scope = this.type;
    } else {
      scope = "tags";
    }

    let filter_params: Record<string, Object> = this.filters() || {};

    return this.Autocompletes.autocomplete(text, scope, undefined, _.keys(filter_params), _.values(filter_params)).then(
      (res) => {
        this.tagSuggestions = _.chain(res)
          .map((entry) => {
            return entry.tag ? new Tag(entry.tag) : null;
          })
          .compact()
          .uniq()
          .value();
      }
    );
  }

  public itemSelected() {
    if (this.type) {
      this.typeTagSearchChangedDeregistration = this.$rootScope.$broadcast(this.type + "-tag-search-changed");
    }
    if (this.initialLoad) {
      this.autocomplete("");
    }
  }

  public $onDestroy() {
    [this.listenOnToUpdateDeregistration, this.typeTagSearchChangedDeregistration]
      .forEach((deregistration: IAngularEvent | (() => void)) => {
        if (angular.isFunction(deregistration)) deregistration();
      });
  }
}
