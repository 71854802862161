import { SavedSearchesCtrl } from "./saved-searches.controller";
import savedSearchesTemplate from "./saved-searches.template.html";

angular.module("saved_searches.components").component("savedSearches", {
  bindings: {
    disableSavedSearch: "=",
    advancedQuery: "=",
    query: "=",
    statePrefix: "@",
  },
  template: savedSearchesTemplate,
  controller: SavedSearchesCtrl,
});
