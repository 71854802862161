import ShowContractDraftCtrl from "./show-contract-draft.controller";
import showContractDraftTemplate from "./show-contract-draft.template.html";

angular.module("contract_drafts.components").component("showContractDraft", {
  bindings: {
    contractDraft: "<",
    showSalaryInformation: "<",
  },
  controller: ShowContractDraftCtrl,
  template: showContractDraftTemplate,
});
