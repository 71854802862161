export class StateTransitionsConfig {
  constructor() {}

  public static reduceParams(params): string {
    var args = _.reduce(
      params,
      (memo, obj) => {
        return _.extend(memo, obj);
      },
      {}
    );
    return JSON.stringify(args);
  }

  public static state(statePrefix, state, params): string {
    return (statePrefix || "") + "." + state + "(" + params + ")";
  }

  public static transitionOptions(initialViewState): { relative: string } {
    return { relative: initialViewState.name };
  }
}
