angular.module("filters").filter("phoneNumber", [
  function () {
    return function (input) {
      if (!input || input.length === 0 || input.match(/[a-z]/i)) {
        return input;
      } else if (window.intlTelInputUtils.isValidNumber(input, "DE")) {
        return window.intlTelInputUtils.formatNumber(input, null, window.intlTelInputUtils.numberFormat.INTERNATIONAL);
      } else if (window.intlTelInputUtils.isValidNumber(input, "DE")) {
        return window.intlTelInputUtils.formatNumber(input, "DE", window.intlTelInputUtils.numberFormat.INTERNATIONAL);
      } else {
        return input;
      }
    };
  },
]);
