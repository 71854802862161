import { IFilterService, ILogService, IQService } from "angular";

export default class EmployeeSelectCtrl {
  public foundEmployees: any[];
  public loading: boolean;
  public cancelers: ng.IDeferred<any>[] = [];
  public params: any;
  public companyId: number;
  public projectId: number;
  public exclude: any;

  static $inject = ["$rootScope", "$q", "$log", "$filter", "Autocompletes", "EmployeesFactory"];

  constructor(
    private $rootScope: IExtendedRootScopeService,
    private $q: IQService,
    private $log: ILogService,
    private $filter: IFilterService,
    private Autocompletes: any,
    private Employee: any
  ) {
    this.foundEmployees = [];
    this.loading = false;
    this.cancelers = [];
  }

  public searchEmployees(viewValue) {
    this.loading = true;
    _.invoke(this.cancelers, "resolve");
    this.cancelers.push(this.$q.defer());

    if (this.companyId) {
      this.params = [
        viewValue,
        "employee_by_company",
        _.last(this.cancelers),
        "company",
        { company_id: this.companyId },
      ];
    } else if (this.projectId) {
      this.params = [
        viewValue,
        "employee_by_project",
        _.last(this.cancelers),
        "project",
        { project_id: this.projectId },
      ];
    } else {
      this.params = [viewValue, "employee_name", _.last(this.cancelers)];
    }

    this.Autocompletes.autocomplete.apply(this, this.params).then((results) => {
      if (!results) {
        return;
      }

      results.splice(0, 1); // remove query string from results list

      this.foundEmployees = _.chain(results)
        .map((result) => {
          switch (result.resource_type) {
            case "employee":
              return _.extend(new this.Employee(result.employee), {
                resource_type: this.$filter("translate")("MANAGER"),
              });
            default:
              result.name = result.highlight[0];
              return _.extend(result, {
                resource_type: "Query",
              });
          }
        })
        .filter((obj) => {
          return this.exclude != obj.id;
        })
        .value();
      this.loading = false;
    });
  };

  public triggerProjectSelectBroadcast($item, $model) {
    this.$rootScope.$broadcast("projectChanged", $item);
  };
};