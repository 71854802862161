import { AdvancedProjectsSearchCtrl } from "./advanced-projects-search.controller";
import advancedProjectsSearchTemplate from "./advanced-projects-search.template.html";

angular.module("projects.components").component("advancedProjectsSearch", {
  bindings: {
    advancedQuery: "=",
    setData: "=",
    closing: "&",
  },
  template: advancedProjectsSearchTemplate,
  controller: AdvancedProjectsSearchCtrl,
});
