const jobOffersTableBodyTemplate = require("./job-offers-table-body.template.html");
import JobOffersTableBodyControllerCtrl from "./job-offers-table-body.controller";
//= require ../components.module.js

angular.module("job_offers.components").component("jobOffersTableBody", {
  bindings: {
    jobOffers: "=data",
    attributes: "=",
    activeRow: "=",
    statePrefix: "@",
  },
  template: jobOffersTableBodyTemplate,
  controller: JobOffersTableBodyControllerCtrl,
});
