import { QuicklistsCandidatesListCtrl } from "./quicklist-candidates-list.controller";
import candidatesListTemplate from "./candidates-list.template.html";

angular.module("quicklists.components").component("quicklistCandidatesList", {
  bindings: {
    advancedQuery: "<",
    quicklist: "<",
    quicklistEntriesLazyLoading: "<",
    setKpiKey: "=",
    steps: "<",
  },
  template: candidatesListTemplate,
  controller: QuicklistsCandidatesListCtrl,
});
