import workingRelationshipFormTemplate from "./working-relationship-form.template.html";
import WorkingRelationshipFormCtrl from "./working-relationship-form.controller";

angular.module("working_relationships.components").component("workingRelationshipForm", {
  bindings: {
    person: "=",
  },
  template: workingRelationshipFormTemplate,
  controller: WorkingRelationshipFormCtrl,
});
