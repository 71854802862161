import systemMenuTemplate from "../../../components/system-menu/system-menu.template.html";
import { Tag } from "../../../../tags/services/tags.service";
import { IFilterService, ITemplateCacheService } from "angular";

export default class MainViewCtrl {
  public online: boolean;
  public currentUser: any;
  public expandUserSidebar: (id: number) => (ids: number[]) => void;
  public expandQuicklist: (id: number) => (ids: number[]) => void;
  public updateExpandUserSidebar: (id: number, status: string) => (ids: number[]) => void;
  public updateExpandQuicklist: (id: number, status: string) => (ids: number[]) => void;
  public sessionTimeoutListener: () => void;
  public loginRequiredListener: () => void;
  static $inject = [
    "$rootScope",
    "$filter",
    "AuthService",
    "Session",
    "AUTH_EVENTS",
    "Keepalive",
    "Idle",
    "QuicklistsFactory",
    "SidebarService",
    "$templateCache"
  ];

  constructor (
    private $rootScope: IExtendedRootScopeService,
    private $filter: IFilterService,
    private AuthService: any,
    private Session: any,
    private AUTH_EVENTS: any,
    private Keepalive: any,
    private Idle: any,
    private Quicklist: any,
    private SidebarService: any,
    private $templateCache: ITemplateCacheService
  ) {
    this.$templateCache.put("system-menu-template", systemMenuTemplate);

    if (this.Session.user) {
      this.loginConfirmed();
    }

    this.sessionTimeoutListener = this.$rootScope.$on("IdleTimeout", () => {
      this.$rootScope.$emit("sessionTimeout", true);
    });

    this.loginRequiredListener = this.$rootScope.$on("KeepaliveResponse", (event, data, status) => {
      if (status === 0) {
        if (this.online) {
          // network not available
          this.online = false;
          window.alert(this.$filter("translate")("NO_INTERNET_CONNECTION_NOTICE"));
        }
      } else {
        // network available
        this.online = true;
      }
    });

    this.online = true;

    this.$rootScope.$on("sessionTimeout", this.timeout);
    this.$rootScope.$on("event:auth-loginRequired", this.timeout);
    this.$rootScope.$on("event:auth-loginConfirmed", this.loginConfirmed);
  }
  
  public $onInit() {
    this.online = true;

    this.expandUserSidebar = this.SidebarService.getSidebarStatus("expandUserSidebar");
    this.expandQuicklist = this.SidebarService.getSidebarStatus("expandQuicklist");

    this.updateExpandUserSidebar = _.partial(this.SidebarService.updateSideBar, "expandUserSidebar");
    this.updateExpandQuicklist = _.partial(this.SidebarService.updateSideBar, "expandQuicklist");
  };

  public logout(force?) {
    this.Keepalive.stop();
    this.Idle.unwatch();
    this.$rootScope.$emit("$stateChangeAbort");

    if (force) {
      this.AuthService.logout().then(() => {
        Tag.invalidateCache();
        this.$rootScope.$broadcast(this.AUTH_EVENTS.logoutSuccess);
      });
    } else {
      this.AuthService.openLoginFormModal();
    }
  };

  public timeout() {
    if (!this.$rootScope.cancelTimeout) {
      this.logout();
    } else {
      delete this.$rootScope.cancelTimeout;
      this.Keepalive.start();
      this.Idle.watch();
    }
  };

  public loginConfirmed() {
    this.currentUser = this.Session.user;

    this.Quicklist.clearCache();
    this.$rootScope.$broadcast("updatedQuicklist");

    this.Keepalive.start();
    this.Idle.watch();
  };

  public $onDestroy() {
    this.sessionTimeoutListener();
    this.loginRequiredListener();
  };
};