import { ResearchCommissionsFormCtrl } from "./research-commissions-form.controller";
import researcherCommissionsFormTemplate from "./researcher-commissions-form.template.html";

angular.module("researcher_commissions.components").component("researchCommissionsForm", {
  bindings: {
    deal: "=",
  },
  controller: ResearchCommissionsFormCtrl,
  template: researcherCommissionsFormTemplate,
});
