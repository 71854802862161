import AdvancedDocumentsSearchCtrl from "./advanced-documents-search.controller";
import advancedDocumentsSearchTemplate from "./advanced-documents-search.template.html";

angular.module("documents.components").component("advancedDocumentsSearch", {
  bindings: {
    advancedQuery: "=",
    setData: "=",
    closing: "&",
  },
  template: advancedDocumentsSearchTemplate,
  controller: AdvancedDocumentsSearchCtrl,
});
