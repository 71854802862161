//= require ../modals.module.js

angular.module("modals").factory("ModalMixin", [
  "$uibModal",
  function ($uibModal) {
    function ModalMixin() {}

    function openConfirmModal(message, pollingService) {
      var modalInstance = $uibModal.open({
        component: "confirmModal",
        size: "sm",
        resolve: {
          message: function () {
            return message;
          },
          pollingService: function () {
            return pollingService;
          },
        },
      });
      return modalInstance.result;
    }

    ModalMixin.extended = function (Resource) {
      Resource.include({
        confirm: openConfirmModal,
      });
    };

    return ModalMixin;
  },
]);
