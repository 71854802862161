import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { CancelationPeriod } from "./cancelation_periods.service";

angular
  .module("cancelation_periods")
  .factory("CancelationPeriodSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(CancelationPeriod, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("CancelationPeriodsFactory", CancelationPeriod);
