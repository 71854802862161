//= require ../locations.module.js

angular.module("locations").factory("LocationsFactory", [
  "Autocompletes",
  "$q",
  function (Autocompletes, $q) {
    function Location() {
      (<any>Location).__super__.constructor.apply(this, arguments);
    }

    Location.displayName = function (address) {
      return _.chain([
        address.road || address.name,
        address.house_number,
        address.postcode,
        address.city,
        address.country,
      ])
        .reject(_.isEmpty)
        .value()
        .join(" ");
    };

    Location.autocomplete = function (searchQuery, scope) {
      var deferred = $q.defer();

      var debouncedFn = _.debounce(function (text, scope) {
        if (_.isEmpty(text)) {
          deferred.resolve([]);
          return;
        }
        Autocompletes.address(text, scope).then(function (res) {
          var locations = _.chain(res)
            .map(function (entry) {
              return {
                displayName: _.first(entry.highlight) || Location.displayName(entry.address),
                id: entry.id,
                address: entry.address,
              };
            })
            .uniq(false, <any>_.iteratee("displayName"))
            .value();

          deferred.resolve(locations);
        });
      }, 300);

      debouncedFn(searchQuery, scope);
      return deferred;
    };

    return Location;
  },
]);
