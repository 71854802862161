//= require ../candidates_projects.module.js

angular.module("candidates_projects").service("CandidatesProjectsFactory", [
  "RailsResource",
  "railsSerializer",
  "$http",
  "$rootScope",
  "$q",
  "$filter",
  "$sce",
  "CandidatesProjectsModalMixin",
  "ModalMixin",
  "RailsResourceSnapshotsMixin",
  "ActionsFactory",
  "CandidatesProjectStatuses",
  function (
    RailsResource,
    railsSerializer,
    $http,
    $rootScope,
    $q,
    $filter,
    $sce,
    CandidatesProjectsModalMixin,
    ModalMixin,
    RailsResourceSnapshotsMixin,
    Action,
    CandidatesProjectStatuses
  ) {
    function CandidatesProjects() {
      (<any>CandidatesProjects).__super__.constructor.apply(this, arguments);

      function invalidClosing(cp) {
        // project job type is not set
        return _.isEmpty(cp.project.jobType) && cp.status.name == "CLOSING";
      }

      function handleStatusChange(changeStatus, cp) {
        if (changeStatus) {
          if (invalidClosing(cp)) {
            window.alert($filter("translate")("SET_PROJECT_TYPE_BEFORE_DEAL_EDIT_NOTICE"));
            cp.rollback();
            cp.snapshot();
            return;
          }

          cp.save().then(
            function (result) {
              cp.snapshot();

              if (cp.status.callback) {
                cp.status.callback(result);
              } else {
                $rootScope.$broadcast("candidate_action_changed_" + cp.candidate.id);
                $rootScope.$broadcast("project_action_changed_" + cp.project.id);
              }

              if (cp.project.company) {
                $rootScope.$broadcast("company_action_changed_" + cp.project.company.id);
              }
            }.bind(cp)
          );
        } else {
          cp.rollback();
          cp.snapshot();
        }
      }

      this.createMessage = function (actionRoute, emailParams) {
        if (!actionRoute) {
          var deferred = $q.defer();
          deferred.resolve(emailParams);
          return deferred.promise;
        }
        var params = angular.merge({ id: this.id }, emailParams);
        return $http.post("candidates_projects/" + this.id + "/" + actionRoute + ".json", {
          candidates_projects: params,
        });
      };

      this.sendDocument = function (actionRoute, emailParams) {
        (<any>CandidatesProjects)
          .$post("candidates_projects/" + this.id + "/" + actionRoute + ".json", emailParams)
          .then(
            function (resCpjoin) {
              $rootScope.$broadcast("candidate_action_changed_" + this.candidate.id);
            }.bind(this)
          );
      }.bind(this);

      this.projectStatusUpdate = function () {
        if (this.status.disabled) {
          window.alert($filter("translate")("ACTION_NOT_POSSIBLE_NOTICE"));
          this.rollback();
          this.snapshot();
          return;
        }

        if (
          _.contains([$filter("translate")("CLOSING"), $filter("translate")("DEAL")], this.status.name) &&
          !this.hasSendout
        ) {
          this.confirm($sce.trustAsHtml("'NO_SENDOUT_AVAILABLE_ADD_NEW' | translate: { name: this.status.name }")).then(
            function (res) {
              if (res) {
                Action.createNewAction(this, "sendout").then(function (result) {
                  handleStatusChange(res, this);
                });
              } else {
                this.rollback();
                this.snapshot();
              }
            }.bind(this)
          );
        } else {
          this.confirm(
            $sce.trustAsHtml(
              this.status.onChangeHintTemplate || $filter("translate")("CHANGE_CANDIDATE_STATUS_CONFIRMATION")
            )
          ).then(
            function (res) {
              handleStatusChange(res, this);
            }.bind(this)
          );
        }
      }.bind(this);

      this.lost = function (action) {
        action.actionEditModal(this).then(
          function () {
            $rootScope.$broadcast("candidate_action_changed_" + this.candidate.id);
            $rootScope.$broadcast("project_action_changed_" + this.project.id);
          }.bind(this)
        );
      };

      this.removeCandidate = function () {
        if (!this.getCandidateLonglist()) {
          return;
        }

        this.project.removeCandidate(this);
      };

      this.getCandidateLonglist = function () {
        if (this.status.id === 0) {
          return true;
        } else {
          return false;
        }
      };

      this.addInterviewDate = function (employee) {
        var _cp = this;
        if (!_cp.hasSendout) {
          _cp.confirm($sce.trustAsHtml("NO_SENDOUT_AVAILABLE_INTERVIEW")).then(function (res) {
            if (res) {
              Action.createNewAction(_cp, "sendout").then(function (result) {
                _cp.createInterview(employee);
              });
            } else {
              _cp.rollback();
              _cp.snapshot();
            }
          });
        } else {
          _cp.createInterview(employee);
        }
      };

      this.createInterview = function (employee) {
        var _this = employee
          ? {
              resourceType: "EmployeesCandidatesProject",
              candidate: this.candidate,
              project: this.project,
              employees: [employee],
            }
          : this;

        Action.createNewAction(_this, "telephone-interview", { followUps: true, actionTypeEditable: true }).then(
          function (action) {
            if (action.actionType.completeDescription.match(/interview/g)) {
              // Set status to Interview
              this.status = _.findWhere(CandidatesProjects.statuses, { id: 2 });
            }
          }.bind(this)
        );
      }.bind(this);

      this.createNewAction = function (actionTypeIdentifier) {
        Action.createNewAction(this, actionTypeIdentifier);
      };

      this.completeAction = function (type) {
        var cp = this;

        cp.selectAction(type, { historyTypeTitle: $filter("translate")("INTERVIEW_APPOINTMENT") }).then(function (
          action
        ) {
          cp.candidate.get().then(function (resCandidate) {
            resCandidate.openContactModal(action.actionEditModal.bind(action, cp, { followUps: true }));
          });
        });
      }.bind(this);

      this.sendJobOffer = function () {
        this.sendOpenJobOfferModal();
      };

      this.params = function () {
        return { candidates_projects_id: this.id, cpjoin_id: this.id, candidate_id: this.candidate.id };
      };

      this.resourceType = "CandidatesProjects";
      this.serviceName = "CandidatesProjectsFactory";
      this.snapshot();
    }

    CandidatesProjects.defaultTableAttributes = {
      id: {
        title: "ID",
        show: true,
        class: "id",
        sortField: "id",
      },
      name: {
        title: "CANDIDATE",
        show: true,
        class: "title",
        sortField: "candidate.full_name_reverse_with_title.not_analyzed",
      },
      projectTitle: {
        title: "PROJECT",
        class: "title",
        show: false,
        sortField: "project.title.not_analyzed",
      },
      sales: {
        title: "SALES",
        class: "responsible",
        show: false,
        sortField: "consultant.full_name.not_analyzed",
      },
      researcher: {
        title: "RESEARCHER",
        class: "responsible",
        show: false,
        sortField: "researcher.full_name.not_analyzed",
      },
      tags: {
        title: "TAGS",
        show: true,
        class: "tags",
      },
      processStatus: {
        title: "PROCESS",
        class: "select",
        show: true,
        sortField: "status",
      },
      projectStatus: {
        title: "STATUS",
        class: "select",
        show: true,
        sortField: "project.project_status",
      },
      sendoutAt: {
        title: "SENDOUT_AT",
        show: false,
        class: "date",
        sortField: "project.sendout_at",
      },
      sendout: {
        title: "SO",
        menu: true,
        show: true,
        class: "icon",
      },
      longlistContextMenu: {
        class: "menu",
        show: true,
      },
    };

    CandidatesProjects.statuses = CandidatesProjectStatuses.statuses;

    RailsResource.extendTo(CandidatesProjects);
    (<any>CandidatesProjects).configure({
      url: "/candidates_projects/{{id}}?candidate_id={{candidate_id}}&project_id={{project_id}}",
      name: "candidates_projects",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("expertProfile", "ExpertProfileFactory");
        this.resource("project", "ProjectsFactory");
        this.resource("candidate", "CandidatesFactory");
        this.resource("phase", "ActionsFactory");
        this.resource("actions", "ActionsFactory");
        this.resource("lastAction", "ActionsFactory");
        this.serializeWith("status", "StatusSerializer");

        this.resource("sales", "UsersFactory");
        this.resource("researcher", "UsersFactory");
      }),
    });

    (<any>CandidatesProjects).extend(CandidatesProjectsModalMixin);
    (<any>CandidatesProjects).extend(ModalMixin);
    (<any>CandidatesProjects).extend(RailsResourceSnapshotsMixin);

    return CandidatesProjects;
  },
]);
