export default class CandidateShowDuplicatesModalCtrl {
  private candidate: any;
  private selectedManager: any;
  private duplicates: any;
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = ["EmployeesFactory", "resolve"];

  constructor (private Employee: any, private resolve: any) {}

  public getAttrName(scopeDescriptor) {
    switch (scopeDescriptor) {
      case "fullNameWithTitle.standard":
        return "DUPL_ATTR_NAME";
      case "workEmail":
        return "DUPL_ATTR_EMAIL_WORK";
      case "workPhone":
        return "DUPL_ATTR_PHONE_WORK";
      case "workMobilePhone":
        return "DUPL_ATTR_MOBILE_WORK";
      case "privateEmail":
        return "DUPL_ATTR_EMAIL_PRIVATE";
      case "privatePhone":
        return "DUPL_ATTR_PHONE_PRIVATE";
      case "privateMobilePhone":
        return "DUPL_ATTR_MOBILE_PRIVATE";
    }
  }

  public $onInit () {
    this.candidate = this.resolve.candidate;
    this.duplicates = this.resolve.duplicates;

    this.selectedManager = undefined;

    _.each(this.duplicates,  (duplicate) => {
      duplicate.match = _.map(duplicate.highlight,  (filterObj, filterKey) => {
        return { key: this.getAttrName(filterKey), value: _.flatten(filterObj) };
      });
    });
  };

  public cancel () {
    this.modalInstance.dismiss("cancel");
  };

  public connectToManager (employee) {
    this.cancel();
    (<any>this.Employee)
      .$post(this.candidate.$url("/transform.json"), { employee_id: employee.id })
      .then((<any>this.Employee).transformResultHandlerOk)
      .catch((<any>this.Employee).transformResultHandlerError);
  };

  public createNewManager () {
    this.cancel();

    (<any>this.Employee)
      .$post(this.candidate.$url("/transform.json"))
      .then((<any>this.Employee).transformResultHandlerOk)
      .catch((<any>this.Employee).transformResultHandlerError);
  };
}