import moment from "moment";

export default class WhiteboardAdvancedSearchCtrl {
  
  private advancedQuery: any;
  private user: any;
  private closing: () => void;
  private initFn: (user: any) => void;
  private dateRangePicker: any;
  public ranges:  { [key: string]: moment.Moment[] } = {
    TODAY: [moment(), moment()],
    YESTERDAY: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    PREVIOUS_SEVEN_DAYS: [moment().subtract(6, "days"), moment()],
    PREVIOUS_THIRTY_DAYS: [moment().subtract(29, "days"), moment()],
  };
  
  static $inject = ["daterangepickerOptions", "$filter", "moment"];

  constructor (private daterangepickerOptions: any, private $filter: ng.IFilterService) {
    
    this.dateRangePicker = {
      options: _.extend(this.daterangepickerOptions, {
        timePicker: false,
        parentEl: ".advanced-search-pop",
        ranges: _.object(
          _.map(this.ranges, (value, key) => {
            return [this.$filter("translate")(key), value];
          })
        ),
      }),
    };
  }

  public $onInit () {
    this.advancedQuery.name = undefined;
    this.advancedQuery.id = undefined;
  };

  public performAdvancedSearch () {
    if (this.user) {
      this.user.$advancedQuery = this.advancedQuery;

      this.initFn(this.user);
    }

    this.closing();
  };
}
