import { ITemplateCacheService } from 'angular';
import { CompaniesTransactionBasis } from "../../../companies_transaction_bases/services/companies_transaction_bases.service";
import companiesDocumentListPaginationTemplate from "../../../directives/components/pagination/pagination.template.html";
export default class CompaniesDocumentsListCtrl{

  private paginationTemplateUrl: string;
  private paginationService: any;
  private documents: any;
  private cancel: boolean;


  static $inject = ["CandidatesFactory", "CompaniesFactory", "ProjectsFactory", "DealsFactory", "DocumentsFactory", "MgPaginateService", "$templateCache"];

  constructor(public Candidate: any,
              public Company: any,
              public Project: any,
              public Deal: any,
              public Document: any,
              private MgPaginateService: any,
              private $templateCache: ITemplateCacheService){
                this.paginationTemplateUrl = "paginationTemplate";
                this.$templateCache.put(this.paginationTemplateUrl, companiesDocumentListPaginationTemplate);

                this.paginationService = new this.MgPaginateService();
              }
  
  public openDocumentFullScreen (openDocument, event) {
    event.preventDefault();
    if (!openDocument) {
      return;
    }
    new this.Document(openDocument).openDocumentFullScreen(this.documents);
  };

  public $onInit () {
    angular.extend(this, {
      paginationId: "documents-list-".concat("-", _.uniqueId()),
      CompaniesTransactionBases: CompaniesTransactionBasis,
      documentFileTypes: this.Document.fileTypes,
      attributes: {
        title: { show: true },
        fileType: { show: !this.cancel },
        actions: { show: this.cancel },
        menu: { show: !this.cancel },
      },
    });

    this.paginationService.setData(this.documents);
    this.paginationService.setTotalItems(this.documents?.length);
  };          
}