//= require ./services.module.js
angular.module("services").provider("$stateGenerator", [
  "$stateProvider",
  function ($stateProvider) {
    this.addStates = function (rootStateName, args) {
      var view = {};

      var options;
      if (_.isArray(args)) {
        options = {
          stateName: args[0],
          viewLayerID: args[1],
          params: args[2],
          urlParamName: args[3],
          forceRootStateName: args[4],
        };
      } else {
        options = args;
      }

      var _stateName = _.compact([rootStateName, options.stateName]).join(".");

      options = _.defaults(options, {
        viewLayerID: "deckPrimary",
        forceRootStateName: false,
        urlParamName: ":id",
      });

      if (_.isString(options.stateName)) {
        $stateProvider.state(_stateName, {
          url: options.url || "/" + options.stateName,
          resolve: options.params.requirements,
        });
      }

      var genericDeck = {};
      options.rootView =
        options.rootView ||
        (options.stateName || options.forceRootStateName
          ? "@" + rootStateName.slice(0, rootStateName.lastIndexOf("."))
          : "");
      genericDeck[options.viewLayerID.replace(/([a-z\d])([A-Z])/g, "$1-$2").toLowerCase() + options.rootView] = {
        resolve: {
          objectType: [
            function () {
              return options.params.objectType;
            },
          ],
          scrollspyScope: function () {
            return _.uniqueId("scrollspy");
          },
        },
        component: "genericDeck",
      };

      _.mapObject(
        { new: "new", edit: options.urlParamName + "/edit", show: options.urlParamName },
        function (value, key) {
          $stateProvider.state(_stateName + "." + key, {
            url: "/" + value,
            resolve: angular.merge(angular.copy(options.params[key].resolve), {
              sidebar: [
                "SidebarFactory",
                function (SidebarFactory) {
                  return new SidebarFactory(options.viewLayerID, options.subDeckOpen && key != "new");
                },
              ],
            }),
            onExit: [
              "sidebar",
              "$rootScope",
              "$state",
              function (sidebar, $rootScope, $state) {
                $rootScope.$broadcast("$stateExit", $state);
                sidebar.close();
              },
            ],
            onEnter: [
              "sidebar",
              function (sidebar) {
                sidebar.open();
              },
            ],
            views: angular.merge(genericDeck, options.params[key].views(_stateName + "." + key)),
          });
        }
      );

      return this;
    };
    this.addRoundtrip = function (rootStateName, options) {
      options.urlParameters = {
        queryParams: options.queryParams,
        paramName: options.paramName,
        url: options.url,
      };

      $stateProvider.state(rootStateName, {
        url: options.urlParameters.url + "?" + options.urlParameters.queryParams,
        abstract: true,
      });

      _.mapObject(
        { new: "new", edit: options.urlParameters.paramName + "/edit", show: options.urlParameters.paramName },
        function (params, route) {
          $stateProvider.state(rootStateName + "." + route, {
            url: "/" + params + "?" + options.urlParameters.queryParams,
            redirect: options.redirect + "." + route,
          });
        }
      );

      return this;
    };
    this.$get = function () {
      return {};
    };
  },
]);
