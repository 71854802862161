import { ITemplateCacheService } from "angular";

export default class TrafficLightCtrl {
  public object: any;
  public requirements: any[];
  public popoverUniqId: string;
  public typeClass: any;

  static $inject = ["$templateCache"];

  constructor (
    private $templateCache: ITemplateCacheService
  ) {}

  public $onInit() {
    if (this.object && _.isFunction(this.object.requirements)) {
      this.requirements = this.object.requirements();
    } else {
      this.requirements = this.typeClass.requirements;
    }

    this.popoverUniqId = _.uniqueId("traffic-light-popover");
    this.$templateCache.put(
      this.popoverUniqId,
      "<traffic-light-requirements requirements='$ctrl.requirements'></traffic-light-requirements>"
    );
  };

  public $onDestroy() {
    this.$templateCache.remove(this.popoverUniqId);
  };
};