import QualificationTemplateCtrl from "./qualification-template.controller";
import qualificationTemplateTemplate from "./qualification-template.template.html";

angular.module("action_types.components").component("qualificationTemplate", {
  bindings: {
    object: "<",
    saveChanges: "=",
    action: "<?",
  },
  template: qualificationTemplateTemplate,
  controller: QualificationTemplateCtrl,
});
