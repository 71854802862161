//= require ./services.module.js
angular
  .module("services")

  // Unpacks the standard rails response envelope into
  // rails-resource compatible objects
  .factory("UnpackEnvelopeInterceptor", [
    "RailsResource",
    "ResourceDependencyInjector",
    function (RailsResource, ResourceDependencyInjector) {
      return {
        afterResponse: function (result, resourceConstructor, context) {
          var ret;

          function construct(res) {
            if (res && res.constructor !== Object) {
              var resource;
              try {
                resource = ResourceDependencyInjector.inject(res.serviceName || res.constructor.name);
                return new resource(res);
              } catch (err) {
                return res;
              }
            } else {
              return new resourceConstructor(res);
            }
          }

          function recursiveConstruct(data) {
            if (_.isArray(data)) {
              return _.map(data, recursiveConstruct);
            } else if (_.isObject(data) && data instanceof RailsResource) {
              _.mapObject(data, function (value, key) {
                data[key] = _.isFunction(value) ? value : recursiveConstruct(value);
              });

              return construct(data);
            } else {
              return data;
            }
          }

          // let rails-resource do its magic on the objects

          if (_.isArray(result.data)) {
            ret = _.map(result.data, function (data) {
              var res = construct(data);
              return recursiveConstruct(res);
            });
          } else {
            ret = construct(result.data);
            ret = recursiveConstruct(ret);
          }

          // add meta data from request to result object
          ret.meta = result.meta;

          return ret;
        },
      };
    },
  ]);
