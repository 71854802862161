import InfoPopoverCtrl from "./info-popover.controller";
import infoPopoverTemplate from "./info-popover.template.html";

angular.module("directives").component("infoPopover", {
  bindings: {
    resource: "<",
  },
  template: infoPopoverTemplate,
  controller: InfoPopoverCtrl,
});
