//= require ./services.module.js
angular
  .module("services")

  .factory("ResourceDependencyInjector", [
    "$injector",
    function ($injector) {
      return {
        inject: function (dependency) {
          if (angular.isString(dependency)) {
            if ($injector.has(dependency)) {
              return $injector.get(dependency);
            } else {
              return $injector.get(dependency + "sFactory");
            }
          } else {
            return dependency;
          }
        },
      };
    },
  ]);
