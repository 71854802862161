import freeDatePickerCtrl from "./free-date-picker.controller";
import freeDatePickerTemplate from "./free-date-picker.template.html";

angular.module("directives").component("freeDatePicker", {
  bindings: {
    freeDate: "<ngModel",
    placeholder: "@",
    disabled: "@",
    required: "@",
    uniqName: "@name",
  },
  template: freeDatePickerTemplate,
  controller: freeDatePickerCtrl,
});
