import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { Country } from "./countries.service";
angular
  .module("countries")

  .factory("CountrySerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(Country, data);
        this.serialize = serialize;
      });
    },
  ])

  .service("CountriesFactory", Country);
