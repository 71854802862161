import EmployeesEditCtrl from "./employees-edit.controller";
import employeesEditViewTemplate from "./employees-edit-view.template.html";

angular.module("employees.views").component("employeesEditView", {
  bindings: {
    employee: "<employeeResponse",
    scrollspyScope: "<",
  },
  template: employeesEditViewTemplate,
  controller: EmployeesEditCtrl,
});
