import { TagSearchCtrl } from "./tag-search.controller";
import tagSearchTemplate from "./tag-search.template.html";

angular.module("tags.components").component("tagSearch", {
  bindings: {
    urModel: "=",
    type: "@",
    placeholder: "@",
    filters: "&",
    initialLoad: "@",
    listenOnToUpdate: "@",
  },
  template: tagSearchTemplate,
  controller: TagSearchCtrl,
});
