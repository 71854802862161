//= require ../companies.module.js

angular.module("companies").factory("CompaniesHotkeys", [
  "hotkeys",
  "$state",
  "$rootScope",
  function (hotkeys, $state, $rootScope) {
    return {
      registerHotkeys: function () {
        $(window).keydown(function (event) {
          if (event.ctrlKey && event.keyCode == 85) {
            // ctrl+u
            event.preventDefault();
          }
        });

        hotkeys.add({
          combo: "mod+u s",
          description: "Nach Unternehmen & Managern suchen",
          callback: function (event, hotkey) {
            event.preventDefault();
            $rootScope.$broadcast("companiesSearch");
            return false;
          },
        });

        hotkeys.add({
          combo: "mod+m c",
          description: "Einen Manager erstellen",
          callback: function (event, hotkey) {
            event.preventDefault();
            $state.go("root.companies.list.employees.new");
          },
        });

        hotkeys.add({
          combo: "mod+u c",
          description: "Ein Unternehmen erstellen",
          callback: function (event, hotkey) {
            event.preventDefault();
            $state.go("root.companies.new");
          },
        });
      },
      deregisterHotkeys: function () {
        if ($state.current.name.indexOf("root.companies") > -1) {
          hotkeys.del("mod+m c");
          hotkeys.del("mod+u c");
          hotkeys.del("mod+u s");
        }
      },
    };
  },
]);
