import { deserialize, serialize } from "../../services/generic-rails-serializer.service";
import { Course } from "./courses.factory";

angular
  .module("courses")
  .factory("CoursesSerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = (data) => deserialize(Course, data);
        this.serialize = serialize;
      });
    },
  ])
  .service("CoursesFactory", Course);
