import { initContract } from "@ts-rest/core";

import { TLeadershipExperience } from "../leadership_experiences";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const LeadershipExperiencesSchema = c.type<TBaseResource<TLeadershipExperience[]>>();
export const leadershipExperiencesContract = c.router({
  query: {
    method: "GET",
    path: "/leadership_experiences",
    responses: {
      200: LeadershipExperiencesSchema,
    },
    summary: "Get all Leadership Experiences",
  },
});
