import { ICompileService, ISCEService, ITemplateCacheService, ITimeoutService } from "angular";
const kpisOpenPopoverTemplate = require("../../../../dashboards/components/dashboard-2021/popover/open-popover.template.html");

export default class processKpisCtrl {
  public model: { kpis: Record<string, any> };
  public steps: Array<{ 
    value?: any; 
    key?: string; 
    isOpen?: boolean; 
    model?: any 
  }>;
  public enableKpiKeySelect: boolean;
  public selectedKpiKey: string;
  static $inject = ["KPISteps", "$compile", "$timeout", "$sce", "$templateCache"];

  constructor (
    private KPISteps: any,
    private $compile: ICompileService,
    private $timeout: ITimeoutService,
    private $sce: ISCEService,
    private $templateCache: ITemplateCacheService
  ) {
    this.$templateCache.put("open-popover-template", kpisOpenPopoverTemplate);
  }

  public $onInit() {
    this.setSteps(this.model.kpis);
  };

  public setSteps(newValue) {
    if (!newValue) {
      return false;
    }

    this.steps = _.chain(newValue)
      .mapObject((value, key) => {
        return this.KPISteps[key]
          ? _.extend({ value: value, key: key, isOpen: false, model: this.model }, this.KPISteps[key])
          : undefined;
      })
      .compact()
      .values()
      .value();
  };

  public setKpiKey(stepkey) {
    if (this.enableKpiKeySelect) {
      this.selectedKpiKey = stepkey;
    }
  };
};
