export class ProjectsAutoSearchCtrl {
  public projectsLazyLoading: boolean;
  public showAutoSearch: boolean;
  public advancedQuery: any;
  public query: string;
  public candidate: any;
  public attributes: any;
  public projects: any; // collectionProxy

  private oldProjectsSelected: any;

  static $inject = ["$timeout", "ProjectsFactory", "SavedSearchesFactory", "makeCollectionProxy", "PrsmTableHelper"];
  constructor(
    private $timeout: angular.ITimeoutService,
    private ProjectService: any,
    private SavedSearchService: any,
    private makeCollectionProxy: any,
    private PrsmTableHelper: any
  ) {}

  public $onInit() {
    this.advancedQuery = new this.SavedSearchService();

    this.query = _.chain([
      this.tag_query(this.candidate.tags),
      this.tag_query(this.candidate.departments),
      this.tag_query(this.candidate.computerSkills),
    ])
      .compact()
      .map((tagQuery) => {
        return "(" + tagQuery + ")";
      })
      .join(" AND ")
      .value();

    this.advancedQuery.basicQuery = this.query;
    _.each(this.candidate.jobCategories, (jobCategory) => {
      this.advancedQuery.conditions.push({
        field: "job_category",
        modifier: "must",
        query: jobCategory,
      });
    });

    this.advancedQuery.conditions.push({
      field: "project_status",
      modifier: "must",
      query: "active",
    });
    this.advancedQuery.deleteAdvancedSearchCondition(0);

    this.attributes = this.PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.ProjectService.defaultTableAttributes),
      ["id", "title", "projectManager", "jobType", "tags", "editableStatus", "priority", "kpis", "createdAt"],
      ["id", "title", "projectManager", "jobType", "kpis"]
    );

    this.oldProjectsSelected = _.filter(this.projects, (project) => {
      return project.$selectedForLonglist && !project.$selectedBySystem;
    });

    this.projectsLazyLoading = false;
    this.showAutoSearch = false;
  }

  public startAutoSearch() {
    this.showAutoSearch = true;
    this.$timeout(() => {
      this.updateProjects(this.advancedQuery.collectSearchParams());
    }, 1);
  }

  public updateProjects(newParams) {
    if (_.isUndefined(this.projects)) {
      this.projectsLazyLoading = true;
      this.makeCollectionProxy(this.ProjectService.query.bind(this.ProjectService), newParams).then(
        this.initSearchResults.bind(this)
      );
    } else {
      this.projects.search(newParams).then(this.initSearchResults.bind(this));
    }
  }

  public getLonglistedProjects() {
    var ret = _.chain(this.candidate.candidatesProjects)
      .reject((cp) => {
        return !cp.project || cp.deleted;
      })
      .map((cp) => {
        return cp.project;
      })
      .value();
    return ret;
  }

  private tag_query(tags) {
    return _.chain(tags)
      .map((tag) => {
        return '"' + tag.name + '"';
      })
      .join(" OR ")
      .value();
  }

  private initSearchResults(projects) {
    var longlistEntries = this.getLonglistedProjects();
    _.chain(projects)
      .filter((project) => {
        return _.findWhere(longlistEntries, { id: project.id });
      })
      .each((project) => {
        project.$selectedForLonglist = true;
        project.$selectedBySystem = true;
      });
    this.projects = projects;
    this.projectsLazyLoading = false;
  }

  public $doCheck() {
    var projectsSelected = _.filter(this.projects, (project) => {
      return project.$selectedForLonglist && !project.$selectedBySystem;
    });
    if (!angular.equals(projectsSelected, this.oldProjectsSelected)) {
      _.chain(projectsSelected)
        .difference(this.oldProjectsSelected)
        .each((project) => {
          this.candidate.addToProject(project);
        });
      this.oldProjectsSelected = angular.copy(projectsSelected);
    }
  }
}
