import ProjectReportingsCtrl from "./index.controller";
import indexTemplate from "./index.template.html";

angular.module("project_reporting.views").component("projectReportings", {
  bindings: {
    kpisPerYear: "<",
    options: "<",
  },
  template: indexTemplate,
  controller: ProjectReportingsCtrl,
});
