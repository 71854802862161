//= require ../filters.module.js

angular.module("filters").filter("germanTimeFormat", [
  "$filter",
  function ($filter) {
    var angularDateFilter = $filter("date");
    return function (theDate) {
      if (Date.parse(theDate)) {
        theDate = new Date(theDate);
      } else {
        return theDate;
      }
      return angularDateFilter(theDate, "dd.MM.yyyy H:mm 'Uhr'");
    };
  },
]);
