//= require ../invoices.module.js

angular.module("invoices").service("InvoicesFactory", [
  "RailsResource",
  "$sce",
  "railsSerializer",
  "$filter",
  "$translate",
  function (RailsResource, $sce, railsSerializer, $filter, $translate) {
    function Invoice() {
      (<any>Invoice).__super__.constructor.apply(this, arguments);
    }

    Invoice.defaultTableAttributes = {
      id: {
        title: "ID",
        show: false,
        pinned: false,
        menu: true,
        class: "id",
      },
      title: {
        title: $filter("translate")("TITLE"),
        show: true,
        fixedWidth: true,
        class: "title",
      },
      invoiceRecipient: {
        title: $filter("translate")("INVOICE_RECIPIENT"),
        show: true,
        class: "title",
      },
      value: {
        title: $filter("translate")("AMOUNT"),
        show: true,
        class: "tag",
      },
      serviceDate: {
        title: $filter("translate")("INVOICE_DATE"),
        show: true,
        class: "date",
      },
      createdAt: {
        title: $filter("translate")("CREATED_AT"),
        show: false,
        class: "date",
      },
    };

    RailsResource.extendTo(Invoice);
    (<any>Invoice).configure({
      url: "/projects/{{project_id}}/invoices",
      name: "invoice",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("project", "ProjectsFactory");
        this.resource("invoiceRecipient", "EmployeesFactory");
        this.serializeWith("value", "MoneySerializer");
        this.serializeWith("serviceDate", "DateSerializer");
      }),
    });

    return Invoice;
  },
]);
