//= require ../documents.module.js

angular.module("documents").factory("FileTypeSerializer", [
  "railsSerializer",
  "$sce",
  "DocumentsFactory",
  function (railsSerializer, $sce, Document) {
    return railsSerializer(function () {
      this.deserialize = function (fileType) {
        return _.findWhere(Document.fileTypes, { id: _.isNumber(fileType) ? fileType : fileType.id });
      };
      this.serialize = function (fileType) {
        return fileType.id;
      };
    });
  },
]);
