import CandidatesProjectSearchCtrl from "./candidates-project-search.controller";
import candidatesProjectSearchTemplate from "./candidates-project-search.template.html"

angular.module("candidates.components").component("candidatesProjectSearch", {
  bindings: {
    project: "<",
    query: "<",
    remote: "<",
    setData: "&",
  },
  template: candidatesProjectSearchTemplate,
  controller: CandidatesProjectSearchCtrl,
});
