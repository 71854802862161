export default class InfoPopoverCtrl {
  constructor() {}

  public state(stateWithPrefix, params) {
    var args = _.reduce(
      params,
      (memo, obj) => {
        return _.extend(memo, obj);
      },
      {}
    );

    return stateWithPrefix + "(" + JSON.stringify(args) + ")";
  };
};