import transactionBasisTableBodyTemplate from "./transaction-basis-table-body.template.html";

angular.module("transaction_bases.components").component("transactionBasisTableBody", {
  bindings: {
    transactionBases: "=data",
    attributes: "=",
    tableRowIdentifier: "=",
  },
  template: transactionBasisTableBodyTemplate,
});
