//= require ../actions.module.js

import { ActionType } from "../../action_types/services/action_types.service";

angular.module("actions").service("ActionsFactory", [
  "RailsResource",
  "RailsResourceSnapshotsMixin",
  "railsSerializer",
  "$rootScope",
  "$http",
  "$q",
  "ActionsModalMixin",
  "ModalMixin",
  "makeCollectionProxy",
  "OutlookDelivery",
  "poller",
  "RedirectLocation",
  "$filter",
  function (
    RailsResource,
    RailsResourceSnapshotsMixin,
    railsSerializer,
    $rootScope,
    $http,
    $q,
    ActionsModalMixin,
    ModalMixin,
    makeCollectionProxy,
    OutlookDelivery,
    poller,
    RedirectLocation,
    $filter
  ) {
    function Action() {
      (<any>Action).__super__.constructor.apply(this, arguments);

      this.params = function () {
        return _.extend({ action_id: this.id }, RedirectLocation.redirectParams());
      };

      this.getCompany = function ($ctrl: any) {
        let cObj = this.company ? { 
                      company: this.company, 
                      state: $ctrl.state('root', 'companies.show', 'action.company.params()') 
                    }
                : (!this.company && this.project?.company) ? { 
                      company: this.project?.company, 
                      state: $ctrl.state('root', 'projects.show.company.show', $ctrl.reduceParams([this.project.params(), this.project.company.params()]))
                    }
                : (!this.company && !this.project?.company && this.employee && this.current_company) ? { 
                      company: this.current_company, 
                      state: $ctrl.state('root', 'employees.show.company.show', $ctrl.reduceParams([this.employee.params(), this.current_company.params()] )) 
                    } : undefined;

        return cObj;
      };

      this.deleteNote = function (object) {
        var deferred = $q.defer();

        return this.delete().then(
          function (result) {
            // should be handled in object class
            if (object && object.resourceType) {
              $rootScope.$broadcast(object.resourceType.toLowerCase() + "_action_changed_" + object.id);
            }

            deferred.resolve(this);
            return deferred.promise;
          },
          function () {
            deferred.reject();
            return deferred.promise;
          }
        );
      };

      this.saveCustomNote = function (object) {
        if (this.plannedFor) {
          this.sendInvitation = true;
        }

        if (!this.completedAt && !this.plannedFor) {
          this.completedAt = new Date().toISOString();
          this.completedByUser = this.user;
        }

        return this.save().then(
          function (resAction) {
            // should be handled in object class
            if (object && object.resourceType) {
              $rootScope.$broadcast(object.resourceType.toLowerCase() + "_action_changed_" + object.id);
            }

            this.versions = resAction.versions;

            if (resAction.errorCode) {
              OutlookDelivery.handleErrors(resAction.errorCode);
            }

            if (this.actionType.isEvent && this.plannedFor && !this.outlookEventId && !this.declinedAt) {
              return resAction.eventConfirm(resAction).then(
                function (accepted) {
                  if (accepted === true) {
                    _.each(this.drafts, function (draft) {
                      return $http.put("draft/" + draft.id + "/send_invitation.json", { draft_params: draft });
                    });

                    if (this.employee || (this.employees && this.employees.length > 0)) {
                      return resAction.createMessage("create_event_confirmation_email", this).then(
                        function (resDraft) {
                          var pollingService = poller.get(resDraft.data.url, { delay: 1000 });
                          this.confirm($filter("translate")("EMAIL_SENT_CONFIRMATION"), pollingService).then(
                            function () {
                              pollingService.remove();
                            },
                            function () {
                              pollingService.remove();
                            }
                          );

                          return resAction;
                        }.bind(resAction),
                        function (declined) {
                          return resAction;
                        }
                      );
                    }
                  }

                  return resAction;
                }.bind(resAction),
                function (declined) {
                  return resAction;
                }
              );
            }

            return resAction;
          }.bind(this)
        );
      };

      this.createMessage = function (actionRoute, emailParams) {
        var params = angular.merge(
          { id: this.id },
          { contact_details_event_text: emailParams.contactDetailsEventText }
        );
        return $http.post("actions/" + this.id + "/" + actionRoute + ".json", { action_params: params });
      };

      this.convertIntoEvent = function () {
        return (<any>Action).$post("actions/" + this.id + "/convert_to_event.json").then(
          function (resAction) {
            if (resAction) {
              _.extend(this, resAction);
            } else {
              return undefined;
            }
          }.bind(this)
        );
      };

      this.isDeclined = function () {
        return this.declinedAt;
      };

      this.isCompleted = function () {
        return !this.declinedAt && this.completedAt;
      };

      this.isPlanned = function () {
        return !(this.declinedAt || this.completedAt) && this.plannedFor;
      };

      this.isFollowUp = this.isPlanned;

      this.isActionType = function (typeName) {
        return this.actionType.typeName == typeName;
      };


      this.dealEditable = function () {
        return this.deal || this.actionType.isDealActionType();
      };

      this.isClosing = function () {
        return this.deal?.closing || _.contains(["closing"], this.actionType.typeName);
      };

      this.hasTracking = function () {
        var options = this.actionType.getActionTypeOptions();
        return options && !!options.hasTracking;
      };

      this.listTitle = function () {
        var subject = this.subject ? this.subject + " (" + this.actionType.completeDescription + ")" : undefined;
        return (
          subject ||
          this.customType ||
          this.actionType.completeDescription ||
          $filter("translate")("NOT_SPECIFIED_SMALL")
        );
      };
    }

    Action.createNewAction = async function (object, actionTypeIdentifier, options) {
      let actionTypes: ActionType[] = await ActionType.getAll();
      var optAttr = {
        actionType: actionTypeIdentifier ? _.findWhere(actionTypes, { typeName: actionTypeIdentifier }) : null,
      };
      if (options && options.followUps) {
        _.extend(optAttr, {
          plannedFor: new Date().toISOString(),
          plannedLength: 30,
        });
      }

      return (<any>Action)
        .$get("actions/new", {
          action_params: _.extend(
            {
              actionable_type: object.resourceType,
              actionable_id: object.id,
            },
            optAttr
          ),
        })
        .then(function (action) {
          return action.actionEditModal(object, options);
        });
    };

    Action.lazyLoadingActions = function (resource, advancedQuery) {
      advancedQuery = advancedQuery || {};
      _.defaults(advancedQuery, { followUps: false });
      this.loading = true;
      return makeCollectionProxy(
        (<any>Action).query.bind(Action),
        _.extend(resource.params(), { advanced: JSON.stringify(advancedQuery) })
      ).then(
        function (actions) {
          if (advancedQuery.followUps) {
            resource.followUps = function () {
              return actions;
            };
          } else {
            resource.actions = function () {
              return actions;
            };
          }
          this.loading = false;
        }.bind(this)
      );
    };

    Action.defaultTableAttributes = {
      id: {
        title: "ID",
        show: false,
        menu: true,
        class: "id",
        sortField: "id",
      },
      type: {
        title: "ENTRY",
        show: false,
        class: "responsible",
        sortField: "action_type.type_name",
      },
      responsibleUser: {
        title: "CONSULTANT",
        show: true,
        class: "responsible",
        sortField: "completed_by_user.full_name.not_analyzed",
      },
      consultant: {
        title: "SALES",
        show: false,
        class: "responsible",
        sortField: "consultant.full_name.not_analyzed",
      },
      researcher: {
        title: "RESEARCHER",
        show: false,
        class: "responsible",
        sortField: "researcher.full_name.not_analyzed",
      },
      processProvisionConsultant: {
        title: "OVERALL_PROCESS",
        show: false,
        class: "responsible",
        sortField: "process_provision_consultant.full_name.not_analyzed",
      },
      project: {
        title: "PROJECT",
        show: true,
        class: "responsible",
        sortField: "project.title.not_analyzed",
      },
      jobType: {
        title: "PROJECT_TYPE",
        show: false,
        class: "responsible",
        sortField: "project.job_type.name",
      },
      deal: {
        title: "DEAL",
        show: false,
        class: "responsible",
        sortField: "deal.service_date",
      },
      amountCovered: {
        title: "PROFIT_CONTRIBUTION_TOKEN",
        show: false,
        class: "responsible",
        sortField: "deal.amount_covered_ist",
      },
      connectionCdsSap: {
        title: "CONNECTION_CDS_SAP_ESTABLISHED",
        show: true,
        class: "responsible",
        sortField: "deal.registered_in_external_platforms",
      },
      status: {
        title: "STATUS",
        show: false,
        class: "responsible",
        sortField: "status",
      },
      countableDate: {
        title: "DATE",
        show: true,
        class: "date",
        sortField: "countable_date",
      },
      candidate: {
        title: "CANDIDATE",
        show: true,
        class: "responsible",
        sortField: "candidate.full_name_with_title.not_analyzed",
      },
      company: {
        title: "COMPANY",
        show: false,
        class: "responsible",
        sortField: "company.name.not_analyzed",
      },
      employees: {
        title: "MANAGER",
        show: true,
        class: "title",
        sortField: "employees.full_name.not_analyzed",
      },
      description: {
        title: "ACTION_NOTE",
        show: false,
      },
    };
    RailsResource.extendTo(Action);
    (<any>Action).configure({
      url: "/actions",
      name: "action_params",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("project", "ProjectsFactory");
        this.resource("employee", "EmployeesFactory");
        this.resource("employees", "EmployeesFactory");
        this.resource("candidate", "CandidatesFactory");
        this.resource("user", "UsersFactory");
        this.resource("createdByUser", "UsersFactory");
        this.resource("completedByUser", "UsersFactory");
        this.serializeWith("actionType", "ActionTypeSerializer");
        this.resource("deal", "DealsFactory");
        this.resource("provision", "ProvFactory");
        this.resource("predecessor", "ActionsFactory");
        this.serializeWith("plannedFor", "DateSerializer");

        this.exclude("$snapshots");
      }),
    });
    (<any>Action).extend(ActionsModalMixin);
    (<any>Action).extend(ModalMixin);
    (<any>Action).extend(RailsResourceSnapshotsMixin);

    return Action;
  },
]);
