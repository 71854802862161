import GenericStaticSelectorCtrl from "./generic-static-selector.controller";
import genericStaticSelectorTemplate from "./generic-static-selector.template.html";

angular.module("directives.advanced_search").component("genericStaticSelector", {
  bindings: {
    condition: "=",
    staticList: "=?",
  },
  template: genericStaticSelectorTemplate,
  controller: GenericStaticSelectorCtrl,
});
