import { initContract } from "@ts-rest/core";

import { TJobTypes } from "../job_types";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const JobTypesSchema = c.type<TBaseResource<TJobTypes[]>>();
export const jobTypesContract = c.router({
  query: {
    method: "GET",
    path: "/job_types",
    responses: {
      200: JobTypesSchema,
    },
    summary: "Get all job types",
  },
});
