import ExternalCvUploaderCtrl from "./external-cv-uploader.controller";
import externalCvUploaderTemplate from "./external-cv-uploader.template.html";

angular.module("documents.components.externalCvUploader").component("externalCvUploader", {
  bindings: {
    resource: "=ngModel",
    translation: "=",
  },
  template: externalCvUploaderTemplate,
  controller: ExternalCvUploaderCtrl,
});
