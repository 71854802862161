import { AdvancedLonglistSearchCtrl } from "./advanced-longlist-search.controller";

import advancedLonglistSearchTemplate from "./advanced-longlist-search.template.html";

angular.module("candidates_projects.components").component("advancedLonglistSearch", {
  bindings: {
    advancedQuery: "=",
    setData: "=",
    closing: "&",
  },
  template: advancedLonglistSearchTemplate,
  controller: AdvancedLonglistSearchCtrl,
});
