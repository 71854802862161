import QualificationEmployeesProjectsTemplateCtrl from "./qualification-employees-projects-template.controller";
import qualificationEmployeesProjectsTemplateTemplate from "./qualification-employees-projects-template.template.html";

angular.module("action_types.components").component("qualificationEmployeesProjectsTemplate", {
  bindings: {
    object: "<",
    action: "<?",
    saveChanges: "<?",
  },
  template: qualificationEmployeesProjectsTemplateTemplate,
  controller: QualificationEmployeesProjectsTemplateCtrl,
});
