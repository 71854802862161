import { PersonsGraduationFormCtrl } from "./persons-graduation-form.controller";
import personsGraduationFormTemplate from "./persons-graduation-form.template.html";

angular.module("persons_graduations.components").component("personsGraduationForm", {
  bindings: {
    employee: "=",
  },
  template: personsGraduationFormTemplate,
  controller: PersonsGraduationFormCtrl,
});
