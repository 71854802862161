export default class DeckSubCtrl {
  public sidebar: { close: () => void };

  static $inject = ["SidebarFactory"];

  constructor (
    private SidebarFactory: any
  ) {
    this.sidebar = new this.SidebarFactory("deckSub");
  }

  public closeSidebar() {
    this.sidebar.close();
  };
};