export class TextkernelOpenSearchModalCtrl {
  static $inject = ["$window", "Textkernel"];
  constructor(private $window: angular.IWindowService, private Textkernel: any) {
    angular.element(this.$window).on("message", this.Textkernel.receiveMessage);
  }

  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  public textkernelSearchUrl: string;
  private resolve: {
    object: any;
  };

  public $onInit() {
    this.textkernelSearchUrl = this.Textkernel.getSearchUrl(this.resolve.object);

    this.Textkernel.addTrackingPoint("opened_" + this.resolve.object.resourceType);
  }

  public ok() {
    this.modalInstance.close(true);
  }

  public cancel() {
    this.modalInstance.close(false);
  }

  // de-register postMessage handler

  private deregTKEvents() {
    angular.element(this.$window).off("message", this.Textkernel.receiveMessage);
  }

  public $onDestroy() {
    this.deregTKEvents();
  }
}
