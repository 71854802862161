const trackingsTemplate = require("./trackings.template.html");
//= require ../components.module.js

angular.module("trackings.components").component("trackings", {
  bindings: {
    action: "=",
  },
  template: trackingsTemplate,
  controller: "TrackingsCtrl",
});
