//= require ./services.module.js
angular.module("services").provider("myCSRF", [
  "csrfHeaderName",
  "csrfCookieName",
  function (headerName, cookieName) {
    var allowedMethods = ["GET"];

    this.setHeaderName = function (n) {
      headerName = n;
    };

    this.setCookieName = function (n) {
      cookieName = n;
    };

    this.setAllowedMethods = function (n) {
      allowedMethods = n;
    };

    this.$get = [
      "$cookies",
      function ($cookies) {
        return {
          request: function (config) {
            if (allowedMethods.indexOf(config.method) === -1) {
              // do something on success
              config.headers[headerName] = $cookies.get(cookieName);
            }
            return config;
          },
        };
      },
    ];
  },
]);
