import EmployeesIndexCtrl from "./employees-index.controller";
import employeesIndexViewTemplate from "./employees-index-view.template.html";

angular.module("employees.views").component("employeesIndexView", {
  bindings: {
    query: "<",
    advancedQuery: "<",
    savedSearches: "<savedSearchesResponse",
    $transition$: "<",
    $state$: "<",
  },
  template: employeesIndexViewTemplate,
  controller: EmployeesIndexCtrl,
});
