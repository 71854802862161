import PersonDuplicateButtonCtrl from "../person-duplicate-button/person-duplicate-button.controller";
import personDuplicatesTemplate from "../../../directives/components/person-duplicates/person-duplicates.template.html";

angular.module("directives").component("personDuplicates", {
  bindings: {
    person: "<ngModel",
  },
  template: personDuplicatesTemplate,
  controller: PersonDuplicateButtonCtrl,
});
