import EmployeeShowDuplicatesModalCtrl from "./employees-show-duplicates-modal.controller";
import employeesShowDuplicatesModalTemplate from "./employees-show-duplicates-modal.template.html";

angular.module("employees.modals").component("employeesShowDuplicates", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: employeesShowDuplicatesModalTemplate,
  controller: EmployeeShowDuplicatesModalCtrl,
});
