import { Graduation } from "../../../graduations/services/graduations.service";
import personsGraduationFormPopoverTemplate from "./persons-graduation-popover.template.html";

export class PersonsGraduationFormCtrl {
  static $inject = ["$templateCache", "PersonsGraduationsFactory"];
  constructor(private $templateCache: ng.ITemplateCacheService, private PersonsGraduation: any) {
    this.personsGraduationFormPopover = "persons-graduation-form-popover";
    $templateCache.put(this.personsGraduationFormPopover, personsGraduationFormPopoverTemplate);
  }

  public personsGraduationFormPopover: string;
  public graduations: Graduation[];
  public employee: any;

  public $onInit() {
    Graduation.getAll().then((graduations: Graduation[]) => {
      this.graduations = graduations;
    });

    if (this.employee.personsGraduations && this.employee.personsGraduations.length === 0) {
      this.addPersonsGraduation();
      this.employee.snapshot();
    }
  }

  public addPersonsGraduation() {
    this.employee.personsGraduations.push(new this.PersonsGraduation());
  }

  public deletePersonsGraduation(index) {
    this.employee.personsGraduations.splice(index, 1);
  }
}
