import PopoverCtrl from "./popover.controller";
import dashboardPopoverTemplate from "./popover.template.html";

angular.module("dashboards.components.dashboard2021").component("popover", {
  bindings: {
    step: "=",
  },
  template: dashboardPopoverTemplate,
  controller: PopoverCtrl,
});
