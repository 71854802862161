export default class MessageStatusCtrl {

  private ready: boolean = false;
  private url: string;
  private pollingService: {
    promise: ng.IPromise<any>;
    remove: () => void;
  };

  static $inject = ["OutlookDelivery"];
  
  constructor ( private OutlookDelivery: any ) {}

  public $onInit () {
    this.pollingService.promise.then(null, null, (response) => {
      const draft = response.data.data;
      if (draft.error_code) {
        this.OutlookDelivery.handleErrors(draft.error_code);
        this.pollingService.remove();
      } else if (draft.url) {
        this.url = draft.url;
        window.open(this.url, "_blank");
        this.pollingService.remove();
        this.ready = true;
      }
    });
  };
}