//= require ./modals.module.js

angular.module("textkernel.modals").factory("TextkernelModalMixin", [
  "$uibModal",
  "$window",
  "$filter",
  "$translate",
  function ($uibModal, $window, $filter, $translate) {
    function TextkernelModalMixin() {}

    function openTkSearchModal() {
      // var newTab = window.open(textkernelSearchUrl, '_blank');
      if (this.resourceType == "Project" && !this.isActive) {
        return;
      }

      if (_.has(this, "hasCv") && !this.hasCv) {
        $window.alert($filter("translate")("CANDIDATE_NO_CV"));
        return;
      }

      var modalInstance = $uibModal.open({
        resolve: {
          object: [
            function () {
              return this;
            }.bind(this),
          ],
        },
        component: "textkernelOpenSearch",
        size: "xl",
      });
      return modalInstance.result;
    }

    function tkImportFinished(projects, promises) {
      var modalInstance = $uibModal.open({
        resolve: {
          promises: [
            function () {
              return promises;
            },
          ],
          projects: [
            function () {
              return projects;
            },
          ],
        },
        component: "textkernelImportFinished",
        size: "sm",
      });
      return modalInstance.result;
    }

    function tkLonglistConfirm(promises, fromCandidate) {
      var modalInstance = $uibModal.open({
        resolve: {
          promises: [
            function () {
              return promises;
            },
          ],
          fromCandidate: [
            function () {
              return fromCandidate || false;
            },
          ],
        },
        component: "textkernelLonglistConfirm",
        size: "sm",
      });
      return modalInstance.result;
    }

    TextkernelModalMixin.extended = function (Resource) {
      Resource.include({
        openTextkernel: openTkSearchModal,
        textkernelImportFinished: tkImportFinished,
        textkernelLonglistConfirm: tkLonglistConfirm,
      });
    };

    return TextkernelModalMixin;
  },
]);
