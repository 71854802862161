angular
  .module("persons_graduations", ["persons_graduations.components"])

  .factory("PersonsGraduationsFactory", [
    "RailsResource",
    "railsSerializer",
    function (RailsResource, railsSerializer) {
      function PersonsGraduation() {
        (<any>PersonsGraduation).__super__.constructor.apply(this, arguments);
      }

      RailsResource.extendTo(PersonsGraduation);
      (<any>PersonsGraduation).configure({
        url: "/persons_graduations",
        name: "persons_graduation",
        interceptors: ["UnpackEnvelopeInterceptor"],
        serializer: railsSerializer(function () {
          this.serializeWith("highschool", "HighschoolsSerializer");
          this.serializeWith("Course", "CoursesSerializer");
          this.serializeWith("Graduation", "GraduationsSerializer");
          this.serializeWith("startDate", "DateSerializer");
          this.serializeWith("endDate", "DateSerializer");
        }),
      });

      return PersonsGraduation;
    },
  ]);
