import { initContract } from "@ts-rest/core";

import { TBaseResource } from "../../api-routing";
import { TActionType } from "../action_types";

const c = initContract();

const ActionTypesSchema = c.type<TBaseResource<TActionType[]>>();
export const actionTypesContract = c.router({
  query: {
    method: "GET",
    path: "/action_types",
    responses: {
      200: ActionTypesSchema,
    },
    summary: "Get all action types",
  },
});
