import { IFilterService } from 'angular';
export default class AmountCoveredBarChartCtrl {
  public options: any;
  public colors: string[] = [];
  public series: any[] = [];
  public weeks: any;
  public data: Array<string[] | number[]>;
  public labels: string[];

  static $inject = ["ChartColors2021", "$filter", "$translate"];

  constructor(
    private ChartColors: any, 
    private $filter: IFilterService, 
    private $translate: angular.translate.ITranslateService
  ) {}

  public $onInit () {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      borderWidth: 0,
      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 12,
          fontSize: 8,
          padding: 16,
          usePointStyle: true,
        },
      },
    };

    this.colors = [this.ChartColors[1], this.ChartColors[3]];
    this.series = [
      this.$filter("translate")("PROFIT_CONTRIBUTION_TOKEN_CURRENT_CAPS"),
      this.$filter("translate")("PROFIT_CONTRIBUTION_TOKEN_TARGET"),
    ];
  };

  public isEmpty(obj) {
    return !_.chain(obj)
      .flatten()
      .any((value) => {
        return value > 0;
      })
      .value();
  };

  public groupAmountCoveredValues() {
    return [
      _.map(this.weeks, (value, key) => {
        return (value.amountCoveredIst.cents / 100).toFixed(0);
      }),
      _.map(this.weeks, (value, key) => {
        return (value.amountCoveredSoll.cents / 100).toFixed(0);
      }),
    ];
  };

  public updateChartData() {
    if (!_.isObject(this.weeks)) {
      return false;
    }

    this.data = this.groupAmountCoveredValues();
    this.labels = _.keys(this.weeks);
  };

  public $onChanges(changes) {
    if (changes.weeks && changes.weeks.previousValue != changes.weeks.currentValue) {
      this.updateChartData();
    }
  };
};