import AddressFormCtrl from "./address-form.controller";
import addressFormTemplate from "./address-form.template.html";

angular.module("directives").component("addressForm", {
  bindings: {
    address: "=",
  },
  template: addressFormTemplate,
  controller: AddressFormCtrl,
  controllerAs: "$ctrl",
});
