import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TDepartments } from "../departments";
import { TTag } from "../../tags/tags";
import { JobCategory } from "../../job_categories/services/job_categories.service";
import { Hierarchy } from "../../hierarchies/services/hierarchies.service";

export class Department {
  private id: number;
  private name: string;
  private slug: string;
  private jobCategories?: TTag[];
  public hierarchies?: Hierarchy[];

  static $inject = [];

  constructor(fields?: Partial<TDepartments>) {
    if (fields) {
      let object = deepMapKeys<Partial<TDepartments>>(fields, camelCase);
      object.jobCategories = object.jobCategories?.map((element) => (<unknown>new JobCategory(element)) as TTag);
      object.hierarchies = object.hierarchies?.map((element) => (<unknown>new Hierarchy(element)) as TTag);

      Object.assign(this, object);
    }
  }

  @cached
  public static getAll() {
    return baseClient.departments.query().then((response) => {
      return response.body.data.map((element) => {
        return new Department(element);
      });
    });
  }

  public async queryHierarchies() {
    return baseClient.departments.getHierarchies({ params: { id: this.id?.toString() } }).then((response) => {
      return this.hierarchies = response.body.data.hierarchies.map((hierarchy) => new Hierarchy(hierarchy));
    });
  }
}
