import { ProjectsListCtrl } from "./projects-list.controller";
import projectsListTemplate from "./projects-list.template.html";

angular.module("projects.components").component("projectsList", {
  bindings: {
    object: "=",
    initialViewState: "<",
  },
  template: projectsListTemplate,
  controller: ProjectsListCtrl,
});
