import { ITemplateCacheService } from "angular";
import priorityPopoverTemplate from "../../../priorities/components/priority-popover/priority-popover.template.html";
import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";

export default class EmployeesTableBodyCtrl {
  public priorityPopoverTemplate: string;
  public createAsCandidate: any;
  public state: any;
  public projectStatuses: any;
  public startCallback: () => void;
  public draggingHelper: (event: DragEvent) => void;

  static $inject = [
    "QuicklistsFactory",
    "EmployeesFactory",
    "CandidatesProjectsFactory",
    "PersonTransformer",
    "$templateCache"
  ];

  constructor(
    private Quicklist: any,
    public Employee: any,
    private CandidatesProjects: any,
    private PersonTransformer: any,
    private $templateCache: ITemplateCacheService,
  ) {
    this.priorityPopoverTemplate = priorityPopoverTemplate;
    this.$templateCache.put("info-popover-employee", "<info-popover resource='employee'></info-popover>");
    this.createAsCandidate = this.PersonTransformer.createAsCandidate;
  }

  public $onInit() {
    angular.extend(this, {
      draggingHelper: this.Quicklist.draggingHelper,
      startCallback: this.Quicklist.startCallback,
      projectStatuses: this.CandidatesProjects.statuses,
      state: StateTransitionsConfig.state,
    });
  };
};