import CompaniesIndexCtrl from "./index.controller";
import companiesIndexTemplate from "./index.template.html";

angular.module("companies.views").component("companiesIndex", {
  bindings: {
    query: "<",
    advancedQuery: "<",
    $state$: "<",
    stateParams: "<",
  },
  template: companiesIndexTemplate,
  controller: CompaniesIndexCtrl,
});
