import { IFilterService, ILogService } from "angular";
import { Language } from "../../../languages/services/languages.service";

export default class JobOffersEditCtrl {
  public oldJobOfferLanguage: Language;
  public countries: any[];
  public languages: Language[];
  public jobOffer: any;
  public saving: boolean;
  public force_validation: boolean;
  public onSaveError: (error: any) => void;
  public onStateChanged: (jobOffer: any) => void;
  public registerSaveHotkey: (jobOffer: any) => void;

  static $inject = [
    "$rootScope",
    "$state",
    "$log",
    "$filter",
    "EditUtilMixin",
    "JobOfferFactory",
    "entourageSubsidiaries"
  ];

  constructor (
    private $rootScope: IExtendedRootScopeService,
    private $state: angular.ui.IStateService,
    private $log: ILogService,
    private $filter: IFilterService,
    private EditUtilMixin: any,
    private JobOffer: any,
    public entourageSubsidiaries: any,
  ) { 
    angular.extend(this, EditUtilMixin, {
      currencies: JobOffer.CURRENCIES,
      countryCodes: JobOffer.COUNTRY_CODES,
    });
  }

  public $onInit() {
    angular.extend(this, {
      countries: _.filter(this.countries, (country) => {
        return country.experteerId;
      }),
      languages: _.filter(this.languages, (language) => {
        return language.slug == "de" || language.slug == "en";
      }),
    });
    this.jobOffer.language = this.languages[0];
    this.oldJobOfferLanguage = this.jobOffer.language;

    this.onStateChanged(this.jobOffer);
    this.registerSaveHotkey(this.saveJobOffer);
  };

  public executeSave(route, params) {
    this.jobOffer.save().then(
      (jobOffer) => {
        this.$log.debug("jobOffer successfully saved");

        this.$rootScope.$broadcast("updatedJobOffer", jobOffer);

        this.jobOffer.snapshot();
        this.$state.go(route || "^.show", params || jobOffer.params());
      },
      (error) => {
        this.onSaveError.bind(this)(error);
        this.force_validation = true;
      }
    );
  };

  public saveJobOffer(route, params) {
    if (!this.saving) {
      this.saving = true;
      this.force_validation = false;

      if (this.jobOffer.project.isActive) {
        this.executeSave(route, params);
      } else if (
        this.jobOffer.visible &&
        window.confirm(this.$filter("translate")("JOB_OFFER_PROJECT_INACTIVE_NOTICE"))
      ) {
        this.jobOffer.project.projectStatus = "active";
        this.jobOffer.project.save().then(() => {
          this.executeSave(route, params);
        }, this.onSaveError.bind(this));
      } else {
        this.executeSave(route, params);
      }
    }
  };

  public updateCompanyDescription(nv, ov) {
    if (ov != nv && window.confirm(this.$filter("translate")("SELECT_LANGUAGE_JOB_OFFER_NOTICE", { name: nv.name }))) {
      this.JobOffer.$get("job_offers/new", { project_id: this.jobOffer.project.id, lang_id: nv.id }).then((
        newJobOffer
      ) => {
        this.jobOffer.companyDescription = newJobOffer.companyDescription;
      });
    }
  };

  public $doCheck() {
    var updatedJobOfferLanguage = this.jobOffer.language;
    if (this.oldJobOfferLanguage != updatedJobOfferLanguage) {
      this.updateCompanyDescription(updatedJobOfferLanguage, this.oldJobOfferLanguage);
      this.oldJobOfferLanguage = updatedJobOfferLanguage;
    }
  };
};