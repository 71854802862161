//= require ../working-relationships.module.js

angular.module("working_relationships").factory("WorkingRelationshipsFactory", [
  "RailsResource",
  "railsSerializer",
  "$filter",
  "$translate",
  function (RailsResource, railsSerializer, $filter, $translate) {
    function WorkingRelationShip() {
      (<any>WorkingRelationShip).__super__.constructor.apply(this, arguments);
    }

    RailsResource.extendTo(WorkingRelationShip);
    (<any>WorkingRelationShip).configure({
      url: "/working_relationships",
      name: "working_relationship",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("employer", "CompaniesFactory");
        this.resource("supervisor", "EmployeesFactory");
        this.serializeWith("cancelationPeriod", "CancelationPeriodSerializer");
        this.serializeWith("startDate", "DateSerializer");
        this.serializeWith("endDate", "DateSerializer");
        this.serializeWith("department", "DepartmentsSerializer");
      }),
    });

    WorkingRelationShip.defaultTableAttributes = {
      id: {
        title: "ID",
        show: true,
        pinned: true,
        menu: true,
        class: "id",
      },
      name: {
        title: $filter("translate")("NAME"),
        show: true,
        fixedWidth: true,
        class: "title",
      },
      department: {
        title: $filter("translate")("DEPARTMENT"),
        show: true,
        class: "minimal",
      },
      hierarchy: {
        title: $filter("translate")("HIERARCHY"),
        show: true,
        class: "minimal",
      },
      supervisor: {
        title: $filter("translate")("SUPERVISOR"),
        show: true,
        class: "minimal",
      },
      startDate: {
        title: $filter("translate")("BEGIN"),
        show: true,
        class: "date",
      },
      endDate: {
        title: $filter("translate")("END"),
        show: true,
        class: "date",
      },
      cancelationPeriod: {
        title: $filter("translate")("NOTICE_PERIOD"),
        show: true,
        class: "minimal",
      },
      position: {
        title: $filter("translate")("POSITION"),
        show: false,
        class: "minimal",
      },
    };

    return WorkingRelationShip;
  },
]);
