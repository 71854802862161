//= require ./services.module.js
angular.module("services").factory("RedirectLocation", [
  "$location",
  function ($location) {
    return {
      encode: function () {
        return encodeURIComponent($location.search("redirect_to", undefined).absUrl());
      },
      decode: function (url) {
        if (!url) {
          return undefined;
        }
        return decodeURIComponent(url);
      },
      redirectParams: function () {
        return {
          params: JSON.stringify($location.$$search), //store the parameters
          referrer: encodeURIComponent($location.$$path), //store the original url
        };
      },
    };
  },
]);
