//= require ./services.module.js
angular.module("services").factory("EditUtilMixin", [
  "$state",
  "$transitions",
  "$rootScope",
  "hotkeys",
  "$filter",
  "$translate",
  function ($state, $transitions, $rootScope, hotkeys, $filter, $translate) {
    function onSaveError(error) {
      error = _.defaults(error || {}, { status: undefined });
      switch (error.status) {
        case 422:
          window.alert($filter("translate")("SAVING_ERROR_NOTICE"));
          break;
        default:
          window.alert($filter("translate")("SAVING_ERROR_NEW_TRY_NOTICE"));
      }

      this.saving = false;
    }

    function onStateChanged(object, onChange) {
      object.snapshot();

      var deregSessionTimeout = $rootScope.$on("sessionTimeout", function (event, timeout) {
        if (object.unsnappedChanges()) {
          // // we couldn't cancel timeout events directly (does not work correctly...)
          if (timeout) {
            $rootScope.cancelTimeout = timeout;
            window.alert($filter("translate")("NO_ACTIVITY_SAVE_CHANGES_NOTICE"));
          } else {
            object.snapshot();
            window.alert($filter("translate")("SESSION_INVALID_NOTICE"));
          }
        }
        $rootScope.$on("$destroy", function () {
          deregSessionTimeout();
        });
      });
      // hacky but necessary (execute logout listener after the one we just added)
      $rootScope.$$listeners.sessionTimeout = _.chain($rootScope.$$listeners.sessionTimeout).reverse().value();

      $transitions.onStart({}, function (transition) {
        if (object.unsnappedChanges()) {
          if (window.confirm($filter("translate")("CHANGE_SITE_INFORMATION_LOSS_NOTICE"))) {
            object.snapshot();
          } else {
            $rootScope.$broadcast("$stateChangeAbort");

            return false;
          }
        } else {
          if (onChange) {
            onChange();
          }
        }
      });

      return function () {
        deregSessionTimeout();
      };
    }

    function registerSaveHotkey(onSave) {
      if (hotkeys.get("mod+enter")) {
        hotkeys.del("mod+enter");
      }
      if (!hotkeys.get("mod+enter")) {
        var save = function (event) {
          event.preventDefault();
          onSave();
        };

        var enter_opts = _.pick(hotkeys.get("enter"), ["callback", "description", "combo"]);
        hotkeys.del("enter");

        hotkeys.add({
          combo: "mod+enter",
          description: "Speichert das aktuell geöffnete Formular",
          callback: save,
        });

        var textAngularWorkaround = $rootScope.$on("$saveTextAngularForm", save);

        $rootScope.$on("$destroy", function () {
          textAngularWorkaround();
        });

        var dereg = $transitions.onSuccess({}, function (transition) {
          hotkeys.del("mod+enter");
          if (!_.isEmpty(enter_opts)) {
            hotkeys.add(enter_opts);
          }
          dereg();
          textAngularWorkaround();
        });
      }
    }

    return {
      onSaveError: onSaveError,
      onStateChanged: onStateChanged,
      registerSaveHotkey: registerSaveHotkey,
    };
  },
]);
