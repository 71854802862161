import GenericDeckCtrl from "./generic-deck.controller";
import genericDeckTemplate from "./generic-deck.template.html";

angular.module("app").component("genericDeck", {
  bindings: {
    objectClass: "<objectType",
    sidebar: "<",
    scrollspyScope: "<",
  },
  template: genericDeckTemplate,
  controller: GenericDeckCtrl,
});
