import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";
import { IRootScopeService } from 'angular';
export default class ActionsTableBodyCtrl {
  private StateTransitionsConfig: any;
  private loading: boolean;
  private dereg: () => void;

  static $inject = ["$rootScope", "ActionsFactory", "DealsFactory"];

  constructor(private $rootScope: IRootScopeService, private Action: any, private Deal: any) {
    angular.extend(this, {
      state: StateTransitionsConfig.state,
      reduceParams: StateTransitionsConfig.reduceParams,
    });
  }
  
  public editDeal (action) {
    this.loading = true;
    this.dereg = this.$rootScope.$on("deal-form-opened", (event: any) => {
      this.loading = false;
      this.dereg();
    });

    if (action.deal) {
      this.Deal.get(action.deal.id)
        .then( (deal: any) => {
          deal.edit();
        })
        .catch(() => {
          this.loading = false;
          this.dereg();
        });
    }
  };

  public $onDestroy () {
    if (_.isFunction(this.dereg)) { this.dereg(); }
  };
}
