import { IFilterService } from "angular";
import { TransactionBasis } from "../../../../transaction_bases/services/transaction_bases.service";
import { TTransactionBase } from "../../../../types/transactionBase"
export default class CompaniesTransactionSearchCtrl {
  public termsAndConditionsUniqID: string;
  public selectedTransactionBasisUniqID: string;
  public documentSelectUniqId: string;
  public transactionBasesTypes: TTransactionBase[];
  public advancedQuery: any;
  static $inject = ["$filter"];

  constructor (
    private $filter: IFilterService
  ) {}

  public async $onInit() {
    this.termsAndConditionsUniqID = _.uniqueId("termsAndConditionsEnabled");
    this.selectedTransactionBasisUniqID = _.uniqueId("selectedTransactionBasis");
    this.documentSelectUniqId = _.uniqueId("documentSelected");
    this.transactionBasesTypes = await TransactionBasis.getAllForCompanies();
    
    var selectAllOption = {
      id: 0,
      name: this.$filter("translate")("SELECT_ALL_TERMS"),
    };
    this.transactionBasesTypes = [selectAllOption].concat(this.transactionBasesTypes);

    this.advancedQuery = this.advancedQuery || {};
    this.advancedQuery.selectedTransactionBasis = this.advancedQuery.selectedTransactionBasis || {};

    if (!this.advancedQuery.selectedTransactionBasis.field && this.transactionBasesTypes.length > 1) {
      this.advancedQuery.selectedTransactionBasis.field = this.transactionBasesTypes[0].id;
    }
  };
};