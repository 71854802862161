export class QuicklistsCandidatesListCtrl {
  static $inject = ["CandidatesFactory", "PrsmTableAttributeService"];

  constructor(private CandidateService: any, private PrsmTableAttributeService: any) {}

  public quicklist: any;
  public candidates_attributes: any;

  public $onInit() {
    var quicklist_entry_attributes = {
      deleteEntry: { show: !this.quicklist.isReadOnly() },
      kpis: { show: false },
    };

    this.candidates_attributes = new this.PrsmTableAttributeService(
      "quicklist-candidates-table-attributes",
      _.extend(angular.copy(this.CandidateService.defaultTableAttributes), quicklist_entry_attributes)
    ).getAttributes();
  }
}
