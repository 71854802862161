import { initContract } from "@ts-rest/core";

import { TCareerLevel } from "../career_levels";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const CareerLevelsSchema = c.type<TBaseResource<TCareerLevel[]>>();
export const careerLevelsContract = c.router({
  query: {
    method: "GET",
    path: "/career_levels",
    responses: {
      200: CareerLevelsSchema,
    },
    summary: "Get all career levels",
  },
});
