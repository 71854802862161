export class QuicklistsShowCtrl {
  static $inject = ["$rootScope", "$templateCache", "Session", "DeckControllerMixin", "KPISteps", "poller"];

  constructor(
    private $rootScope: IExtendedRootScopeService,
    private $templateCache: ng.ITemplateCacheService,
    private Session: any,
    private DeckControllerMixin: any,
    private KPISteps: any,
    private poller: any
  ) {
    this.updateEntries = this.updateEntries.bind(this);
    this.setKpiKey = this.setKpiKey.bind(this);
  }

  private deregActiveRowConnector: () => void;

  public advancedQuery: any;
  public query: string;
  public quicklist: any;
  public quicklistEntriesLazyLoading: boolean;
  public stateParams: any;
  private initializeActiveRowConnector: (quicklist: any) => () => void;
  public allowDownloadFn: boolean;
  public steps: any;
  public downloadingPdf: boolean;

  public $onInit() {
    this.$templateCache.put(
      this.quicklist.getListTemplate("list"),
      "<" +
        this.quicklist.getListTemplate("list") +
        ' quicklist="$ctrl.quicklist" ' +
        ' quicklist-entries-lazy-loading="$ctrl.quicklistEntriesLazyLoading" ' +
        ' steps="$ctrl.steps" ' +
        ' set-kpi-key="$ctrl.setKpiKey" ' +
        ' advanced-query="$ctrl.advancedQuery" ></' +
        this.quicklist.getListTemplate("list") +
        ">"
    );

    this.$templateCache.put(
      this.quicklist.getListTemplate("search"),
      "<" +
        this.quicklist.getListTemplate("search") +
        ' query="$ctrl.query" ' +
        ' advanced-query="$ctrl.advancedQuery" ' +
        ' update-entries="$ctrl.updateEntries" ></' +
        this.quicklist.getListTemplate("search") +
        ">"
    );

    angular.extend(this, this.DeckControllerMixin, {
      allowDownloadFn: this.Session.user.allowQuicklistDownload,
      advancedQuery: _.defaults(this.advancedQuery, { kpiFilter: {} }),
      steps: _.map(this.KPISteps, (value, key) => {
        return { key: key, value: value };
      }),
    });

    this.updateEntries(this.advancedQuery.collectSearchParams());

    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.quicklist);
    this.$rootScope.setPageTitle(this.quicklist.name);
    this.downloadingPdf = false;
  }

  public updateEntries(newParams: any): void {
    this.quicklistEntriesLazyLoading = true;
    this.quicklist.loadEntries(angular.merge(this.stateParams, newParams)).then(() => {
      this.quicklistEntriesLazyLoading = false;
    });
  }

  public setKpiKey(key: string) {
    this.advancedQuery.kpiFilter[key] = !this.advancedQuery.kpiFilter[key];
    this.advancedQuery.basicQuery = this.query;
    this.updateEntries(this.advancedQuery.collectSearchParams());
  }

  public isRemovableShare(user: any) {
    return user.id == this.Session.user.id;
  }

  public removeShare() {
    this.quicklist.deleteWrapper();
  }

  public $onDestroy() {
    this.deregActiveRowConnector();
  }

  public getQuicklistPdf() {
    this.downloadingPdf = true;
    var _this = this;
    const _quicklist = this.quicklist;

    _quicklist.$post(_quicklist.$url("/generate_pdf")).then(() => {
      const pollingService = this.poller.get(_quicklist.$url("/pdf_status.json"), { catchError: true, delay: 2000 });

      pollingService.promise.then(null, null, function (response) {
        if (response.status === 200) {
          // @ts-ignore
          if(response.data.data.pdf_download_url) {
            _quicklist.pdfDownloadUrl = response.data.data.pdf_download_url;
            window.open(_quicklist.pdfDownloadUrl, "_blank");
            pollingService.stop();
            pollingService.remove();
            _quicklist.$post(_quicklist.$url("/delete_document"));
            _this.downloadingPdf = false;
          }
        } else {
          pollingService.remove();
        }
      });
    });
  }
}
