//= require ../modals.module.js

angular.module("persons.modals").factory("PersonModalMixin", [
  "$uibModal",
  "$filter",
  "$translate",
  "poller",
  "QuicklistsFactory",
  "ModalUtilMixin",
  function ($uibModal, $filter, $translate, poller, Quicklist, ModalUtilMixin) {
    function PersonModalMixin() {}

    function checkForEmail(person) {
      if (!person.privateEmail && !person.workEmail) {
        window.alert($filter("translate")("EMAIL_ADDRESS_NEEDED_NOTICE"));
        return true;
      } else if (!person.privateEmail && person.resourceType == "Candidate") {
        if (!window.confirm($filter("translate")("NO_PRIVATE_EMAIL_CANDIDATE_NOTICE"))) {
          return true;
        }
      } else if (!person.workEmail && person.resourceType == "Employee") {
        if (!window.confirm($filter("translate")("NO_WORK_EMAIL_MANAGER_NOTICE"))) {
          return true;
        }
      }
      return false;
    }

    function openQuicklistModal() {
      var _this = this;
      var quicklistType = this.resourceType + "Quicklist";
      Quicklist.openModal(_this, [
        function () {
          return Quicklist.getAll().then(function (quicklists) {
            quicklists = _.filter(quicklists, function (quicklist) {
              return quicklist.type == quicklistType || !quicklist.type;
            });
            return _.map(quicklists, function (quicklist) {
              var entries = quicklist.entryIds;
              return _.extend(quicklist, {
                selected: !_.isUndefined(
                  _.find(entries, function (entryId) {
                    return entryId == _this.id;
                  })
                ),
              });
            });
          });
        },
      ]);
    }

    function openContactModal(callback) {
      var person = this;

      return $uibModal.open({
        component: "contactModal",
        resolve: {
          person: function () {
            return person;
          },
          callback: function () {
            return callback;
          },
        },
      });
    }

    function createChooseCompanyModal(title, person, sendMessageToObject) {
      var modalInstance = $uibModal.open({
        component: "chooseCompany",
        resolve: {
          person: function () {
            return person;
          },
        },
        size: "lg",
      });

      return modalInstance.result;
    }

    function openNDAModal() {
      var _this = this;
      var title = $filter("translate")("SEND_NDA_TITLE");
      var ndaParams = {};

      if (checkForEmail(_this)) {
        return;
      }

      ModalUtilMixin.chooseLocalesModal(true)
        .then(function (params) {
          _.extend(ndaParams, {
            locale: params.lang,
            subsidiary_slug: params.subsidiarySlug,
          });
          if (_this.resourceType == "Employee") {
            return createChooseCompanyModal(title, _this, _this);
          }
        })
        .then(function (company) {
          _.extend(ndaParams, { company: company });
          var modalInstance = ModalUtilMixin.createChooseContactModal(
            "create_nda_email",
            title,
            _this,
            _this,
            ndaParams
          );

          return modalInstance.result.then(
            function (ndaEmail) {
              ndaEmail.callback.then(function (res) {
                var pollingService = poller.get(res.data.url, { delay: 1000 });
                _this.confirm($filter("translate")("NDA_EMAIL_CONFIRMATION"), pollingService).then(
                  function (res) {
                    if (res) {
                      _this.sendDocument("send_nda");
                    }
                    pollingService.remove();
                  },
                  function () {
                    pollingService.remove();
                  }
                );
              });
            },
            function () {}
          );
        });
    }

    function openEventConfirmationModal() {
      var _this = this;
      var title = $filter("translate")("SEND_APPOINTMENT_CONFIRMATION");
      var modalInstance = ModalUtilMixin.createChooseContactModal("create_event_confirmation_email", title, this);

      return modalInstance.result.then(function (eventConfirmationEmail) {
        console.log("eventConfirmationEmail finished");
      }, angular.noop);
    }

    function chooseInfoPresentationModal() {
      var modalInstance = $uibModal.open({
        component: "chooseInfoPresentation",
        size: "lg",
        resolve: {
          infoPresentations: [
            "$http",
            function ($http) {
              return $http.get("info_presentations.json").then(function (infoPresentations) {
                return infoPresentations.data.data;
              });
            },
          ],
        },
      });

      return modalInstance.result;
    }

    function openInfoPresentationModal() {
      var _this = this;
      var title = $filter("translate")("SEND_PRESENTATION");

      if (checkForEmail(_this)) {
        return; // returns unless confirmed
      }

      return chooseInfoPresentationModal().then(
        function (presentation) {
          var modalInstance = ModalUtilMixin.createChooseContactModal(
            "create_presentation_email",
            title,
            _this,
            _this,
            presentation
          );

          modalInstance.result.then(function (infoPresentationEmail) {
            infoPresentationEmail.callback.then(function (res) {
              var pollingService = poller.get(res.data.url, { delay: 1000 });
              _this.confirm($filter("translate")("INFOPRESENTATION_SENT_CONFIRMATION"), pollingService).then(
                function (res) {
                  if (res) {
                    _this.sendDocument("send_presentation", infoPresentationEmail);
                  }
                  pollingService.remove();
                },
                function () {
                  pollingService.remove();
                }
              );
            });
          });
        },
        function () {}
      );
    }

    function openSendSkillmatrixModal(person) {
      var _this = this;
      var title = $filter("translate")("SEND_SKILLMATRIX");

      if (checkForEmail(_this)) {
        return; // returns unless confirmed
      }

      var modalInstance = ModalUtilMixin.createChooseContactModal("create_skillmatrix_email", title, this);

      return modalInstance.result.then(
        function (skillmatrixEmail) {
          skillmatrixEmail.callback.then(function (res) {
            var pollingService = poller.get(res.data.url, { delay: 1000 });
            _this.confirm($filter("translate")("SKILLMATRIX_SENT_CONFIRMATION"), pollingService).then(
              function (res) {
                if (res) {
                  _this.sendDocument("send_skillmatrix");
                }
                pollingService.remove();
              },
              function () {
                pollingService.remove();
              }
            );
          });
        },
        function () {}
      );
    }

    function openSkillmatrixEditModal() {
      return $uibModal.open({
        component: "openSkillmatrixEdit",
        size: "lg",
        resolve: {
          person: function () {
            return this;
          }.bind(this),
        },
      });
    }

    PersonModalMixin.extended = function (Resource) {
      Resource.include({
        openQuicklistModal: openQuicklistModal,
        openContactModal: openContactModal,
        openNDAModal: openNDAModal,
        openInfoPresentationModal: openInfoPresentationModal,
        openEventConfirmationModal: openEventConfirmationModal,
        openSendSkillmatrixModal: openSendSkillmatrixModal,
        openSkillmatrixEditModal: openSkillmatrixEditModal,
      });
    };

    return PersonModalMixin;
  },
]);
