export default class HighschoolSelectCtrl {
  private cancelers: ng.IDeferred<any>[] = [];
  private foundHighschools: any[] = [];

  static $inject = ["$q", "Autocompletes",];

  constructor (private $q: ng.IQService, private Autocompletes: any) {}

  public searchHighschools (viewValue) {
    if (viewValue.length) {
      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      this.Autocompletes.autocomplete(viewValue, "highschools", _.last(this.cancelers)).then( (results) => {
        this.foundHighschools = _.chain(results)
          .filter({ resource_type: "tag" })
          .map( (result) => {
            return _.extend(result, {
              resource_type: "Hochschule",
            });
          })
          .uniq( (resource) => {
            return resource.tag.id;
          })
          .value();
      });
    } else {
      this.foundHighschools = [];
    }
  };
}