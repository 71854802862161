import { ITemplateCacheService } from "angular";
import advancedSearchTranscludeTemplate from "./advanced-search-transclude.template.html";

export default class AdvancedSearchCtrl {
  public directiveOptions: { advancedQuery: { getNumberOfConditions: () => Promise<number>; } };
  public dropdownIsOpen: boolean;
  public numberOfSearchConditions: number;
  public getNumberOfConditions: () => number;

  static $inject = ["$templateCache"];

  constructor (
    private $templateCache: ITemplateCacheService
  ) {
    this.$templateCache.put("advanced-search-transclude-template", advancedSearchTranscludeTemplate);
  };

  public $onInit() {
    _.defaults(this.directiveOptions, {
      closing: () => {
        this.dropdownIsOpen = false;
      },
    });

    this.getNumberOfSearchConditions();
  };

  public async getNumberOfSearchConditions () {
    this.directiveOptions.advancedQuery
      .getNumberOfConditions()
      .then((result) => (this.numberOfSearchConditions = result));
  };
};
