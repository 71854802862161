import CandidateShowDuplicatesModalCtrl from "./candidate-show-duplicates-modal.controller";
import candidateShowDuplicatesModalTemplate from "./candidate-show-duplicates-modal.template.html";

angular.module("candidates.modals").component("candidateShowDuplicates", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: candidateShowDuplicatesModalTemplate,
  controller: CandidateShowDuplicatesModalCtrl,
});
