export class ProjectsAdvancedSearchCtrl {
  public advancedQuery: any;
  public advancedQueryCopy: any;
  public remote: boolean;
  private closing: () => void;
  private setData: () => (advancedQuery: any) => void;

  static $inject = ["$state"];
  constructor(private $state: angular.ui.IStateService) {}

  public $onInit() {
    this.advancedQueryCopy = angular.copy(this.advancedQuery);
    this.advancedQueryCopy.name = undefined;
    this.advancedQueryCopy.id = undefined;
  }

  public performAdvancedSearch() {
    if (!this.remote) {
      this.$state.go("root.projects", this.advancedQueryCopy.collectSearchParams(), { reload: true, inherit: false });
    } else {
      _.extend(this.advancedQuery, this.advancedQueryCopy);
      this.setData()(this.advancedQueryCopy.collectSearchParams());
      this.closing();
    }
  }
}
