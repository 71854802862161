//= require ./modals.module.js

angular.module("project_reporting.modals").factory("ProjectReportingsModalMixin", [
  "$uibModal",
  function ($uibModal) {
    function ProjectReportingsModalMixin() {}

    function editModal(user) {
      var modalInstance = $uibModal.open({
        component: "kpiOperativeEditModal",
        size: "lg",
        resolve: {
          user: [
            function () {
              return user;
            },
          ],
        },
      });

      return modalInstance.result.then(
        function () {},
        function () {}
      );
    }

    ProjectReportingsModalMixin.extended = function (Resource) {
      Resource.include({
        edit: editModal,
      });
    };

    return ProjectReportingsModalMixin;
  },
]);
