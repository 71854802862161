import datePickerCtrl from "../date-picker/date-picker.controller";
import weekPickerTemplate from "./week-picker.template.html";

angular.module("directives").component("weekPicker", {
  bindings: {
    date: "=ngModel",
    placeholder: "<ngPlaceholder",
    disabled: "@",
    required: "@",
    uniqName: "@name",
    onChanges: "&",
  },
  template: weekPickerTemplate,
  controller: datePickerCtrl,
});
