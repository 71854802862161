import { IFilterService } from "angular";

export default class CourseSelectCtrl {
  public cancelers: ng.IDeferred<any>[] = [];
  public foundCourses: any[] = [];
  static $inject = ["$q", "Autocompletes", "$filter"];

  constructor(
    private $q: ng.IQService,
    private Autocompletes: any,
    private $filter: IFilterService
  ) {}

  public searchCourses(viewValue) {
    var _this = this;
    if (viewValue.length) {
      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      this.Autocompletes.autocomplete(viewValue, "courses", _.last(this.cancelers)).then((results) => {
        _this.foundCourses = _.chain(results)
          .filter({ resource_type: "Tag" })
          .map((result) => {
            return _.extend(result, {
              resource_type: this.$filter("translate")("FIELD_OF_STUDY"),
            });
          })
          .uniq((resource) => {
            return resource.tag.id;
          })
          .value();
      });
    } else {
      this.foundCourses = [];
    }
  };
};
