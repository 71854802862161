import { Language } from "../../../languages/services/languages.service";

export class ActiveProcessesModalCtrl {
  public user: any;
  public managers: any[];
  public project: any;
  public resolve: {
    managers: any[];
    project: any;
  };
  public managerContactEntries: any[];
  public availableManagerContactEntries: any[];
  public languages: { key: string; name: string }[];
  public activeProcessesEmail: {
    employees: any[];
    callback: (() => void) | null;
    locale: string | undefined;
  };
  public modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = ["Session"];
  constructor(private Session: any) {}

  public $onInit() {
    this.user = this.Session.user;
    this.managers = this.resolve.managers;
    this.project = this.resolve.project;

    this.managerContactEntries = _.each(this.managers, (manager) => {
      manager.$hasContactAddress = !_.isEmpty(manager.workEmail || manager.privateEmail);
    });
    this.availableManagerContactEntries = _.where(this.managerContactEntries, { $hasContactAddress: true });
    this.languages = Language.defaultOptions;

    this.activeProcessesEmail = {
      employees: this.managerContactEntries,
      callback: null,
      locale: _.first(this.languages)?.key,
    };
  }

  public cancel() {
    this.modalInstance.dismiss("cancel");
  }

  public save() {
    this.activeProcessesEmail.callback = this.project.createMessage(
      "create_active_processes_email",
      this.activeProcessesEmail
    );
    this.modalInstance.close(this.activeProcessesEmail);
  }
}
