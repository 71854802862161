import { IFilterService } from 'angular';
import { IStateService } from 'angular-ui-router';
export default class CompanySearchCtrl {
  public foundCompanies: any[] = [];
  public loading: boolean;
  public cancelers: ng.IDeferred<any>[] = [];
  public advancedQuery: any;
  public advancedCompanySearchOptions: any;
  public query: string;
  public remote: boolean;
  private setData: () => (params: any) => void;

  static $inject = [
    "$state",
    "$q",
    "$filter",
    "CompaniesFactory",
    "EmployeesFactory",
    "Autocompletes",
    "SavedSearchesFactory"
  ];

  constructor(
    private $state: IStateService,
    private $q: ng.IQService,
    private $filter: IFilterService,
    private Companies: any,
    private Employees: any,
    private Autocompletes: any,
    private SavedSearch: any,
  ) { }
  
  public $onInit() {
    if (this.advancedQuery) {
      this.advancedQuery.resourceType = "CompanySearch";
      this.loading = false;
    }
    this.advancedCompanySearchOptions = {
      query: this.query,
      setData: this.setData(),
      remote: this.remote,
      advancedQuery: this.advancedQuery,
    };
  };

  public searchCompanies(viewValue) {
    if (viewValue.length) {
      this.loading = true;
      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      this.Autocompletes.autocomplete(viewValue, "company_name,employee_name", _.last(this.cancelers)).then(
        (results) => {
        this.foundCompanies = _.map(results, (result: any) => {
          switch (result.resource_type) {
            case "company":
              if (result.company && result.company.name) {
                result.name = result.company.name;
              }
              return _.extend(result, {
                resource_type: this.$filter("translate")("COMPANY"),
                fn: (id, query) => {
                  this.advancedQuery.basicQuery = query;
                  this.$state.go("root.companies.show", this.advancedQuery.collectSearchParams({ company_id: id }), {
                    reload: true,
                    inherit: false,
                  });
                },
              });
            case "employee":
              if (result.employee && result.employee.full_name) {
                result.name = result.employee.full_name;
              }
              return _.extend(result, {
                resource_type: this.$filter("translate")("MANAGER"),
                fn: function (id, query) {
                  this.advancedQuery.basicQuery = query;
                  this.$state.go(
                    "root.companies.list.employees.show",
                    this.advancedQuery.collectSearchParams({ employee_id: id }),
                    { reload: true, inherit: false }
                  );
                },
              });
            default:
              result.name = result.highlight[0];
              return _.extend(result, {
                resource_type: "Query",
                fn: function (id, query) {
                  this.search(query);
                },
              });
          }
        });
        this.loading = false;
      });
    } else {
      this.foundCompanies = [];
    }
  };

  public search(item, query) {
    if (item && item.id) {
      query = item.name;
    }
    _.invoke(this.cancelers, "resolve");

    this.advancedQuery.basicQuery = query;
    var params = this.advancedQuery.collectSearchParams({ company_id: item ? item.id : undefined });

    if (!this.remote) {
      var route = "root.companies";
      if (item && item.id) {
        route = "root.companies.show";
        params.q = '"' + query.replace(/\//g, " ") + '"';
      }

      this.$state.go(route, params, { reload: true, inherit: false });
    } else {
      this.setData()(params);
    }
  };

};