const workingRelationshipPopoverTemplate = require("./working-relationship-popover.template.html");
const workingRelationshipDepartmentHierarchyPopoverTemplate = require("./working-relationship-department-hierarchy-popover.template.html");

import { CancelationPeriod } from "../../../cancelation_periods/services/cancelation_periods.service";
import { Department } from "../../../departments/services/departments.service";
import { JobCategory } from "../../../job_categories/services/job_categories.service";

export default class WorkingRelationshipFormCtrl {
  private person: any;
  private markets: any;
  private workingRelationshipPopover: string;
  private workingRelationshipDepartmentAndHierarchyPopover: string;
  private cancelationPeriods: any;

  static $inject = [
    "$templateCache",
    "$timeout",
    "WorkingRelationshipsFactory",
    "Autocompletes",
  ];

  constructor(private $templateCache: ng.ITemplateCacheService, private $timeout: ng.ITimeoutService, private WorkingRelationShip: any, private Autocompletes: any) {}

  public $onInit () {
    this.workingRelationshipPopover = "working-relationship-popover";
    this.workingRelationshipDepartmentAndHierarchyPopover = "working-relationship-department-hierarchy-popover";
    this.$templateCache.put(this.workingRelationshipPopover, workingRelationshipPopoverTemplate);
    this.$templateCache.put(
      this.workingRelationshipDepartmentAndHierarchyPopover,
      workingRelationshipDepartmentHierarchyPopoverTemplate
    );

    CancelationPeriod.getAll().then((cancelationPeriods) => (this.cancelationPeriods = cancelationPeriods));


    if (this.person.workingRelationships && this.person.workingRelationships.length === 0) {
      this.addWorkingRelationship();
      this.person.snapshot();
    }
    JobCategory.getAll().then((jobCategories: any)  => this.markets = jobCategories);

    this.person.workingRelationships.forEach(
      (workingRelationship) => { 
        this.updateSelectableDepartments(workingRelationship);
        this.updateSelectableHierarchies(workingRelationship); 
      }
    );
  };

  public addWorkingRelationship() {
    this.person.workingRelationships.push(new this.WorkingRelationShip());
  };

  public deleteWorkingRelationship(index) {
    this.person.workingRelationships.splice(index, 1);
  };

  public updateSelectableDepartments(workingRelationship: any) {
    if (!workingRelationship.market) {
      Department.getAll().then(function (departments) {
        workingRelationship.$departments = departments;
      });
      return;
    }

    const filterParams = { job_category_ids: { job_category_ids: [workingRelationship.market.id] } };
    return this.Autocompletes.autocomplete(
      "",
      "departments",
      undefined,
      _.keys(filterParams),
      _.values(filterParams),
      { size: 50 }
    ).then(function (res) {
      workingRelationship.$departments = _.chain(res)
        .map(function (entry) {
          return entry.tag ? new Department(entry.tag) : null;
        })
        .compact()
        .uniq()
        .value();
    });
  };

  public updateSelectableHierarchies(workingRelationship) {
    if (!workingRelationship.department) return;

    workingRelationship.department.queryHierarchies().then((hierarchies) => {
      this.$timeout(() => workingRelationship.department.hierarchies = hierarchies)
    });
  };
}
