export default class CompanyOrgChartModalCtrl {
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  private resolve: { company: any }
  static $inject = ["$rootScope", "$compile"];

  constructor (private $rootScope: IExtendedRootScopeService, private $compile: ng.ICompileService) {
    this.nodeTemplate = this.nodeTemplate.bind(this);
  }

  private unflatten(array: any[], parent?: any, tree?: any) {
    tree = typeof tree !== "undefined" ? tree : [];
    parent = typeof parent !== "undefined" ? parent : { id: null };

    const children = _.filter(array, (child) => {
      return child.managerId == parent.id;
    });

    if (!_.isEmpty(children)) {
      if (!parent.id) {
        tree = children;
      } else {
        parent.children = children;
      }
      _.each(children, (child) => {
        this.unflatten(array, child, undefined);
      });
    }

    return tree;
  }

  public nodeTemplate(data) {
    const newScope = this.$rootScope.$new() as IExtendedRootScopeService;
    newScope.data = data;

    const el = angular.element("<company-org-chart-node data='data'></company-org-chart-node>");
    return this.$compile(el)(newScope);
  }

  public getEmployementHierarchy() {
    var withCurrentWorkingRelationship = _.chain(this.resolve.company.employees)
      .each( (employee: any) => {
        employee.$currentSupervisor = _.chain(employee.workingRelationships)
          .findWhere( (workingRelationship) => {
            return workingRelationship.employer && workingRelationship.employer.id == this.resolve.company.id;
          })
          .value().supervisor;
      })
      .value();

    return this.unflatten(
      _.map(withCurrentWorkingRelationship, (employee) => {
        return {
          id: employee.id,
          managerId: employee.$currentSupervisor && employee.$currentSupervisor.id,
          employee: employee,
        };
      })
    );
  }

  public initOrgChartContainer() {
    const $container = $("#companyOrgChart") as JQuery;

    const orgChartNodes = {
      id: 0,
      company: this.resolve.company,
      managerId: null,
      isCompany: true,
      children: _.map(this.getEmployementHierarchy(), (employee) => {
        employee.managerId = 0;
        return employee;
      }),
    };

    $container.orgchart({
      data: orgChartNodes,
      verticalLevel: 0,
      nodeTemplate: this.nodeTemplate,
      initCompleted: ($chart) => {
        $container.scrollLeft(($container[0].scrollWidth - $container.width()) / 2);
      },
    });
  }

  public $onInit () {
    this.initOrgChartContainer();
  };

  public cancel () {
    this.modalInstance.dismiss("cancel");
  };
}
