import CandidatesDocumentsViewCtrl from "./candidates-documents-view.controller";
import candidatesDocumentsViewTemplate from "./candidates-documents-view.template.html";

angular.module("candidates.views").component("candidatesDocumentsView", {
  bindings: {
    candidate: "<candidateResponse",
    $transition$: "<",
    scrollspyScope: "<",
  },
  template: candidatesDocumentsViewTemplate,
  controller: CandidatesDocumentsViewCtrl,
});
