import WhiteboardKpisController from "./whiteboard-kpis.controller";
import whiteboardKpisTemplate from "./whiteboard-kpis.template.html";
angular.module("project_reporting.components").component("whiteboardKpis", {
  bindings: {
    advancedQuery: "=",
    currentLonglistEntries: "=",
    whiteboardsLazyLoading: "=",
    setKpiKey: "&",
    userOrTeam: "=",
  },
  template: whiteboardKpisTemplate,
  controller: WhiteboardKpisController,
});
