const prsmTableHighlightsTemplate = require("./prsm-table-highlights.template.html");
//= require ../prsm-table.module.js

angular.module("components.prsmTable").component("prsmTableHighlights", {
  bindings: {
    resource: "=",
  },
  template: prsmTableHighlightsTemplate,
  controller: "PrsmTableHighlightsCtrl",
});
