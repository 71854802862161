export class ProjectsShowCtrl {
  public project: any;
  public projectStatuses: string[];

  private deregActiveRowConnector: () => void;
  private deregUpdatedJobOffer: () => void;

  private initializeActiveRowConnector: (project: any) => () => void;

  static $inject = ["$state", "$http", "$rootScope", "CandidatesFactory", "ProjectsFactory", "DeckControllerMixin"];
  constructor(
    private $state: angular.ui.IStateService,
    private $http: ng.IHttpService,
    private $rootScope: IExtendedRootScopeService,
    private CandidateService: any,
    private ProjectService: any,
    private DeckControllerMixin: any
  ) {
    this.deregUpdatedJobOffer = this.$rootScope.$on("updatedJobOffer", (event, jobOffer) => {
      var index = _.findIndex(this.project.jobOffers, _.matcher({ id: jobOffer.id }));
      if (index > -1) {
        this.project.jobOffers[index] = jobOffer;
      } else {
        this.project.jobOffers.push(jobOffer);
      }
    });
  }

  public $onInit() {
    angular.extend(this, this.DeckControllerMixin, {
      Project: this.ProjectService,
    });
    this.$rootScope.setPageTitle(this.project.title);

    if (this.$state.current.name.match(/^root.projects.(edit|show)$/g)) {
      if (this.project.candidatesProjectsCount > 0) {
        this.$http.get(this.project.$url("/first_longlist_entry")).then(
          (result: { data: any }) => {
            var candidate = new this.CandidateService(result.data.data);
            this.$state.go(
              "root.projects.show.candidate.show",
              angular.extend(this.project.params(), candidate.params())
            );
          },
          (result_error: any) => {}
        );
      } else {
        if (this.project.employees.length > 0) {
          this.$state.go(
            "root.projects.show.employee.show",
            angular.extend(this.project.params(), this.project.employees[0].params())
          );
        } else {
          this.$state.go("root.projects.show");
        }
      }
    }

    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.project);
  }

  public textkernelDiffProject() {
    this.project.getDocumentExportModal(this.project).then((doc: any) => {
      doc.textkernelDiff();
    });
  }

  public $onDestroy() {
    this.deregUpdatedJobOffer();
    this.deregActiveRowConnector();
  }
}
