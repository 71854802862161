const employeesListTemplate = require("./employees-list.template.html");
//= require ../components.module.js

angular.module("employees.components").component("employeesList", {
  bindings: {
    object: "=",
    companyChart: "&",
    closing: "&",
    initialViewState: "<",
  },
  template: employeesListTemplate,
  controller: "EmployeesListCtrl",
});
