import { ITemplateCacheService } from "angular";
import { KPISteps, kpiIST, kpiSOLL } from "../../../../types/kpis"
import dashboardsOpenPopoverTemplate from "../popover/open-popover.template.html";

export default class DashboardAccounts2021CardCtrl {
  public KPISteps: Partial<KPISteps>;
  public kpiIST: Partial<kpiIST>;
  public kpiSOLL: Partial<kpiSOLL>;
  public loading: boolean;
  public enableKpiKeySelect: boolean; 
  public selectedKpiKey: any;
  public steps: any[];
  public data: {
    accounts: Array<{name: string, count: number}>,
    fullTimeEquivalent: number,
    kpis: any,
    meta: any
  };
  public params: any;
  public labels: string[]; 
  public options: any;
  public readonly projectReportingRequestObj: any;

  static $inject = ["ProjectReportingRequestObj", "ManagerKPISteps", "$templateCache"];

  constructor (
    private ProjectReportingRequestObj: any,
    private ManagerKPISteps: any,
    private $templateCache: ITemplateCacheService,
  ) {
    this.$templateCache.put("open-popover-template", dashboardsOpenPopoverTemplate);

    this.projectReportingRequestObj = new this.ProjectReportingRequestObj(this, "dashboardAccounts2021Card", (
      result
    ) => {
      this.kpiIST = {
        kpis: result.kpis.kpiIst,
        params: this.params,
      };
      this.kpiSOLL.kpis = result.kpis.kpiSoll;
  
      this.data = result;
  
      this.setSteps();
  
      var accounts = _.sortBy(this.data.accounts, _.iteratee("count"));
      this.data.kpis.accounts = _.pluck(accounts, "count");
  
      this.labels = _.pluck(accounts, "name");
      this.options = {
        legend: {
          display: false,
        },
      };
    });
  }
  
  public $onInit() {
    this.KPISteps = this.ManagerKPISteps;
    this.kpiIST = {};
    this.kpiSOLL = {};

    this.loading = true;
  };

  public setSteps() {
    this.steps = _.chain(this.KPISteps)
      .mapObject((kpiStep, key) => {
        return _.extend(kpiStep, {
          key: key,
          isOpen: false,
          model: this.kpiIST,
          ist: this.kpiIST.kpis[key],
          soll: this.kpiSOLL.kpis[key],
          sollPerYear: this.data.kpis.kpiSollPerYear[key],
        });
      })
      .values()
      .value();
  };

  public setKpiKey(stepkey) {
    if (this.enableKpiKeySelect) {
      this.selectedKpiKey = stepkey;
    };
  };

  public $onDestroy() {
    this.projectReportingRequestObj.deregisterEventListeners();
  };
};

