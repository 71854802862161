export class EditResearcherModalCtrl {
  // candidatesProjects could be also a ecp in case we try to edit the researcher on an initiative application project

  private modalInstance: angular.ui.bootstrap.IModalInstanceService;
  public project: any;
  public candidatesProject: any;
  public researcherFromTeam: any;
  public filterUsers: () => boolean;
  private resolve: any;


  static $inject = ["UsersFactory"];
  
  constructor(private User: any) {}

  private extend(resolve): void {
    this.project = resolve.project;
    this.candidatesProject = resolve.candidatesProject;
  }

  public $onInit(): void {
    this.extend(this.resolve);

    this.filterUsers = this._filterUsers.bind(this);
    this.researcherFromTeam = _.filter(this.User.getAll(), {
      isSelectable: true,
      team: this.project.projectManager.team,
      type: "ResearchConsultantUser",
    });
  };

  private _filterUsers(user: any): boolean {
    return _.any(
      _.compact(_.union(this.project.researchConsultants, [this.project.projectManager], this.researcherFromTeam)),
      function (u2) {
        return u2.id == user.id;
      }
    );
  };

  public cancel(): void {
    this.modalInstance.dismiss("cancel");
  };

  public save(): void {
    this.modalInstance.close(this.candidatesProject);
  };
};
