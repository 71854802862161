import { Priority } from "../../../priorities/services/priority.service";
import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";

export class ProjectsTableBodyControllerCtrl {
  public state: (...params) => string;
  public selectedProjects: any[];
  public priorities: Priority[];

  static $inject = ["$rootScope", "$filter", "ProjectsFactory"];
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $filter: ng.IFilterService,
    private ProjectService: any
  ) {
    this.state = StateTransitionsConfig.state;
  }

  public $onInit() {
    Priority.getAll().then((priorities: Priority[]) => (this.priorities = priorities));
  }

  public remove(project) {
    var tmpPro = _.findIndex(this.selectedProjects, (p) => {
      return project.id == p.id;
    });
    if (tmpPro != -1) {
      this.selectedProjects.splice(tmpPro, 1);
    }
  }

  public projectStatusUpdate(project) {
    if (window.confirm(this.$filter("translate")("CHANGE_PROJECT_STATUS_CONFIRMATION"))) {
      new this.ProjectService({ id: project.id, projectStatus: project.projectStatus, saveBasics: true })
        .save()
        .then((result) => {
          project.snapshot();
          this.$rootScope.$broadcast("project_action_changed_" + project.id);
        });
    } else {
      project.rollback(-1);
    }
  }

  public projectPriorityUpdate(project) {
    if (window.confirm(this.$filter("translate")("CHANGE_PROJECT_PRIORITY_CONFIRMATION"))) {
      new this.ProjectService({ id: project.id, priority: project.priority.id, saveBasics: true })
        .save()
        .then((result) => {
          project.snapshot();
          this.$rootScope.$broadcast("project_action_changed_" + project.id);
        });
    } else {
      project.rollback(-1);
    }
  }
}
