//= require ../project_reportings.module.js

angular.module("project_reporting").service("ProjectReportingsFactory", [
  "RailsResource",
  "railsSerializer",
  "ProjectReportingsModalMixin",
  "RailsResourceSnapshotsMixin",
  function (RailsResource, railsSerializer, ProjectReportingsModalMixin, RailsResourceSnapshotsMixin) {
    function ProjectReporting() {
      (<any>ProjectReporting).__super__.constructor.apply(this, arguments);
    }

    ProjectReporting.hoursPerWeek = 41.5;
    ProjectReporting.ProjectDayAttrs = ["research", "qualificationCandidate", "qualificationManager", "administration"];
    ProjectReporting.DayAttrs = [
      "meetingIntern",
      "administration",
      "meetingManager",
      "meetingCandidate",
      "training",
      "vacation",
      "businessDevelopment",
    ];

    ProjectReporting.sumDay = function (day, dayAttrs) {
      return _.reduce(
        dayAttrs,
        function (memo, attr) {
          return memo + (day[attr] || 0);
        },
        0
      );
    };

    ProjectReporting.sumWeek = function (inputDays, attr) {
      if (_.isString(attr)) {
        return _.reduce(
          inputDays,
          function (memo, day) {
            return memo + (day[attr] || 0);
          },
          0
        );
      } else {
        return _.reduce(
          inputDays,
          function (memo, day) {
            return memo + ProjectReporting.sumDay(day, attr);
          },
          0
        );
      }
    };

    ProjectReporting.sumProjectsWorkingDay = function (projects, dayIndex) {
      return _.reduceRight(
        projects,
        function (memo, project) {
          return memo + ProjectReporting.sumDay(project.days[dayIndex], ProjectReporting.ProjectDayAttrs);
        },
        0
      );
    };

    ProjectReporting.whiteboardAttributes = {
      sales: {
        title: "SALES",
        show: false,
        class: "responsible",
        sortField: "consultant.full_name.not_analyzed",
      },
      researcher: {
        title: "RESEARCHER",
        show: true,
        class: "responsible",
        sortField: "researcher.full_name.not_analyzed",
      },
      candidate: {
        title: "CANDIDATE",
        show: true,
        class: "title",
        sortField: "candidate.full_name_reverse_with_title.not_analyzed",
      },
      company: {
        title: "COMPANY",
        show: true,
        class: "title",
        sortField: "company.name",
      },
      employees: {
        title: "CONTACT_PERSON",
        show: true,
        class: "title",
        //sortField: 'project.employees.full_name_reverse_with_title.not_analyzed'
      },
      job_type: {
        title: "PROJECT_TYPE",
        show: true,
        class: "tag",
        sortField: "project.job_type.id",
      },
      processStatus: {
        title: "PROCESS",
        class: "select",
        show: true,
        sortField: "status",
      },
      project: {
        title: "PROJECT",
        show: true,
        class: "title",
        sortField: "project.title.not_analyzed",
      },
      created_at: {
        title: "CREATED_AT",
        show: false,
        class: "date",
        sortField: "project.created_at",
      },
      longlist: {
        title: "LONGLIST",
        show: true,
        class: "date",
        sortField: "longlist",
      },
      qualification: {
        title: "QUALIFICATION",
        show: true,
        class: "date",
        sortField: "qualification",
      },
      shortlist: {
        title: "SHORTLIST",
        show: true,
        class: "date",
        sortField: "shortlist",
      },
      sendout: {
        title: "SENDOUT",
        show: true,
        class: "date",
        sortField: "sendout",
      },
      firstInterview: {
        title: "FIRST_INTERVIEWS",
        show: true,
        class: "date",
        sortField: "first_interview",
      },
      secondInterview: {
        title: "SECOND_INTERVIEWS",
        show: true,
        class: "date",
        sortField: "second_interview",
      },
      closing: {
        title: "Closing",
        show: true,
        class: "date",
        sortField: "closing",
      },
      deal: {
        title: "WON_LOST",
        show: false,
        class: "date",
        sortField: "deal", //should sort by 2 fields ?
      },
    };

    RailsResource.extendTo(ProjectReporting);
    (<any>ProjectReporting).configure({
      url: "/project_reporting",
      name: "project_reporting",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.serializeWith("shortlist", "KPISerializer");
        this.serializeWith("longlist", "KPISerializer");
        this.serializeWith("sendout", "KPISerializer");
        this.serializeWith("firstInterview", "KPISerializer");
        this.serializeWith("secondInterview", "KPISerializer");
        this.serializeWith("closing", "KPISerializer");
        this.serializeWith("deal", "KPISerializer");
        this.serializeWith("out", "KPISerializer");
        this.serializeWith("amountCoveredIstPerYear", "MoneySerializer");
        this.serializeWith("amountCoveredSollPerYear", "MoneySerializer");
        this.serializeWith("longlistEntry", "LonglistEntrySerializer");
      }),
    });

    (<any>ProjectReporting).extend(ProjectReportingsModalMixin);
    (<any>ProjectReporting).extend(RailsResourceSnapshotsMixin);

    return ProjectReporting;
  },
]);
