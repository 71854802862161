//= require ../deals.module.js

angular.module("deals").constant("periodOfPaymentOptions", [
  { name: "PAYMENT_PERIOD_0_DAYS", value: 0 },
  { name: "PAYMENT_PERIOD_14_DAYS", value: 14 },
  { name: "PAYMENT_PERIOD_30_DAYS", value: 30 },
  { name: "PAYMENT_PERIOD_45_DAYS", value: 45 },
  { name: "PAYMENT_PERIOD_60_DAYS", value: 60 },
  { name: "PAYMENT_PERIOD_90_DAYS", value: 90 },
]);
