import { ChooseQuicklistModalCtrl } from "./choose-quicklist-modal.controller";
import chooseQuicklistModalTemplate from "./choose-quicklist-modal.template.html";

angular.module("quicklists.modals").component("chooseQuicklistModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: chooseQuicklistModalTemplate,
  controller: ChooseQuicklistModalCtrl,
});
