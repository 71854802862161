import { PrioritySelectCtrl } from "./priority-select.controller";
import prioritySelectTemplate from "./priority-select.template.html";

angular.module("priorities.components").component("prioritySelect", {
  bindings: {
    priority: "=",
  },
  controller: PrioritySelectCtrl,
  template: prioritySelectTemplate,
});
