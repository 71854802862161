export default class LastUpdateSelectorCtrl {
  public lastUpdateUniqID: string;
  public advancedQuery: any;
  
  constructor() {
    this.lastUpdateUniqID = _.uniqueId("lastUpdateEnabled");
  }

  public $onInit() {
    _.defaults(this.advancedQuery, { lastUpdateEnabled: false });
  };
};
