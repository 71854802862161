import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TGraduation } from "../graduations";

export class Graduation {
  private id: number;
  private name: string;
  static $inject = [];

  constructor(fields?: Partial<TGraduation>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TGraduation>>(fields, camelCase));
  }

  @cached
  public static getAll() {
    return baseClient.graduations.query().then((response) => {
      return response.body.data.map((element) => {
        return new Graduation(element);
      });
    });
  }
}
