export class QuicklistsEditCtrl {
  static $inject = ["$rootScope", "$state", "Session", "EditUtilMixin", "DeckControllerMixin", "QuicklistsFactory"];
  constructor(
    private $rootScope: IExtendedRootScopeService,
    private $state: angular.ui.IStateService,
    private Session: any,
    private EditUtilMixin: any,
    private DeckControllerMixin: any,
    private QuicklistService: any
  ) {}

  private deregOnStateChanged: () => void;
  private deregActiveRowConnector: () => void;

  private registerSaveHotkey: (saveQuicklist: () => void) => void;
  private onStateChanged: (quicklistEdit: any) => () => void;
  private initializeActiveRowConnector: (quicklistEdit: any) => () => void;
  private onSaveError: () => void;

  public quicklist: any;
  public users: any[];
  public quicklistEdit: any;

  public $onInit() {
    this.QuicklistService.updateCache(this.quicklist);
    angular.extend(this, this.EditUtilMixin, this.DeckControllerMixin, {
      quicklistEdit: new this.QuicklistService(angular.copy(this.quicklist)),
      users: _.reject(this.users, (user: any) => {
        return user.id == this.Session.user.id;
      }),
    });
    this.$rootScope.setPageTitle(this.quicklistEdit.name);

    if (!this.quicklistEdit.isListOwner()) {
      this.$state.go("^.show", this.quicklistEdit.params());
    }

    this.registerSaveHotkey(this.saveQuicklist.bind(this));
    this.deregOnStateChanged = this.onStateChanged(this.quicklistEdit);
    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.quicklistEdit);
  }

  public saveQuicklist(route: any, params: any) {
    this.quicklistEdit.editMode = true;
    this.quicklistEdit.save().then((quicklist: any) => {
      this.quicklist.$outOfSync = true;
      this.quicklistEdit.snapshot();

      _.extend(this.quicklist, this.quicklistEdit);
      this.QuicklistService.updateCache(this.quicklist);

      this.$state.go(route || "^.show", params || quicklist.params());
      this.$rootScope.$broadcast("updatedQuicklist");
    }, this.onSaveError.bind(this));
  }

  public $onDestroy() {
    this.deregOnStateChanged();
    this.deregActiveRowConnector();
  }
}
