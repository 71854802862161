import processKpisCtrl from "./process-kpis.controller";
import processKpisTemplate from "./process-kpis.template.html";

angular.module("components.kpis").component("processKpis", {
  bindings: {
    model: "<ngModel",
    selectedKpiKey: "<",
    enableKpiKeySelect: "<",
    disableKpiPopover: "<",
    small: "<",
  },
  template: processKpisTemplate,
  controller: processKpisCtrl,
});
