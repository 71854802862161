import { IStateService } from "angular-ui-router";

export default class ProjectReportingsCtrl {
  private options: { selectedYear: number; selectedUser: any };
  private allowProjectReporting: boolean;
  private kpis: any;
  private kpisPerYear: { kpis: any; userId: number; year: number; amountCoveredSollPerYear: number; amountCoveredIstPerYear: number }; 
  private user: any;
  private isLegacyCalculation: boolean;
  private saving: boolean = false;

  static $inject = ["$state", "Session", "UsersFactory", "ProjectReportingsFactory"];

  constructor (private $state: IStateService, private Session: any, private User: any, private ProjectReporting: any) {
    this.allowProjectReporting = Session.user.allowProjectReporting;
  }

  public redirectToProjectReporting () {
    this.$state.go(
      "root.project_reportings",
      { user_id: this.options.selectedUser.id, year: this.options.selectedYear },
      { reload: true, inherit: false }
    );
  };

  public $onInit () {
    this.kpis = new this.ProjectReporting(this.kpisPerYear.kpis);
    this.user = this.User.findBy(this.kpisPerYear.userId);
    this.kpis.userId = this.kpisPerYear.userId;
    this.kpis.year = this.kpisPerYear.year;
    this.kpis.amountCoveredSollPerYear = this.kpisPerYear.amountCoveredSollPerYear;
    this.kpis.amountCoveredIstPerYear = this.kpisPerYear.amountCoveredIstPerYear;
    this.options = {
      selectedYear: this.kpisPerYear.year,
      selectedUser: this.user,
    };
    this.isLegacyCalculation = this.options.selectedYear < 2020;
  };

  public saveProjectReporting () {
    if (!this.saving) {
      this.saving = true;
      this.kpis.$post(this.kpis.$url("kpis_per_year.json")).then( () => {
        this.saving = false;
        this.redirectToProjectReporting();
      });
    }
  };
}