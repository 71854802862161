//= require ../projects.module.js

angular.module("projects").service("ProjectsFactory", [
  "RailsResource",
  "RailsResourceSnapshotsMixin",
  "ProjectsModalMixin",
  "TextkernelModalMixin",
  "railsSerializer",
  "CompaniesFactory",
  "CandidatesProjectsFactory",
  "UsersFactory",
  "ActionsFactory",
  "$sce",
  "$rootScope",
  "$state",
  "Highlighter",
  "$http",
  "ModalMixin",
  "moment",
  "$translate",
  "$filter",
  function (
    RailsResource,
    RailsResourceSnapshotsMixin,
    ProjectsModalMixin,
    TextkernelModalMixin,
    railsSerializer,
    Company,
    CandidatesProjects,
    User,
    Action,
    $sce,
    $rootScope,
    $state,
    Highlighter,
    $http,
    ModalMixin,
    moment,
    $translate,
    $filter
  ) {
    function Project() {
      (<any>Project).__super__.constructor.apply(this, arguments);
      this.resourceType = "Project";
      this.name = this.title;
      this.candidatesProjects = this.candidatesProjects || [];
      this.employees = this.employees || [];

      this.params = function () {
        return { project_id: this.id };
      };

      this.extendByDiff = function (projectDiffData) {
        var mappedTags = _.map(projectDiffData.taggings, function (tagging) {
          return tagging.tag;
        });
        angular.extend(this, _.omit(projectDiffData, "changes"), {
          tags: _.reject(mappedTags, _.matcher({ type: "ComputerSkill" })),
          computerSkills: _.where(mappedTags, { type: "ComputerSkill" }),
        });

        this.$attributeChanges = [];
        _.chain(this._prepSnapshot())
          .keys()
          .omit("$attribute_changes")
          .each(
            function (attrKey) {
              this.attributeChanged(attrKey);
            }.bind(this)
          );
      };

      this.attributeChanged = function (attribute) {
        this.$attributeChanges = this.$attributeChanges || {};

        if (_.isUndefined(this.$attributeChanges[attribute])) {
          if (_.isEmpty(this.$snapshots)) {
            this.$attributeChanges[attribute] = false;
          } else {
            this.$attributeChanges[attribute] = !angular.equals(
              _.last(this.$snapshots)[attribute],
              this._prepSnapshot()[attribute]
            );
          }
        }
        return this.$attributeChanges[attribute];
      };

      this.mapSearchScopesToHumanReadable = Project.mapSearchScopesToHumanReadable;

      this.addCandidate = function (candidate) {
        // create candidates_projects join model
        var cp = new CandidatesProjects({ project: this, candidate: candidate });
        cp.projectId = this.id;
        cp.candidateId = candidate.id;
        return cp
          .save()
          .then(
            function (created_cp) {
              candidate.candidatesProjects.push(created_cp);
              this.candidatesProjects.push(created_cp);
              $rootScope.$broadcast("candidate_add_to_project");
              $rootScope.$broadcast("candidate_action_changed_" + candidate.id);
              return created_cp;
            }.bind(this)
          )
          .catch(function (result) {
            if (result.status == 409) {
              // conflict if already completed
              return cp;
            } else {
              throw result;
            }
          });
        // TODO create action with corresponding action type to update the xs process view on the candidates list
      };

      this.removeCandidate = function (candidates_projects) {
        candidates_projects
          .delete()
          .then(function (ret) {
            candidates_projects.deleted = true;
            candidates_projects.actions = ret.actions;
            $rootScope.$broadcast("candidate_remove_from_project");
            // $rootScope.$broadcast("candidate_remove_from_project");
          })
          .catch(function (ret) {
            if (ret.status == 409) {
              window.alert($filter("translate")("REMOVE_FROM_LONGLIST_FAIL"));
              candidates_projects.status = _.findWhere(CandidatesProjects.statuses, { id: 4 });
              candidates_projects.projectStatusUpdate();
            }
          });
      };

      this.createSendout = function (employee) {
        this.openChooseCandidateModal().then(function (cpjoin) {
          $state.go("root.candidates.show.expert_profile.new", cpjoin.params());
        });
      };

      this.updateSales = function (sales) {
        this.projectManager = sales;
        this.save().then(
          function (resProject) {
            _.extend(this, resProject);
          }.bind(this)
        );
      };

      this.addInterviewDate = function (employee) {
        this.openChooseCandidateModal().then(function (cpjoin) {
          cpjoin.addInterviewDate(employee);
        });
      };

      this.sendContractOfferToEmployee = function (employee) {
        this.openChooseCandidateModal().then(function (cpjoin) {
          employee.sendContractOfferModal(cpjoin);
        });
      };

      this.getCpjoin = function (candidate) {
        return _.find(this.candidatesProjects, function (cpjoin) {
          return cpjoin.candidate.id == candidate.id;
        });
      };

      this.openEventConfirmationModal = function (action) {
        action.candidate.openEventConfirmationModal();
      };

      this.saveCustomNote = function (action) {
        Action.saveCustomNote(this, action);
      }.bind(this);

      this.getShareLink = function (project_id) {
        return $http.get(this.$url("url_with_authtoken"), { params: { project_id: project_id } });
      };

      this.createMessage = function (actionRoute, emailParams) {
        var params = angular.merge({ id: this.id }, emailParams);
        return $http.post(this.$url(actionRoute + ".json"), { project: params });
      };

      if (_.isString(this.description)) {
        this.description = $sce.trustAsHtml(
          this.description
            .replace(/"/g, "'")
            .replace(/(background: url\('.*'\).*;)/g, "")
            .replace(/font-family: ('.*',)/g, "")
        );
      }

      this.$class = Project;

      this.companyWatcher = function (nv, ov) {
        if (_.isEmpty(this.transactionBasis)) {
          this.transactionBasis = _.chain(nv.transactionBasis)
            .sortBy(function (transactionBasis) {
              return transactionBasis.createdAt;
            })
            .last()
            .omit("createdAt")
            .value();
          this.transactionBasis = _.isEmpty(this.transactionBasis) ? undefined : this.transactionBasis;
        }

        if (ov && nv.id != ov.id) {
          this.employees = [];
        }
      };

      this.requirements = function () {
        if (this.initiativeApplicationProject) {
          //requirements =
          return {
            yellow: ["REQ_YELLOW_NAME", "REQ_YELLOW_ACTIVE", "REQ_YELLOW_MANAGER"],
            green: ["REQ_GREEN_SENDOUT"],
          };
        } else {
          //requirements =
          return {
            yellow: ["REQ_YELLOW_NAME", "REQ_YELLOW_ACTIVE", "REQ_YELLOW_DESCRIPTION", "REQ_YELLOW_MANAGER"],
            green: ["REQ_GREEN_PRIORITY"],
          };
        }
      };

      this.togglePinned = function (event) {
        this.pinned = !this.pinned;
        (<any>Project).$put(this.$url("update_pinned.json"), this).then(function (result) {
          _.extend(this, result);
        });
      };

      this.sendDocument = function (actionRoute, emailParams) {
        (<any>Project).$post(this.$url(actionRoute + ".json"), emailParams).then(
          function (resProject) {
            $rootScope.$broadcast("project_action_changed_" + this.id);
          }.bind(this)
        );
      }.bind(this);

      this.getExpireDate = function () {
        return moment(this.updatedAt).add(4, "weeks").fromNow();
      };

      this.getCriticalTimespanCrossed = function () {
        var a = moment();
        var b = moment(this.updatedAt).add(4, "weeks");
        if (b.diff(a, "days") < 7) {
          return true;
        } else {
          return false;
        }
      };

      this.updateExpireDate = function () {
        this.save().then(
          function (resProject) {
            _.extend(this, resProject);
          }.bind(this)
        );
      };

      this.filterDepartments = function () {
        return { job_category_ids: { job_category_ids: _.pluck(this.jobCategories, "id") } };
      }.bind(this);
    }

    Project.attributeNames = {
      title: "ATTR_TITLE",
      taggings: "ATTR_TAGS",
      languages: "ATTR_LANGUAGES",
      companyId: "ATTR_COMPANY_DATA",
      tasksDescription: "ATTR_TASKS",
      requirementsDescription: "ATTR_REQUIREMENTS",
    };

    Project.mapAttribute = function (attr) {
      return $filter("translate")(Project.attributeNames[attr]);
    };

    Project.statuses = ["active", "inactive", "completed"];

    Project.mapSearchScopesToHumanReadable = function (scopeDescriptor) {
      switch (scopeDescriptor) {
        case "title.standard":
          return "MAP_READ_TITLE";
        case "description.de":
          return "MAP_READ_PROJECT_DESCRIPTION";
        case "tags.name":
          return "MAP_READ_TAG";
        case "jobCategories.name":
          return "MAP_READ_JOB_CATEGORY";
        case "allActions.description.de":
          return "MAP_READ_HISTORY";
        case "responsibleUser.fullName.notAnalyzed":
        case "responsibleUser.symbole":
          return "MAP_READ_RESPONSIBLE_USER";
        case "researchConsultants.fullName.notAnalyzed":
        case "researchConsultants.symbole":
          return "MAP_READ_RESEARCHERS";
        case "projectStatus":
          return "MAP_READ_STATUS";
        case "company.name":
        case "company.name.standard":
        case "company.name.de":
          return "MAP_READ_COMPANY";
        case "urgency":
          return "MAP_READ_URGENCY";
        case "jobOffers.name":
        case "jobOffers.name.de":
        case "jobOffers.name.standard":
          return "MAP_READ_JOB_OFFER";
        case "priority":
          return "MAP_READ_PRIORITY";
        default:
          return undefined;
      }
    };

    Project.defaultTableAttributes = {
      id: {
        title: "TABLE_ID",
        show: true,
        pinned: true,
        menu: true,
        class: "id",
        sortField: "id",
      },
      title: {
        title: "TABLE_PROJECT_TITLE",
        show: true,
        fixedWidth: true,
        class: "title",
        sortField: "title.not_analyzed",
      },
      projectManager: {
        title: "TABLE_RESPONSIBLE_USER",
        show: true,
        class: "responsible",
        sortField: "responsible_user.full_name.not_analyzed",
      },
      jobType: {
        title: "TABLE_PROJECT_TYPE",
        show: true,
        class: "tag",
        sortField: "job_type.id",
      },
      kpis: {
        title: "TABLE_KPIS",
        show: true,
        class: "kpis",
      },
      tags: {
        title: "TABLE_TAGS",
        show: true,
        class: "tags",
      },
      editableStatus: {
        title: "TABLE_STATUS",
        show: true,
        class: "select",
        sortField: "project_status",
      },
      priority: {
        title: "TABLE_PRIORITY",
        show: false,
        class: "priority",
        sortField: "priority",
      },
      createdAt: {
        title: "TABLE_CREATED_AT",
        show: false,
        class: "date",
        sortField: "created_at",
      },
      startDate: {
        title: "TABLE_START_DATE",
        show: false,
        class: "date",
        sortField: "start_date",
      },
      expiresAt: {
        title: "TABLE_EXPIRES_IN",
        show: false,
        class: "date",
        sortField: "updated_at",
      },
    };

    RailsResource.extendTo(Project);
    (<any>Project).configure({
      url: "/projects",
      name: "project",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.nestedAttribute("allLanguages");
        this.serializeWith("allLanguages", "LanguagesSerializer");
        this.resource("projectManager", "UsersFactory");
        this.resource("researchConsultants", "UsersFactory");
        this.serializeWith("transactionBasis", "CompaniesTransactionBasesSerializer");
        this.serializeWith("companiesTransactionBasis", "CompaniesTransactionBasesSerializer");
        this.resource("company", "CompaniesFactory");
        this.resource("candidatesProjects", "CandidatesProjectsFactory");
        this.serializeWith("expiresAt", "DateSerializer");
        this.serializeWith("createdAt", "DateSerializer");
        this.serializeWith("startDate", "DateSerializer");
        this.serializeWith("conclusionOfContractAt", "DateSerializer");
        this.resource("actions", "ActionsFactory");
        this.resource("employees", "EmployeesFactory");
        this.resource("invoiceRecipient", "EmployeesFactory");
        this.resource("jobOffers", "JobOfferFactory");
        this.serializeWith("jobType", "JobTypesSerializer");
        this.serializeWith("tags", "TagSerializer");
        this.serializeWith("description", "SanitizeSerializer");

        this.rename("documents", "projectDocuments");
        this.resource("documents", "DocumentsFactory");

        this.resource("candidate", "CandidatesFactory");
        this.resource("invoices", "InvoicesFactory");

        this.serializeWith("priority", "PrioritySerializer");
        this.serializeWith("salaryFixMax", "MoneySerializer");
        this.serializeWith("salaryFixMin", "MoneySerializer");
        this.serializeWith("salaryVariableMax", "MoneySerializer");
        this.serializeWith("salaryVariableMin", "MoneySerializer");
        this.serializeWith("invoicesDiff", "MoneySerializer");
        this.serializeWith("country", "CountrySerializer");

        this.exclude("$snapshots");
      }),
    });
    (<any>Project).extend(RailsResourceSnapshotsMixin);
    (<any>Project).extend(ProjectsModalMixin);
    (<any>Project).extend(ModalMixin);
    (<any>Project).extend(TextkernelModalMixin);
    (<any>Project).extend(Highlighter);

    return Project;
  },
]);
