import AmountCoveredBarChartCtrl from "./amount-covered-bar-chart.controller";
import amountCoveredBarChartTemplate from "./amount-covered-bar-chart.template.html";
//= require ../components.module.js

angular.module("dashboards.components").component("amountCoveredBarChart", {
  bindings: {
    weeks: "<projectReportingWeeks",
  },
  template: amountCoveredBarChartTemplate,
  controller: AmountCoveredBarChartCtrl,
});
