import { PitchModalCtrl } from "./pitch-modal.controller";
import pitchModalTemplate from "./pitch-modal.template.html";

angular.module("projects.modals").component("pitchModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: pitchModalTemplate,
  controller: PitchModalCtrl,
});
