import { TextkernelOpenSearchModalCtrl } from "./textkernel-open-search.controller";
import textkernelOpenSearchTemplate from "./textkernel-open-search.template.html";

angular.module("textkernel.modals").component("textkernelOpenSearch", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: textkernelOpenSearchTemplate,
  controller: TextkernelOpenSearchModalCtrl,
});
