import SendoutModalCtrl from "./sendout-modal.controller";
import sendoutModalTemplate from "./sendout-modal.template.html";

angular.module("expert_profiles.modals").component("sendoutModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: sendoutModalTemplate,
  controller: SendoutModalCtrl,
});
