export default class DocumentsFullscreenModeModalCtrl {

  public static readonly EXTENSION_WHITELIST: string[] = ["pdf", "jpg", "png", "jpeg"];
  public resolve: { documents: Document[], openDocument: Document };
  private modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = ["$filter", "poller", "DocumentsFactory"];

  constructor (private $filter: ng.IFilterService, private poller: any, private Document: any) {}

  public $onInit () {
  };

  public cancel () {
    this.modalInstance.close();
  };
}
