//= require ../saved_searches.module.js

angular.module("saved_searches").factory("AdvancedSearchConditionSerializer", [
  "railsSerializer",
  function (railsSerializer) {
    return railsSerializer(function () {
      this.deserialize = function (data) {
        return this.deserializeData(data);
      };
      this.serialize = function (data) {
        return _.chain(data)
          .map(function (c) {
            return _.omit(c, "suggestions");
          })
          .value();
      };
    });
  },
]);
