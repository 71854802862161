import ContactDetailsCtrl from "./contact-details.controller";
import contactDetailsTemplate from "./contact-details.template.html";

angular.module("directives").component("contactDetails", {
  bindings: {
    object: "<",
    callback: "<",
  },
  template: contactDetailsTemplate,
  controller: ContactDetailsCtrl,
});
