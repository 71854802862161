import { SendoutsEpTableBodyCtrl } from "./sendouts-ep-table-body.controller";
import sendoutsEpTableBodyTemplate from "./sendouts-ep-table-body.template.html";

angular.module("sendouts.components").component("sendoutsEpTableBody", {
  bindings: {
    cpjoin: "=data",
    attributes: "=",
    activeRow: "=",
  },
  template: sendoutsEpTableBodyTemplate,
  controller: SendoutsEpTableBodyCtrl,
});
