export default class WhiteboardAdvancedSearchCtrl {
  public projectStatuses: { [key: string]: any };

  static $inject = ["CandidatesProjectsFactory"];

  constructor (private CandidatesProjects: any) {
    this.projectStatuses = CandidatesProjects.statuses;
  }
  public ganttActive(entry: { [key: string]: { date?: Date } }, status: string): boolean {
    const statuses: string[] = [
      "longlist",
      "qualification",
      "shortlist",
      "sendout",
      "firstInterview",
      "secondInterview",
      "closing",
      "deal",
    ];

    return _.some(
      _.rest(statuses, _.indexOf(statuses, status)),
      (key: string) => !!entry[key]?.date
    );
  };
}