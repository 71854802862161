import SalaryRangeSelectorCtrl from "./salary-range-selector.controller";
import salaryRangeSelectorTemplate from "./salary-range-selector.template.html";

angular.module("directives.advanced_search").component("salaryRangeSelector", {
  bindings: {
    advancedQuery: "=",
  },
  template: salaryRangeSelectorTemplate,
  controller: SalaryRangeSelectorCtrl,
});
