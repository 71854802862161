import { Priority } from "./priority.service";

angular
  .module("priorities", ["rails", "priorities.components"])

  .factory("PrioritySerializer", [
    "railsSerializer",
    function (railsSerializer) {
      return railsSerializer(function () {
        this.deserialize = function (data) {
          return _.isString(data) ? new Priority({ name: data, id: _.random(100, 1000) }) : data;
        };
        this.serialize = function (data) {
          return data;
        };
      });
    },
  ])

  .service("PrioritiesFactory", Priority);
