//= require ./services.module.js
angular.module("services").service("SidebarService", [
  "$cookies",
  "SidebarFactory",
  function ($cookies, SidebarFactory) {
    var sidebar = new SidebarFactory("deckSub");
    let ids = {};

    this.getSidebarStates = function () {
      return ids;
    };

    this.getSidebarStatus = function (id) {
      ids[id] = $cookies.get(id) != "false";
      return ids[id];
    };

    this.updateSideBar = function (id, status) {
      ids[id] = status;
      $cookies.put(id, status);
      return ids[id];
    };

    this.closeSidebar = function () {
      sidebar.close();
    };
  },
]);
