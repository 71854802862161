import ChooseLocalesModalCtrl from "./choose-locales-modal.controller";
import chooseLocalesModalTemplate from "./choose-locales-modal.template.html";

angular.module("modals.modals").component("chooseLocalesModal", {
  template: chooseLocalesModalTemplate,
  controller: ChooseLocalesModalCtrl,
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
});
