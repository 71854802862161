import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { TTracking } from "../trackings";

export class Tracking {
  public id: number;
  public createdAt: string;
  userId?: number;
  static $inject = [];

  constructor(fields?: Partial<TTracking>) {
    if (fields) Object.assign(this, deepMapKeys<Partial<TTracking>>(fields, camelCase));
  }
}
