import DealNotificationsCtrl from "./deal-notifications.controller";
import dealNotificationsTemplate from "./deal-notifications.template.html";

angular.module("deals.components").component("dealNotifications", {
  bindings: {
    deal: "<",
  },
  controller: DealNotificationsCtrl,
  template: dealNotificationsTemplate,
});
