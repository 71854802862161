import { TLanguage } from "../../../languages/languages";
import { Language } from "../../../languages/services/languages.service";
import { TLeadershipExperience } from "../../../leadership_experiences/leadership_experiences";

export class ProjectsEditCtrl {
  private deregActiveRowConnector: () => void;
  private deregCompanyChanged: () => void;
  private deregProjectDiff: () => void;

  private onSaveError: () => void;

  private onStateChanged: (project: any) => void;
  private registerSaveHotkey: (callback: (...params) => void) => void;
  private initializeActiveRowConnector: (project: any) => () => void;

  public project: any;
  public employees: any[];
  public loading: boolean;
  private cancelers: ng.IDeferred<any>[] = [];
  public saving: boolean;
  public forceValidation: boolean;
  public projectForm: ng.IFormController;
  public sendoutLanguages: any[];
  public allLanguages: Language[];
  private languages: TLanguage[];
  private leadershipExperiences: TLeadershipExperience[];

  static $inject = [
    "$state",
    "$log",
    "$rootScope",
    "$filter",
    "$q",
    "EmployeesFactory",
    "CompaniesFactory",
    "Autocompletes",
    "EditUtilMixin",
    "DeckControllerMixin",
  ];
  constructor(
    private $state: angular.ui.IStateService,
    private $log: ng.ILogService,
    private $rootScope: IExtendedRootScopeService,
    private $filter: ng.IFilterService,
    private $q: ng.IQService,
    private EmployeeService: any,
    private CompanyService: any,
    private Autocompletes: any,
    private EditUtilMixin: any,
    private DeckControllerMixin: any
  ) {
    this.deregCompanyChanged = this.$rootScope.$on("companyChanged", (event, company: any) => {
      if (_.isUndefined(company) || _.isNull(company)) {
        this.project.company = null;
        this.resetEmployees(this.project.company, undefined);
      } else {
        this.CompanyService.get(company.id).then((company_obj: any) => {
          this.project.company = company_obj;
        });
        this.resetEmployees(this.project.company, undefined);
      }
    });
  }

  private resetEmployees(nv, ov?: any) {
    if (_.isUndefined(nv)) {
      this.employees = [];
      this.project.employees = [];
      this.project.invoiceRecipient = undefined;
    } else {
      if (_.isUndefined(ov) || nv.id !== ov.id) {
        this.project.employees = [];
        this.project.invoiceRecipient = undefined;
      }
      this.searchAutocomplete(""); // initialize with first 20 managers
    }
  }

  public $onInit() {
    angular.extend(this, this.EditUtilMixin, this.DeckControllerMixin);

    this.project.projectStatus =
      _.find(this.project.$class.statuses, (status) => {
        return status == this.project.projectStatus;
      }) || this.project.$class.statuses[0];

    this.sendoutLanguages = Language.defaultOptions;
    this.allLanguages = _.map(this.languages, (language) => {
      var oneLanguage = _.find(this.project.projectsLanguages, (projLang) => {
        return projLang.language.id == language.id;
      });
      if (oneLanguage) {
        return oneLanguage;
      } else {
        return { id: null, language: language };
      }
    });

    if (this.project.id) {
      this.$rootScope.setPageTitle(this.project.title);
    } else {
      this.$rootScope.setPageTitle(this.$filter("translate")("PAGE_TITLE_NEW_PROJECT"));
    }

    this.employees = this.project.company ? this.project.company.employees : this.project.employees;

    if (this.project.leadershipExperience) {
      this.project.leadershipExperience = _.find(this.leadershipExperiences, (leadershipExperience) => {
        return leadershipExperience.id === this.project.leadershipExperience.id;
      });
    }

    this.onStateChanged(this.project);

    this.deregActiveRowConnector = this.initializeActiveRowConnector(this.project);
    this.registerSaveHotkey(this.saveProject);
  }

  public searchAutocomplete(value) {
    if (this.project.company) {
      this.loading = true;

      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      var params = [
        value,
        "employee_name",
        _.last(this.cancelers),
        "company",
        { company_ids: _.compact([this.project.company.id, this.project.company.parentId]) },
      ];

      this.Autocompletes.autocomplete.apply(this, params).then((results) => {
        if (!results) {
          this.employees = [];
          return;
        }

        results.splice(0, 1);
        this.employees = _.chain(results)
          .map((employee_obj) => {
            return new this.EmployeeService(employee_obj.employee);
          })
          .value();
        this.loading = false;
      });
    } else {
      this.employees = [];
    }
  }

  public analyzeOrigJobOfferLink() {
    if (!this.project.origJobOfferLink) {
      return;
    }
    if (this.saving) {
      return;
    }

    if (!this.project.title) {
      // set temporary title for validations
      this.project.title = this.$filter("translate")("DEFAULT_PROJECT_TITLE");
    }

    // save new project before analyzing
    this.saving = true;
    this.project.save().then((project) => {
      _.extend(this.project, project);

      this.saving = false;
      this.project.snapshot();

      this.project.getDocumentExportModal(this.project).then((doc: any) => {
        doc.textkernelDiff();
      });
    }, this.onSaveError.bind(this));
  }

  public saveProject(route, params) {
    if (this.saving) {
      return;
    }

    if (this.projectForm.$invalid) {
      window.alert(this.$filter("translate")("COMPLETE_REQUIERED_FIELDS_NOTICE"));
      this.forceValidation = true;
      return;
    }

    this.saving = true;
    this.project.save().then((project: any) => {
      this.$log.debug("project successfully saved");

      if (!this.project.id) {
        this.$rootScope.$broadcast("addProject", project);
      }

      this.project.snapshot();
      this.$state.go(route || "^.show", params || project.params());
    }, this.onSaveError.bind(this));
  }

  public $onDestroy() {
    this.deregProjectDiff();
    this.deregCompanyChanged();
    this.deregActiveRowConnector();
  }
};
