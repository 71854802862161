import { QuicklistsShowCtrl } from "./show.controller";
import quicklistsShowTemplate from "./show.template.html";

angular.module("quicklists.views").component("quicklistShowView", {
  bindings: {
    quicklist: "<quicklistResponse",
    query: "<",
    advancedQuery: "<",
    $state$: "<",
    stateParams: "<",
  },
  template: quicklistsShowTemplate,
  controller: QuicklistsShowCtrl,
});
