import toggleButtonTemplate from "./toggle-button.template.html";

angular.module("directives").component("toggleButton", {
  bindings: {
    model: "=",
    type: "@",
    disabled: "<",
  },
  template: toggleButtonTemplate,
});
