//= require ../employees_candidates_projects.module.js

angular.module("employees_candidates_projects").service("EmployeesCandidatesProjectsFactory", [
  "RailsResource",
  "railsSerializer",
  "RailsResourceSnapshotsMixin",
  "CandidatesProjectsFactory",
  "DealsFactory",
  "ModalMixin",
  "$sce",
  "$rootScope",
  "$filter",
  "$translate",
  function (
    RailsResource,
    railsSerializer,
    RailsResourceSnapshotsMixin,
    CandidatesProjects,
    Deal,
    ModalMixin,
    $sce,
    $rootScope,
    $filter,
    $translate
  ) {
    function EmployeesCandidatesProjects() {
      (<any>EmployeesCandidatesProjects).__super__.constructor.apply(this, arguments);

      this.lost = function (action) {
        action.actionEditModal(this).then(
          function () {
            $rootScope.$broadcast("candidate_action_changed_" + this.candidate.id);
            $rootScope.$broadcast("project_action_changed_" + this.project.id);
            _.each(this.employees, function (employee) {
              $rootScope.$broadcast("employee_action_changed_" + employee.id);
            });
          }.bind(this)
        );
      };

      this.projectStatusUpdate = function () {
        if (this.status.disabled) {
          window.alert($filter("translate")("OPTION_DEACTIVATED_NOTICE"));
          this.rollback();
          this.snapshot();
          return;
        }

        this.confirm(
          $sce.trustAsHtml(
            this.status.onChangeHintTemplate || $filter("translate")("CHANGE_STATUS_MANAGER_CONFIRMATION")
          )
        ).then(
          function (res) {
            if (res) {
              this.employee_id = _.first(this.employees).id;
              this.save().then(
                function (result) {
                  this.snapshot();

                  if (this.status.callback) {
                    var callback =
                      EmployeesCandidatesProjects.override_status_callbacks[this.status.name] || this.status.callback;
                    callback(result);
                  } else {
                    $rootScope.$broadcast("candidate_action_changed_" + this.candidate.id);
                    $rootScope.$broadcast("project_action_changed_" + this.project.id);
                    _.each(this.employees, function (employee) {
                      $rootScope.$broadcast("employee_action_changed_" + employee.id);
                    });
                  }
                }.bind(this)
              );
            } else {
              this.rollback();
              this.snapshot();
            }
          }.bind(this)
        );
      };

      this.resourceType = "EmployeesCandidatesProjects";
      this.serviceName = "EmployeesCandidatesProjectsFactory";
      this.snapshot();
    }

    EmployeesCandidatesProjects.override_status_callbacks = {
      Closing: function (ecp) {
        return Deal.createAndEdit({
          candidatesProjectId: ecp.candidatesProjectId,
          employeesCandidatesProjectId: ecp.id,
          add_action: ecp.lastAction,
          closing: true,
        });
      },
      Deal: function (ecp) {
        return Deal.createAndEdit({
          candidatesProjectId: ecp.candidatesProjectId,
          employeesCandidatesProjectId: ecp.id,
          add_action: ecp.lastAction,
          closing: false,
        });
      },
      Lost: function (ecp) {
        return ecp.lost(ecp.lastAction);
      },
    };

    RailsResource.extendTo(EmployeesCandidatesProjects);
    (<any>EmployeesCandidatesProjects).configure({
      url: "/employees/{{employee_id}}/employees_candidates_projects/{{id}}",
      name: "employees_candidates_projects",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("sales", "UsersFactory");
        this.resource("researcher", "UsersFactory");
        this.resource("lastAction", "ActionsFactory");
        this.serializeWith("status", "StatusSerializer");
      }),
    });

    (<any>EmployeesCandidatesProjects).extend(RailsResourceSnapshotsMixin);
    (<any>EmployeesCandidatesProjects).extend(ModalMixin);

    return EmployeesCandidatesProjects;
  },
]);
