import { ITemplateCacheService, ITimeoutService } from "angular";

export default class PrsmTableCtrl {
  public bodyDirectiveIdentifier: string;
  public bodyDirective: string;
  public activeRow: any;
  public descriptor: any;
  public enableHotkeys: boolean;
  public data: any;
  public tableRowIdentifier: string;
  public pathAppendix: string;
  public path: string;
  public initialViewState: any;
  public searchTime: any;
  public attributes: any;
  public deregActiveRowClosed: () => void;
  public deregActiveRowChanged: () => void;

  static $inject = ["$templateCache", "$rootScope", "$state", "hotkeys", "$timeout"];

  constructor (
    private $templateCache: ITemplateCacheService,
    private $rootScope: IExtendedRootScopeService,
    private $state: angular.ui.IStateService,
    private hotkeys: any,
    private $timeout: ITimeoutService
  ) {
    this.nextItem = this.nextItem.bind(this);
    this.previousItem = this.previousItem.bind(this);
  }

  public $onInit() {
    _.defaults(this, {
      enableHotkeys: false,
      collapseHeader: false,
      pathAppendix: "",
      enableInfiniteScrolling: false,
    });

    this.bodyDirectiveIdentifier = "prsm-table-body-" + this.bodyDirective;
    this.$templateCache.put(
      this.bodyDirectiveIdentifier,
      "<" +
        this.bodyDirective +
        ' table-row-identifier="$ctrl.tableRowIdentifier" context="$ctrl.context" context-menu-options="$ctrl.contextMenuOptions"' +
        ' list="$ctrl.list" initial-view-state="$ctrl.initialViewState" attributes="$ctrl.attributes" active-row="$ctrl.activeRow" identifier="$ctrl.activeRowChanged-' +
        _.uniqueId("prsm-table-body") +
        '" data="$ctrl.data" state-args="$ctrl.stateArgs" state-prefix="{{$ctrl.statePrefix}}"></' +
        this.bodyDirective +
        ">"
    );

    this.deregActiveRowChanged = this.$rootScope.$on("activeRowChanged", (event, newActiveRow) => {
      if (this.activeRow && this.activeRow.resourceType == newActiveRow.resourceType) {
        this.activeRow = newActiveRow;
      }
      if (!this.activeRow && newActiveRow.resourceType == this.descriptor) {
        this.activeRow = newActiveRow;
      }
    });

    if (!this.enableHotkeys) {
      this.deregActiveRowClosed = this.$rootScope.$on("activeRowClosed", (event, activeRow) => {
        if (this.activeRow == activeRow) {
          this.activeRow = undefined;
        }
      });
    }
  };

  public $onChanges(changes) {
    if (changes.lazyLoading && !changes.lazyLoading.currentValue && this.data) {
      this.$rootScope.$broadcast("finishedLazyLoading");
      this.initializeTable();
    }
  };

  public $onDestroy() {
    if (this.enableHotkeys) {
      this.deregisterHotkeys();
    }

    this.deregActiveRowChanged();
    if (_.isFunction(this.deregActiveRowClosed)) {
      this.deregActiveRowClosed();
    }
  };

  public getListIndex() {
    return _.chain(this.data).pluck("id").indexOf(this.activeRow.id).value();
  };

  public scrollListElementIntoView() {
    $("#" + this.tableRowIdentifier + this.activeRow.id)[0].scrollIntoView(false);
  };

  public openDetailView(mode) {
    let path = this.pathAppendix ? this.path + this.pathAppendix + mode : this.path + mode;
    this.$state.go(path, this.activeRow.params(), { relative: this.initialViewState });
  };

  public nextItem(event) {
    if (!this.data) {
      return;
    }
    event.preventDefault();

    var index = this.getListIndex();

    if (!this.data[index + 1]) {
      var loading = this.moreWithoutReload();
      if (loading) {
        loading.then(() => {
          this.activeRow = this.data[index + 1];
          this.$timeout(this.scrollListElementIntoView);
        });
      }
      return;
    } else {
      this.activeRow = this.data[index + 1];
      this.scrollListElementIntoView();
    }

    this.openDetailView(".show");
  };

  public previousItem(event) {
    if (!this.data) {
      return;
    }
    event.preventDefault();

    var index = this.getListIndex();
    if (index > 0) {
      this.activeRow = this.data[index - 1];
      this.scrollListElementIntoView();
    }

    this.openDetailView(".show");
  };

  public registerHotkeys() {
    this.hotkeys.add({
      combo: "up",
      description: "Next " + this.descriptor,
      callback: this.previousItem,
    });
    this.hotkeys.add({
      combo: "down",
      description: "Previous " + this.descriptor,
      callback: this.nextItem,
    });
    this.hotkeys.add({
      combo: "esc",
      description: "Detailansicht schließen",
      callback: () => {
        this.$state.go(this.path, {});
      },
    });
    this.hotkeys.add({
      combo: "enter",
      description: "Detailansicht öffnen",
      callback: () => {
        if (this.activeRow) {
          this.openDetailView(".show");
        }
      },
    });
    this.hotkeys.add({
      combo: "mod+b",
      description: "Bearbeitungsansicht öffnen",
      callback: () => {
        if (this.activeRow) {
          this.openDetailView(".edit");
        }
      },
    });
  };

  public deregisterHotkeys() {
    this.hotkeys.del("up");
    this.hotkeys.del("down");
    this.hotkeys.del("esc");
    this.hotkeys.del("enter");
    this.hotkeys.del("mod+b");
  };

  public searchTimeCalc() {
    return this.data?.meta?.took ? this.data.meta.took / 1000 : null;
  };

  public moreWithoutReload() {
    if (this.data?.isLoading() || !this.data?.isMoreAvailable()) return;
    return this.data.getMore();
  };

  public resetSortOrder(sortOrder?: string) {
    _.chain(this.attributes)
      .each((attr) => {
        if (_.isUndefined(attr.$sortOrder) || attr.$sortOrder !== sortOrder) {
          attr.$sortOrder = [undefined, "desc", "asc"];
        }
      })
      .value();
  };

  public sortBy(paramName, sortOrder) {
    if (!paramName) {
      return false;
    }

    this.resetSortOrder(sortOrder);
    var sortParams = {};
    sortOrder.push(sortOrder.shift());

    if (sortOrder[0]) {
      sortParams[paramName] = { order: sortOrder[0] };
    }

    this.searchTime = null;
    this.data.sortBy(sortParams).then((result) => {
      this.searchTime = this.searchTimeCalc();
    });
  };

  public isMoreAvailable() {
    if (_.isFunction(this.data?.isMoreAvailable)) {
      return this.data.isMoreAvailable();
    }
    return false;
  };

  public initializeTable = _.once(() => {
    this.searchTime = this.searchTimeCalc();

    this.resetSortOrder(undefined);

    if (this.enableHotkeys && !this.hotkeys.get("left") && !this.hotkeys.get("right")) {
      if (!this.activeRow) {
        this.activeRow = this.data[0];
      }
      this.registerHotkeys();
    }
  });
};
