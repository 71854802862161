import { Priority } from "../../../../priorities/services/priority.service";

export default class PrioritySearchCtrl {
  public prioritySelectUnigId: string;
  public priorities: Priority[];
  public advancedQuery: any;
  
  constructor() {
    this.prioritySelectUnigId = _.uniqueId("prioritySelected");
  }

  public $onInit() {
    Priority.getAll().then((priorities: Priority[]) => {
      this.priorities = priorities;

      // select all by default
      if (_.isEmpty(this.advancedQuery.priorities)) {
        _.each(this.priorities, (priority: Priority) => {
          priority.$selected = true;
        });
      } else {
        _.each(this.priorities, (priority: Priority) => {
          var index = _.findIndex(this.advancedQuery.priorities, (p) => {
            return p == priority.name;
          });
          priority.$selected = index > -1;
        });
      }
    });
  };

  public updatePriorityFilter() {
    this.advancedQuery.priorities = _.chain(this.priorities)
      .where({ $selected: true })
      .map((priority: Priority) => {
        return priority.name;
      })
      .value();
  };
};