import { Tag } from "../../services/tags.service";
import tagsListPopoverTemplate from "./tags-list-popover.template.html";

export class TagsListInlineCtrl {
  static $inject = ["$templateCache"];

  constructor(private $templateCache: ng.ITemplateCacheService) {
    this.tagsListPopoverTemplate = "tags-list-popover-template";
    $templateCache.put(this.tagsListPopoverTemplate, tagsListPopoverTemplate);
  }

  public groups: Record<string, Tag[]>;
  public tags: Tag[];
  public tagsListPopoverTemplate: string;

  public $onInit() {
    this.groups = _.groupBy(this.tags, "type");
  }
}
