import { IFilterService } from "angular";

export default class TeamSelectCtrl {
  private teams: any;
  private users: any;
  private teamsLabel: string | undefined;
  private oldOptionsAllActive: boolean;
  private oldOptions: any;
  private oldParentOptions: any;
  private options: any;
  private parentOptions: any;
  private closing: () => void;
  private onChanges: () => void;
  static $inject = ["$filter", "UsersFactory"];

  constructor(private $filter: IFilterService, private User: any) {}

  public $onInit() {
    this.teams = this.User.translatedTeams();
    this.users = _.where(this.User.getAll(), { active: true, isSelectable: true });
  }

  public search() {
    var defaultOptions = { allActive: undefined, team: undefined, users: [] };
    angular.extend(this.parentOptions, _.defaults(this.options, defaultOptions));
    this.closing();

    if (_.isFunction(this.onChanges)) {
      this.onChanges();
    }
  }

  public updateTeamInputLabel(nv, ov) {
    if (!nv) {
      return;
    }

    if (nv.allActive) {
      this.teamsLabel = "Entourage";
    } else if (nv.team) {
      this.teamsLabel = nv.team.name;
    } else if (nv.users && nv.users.length > 1) {
      this.teamsLabel = this.$filter("translate")("MULTIPLE_EMPLOYEES_SELECTED", {
        name: _.first(nv.users).fullName,
        number: nv.users.length - 1,
      });
    } else if (_.first(nv.users)) {
      this.teamsLabel = _.first(nv.users).fullName;
    } else {
      this.teamsLabel = undefined;
    }
  }

  public setTeamOption(team) {
    if (team) {
      this.options = { team: team };
      this.search();
    }
  }

  public userSelected($item) {
    this.options = { users: this.options.users };
  }

  public $doCheck() {
    if (this.options) {
      if (this.options.allActive && this.options.allActive !== this.oldOptionsAllActive) {
        this.oldOptionsAllActive = this.options.allActive;
        this.options = { allActive: true };
        this.search();
      }
      if (!angular.equals(this.oldOptions, this.options)) {
        this.oldOptions = angular.copy(this.options);
        this.updateTeamInputLabel(this.options, this.oldOptions);
      }
    }

    if (this.parentOptions && !angular.equals(this.parentOptions, this.oldParentOptions)) {
      this.oldParentOptions = angular.copy(this.parentOptions);
      this.options = angular.copy(this.parentOptions);
    }
  }
}
