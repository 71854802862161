import EntourageCandidatesSearchCtrl from "./entourage-candidates-search.controller";
import entourageCandidatesSearchTemplate from "./entourage-candidates-search.template.html";

angular.module("directives.advanced_search").component("entourageCandidatesSearch", {
  bindings: {
    advancedQuery: "<",
  },
  template: entourageCandidatesSearchTemplate,
  controller: EntourageCandidatesSearchCtrl,
});
