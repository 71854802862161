import actionsPaginationTemplate from "../../../directives/components/pagination/pagination.template.html";
export default class ActionsHistoryCtrl {

  private id: any =  _.uniqueId;
  private paginationId: string = "pagination-id-" + this.id;
  private oldActionList: any[] = [];
  private paginationTemplateUrl: any = "paginationTemplate";
  private paginationService: any;
  private advancedActionsSearchOptions: any;
  private dereg: () => void;
  private followUps: any;
  private actionAttributes: any;
  private object: any;
  private identifier: string;
  private actionsPage: any;
  private advancedQuery: any;
  private setData: () => void;


  static $inject = ["ActionsFactory", "SavedSearchesFactory", "hotkeys", "$rootScope", "$timeout", "PrsmTableHelper", "$filter", "dynamicRoute", "MgPaginateService", "$transitions", "$templateCache"];
  
  constructor(
    private Action: any,
    private SavedSearch: any,
    private hotkeys: any,
    private $rootScope: ng.IRootScopeService,
    private $timeout: ng.ITimeoutService,
    private PrsmTableHelper: any,
    private $filter: ng.IFilterService,
    private dynamicRoute: any,
    private MgPaginateService: any,
    private $transitions: any,
    private $templateCache: ng.ITemplateCacheService
  ) {
    this.$templateCache.put(this.paginationTemplateUrl, actionsPaginationTemplate);

    angular.extend(this, dynamicRoute);

    this.paginationService = new this.MgPaginateService();

    if (!this.hotkeys.get("mod+h")) {
      this.hotkeys.add({
        combo: "mod+h",
        description: this.$filter("translate")("CREATE_NEW_HISTORY_ENTRY"),
        callback: (event, hotkey) => {
          event.preventDefault();
          this.createAction();
        },
      });

      var dereg = this.$transitions.onSuccess({}, () => {
        this.hotkeys.del("mod+h");
        dereg();
      });
    }

    this.advancedQuery = new this.SavedSearch({
      defaultCondition: { modifier: "must", field: "action_type", query: "" },
      systemDisabled: false,
    });
    this.advancedQuery.deleteAdvancedSearchCondition(0);

    this.advancedActionsSearchOptions = {
      setData: this.initializeActionsSearch.bind(this),
      advancedQuery: this.advancedQuery,
      followUps: this.followUps,
    };

    this.actionAttributes = this.PrsmTableHelper.setVisibleAttributes(
      angular.copy(this.Action.defaultTableAttributes),
      [
        "id",
        "type",
        "responsibleUser",
        "consultant",
        "researcher",
        "project",
        "deal",
        "amountCovered",
        "status",
        "countableDate",
        "candidate",
        "company",
        "employees",
        "description",
      ],
      ["type", "countableDate", "status", "project", "candidate", "company", "employees", "description"]
    );
  }

  public $doCheck () {
    if (!this.object) return;
    if (!_.isFunction(this.object.actions)) return;

    var actionList = this.getActions();
    if (!angular.equals(actionList, this.oldActionList)) {
      if (actionList.meta) {
        this.actionsPage = this.getActions();
        this.paginationService.setData(this.actionsPage);
        this.paginationService.setTotalItems(this.actionsPage.meta.totalCount);
      }

      if (actionList.length !== this.oldActionList.length) {
        this.$rootScope.$broadcast("action", this.object);
      }

      this.oldActionList = this.getActions();
    }
  };

  public getActions () {
    if (_.isFunction(this.object.actions)) {
      return this.object.actions();
    }
  };

  public createAction(options: any = {}) {
    _.defaults(options, { actionTypeEditable: true });
    this.Action.createNewAction(this.object, undefined, options).catch(angular.noop);
  }

  public initializeActionsSearch () {
    if (this.dereg) {
      this.dereg();
    }
    this.dereg = this.$rootScope.$on("History_" + this.identifier + this.object.id, (event: any) => {
      this.$timeout(() => {
        this.Action.lazyLoadingActions.bind(this)(this.object, this.advancedQuery);
      }, 1);
    });

    this.dereg = this.$rootScope.$on(this.identifier + this.object.id, (event: any) => {
      this.$timeout(() => {
        this.Action.lazyLoadingActions.bind(this)(this.object, this.advancedQuery);
      }, 1);
    });

    this.$rootScope.$broadcast("History_" + this.identifier + this.object.id);
  };

  public initializePagination () {
    this.advancedQuery.followUps = this.followUps;
    this.initializeActionsSearch();
  };
  public $postLink () {
    this.initializePagination();
  };
 }