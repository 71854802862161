import AdvancedEmployeesSearchCtrl from "./advanced-employees-search.controller";
import advancedEmployeesSearchTemplate from "./advanced-employees-search.template.html";

angular.module("employees.components").component("advancedEmployeesSearch", {
  bindings: {
    advancedQuery: "=",
    setData: "=",
    closing: "&",
  },
  template: advancedEmployeesSearchTemplate,
  controller: AdvancedEmployeesSearchCtrl,
});
