export class QuicklistsCompaniesListCtrl {
  static $inject = ["CompaniesFactory", "PrsmTableAttributeService"];

  constructor(private CompanyService: any, private PrsmTableAttributeService: any) {}

  public quicklist: any;
  public companiesAttributes: any;

  public $onInit() {
    var quicklistCompanyEntryAttributes = {
      deleteEntry: { show: !this.quicklist.isReadOnly() },
      kpis: { show: false },
    };

    this.companiesAttributes = new this.PrsmTableAttributeService(
      "quicklist-companies-table-attributes",
      _.extend(angular.copy(this.CompanyService.defaultTableAttributes), quicklistCompanyEntryAttributes)
    ).getAttributes();
  }
}
