import { SelectProcessStatusCtrl } from "./select-process-status.controller";
import selectProcessStatusTemplate from "./select-process-status.template.html";

angular.module("projects.components").component("selectProcessStatus", {
  bindings: {
    cp: "<",
    projectStatuses: "<",
  },
  template: selectProcessStatusTemplate,
  controller: SelectProcessStatusCtrl,
});
