import eventConfirmModalCtrl from "./event-confirm-modal.controller";
import eventConfirmModalTemplate from "./event-confirm-modal.template.html";

angular.module("actions.modals").component("eventConfirmModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: eventConfirmModalTemplate,
  controller: eventConfirmModalCtrl,
});
