//= require ../modals.module.js

angular.module("login.modals").factory("LoginModalMixin", [
  "$uibModal",
  function ($uibModal) {
    var modalInstanceSingelton;

    function LoginFormModal() {
      if (!modalInstanceSingelton) {
        modalInstanceSingelton = $uibModal.open({
          component: "loginForm",
          size: "sm",
          backdrop: "static",
          resolve: {},
        });
        return modalInstanceSingelton.result.finally(function () {
          modalInstanceSingelton = undefined;
        });
      }
    }

    return {
      openLoginFormModal: LoginFormModal,
    };
  },
]);
