//= require ./values.module.js
angular
  .module("values")

  .constant("AUTH_EVENTS", {
    loginSuccess: "auth-login-success",
    loginFailed: "auth-login-failed",
    logoutSuccess: "auth-logout-success",
    sessionTimeout: "auth-session-timeout",
    notAuthenticated: "event:auth-loginRequired",
    notAuthorized: "auth-not-authorized",
  });
