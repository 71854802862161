export default class HistoryAdvancedSearchCtrl {
  private followUps: boolean;
  private setData: () => void;
  private closing: () => void;
  private selectableFields: string[] = [
    "action_type",
    "responsible_user",
    "company_name",
    "candidate_name",
    "employee_name",
    "project_title",
  ];

  constructor() {}

  public $onInit () {
    if (!this.followUps) {
      this.selectableFields.push("completed_by_user");
    }
  };
  public performAdvancedSearch () {
    this.setData();
    this.closing();
  };
}