import { QuicklistsTableBodyCtrl } from "../quicklists-table-body.controller";
import quicklistsCompaniesTableBodyTemplate from "./quicklists-companies-table-body.template.html";

angular.module("quicklists.components").component("quicklistsCompaniesTableBody", {
  bindings: {
    companies: "=data",
    quicklist: "=list",
    attributes: "=",
    tableRowIdentifier: "=",
    activeRow: "=",
    initialViewState: "<",
  },
  template: quicklistsCompaniesTableBodyTemplate,
  controller: QuicklistsTableBodyCtrl,
});
