import { camelCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { baseClient, cached } from "../../api-routing/base-client";
import { TJobCategories } from "../job_categories";
import { TDepartments } from "../../departments/departments";
import { Department } from "../../departments/services/departments.service";

export class JobCategory {
  private serviceName: string = "JobCategoriesFactory";
  private id: number;
  private name: string;
  private slug: string;
  private departments?: TDepartments[];

  static $inject = [];

  constructor(fields?: Partial<TJobCategories>) {
    if (fields) {
      let object = deepMapKeys<Partial<TJobCategories>>(fields, camelCase);
      object.departments = object.departments?.map((element) => (<unknown>new Department(element)) as TDepartments);

      Object.assign(this, object);
    }
  }

  @cached
  public static getAll() {
    return baseClient.jobCategories.query().then((response) => {
      return response.body.data.map((element) => {
        return new JobCategory(element);
      });
    });
  }
}
