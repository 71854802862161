import ActionsHistoryCtrl from "../actions-history.controller";
import historyTemplate from "./history.template.html";

angular.module("actions.components").component("history", {
  bindings: {
    object: "=",
    identifier: "@",
    disableNew: "@",
  },
  template: historyTemplate,
  controller: ActionsHistoryCtrl,
});
