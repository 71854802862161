import CandidateSearchCtrl from "./candidate-search.controller";
import candidateSearchTemplate from "./candidate-search.template.html";

angular.module("candidates.components").component("candidateSearch", {
  bindings: {
    savedSearches: "=",
    disableSavedSearch: "@",
    advancedQuery: "=",
    query: "=",
    remote: "=",
    setData: "&",
  },
  template: candidateSearchTemplate,
  controller: CandidateSearchCtrl,
});
