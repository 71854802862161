import { TextkernelImportFinishedModalCtrl } from "./textkernel-import-finished.controller";
import textkernelImportFinishedTemplate from "./textkernel-import-finished.template.html";

angular.module("textkernel.modals").component("textkernelImportFinished", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: textkernelImportFinishedTemplate,
  controller: TextkernelImportFinishedModalCtrl,
});
