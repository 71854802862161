import InvoicesFormCtrl from "./invoices-form.controller";
import invoicesFormTemplate from "./invoices-form.template.html";

angular.module("invoices.components").component("invoicesForm", {
  bindings: {
    project: "=",
    preload: "@",
  },
  template: invoicesFormTemplate,
  controller: InvoicesFormCtrl,
});
