//= require ../login.module.js

angular
  .module("login")
  // This stores the user session locally
  .service("Session", [
    "UsersFactory",
    "DefaultCurrency",
    function (User, DefaultCurrency) {
      this.create = function (user_session) {
        this.user = new User(user_session.user);
        window.user_session = { user: this.user };

        DefaultCurrency.currency = user_session.user.currency || "EUR";
      };
      this.destroy = function () {
        this.user = null;
      };
      return this;
    },
  ]);
