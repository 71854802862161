import { AdvancedSendoutsSearchCtrl } from "./advanced-sendouts-search.controller";
import advancedSendoutsSearchTemplate from "./advanced-sendouts-search.template.html";

angular.module("sendouts.components").component("advancedSendoutsSearch", {
  bindings: {
    advancedQuery: "=",
    setData: "=",
    closing: "&",
  },
  template: advancedSendoutsSearchTemplate,
  controller: AdvancedSendoutsSearchCtrl,
});
