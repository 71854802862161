import { ProjectsAutoSearchCtrl } from "./projects-auto-search.controller";
import projectsAutoSearchTemplate from "./projects-auto-search.template.html";

angular.module("projects.components").component("projectsAutoSearch", {
  bindings: {
    candidate: "=",
    savedSearches: "=",
    activeRow: "=",
    initialViewState: "<",
  },
  template: projectsAutoSearchTemplate,
  controller: ProjectsAutoSearchCtrl,
});
