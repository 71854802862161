//= require ../researcher_commissions.module.js

angular.module("researcher_commissions").service("ResearcherCommissionsFactory", [
  "RailsResource",
  "railsSerializer",
  function (RailsResource, railsSerializer) {
    function ResearcherCommission() {
      (<any>ResearcherCommission).__super__.constructor.apply(this, arguments);
    }

    RailsResource.extendTo(ResearcherCommission);
    (<any>ResearcherCommission).configure({
      url: "/deals/{{dealId}}/researcher_commissions/{{id}}",
      name: "researcher_commission",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("researcher", "UsersFactory");
        this.serializeWith("commission", "MoneySerializer");
      }),
    });

    return ResearcherCommission;
  },
]);
