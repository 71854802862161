//= require ../login.module.js

angular.module("login").service("UserSessionsFactory", [
  "RailsResource",
  function (RailsResource) {
    function UserSession() {
      (<any>UserSession).__super__.constructor.apply(this, arguments);
    }

    RailsResource.extendTo(UserSession);
    (<any>UserSession).configure({
      url: "/user_session",
      name: "user_session",
    });

    return UserSession;
  },
]);
