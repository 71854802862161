import EmployeesShowCtrl from "./employees-show.controller";
import employeesShowViewTemplate from "./employees-show-view.template.html";

angular.module("employees.views").component("employeesShowView", {
  bindings: {
    employee: "<employeeResponse",
    scrollspyScope: "<",
    $state$: "<",
  },
  template: employeesShowViewTemplate,
  controller: EmployeesShowCtrl,
});
