import { IStateService } from 'angular-ui-router';
export default class CompaniesAdvancedSearchCtrl {
  static $inject = ["$state"];
  public advancedQuery: any;
  public advancedQueryCopy: any;
  private setData: () => (collectedparams: any) => void;
  private closing: () => void;
  public remote: boolean;

  constructor(private $state: IStateService) { }

  public $onInit() {
    this.advancedQueryCopy = angular.copy(this.advancedQuery);
    this.advancedQueryCopy.name = undefined;
    this.advancedQueryCopy.id = undefined;
  };

  public performAdvancedSearch() {
    if (!this.remote) {
      this.$state.go("root.companies", this.advancedQueryCopy.collectSearchParams(), {
        reload: true,
        inherit: false,
      });
    } else {
      _.extend(this.advancedQuery, this.advancedQueryCopy);
      this.setData()(this.advancedQueryCopy.collectSearchParams());
      this.closing();
    }
  };
};
