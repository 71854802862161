import CompanyContactModalCtrl from "./company-contact-modal.controller";
import companyContactModalTemplate from "./company-contact-modal.template.html";

angular.module("companies.modals").component("companyContactModal", {
  bindings: {
    resolve: "<",
    dismiss: "&",
  },
  template: companyContactModalTemplate,
  controller: CompanyContactModalCtrl,
});
