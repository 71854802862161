//= require ../sendouts.module.js

angular.module("sendouts").service("SendoutsFactory", [
  "RailsResource",
  "railsSerializer",
  "$state",
  "$rootScope",
  function (RailsResource, railsSerializer, $state, $rootScope) {
    function Sendout() {
      (<any>Sendout).__super__.constructor.apply(this, arguments);
    }

    RailsResource.extendTo(Sendout);
    (<any>Sendout).configure({
      url: "/sendouts",
      name: "sendout",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.nestedAttribute("expertProfile");
        this.resource("expertProfile", "ExpertProfileFactory");
        this.nestedAttribute("employees");
        this.resource("employee", "EmployeesFactory");
        this.nestedAttribute("candidates");
        this.resource("candidate", "CandidatesFactory");
        this.nestedAttribute("projects");
        this.resource("project", "ProjectsFactory");

        this.resource("action", "ActionsFactory");

        this.serializeWith("pdfUrl", "UrlSerializer");
      }),
    });

    return Sendout;
  },
]);
