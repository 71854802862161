//= require ../documents.module.js

angular.module("documents").service("DocumentsFactory", [
  "RailsResource",
  "$sce",
  "$filter",
  "$translate",
  "railsSerializer",
  "DocumentsModalMixin",
  "makeCollectionProxy",
  function (RailsResource, $sce, $filter, $translate, railsSerializer, DocumentsModalMixin, makeCollectionProxy) {
    function Document() {
      (<any>Document).__super__.constructor.apply(this, arguments);

      this.isCv = function () {
        return this.fileTypeId() === 1;
      };

      this.isExpertProfile = function () {
        return this.fileTypeId() === 8;
      };

      this.isRelevantForExpertProfile = function () {
        // only return cvs, documents and certificates
        return _.contains([0, 1, 2, 3, 11], this.fileTypeId());
      };

      this.fileTypeId = function () {
        return _.isNumber(this.fileType) ? this.fileType : this.fileType.id;
      };

      this.isTextkernelAvailable = function () {
        return this.resourceType == "CandidateDocument" || this.resourceType == "ProjectDocument";
      };

      this.textkernelDiff = function () {
        switch (this.resourceType) {
          case "CandidateDocument":
            this.textkernelCandidateDiff();
            break;
          case "ProjectDocument":
            this.textkernelProjectDiff();
            break;
          default:
            return;
        }
      };

      this.documentLabel = function () {
        return this.originalFilename || this.title;
      };

      this.documentViewable = function () {
        return this.extension == "pdf";
      };
    }

    Document.lazyLoadingDocuments = function (resource, advancedQuery) {
      advancedQuery = advancedQuery || {};
      _.defaults(advancedQuery, { followUps: false });
      this.loading = true;
      return makeCollectionProxy(
        (<any>Document).query.bind(Document),
        _.extend(resource.params(), { advanced: JSON.stringify(advancedQuery) })
      ).then(
        function (documents) {
          if (advancedQuery.followUps) {
            resource.followUps = function () {
              return documents;
            };
          } else {
            resource.documents = function () {
              return documents;
            };
          }
          this.loading = false;
        }.bind(this)
      );
    };

    Document.fileTypes = [
      { name: "DOCUMENT", id: 0 },
      { name: "CV_ORIGINAL", id: 1 },
      { name: "CV_EXTERNAL", id: 2 },
      { name: "CV_ANONYMOUS", id: 3 },
      { name: "NON_DISCLOSURE_AGREEMENT_TOKEN", id: 4 },
      { name: "NON_DISCLOSURE_AGREEMENT_SIGNED", id: 5 },
      { name: "EMPLOYMENT_CONTRACT", id: 6 },
      { name: "EMPLOYMENT_CONTRACT_SIGNED", id: 7 },
      { name: "EXPERT_PROFILE", id: 8 },
      { name: "CANDIDATE_AGREEMENT", id: 9 },
      { name: "CANDIDATE_AGREEMENT_SIGNED", id: 10 },
      { name: "CERTIFICATE_TESTIMONIAL", id: 11 },
      { name: "JOB_OFFER", id: 12 },
      { name: "PITCH", id: 13 },
      { name: "CLIENT_CONTRACT", id: 14 },
      { name: "PROJECT_CONTRACT", id: 15 },
      { name: "SERVICE_CONTRACT", id: 16 },
      { name: "PURCHASE_ORDER", id: 17 },
    ];

    RailsResource.extendTo(Document);
    (<any>Document).configure({
      url: "/documents",
      name: "document",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.serializeWith("url", "UrlSerializer");
        this.serializeWith("fileType", "FileTypeSerializer");
        this.resource("candidate", "CandidatesFactory");
      }),
    });

    (<any>Document).extend(DocumentsModalMixin);
    return Document;
  },
]);
