export default class EmployeesAdvancedSearchCtrl {
  public advancedQueryCopy: any;
  public advancedQuery: any;
  public remote: string;
  public setData: () => (data: any) => void;
  public closing: () => void;

  static $inject = ["$state"];

  constructor( 
    private $state: angular.ui.IStateService
  ) {}

  public $onInit() {
    this.advancedQueryCopy = angular.copy(this.advancedQuery);
    this.advancedQueryCopy.name = undefined;
    this.advancedQueryCopy.id = undefined;
  };

  public performAdvancedSearch() {
    if (!this.remote) {
      this.$state.go("root.employees", this.advancedQueryCopy.collectSearchParams(), { reload: true, inherit: false });
    } else {
      _.extend(this.advancedQuery, this.advancedQueryCopy);
      this.setData()(this.advancedQueryCopy.collectSearchParams());
      this.closing();
    }
  };
};