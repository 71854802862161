import { camelCase, snakeCase } from "change-case-all";
import deepMapKeys from "deep-map-keys";

import { TCompaniesTransactionBases } from "../companies_transaction_bases";
import { TransactionBasis } from "../../transaction_bases/services/transaction_bases.service";
import { baseClient } from "../../api-routing/base-client";
import { deepMapKeysMega } from "../../api-routing/deep-map-keys-mega";

export class CompaniesTransactionBasis {
  public id: number;
  public companyId: number;
  startDate: Date;
  endDate: Date;
  brokerage: number;
  periodOfPayment: number;
  surchargePresent: boolean;
  surchargePercentage: number;
  contractType: InstanceType<typeof TransactionBasis>;
  document: { id: number } | undefined;
  documents: ({ id: number } | undefined)[];

  static $inject = [];

  constructor(fields?: Partial<TCompaniesTransactionBases>) {
    if (fields) {
      let object = deepMapKeysMega<Partial<TCompaniesTransactionBases>>(fields, camelCase);
      object.contractType = new TransactionBasis(object.contractType);
      object.startDate = object.startDate ? new Date(object.startDate) : undefined;
      object.endDate = object.endDate ? new Date(object.endDate) : undefined;

      Object.assign(this, object);
    }
  }

  public isAGBContract() {
    return this.contractType.isAGBContract();
  }

  public isFullContract() {
    return this.contractType.isFullContract();
  }

  public isFreelancerContract() {
    return this.contractType.isFreelancerContract();
  }

  public isProjectConsultingContract() {
    return this.contractType.isProjectConsultingContract();
  }

  public isServiceContract() {
    return this.contractType.isServiceContract();
  }

  public isIndividualContract() {
    return this.contractType.isIndividualContract();
  }

  public $url() {
    return `/companies_transaction_basis/${this.id}`;
  }

  public snapshot() {}

  public save() {
    let body = deepMapKeys({ companiesTransactionBasis: this }, snakeCase);

    let request = this.id
      ? baseClient.companiesTransactionBasis.update({ params: { id: this.id }, body: body })
      : baseClient.companiesTransactionBasis.create({ body: body });

    return request.then((response) => {
      return new CompaniesTransactionBasis(response.body.data);
    });
  }

  public delete() {
    return baseClient.companiesTransactionBasis.delete({ params: { id: this.id } }).then((response) => {
      return true;
    });
  }
}
