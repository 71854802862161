angular.module("modals", ["modals.modals"]).directive("modalDialog", [
  "hotkeys",
  function (hotkeys) {
    return {
      restrict: "C",
      link: function (scope, element, attrs) {
        var tmpKeyOpts = [hotkeys.get("esc"), hotkeys.get("mod+h")];
        hotkeys.del("esc");
        hotkeys.del("mod+h");

        scope.$on("$destroy", function (e) {
          if (!hotkeys.get("esc") && tmpKeyOpts[0]) {
            hotkeys.add(tmpKeyOpts[0]);
          }

          if (!hotkeys.get("mod+h") && tmpKeyOpts[1]) {
            hotkeys.add(tmpKeyOpts[1]);
          }
        });
      },
    };
  },
]);
