export class AdvancedSendoutsSearchCtrl {
  public advancedQuery: any;
  public setData: () => void;
  public closing: () => void;

  constructor() {}

  public $onInit() {
    this.advancedQuery.name = undefined;
    this.advancedQuery.id = undefined;
  }

  public performAdvancedSearch() {
    this.setData();
    this.closing();
  }
}
