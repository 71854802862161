import { DocumentExportModalCtrl } from "./project-document-export-modal.controller";
import projectDocumentExportModalTemplate from "./project-document-export-modal.template.html";

angular.module("projects.modals").component("documentExportModal", {
  bindings: {
    resolve: "<",
    modalInstance: "<",
  },
  template: projectDocumentExportModalTemplate,
  controller: DocumentExportModalCtrl,
});
