//= require ../quicklists.module.js

angular.module("quicklists").service("QuicklistsFactory", [
  "RailsResource",
  "RailsResourceSnapshotsMixin",
  "railsSerializer",
  "Session",
  "quicklistsSingleton",
  "$q",
  "$state",
  "$log",
  "$filter",
  "QuicklistsModalMixin",
  "$rootScope",
  "EditUtilMixin",
  "makeCollectionProxy",
  "$injector",
  function (
    RailsResource,
    RailsResourceSnapshotsMixin,
    railsSerializer,
    Session,
    quicklistsSingleton,
    $q,
    $state,
    $log,
    $filter,
    QuicklistsModalMixin,
    $rootScope,
    EditUtilMixin,
    makeCollectionProxy,
    $injector
  ) {
    function Quicklist() {
      (<any>Quicklist).__super__.constructor.apply(this, arguments);

      this.params = function () {
        return { quicklist_id: this.id };
      };

      this.togglePinned = function (event) {
        this.pinned = !this.pinned;
        this.save();
      };

      this.isReadOnly = function () {
        return this.readOnly && this.owner.id != Session.user.id;
      };

      this.isListOwner = function () {
        return this.owner.id == Session.user.id;
      };

      this.isDroppableQuicklist = function (scope) {
        if (scope.cpjoin) {
          return (
            (scope.cpjoin.candidate && (!this.type || this.type == "CandidateQuicklist")) ||
            (scope.employee && (!this.type || this.type == "EmployeeQuicklist")) ||
            (scope.company && (!this.type || this.type == "CompanyQuicklist"))
          );
        }
        return (
          (scope.candidate && (!this.type || this.type == "CandidateQuicklist")) ||
          (scope.employee && (!this.type || this.type == "EmployeeQuicklist")) ||
          (scope.company && (!this.type || this.type == "CompanyQuicklist"))
        );
      };

      this.add = function (newMember) {
        var list;
        function errorHandler(error) {
          EditUtilMixin.onSaveError();
          list.pop();

          delete this.patch;
          return false;
        }

        if (_.contains(["CandidatesProjects"], newMember.resourceType)) {
          newMember = newMember.candidate;
        }

        if (
          !_.contains(["Candidate", "Employee", "Company"], newMember.resourceType) ||
          (this.type && newMember.resourceType + "Quicklist" != this.type)
        ) {
          return false;
        }

        if (!this.type) {
          this.type = newMember.resourceType + "Quicklist";
        }

        if (_.indexOf(this.entryIds, newMember.id) < 0) {
          this.entryIds = this.entryIds || [];
          this.entryIds.push(newMember.id);
          list = this.entryIds;

          this.patch = { op: "add", value: newMember };
          this.save().then(
            function (res) {
              this.updatedAt = res.updatedAt;

              this.loadEntries();
            }.bind(this),
            errorHandler
          );

          delete this.patch;
          return true;
        }
      };

      this.size = function () {
        return this.entryIds.length;
      };

      this.fullList = function () {
        return this.entries;
      };

      var Candidate, Employee, Company;

      this.loadEntries = function (params) {
        if (!Candidate) {
          Candidate = $injector.get("CandidatesFactory");
        }
        if (!Employee) {
          Employee = $injector.get("EmployeesFactory");
        }
        if (!Company) {
          Company = $injector.get("CompaniesFactory");
        }

        var queryFunction = function (params) {
          var url = "quicklists/" + this.id + "/entries";
          switch (this.type) {
            case "CandidateQuicklist":
              return Candidate.$get(url, params);
            case "EmployeeQuicklist":
              return Employee.$get(url, params);
            case "CompanyQuicklist":
              return Company.$get(url, params);
            default:
              var dfd = $q.defer();
              dfd.resolve([]);
              return dfd.promise;
          }
        }.bind(this);

        return makeCollectionProxy(queryFunction, params).then(
          function (entries) {
            this.entries = entries;
          }.bind(this)
        );
      };

      this.deleteWrapper = function () {
        if (window.confirm($filter("translate")("DELETE_QUICKLIST_CONFIRMATION"))) {
          this.delete().then(
            function (result) {
              quicklistsSingleton.data = _.reject(
                quicklistsSingleton.data,
                function (quick) {
                  return quick.id == this.id;
                }.bind(this)
              );
              $rootScope.$broadcast("updatedQuicklist");
              $state.go("root.home");
            }.bind(this)
          );
        }
      };

      this.deleteEntry = function (index) {
        var entry;
        var list;
        function errorHandler() {
          EditUtilMixin.onSaveError();
          list.splice(index, 0, entry);
        }

        entry = this.entryIds.splice(index, 1);
        list = this.entryIds;

        this.save().then(
          function (quicklist) {
            $log.debug("successfully deleted quicklist entry");

            // ## Update Frontend List
            this.loadEntries();
            Quicklist.updateCache(this);
          }.bind(this),
          errorHandler
        );
      };

      this.findAndDeleteEntry = function (entryForDelete) {
        var index = _.indexOf(this.entryIds, entryForDelete.id);
        if (index >= 0) {
          this.patch = { op: "remove", value: entryForDelete };
          this.deleteEntry(index);
          delete this.patch;
        }
      };

      this.getListTemplate = function (templateType) {
        templateType = templateType || "list";
        switch (this.type) {
          case "CandidateQuicklist":
            return "quicklist-candidates-" + templateType;
          case "EmployeeQuicklist":
            return "quicklist-employees-" + templateType;
          case "CompanyQuicklist":
            return "quicklist-companies-" + templateType;
        }
      };
    }

    Quicklist.draggingHelper = function () {
      return $('<div id="ur-dragging-helper"></div>');
    };

    Quicklist.startCallback = function (event, ui, title) {
      ui.helper.html(title);
    };

    Quicklist.getAll = function () {
      var dfd = $q.defer();

      if (quicklistsSingleton.data) {
        dfd.resolve(quicklistsSingleton.data);
      } else {
        (<any>Quicklist).query().then(function (response) {
          quicklistsSingleton.data = response;
          dfd.resolve(response);
        });
      }
      return dfd.promise;
    };

    Quicklist.clearCache = function () {
      quicklistsSingleton.data = null;
    };

    Quicklist.updateCache = function (quicklist) {
      Quicklist.getAll().then(function (quicklists) {
        var ql = _.findWhere(quicklists, { id: quicklist.id });
        _.extend(ql, quicklist);
      });
    };

    Quicklist.findById = function (id) {
      if (!id) {
        return undefined;
      }
      return Quicklist.getAll().then(function (quicklists) {
        var ql = _.findWhere(quicklists, { id: id });
        if (!ql.$outOfSync) {
          return ql;
        } else {
          return (<any>Quicklist).get({ id: id }).then(function (quicklist) {
            var index = _.indexOf(quicklistsSingleton.data, _.findWhere(quicklistsSingleton.data, { id: id }));
            var cache = quicklistsSingleton.data.splice(index, 1, quicklist);
            return quicklist;
          });
        }
      });
    };

    RailsResource.extendTo(Quicklist);
    (<any>Quicklist).configure({
      url: "/quicklists",
      name: "quicklist",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.exclude("$snapshots", "listItems");
      }),
    });
    (<any>Quicklist).extend(RailsResourceSnapshotsMixin);
    (<any>Quicklist).extend(QuicklistsModalMixin);

    return Quicklist;
  },
]);
