export default class CandidatesIndexViewCtrl {
  private loader: () => any; 
  private candidatesLazyLoading: boolean;
  private canLonglist: boolean;
  private onLonglist: any;
  private advancedQuery: any;
  private query: string;
  private attributes: any; 
  private stateParams: any;
  private longlist: (() => any[]) | undefined;

  static $inject = ["makeCollectionProxy", "$state", "SavedSearchesFactory", "CandidatesFactory", "KPISteps", "PrsmTableAttributeService"];

  constructor ( private makeCollectionProxy: (loader: () => any, stateParams: any) => Promise<any[]>,
                private $state: angular.ui.IStateService,
                private SavedSearch: { results: (candidate: any) => any},
                public Candidate: { 
                  query: (params: any) => any,
                  defaultTableAttributes: any 
                },
                private KPISteps: { [key: string]: any },
                private PrsmTableAttributeService: { new (name: string, defaultAttributes: any): { getAttributes: () => any } }) {

                this.candidatesLazyLoading = true;
                this.canLonglist = this.onLonglist !== undefined;
              }

  
  public setKpiKey (key: string): void {
    this.advancedQuery.kpiFilter[key] = !this.advancedQuery.kpiFilter[key];
    this.advancedQuery.basicQuery = this.query;
    this.$state.go("root.candidates", this.advancedQuery.collectSearchParams(), { reload: true, inherit: false });
  };

  public $onInit (): void {
    this.attributes = new this.PrsmTableAttributeService(
      "candidates-index-table-attributes",
      this.Candidate.defaultTableAttributes
    ).getAttributes();

    angular.extend(this, {
      advancedQuery: _.defaults(this.advancedQuery, { kpiFilter: {} }),
      steps: _.map(this.KPISteps, (value, key) => {
        return { key: key, value: value };
      }),
    });

    if (this.stateParams.saved_search_id) {
      this.loader = this.SavedSearch.results.bind(this.Candidate);
    } else {
      this.loader = this.Candidate.query.bind(this.Candidate);
    }

    this.makeCollectionProxy(this.loader, this.stateParams)
      .then( (candidates) => {
        angular.extend(this, {
          candidates: candidates,
          currentCandidate: candidates[0],
        });
      })
      .then( () => {
        this.candidatesLazyLoading = false;
      });
  };

  public isLonglisted (candidate): boolean {
    if (this.longlist !== undefined) {
      return _.any(this.longlist(), (c) => {
        return c.id == candidate.id;
      });
    } else {
      return false;
    }
  };

  public onLonglistClicked (candidate): void {
    this.onLonglist({ $candidate: candidate });
  };
}