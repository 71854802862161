//= require ./services.module.js
angular
  .module("services")

  .factory("FreeDateSerializer", [
    "railsSerializer",
    "DateSerializer",
    "freeDateOptions",
    function (railsSerializer, DateSerializer, freeDateOptions) {
      var dateSerializer = new DateSerializer();

      return railsSerializer(function () {
        this.deserialize = function (data) {
          if (data.option && _.isNumber(data.option.code)) {
            data.option = _.findWhere(freeDateOptions, { code: data.option.code });
          } else {
            data.option = undefined;
          }
          data.date = dateSerializer.deserialize(data.date);

          return data;
        };
        this.serialize = function (data) {
          data.date = dateSerializer.serialize(data.date);
          return data;
        };
      });
    },
  ]);
