export class ProjectsCandidateSearchCtrl {
  public foundProjects: any[] = [];
  public loading: boolean = false;
  private cancelers: ng.IDeferred<any>[] = [];
  public advancedQuery: any;
  public query: string;
  public remote: boolean;
  public candidate: any;
  public setData: () => (advancedQuery: any) => void;

  static $inject = ["$state", "$q", "ProjectsFactory", "Autocompletes"];
  constructor(
    private $state: angular.ui.IStateService,
    private $q: ng.IQService,
    private ProjectService: any,
    private Autocompletes: any
  ) {}

  public searchProjects(query) {
    if (query.length) {
      this.loading = true;
      _.invoke(this.cancelers, "resolve");
      this.cancelers.push(this.$q.defer());

      this.Autocompletes.autocomplete(query, "project_title", _.last(this.cancelers), [
        "project_status_active",
        "only_company_project",
      ]).then((results) => {
        this.foundProjects = _.map(results, (result) => {
          result.name = result.highlight[0];
          switch (result.resource_type) {
            case "project":
              return _.extend(new this.ProjectService(result.project), {
                resource_type: "Projekt",
                fn: (id, query) => {
                  this.advancedQuery.basicQuery = query;
                  this.$state.go("root.projects.show", this.advancedQuery.collectSearchParams({ project_id: id }), {
                    reload: true,
                    inherit: false,
                  });
                },
              });
            default:
              return _.extend(result, {
                resource_type: "Query",
                fn: function (id, query) {
                  this.search(query);
                },
              });
          }
        });
        this.loading = false;
      });
    } else {
      this.foundProjects = [];
    }
  }

  public search(query, routeFn) {
    this.advancedQuery.basicQuery = query;

    if (!this.remote) {
      this.$state.go("root.projects", this.advancedQuery.collectSearchParams(), { reload: true, inherit: false });
    } else {
      this.setData()(this.advancedQuery.collectSearchParams());
    }
  }

  public addProjectToCandidate(item) {
    if (!item || !item.id) {
      return;
    }

    this.$state.go("root.candidates.show.projects.show", { candidate_id: this.candidate.id, project_id: item.id });
    this.candidate.addToProject(item);
  }
}
