import DateRangeSelectorCtrl from "./date-range-selector.controller";
import dateRangeSelectorTemplate from "./date-range-selector.template.html";

angular.module("directives.advanced_search").component("dateRangeSelector", {
  bindings: {
    advancedQuery: "=",
    dateRangeSelectable: "=",
  },
  template: dateRangeSelectorTemplate,
  controller: DateRangeSelectorCtrl,
});
