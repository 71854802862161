import { initContract } from "@ts-rest/core";

import { TJobCategories } from "../job_categories";
import { TBaseResource } from "../../api-routing";

const c = initContract();

const JobCategoriesSchema = c.type<TBaseResource<TJobCategories[]>>();
export const jobCategoriesContract = c.router({
  query: {
    method: "GET",
    path: "/job_categories",
    responses: {
      200: JobCategoriesSchema,
    },
    summary: "Get all job categories",
  },
});
