export interface LocationDistance {
  value: number;
  label: string;
}

export const LOCATION_DISTANCES: LocationDistance[] = [
  { value: 5, label: "5km" },
  { value: 10, label: "10km" },
  { value: 20, label: "20km" },
  { value: 30, label: "30km" },
  { value: 40, label: "40km" },
  { value: 50, label: "50km" },
  { value: 60, label: "60km" },
  { value: 70, label: "70km" },
  { value: 100, label: "100km" },
  { value: 200, label: "200km" },
];
