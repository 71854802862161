import { StateTransitionsConfig } from "../../../services/state-transitions-config.factory";

export default class EmployersTableBodyCtrl {
  public state: StateTransitionsConfig;

  constructor() {}

  public $onInit() {
    angular.extend(this, {
      state: StateTransitionsConfig.state,
    });
  };
};
