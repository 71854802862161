export default class documentKpisCtrl {

  private oldModelDocumentsMeta: any;
  public steps: { title: string; description: string; value: number }[] = [];
  public model: any;
  static $inject = ["$filter", "$translate"];

  constructor(private $filter: ng.IFilterService, private $translate: angular.translate.ITranslateService) {}

  public setSteps(newValue) {
    if (!newValue) {
      return false;
    }

    this.steps = [
      { title: "D", description: this.$filter("translate")("ALL_DOCUMENTS"), value: newValue.documentCount },
      { title: "CV", description: this.$filter("translate")("CV_TYPES"), value: newValue.cvCount },
      {
        title: "EP",
        description: this.$filter("translate")("SENT_EXPERT_PROFILES"),
        value: newValue.expertProfileCount,
      },
    ];
  }

  public $onInit () {
    this.oldModelDocumentsMeta = this.model.documentsMeta;
    this.setSteps(this.oldModelDocumentsMeta);
  };

  public $doCheck () {
    var currentDocumentsMeta = this.model.documentsMeta;
    if (currentDocumentsMeta != this.oldModelDocumentsMeta) {
      this.setSteps(currentDocumentsMeta);
      this.oldModelDocumentsMeta = currentDocumentsMeta;
    }
  };
}