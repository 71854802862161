import { LOCATION_DISTANCES, LocationDistance } from "../../../../locations/values/location-distances.constant";

export default class LocationSearchCtrl {
  public advancedQuery: any;
  public locationSearchUniqID: string;
  public includeGeoflexUniqID: string;
  public locations: string[];
  public locationDistances: LocationDistance[];
  public autocompleteLocation: (searchQuery: string) => void;

  static $inject = ["LocationsFactory"];

  constructor (
    private Location: any,
  ) {
    this.locationSearchUniqID = _.uniqueId("locationSearchEnabled");
    this.includeGeoflexUniqID = _.uniqueId("geoflexEnabled");

    this.locations = [];
    this.locationDistances = LOCATION_DISTANCES;
    this.autocompleteLocation = (searchQuery) => {
      var deferred = this.Location.autocomplete(searchQuery);

      deferred.promise.then((locations) => {
        this.locations = _.pluck(locations, "displayName");
      });
    };
  }

  public $onInit() {
    if (_.isUndefined(this.advancedQuery)) {
      this.advancedQuery = { locationDistance: 20 };
    }
  };
};
