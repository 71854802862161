const vwsUsersTableBodyTemplate = require("./vws-users-table-body.template.html");

angular.module("users.components").component("vwsUsersTableBody", {
  bindings: {
    users: "=data",
    attributes: "=",
    activeRow: "=",
    statePrefix: "@",
    tableRowIdentifier: "=",
  },
  template: vwsUsersTableBodyTemplate,
});
