import FollowUpsCtrl from "./follow-ups.controller";
import followUpsTemplate from "./follow-ups.template.html";

angular.module("actions.components").component("followUps", {
  bindings: {
    object: "=",
    identifier: "@",
    disableNew: "@",
  },
  template: followUpsTemplate,
  controller: FollowUpsCtrl,
});
