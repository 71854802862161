import { initContract } from "@ts-rest/core";

import { TRegion } from "../regions";
import { TBaseResource } from "../../api-routing";

const c = initContract();
const RegionsSchema = c.type<TBaseResource<TRegion[]>>();
export const RegionsContract = c.router({
  query: {
    method: "GET",
    path: "/regions",
    responses: {
      200: RegionsSchema,
    },
    summary: "Get all regions",
  },
});
