import { QuicklistsEmployeesListCtrl } from "./quicklist-employees-list.controller";
import quicklistsEmployeesListTemplate from "./employees-list.template.html";

angular.module("quicklists.components").component("quicklistEmployeesList", {
  bindings: {
    quicklist: "<",
    quicklistEntriesLazyLoading: "<",
  },
  template: quicklistsEmployeesListTemplate,
  controller: QuicklistsEmployeesListCtrl,
});
