import "./../../quicklists/components/components.module";
import "./../../quicklists/components/quicklist-candidates-list/quicklist-candidates-list.component";
import "./../../quicklists/components/quicklist-candidates-search/quicklist-candidates-search.component";
import "./../../quicklists/components/quicklist-employees-list/quicklist-employees-list.component";
import "./../../quicklists/components/quicklist-employees-search/quicklist-employees-search.component";
import "./../../quicklists/components/quicklist-companies-list/quicklist-companies-list.component";
import "./../../quicklists/components/quicklist-companies-search/quicklist-companies-search.component";
import "./../../quicklists/components/quicklists-table-body/quicklists-table-body.component";
import "./../../quicklists/components/search-to-quicklist-button/search-to-quicklist-button.component";

angular.module("actions.components", []);
