import firstCallTemplateTemplate from  "./first-call-template.template.html";

angular.module("action_types.components").component("firstCallTemplate", {
  bindings: {
    object: "<",
    action: "<?",
    saveChanges: "<?",
    resolves: "<?",
  },
  template: firstCallTemplateTemplate,
});
