export class QuicklistsEmployeesListCtrl {
  static $inject = ["EmployeesFactory", "PrsmTableAttributeService"];

  constructor(private EmployeeService: any, private PrsmTableAttributeService: any) {}

  public employees_attributes: any;
  public quicklist: any;

  public $onInit() {
    var quicklist_entry_attributes = {
      deleteEntry: { show: !this.quicklist.isReadOnly() },
      kpis: { show: false },
    };

    this.employees_attributes = new this.PrsmTableAttributeService(
      "quicklist-employees-table-attributes",
      _.extend(
        _.omit(this.EmployeeService.defaultTableAttributes, "sales", "researcher", "processStatus"),
        quicklist_entry_attributes
      )
    ).getAttributes();
  }
}
