import ActionsHistoryCtrl from "../actions-history.controller";
import historyTableTemplate from "./history-table.template.html";

angular.module("actions.components").component("historyTable", {
  bindings: {
    object: "=",
    identifier: "@",
    disableNew: "@",
  },
  template: historyTableTemplate,
  controller: ActionsHistoryCtrl,
});
