import { ISCEService } from "angular";
import { TAddress } from "../../../locations/types/address";

export default class AddressBlockCtrl {
  public address: Partial<TAddress>;
  
  static $inject = ["$scope", "$sce"];

  constructor(
    private $scope: angular.IScope,
    private $sce: ISCEService
  ) { }

  public $onInit() {
    if (this.address) {
      this.address.$googleMapsUrl = this.googleMapsUrl("embed");
      this.address.$googleMapsUrlStatic = this.googleMapsUrl();

      this.$scope.$watch(
        () => {
          if (!this.address) {
            return undefined;
          }
          return this.address_query();
        },
        (nv, ov) => {
          if (_.isString(nv) && nv !== ov) {
            this.address.$googleMapsUrl = this.googleMapsUrl("embed");
            this.address.$googleMapsUrlStatic = this.googleMapsUrl();
          }
        }
      );
    }
  };

  public address_query() {
    var countryName = this.address.country ? this.address.country.name : null;

    return _.compact([this.address.street, this.address.zipCode, this.address.city + ",", countryName]).join(
      "%20"
    );
  };

  private googleMapsUrl(type?) {
    var url =
      type === "embed"
        ? "https://www.google.com/maps/embed/v1/place?key=AIzaSyCdK8ubWf8ccVZ4zUURNJwNsbSz8hv-DQ4&language=de&"
        : "https://maps.google.com/?";

    if (this.address.city || this.address.zipCode) {
      return this.$sce.trustAsResourceUrl(url + "zoom=5&q=" + this.address_query());
    } else {
      return null;
    }
  };
};