//= require ./services.module.js
angular
  .module("services")

  .factory("CollectionProxy", [
    function () {
      return function (arr, queryFunction, params) {
        arr.pickParams = function (params) {
          return _.pick(
            params,
            "q",
            "advanced",
            "sort",
            "additional_pages",
            "pages",
            "new",
            "my_active_projects",
            "my_employees",
            "company_id",
            "employee_id",
            "candidate_id",
            "project_id",
            "user",
            "user_id",
            "user_ids[]",
            "quicklist_id",
            "saved_search_id",
            "teams[]",
            "team_id",
            "all_active",
            "followUps",
            "date",
            "key",
            "limit"
          );
        };

        var searchParams = arr.pickParams(params);
        var page = parseInt(searchParams.pages || "1", 10);
        var loading = false;
        var loadingPromise;

        arr.isLoading = function () {
          return loading;
        };

        arr.isMoreAvailable = function () {
          return arr.meta.count < arr.meta.totalCount;
        };

        arr.sortBy = function (sortParams) {
          _.extend(searchParams, { sort: sortParams });

          return arr.search(searchParams);
        };

        arr.getMore = function (pages) {
          pages = pages || 1;
          var cleanParams = _.omit(searchParams, "additional_pages");
          var paramsWithPages = _.extend(cleanParams, { page: page, pages: pages });
          loading = true;
          return queryFunction(paramsWithPages).then(function (newRecords) {
            page++;
            arr.splice.apply(arr, _.flatten([arr.length, 0, newRecords]));

            arr.meta = _.extend(arr.meta || {}, newRecords.meta, { count: arr.length });
            loading = false;

            return arr;
          });
        };

        arr.search = function (params) {
          page = 0;
          searchParams = arr.pickParams(params);
          var pages = 1;
          var cleanParams = _.omit(searchParams, "additional_pages");
          var paramsWithPages = _.extend(cleanParams, { page: page, pages: pages });

          arr.splice(0, arr.length);
          arr.meta = {};

          if (loading && loadingPromise) {
            loadingPromise.abort();
          }

          loading = true;

          loadingPromise = queryFunction(paramsWithPages).then(
            function (newRecords) {
              page++;
              arr.splice.apply(arr, _.flatten([0, 0, newRecords]));

              arr.meta = _.extend(arr.meta || {}, newRecords.meta, { count: arr.length });
              loading = false;

              return arr;
            },
            function (abort) {
              if (abort.xhrStatus != "abort") {
                window.rollbar.error("CollectionProxy#search failed", { status: abort.config });
              }
            }
          );

          return loadingPromise;
        };

        return arr;
      };
    },
  ]);
