import { Priority } from "../../priorities/services/priority.service";
import { LeadershipExperience } from "../../leadership_experiences/services/leadership_experiences.service";
import { Language } from "../../languages/services/languages.service";
import { Country } from "../../countries/services/countries.service";

import { TransactionBasis } from "../../transaction_bases/services/transaction_bases.service";

angular
  .module("values.routing-options")

  .constant("projectOpts", {
    show: {
      resolve: {
        projectResponse: [
          "$transition$",
          "ProjectsFactory",
          function ($transition$, ProjectsFactory) {
            var params = _.pick($transition$.params(), _.identity);
            return ProjectsFactory.get(params.project_id);
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          componentProvider: [
            "projectResponse",
            function (project) {
              return project.initiativeApplicationProject ? "projectsIAShowView" : "projectsShowView";
            },
          ],
        };
        viewObj["deck-sidebar@" + state] = {
          component: "projectsMenuView",
        };
        return viewObj;
      },
    },
    new: {
      resolve: {
        projectResponse: [
          "$transition$",
          "ProjectsFactory",
          function ($transition$, ProjectsFactory) {
            var params = _.pick($transition$.params(), _.identity);
            return ProjectsFactory.$get("projects/new", {
              company_id: params.company_id,
              employee_id: params.employee_id,
            });
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          componentProvider: [
            "projectResponse",
            function (project) {
              return project.initiativeApplicationProject ? "projectsIAEditView" : "projectsEditView";
            },
          ],
        };
        viewObj["deck-sidebar@" + state] = {
          component: "projectsMenuView",
        };
        return viewObj;
      },
    },
    edit: {
      resolve: {
        projectResponse: [
          "$transition$",
          "ProjectsFactory",
          function ($transition$, ProjectsFactory) {
            var params = _.pick($transition$.params(), _.identity);
            return ProjectsFactory.get(params.project_id);
          },
        ],
        deregProjectDiff: [
          "$rootScope",
          "projectResponse",
          function ($rootScope, project) {
            return $rootScope.$on("showProjectDiff", function (event, projectDiffData) {
              project.snapshot();
              project.extendByDiff(projectDiffData);
            });
          },
        ],
      },
      views: function (state) {
        var viewObj = {};
        viewObj["deck-content@" + state] = {
          componentProvider: [
            "projectResponse",
            function (project) {
              return project.initiativeApplicationProject ? "projectsIAEditView" : "projectsEditView";
            },
          ],
        };
        viewObj["deck-sidebar@" + state] = {
          component: "projectsMenuView",
        };
        return viewObj;
      },
    },
    requirements: {
      priorities: [
        function () {
          return Priority.getAll();
        },
      ],
      leadershipExperiences: [
        function () {
          return LeadershipExperience.getAll();
        },
      ],
      transactionBasesResponse: [
        function () {
          return TransactionBasis.getAll();
        },
      ],
      // need to reference action_types (force loading before initialize SavedSearchesFactory)
      savedSearchesResponse: [
        "action_types",
        "SavedSearchesFactory",
        function (action_types, SavedSearchesFactory) {
          return SavedSearchesFactory.getAll();
        },
      ],
      languages: [
        function () {
          return Language.getAll();
        },
      ],
      countries: [
        function () {
          return Country.getAll();
        },
      ],
      objectType: [
        function () {
          return "project";
        },
      ],
      scrollspyScope: [
        function () {
          return _.uniqueId("scrollspy");
        },
      ],
      stateParams: [
        "$transition$",
        function ($transition$) {
          // remove undefined params
          return _.pick($transition$.params(), _.identity);
        },
      ],
    },
  });
