export default class WorkingRelationshipListCtrl {
  private person: any;
  private workingRelationshipsListAttributes: any;

  static $inject = [
    "WorkingRelationshipsFactory",
  ];

  constructor(private WorkingRelationship: any) {}

  public $onInit () {
    this.workingRelationshipsListAttributes = angular.copy(this.WorkingRelationship.defaultTableAttributes);

    var today = new Date();
    _.each(this.person.workingRelationships, function (workingRelationship) {
      const startDate = workingRelationship.startDate;
      const endDate = workingRelationship.endDate;
      const isCurrent = (!startDate || today >= startDate) && (!endDate || today <= endDate);
      const hasLeftTheCompany = endDate && today > endDate;

      if (isCurrent) {
        workingRelationship.$current = true;
      } else if (hasLeftTheCompany) {
        workingRelationship.$hasLeftTheCompany = true;
      }
    });
  };
}
