import { Money } from "../../../directives/money/services/money.service";

export class ResearchCommissionsFormCtrl {
  static $inject = ["ResearcherCommissionsFactory"];

  constructor(private ResearcherCommissionService: any) {}

  public deal: any;

  public $onInit() {
    if (this.deal.researcherCommissions && this.deal.researcherCommissions.length === 0) {
      this.addResearcherCommission();
    }
  }

  public addResearcherCommission() {
    const rCommission = new this.ResearcherCommissionService({ commission: new Money() });
    rCommission.commission.inCents = true;
    this.deal.researcherCommissions.push(rCommission);
  }
}
